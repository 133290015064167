import React, { useState, useEffect, useRef } from 'react'
import { questionsAndChoices, findQuestion, updateAnswers, nextRoute, paragraphs, medicationOptionsList, helpfulOptionsList, whatMedicationsList, otherIdTextbox, addSequence } from './constants'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Container, Row, Col, Button } from 'reactstrap'
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import { connect } from 'react-redux'
import Medication from './Medication'
import BiologicalRelatives from './BiologicalRelatives'
import MultipleInput from './MultipleInput';
import Helpful from './Helpful';
import CompleteSurvey from './CompleteSurvey';
import WhatMedication from './WhatMedication';
import service from '../../surveyInterceptor';
import { toast } from 'react-toastify'
import { useRadioGroup } from '@material-ui/core';

const ageFirstUsedLastUsed = []

function QuestionsById({
    questionID,
    cmrID,
    history,
    data,
    response_id,
    ...props    
}) {

    console.log(data,'basee')

    const { t } = useTranslation(); 

    
    const [questiosn] = useState([5])


    const [otherStatus, setOther] = useState(false)

    const [flag, setFlag] = useState(false)

    const [matrixSelectedIds, setMatrixSelectedState] = useState({})
    const [multipleChoiceAnswers, setMultipleChoiceAnswers] = useState([])
    const [singleChoiceAnswer, setSingleChoiceAnswer] = useState({})
    const [error, setError] = useState(false)
    const [multipleChoiceOthers, setMultipleChoiceOthers] = useState('')
    const [singleChoiceOthers, setSingleChoiceOthers] = useState('')
    const [questionDetails, setQuestionDetails] = useState()
    const [questionIndex, setQuestionIndex] = useState()
    const [noneError, setnoneError] = useState(false)
    const [currentQuestionNumber, setCurrentQuestionNumber] = useState()
    const [update, setUpdate] = useState(false)
    const [openEndedText, setOPenendedText] = useState('')
    const [multipleQuestionAnswer, setMultipleQuestionAnswer] = useState([])
    const [paragraphText, setParagraphText] = useState('')
    
    const [questionContent, setQuestionContent] = useState({questions: props.content.questions})    
    const [feet, setFeet] = useState()
    const [inches, setInches] = useState()
    const [medicationAnswers, setMedicationAnswers] = useState({})
    const [medicationListToShow, setMedicationListToShow] = useState([medicationOptionsList[0]])
    const [biologicalRelativesOptions, setBiologicalRelativesOptions] = useState([])
    const [multipleChoiceRadioAnswer, setMultipleChoiceRadioAnswer] = useState([])
    let [multipleInputAnswer, setMultipleInputAnswer] = useState({})
    const [helpfulOptions, setHelpfulOptions] = useState([helpfulOptionsList[0]])
    const [whatMedicationOptions, setWhatMedicationOptions] = useState([whatMedicationsList[0]])
    const [multipleChoiceInput, setMultipleChoiceInput] = useState({})
    const [errorMsg, setErrorMsg] = useState('')
    const isfirstrender = useRef()

    const setOpenEndedTextFunc = (e) => {
        if (e.target.value >= 0) {
            setOPenendedText(e.target.value)
        }
        else if (e.target.value == "") {
            setOPenendedText('')
        }
        else {
            alert("Invalid value")
        }
    }

    const multipleChoiceInputOnChange = (id, text) => {
        setMultipleChoiceInput({
            ...multipleChoiceInput,
            [id]: text
        })
    }
    
    const updateQuestionDetails = (question, previousAnswers, currentQuestionId) => {
        if(currentQuestionId == 5){
            const qustionNo4Answer = previousAnswers.questions?previousAnswers.questions.find(item => item.id == 4):{}
            if(qustionNo4Answer && qustionNo4Answer.answers){
                qustionNo4Answer.answers.forEach((item) => {
                    if(item.choice_id == 25){
                        question.fields.options.splice(question.fields.options.findIndex(item => item.id == 41), 1)
                    }
                })
            }
        }
        if(currentQuestionId == 113){
            const qustionNo8Answer = previousAnswers.questions?previousAnswers.questions.find(item => item.id == 8):{}
            if(qustionNo8Answer && qustionNo8Answer.answers){
                qustionNo8Answer.answers.forEach((item) => {
                    if(item.choice_id == 58){
                        question.fields.question_text = `How often did you have 5 or more alcohol-containing drinks within a few hours in the past month?`
                    }
                })
            }
        }
        if(currentQuestionId == 41){
            const qustionNo9Answer = previousAnswers.questions?previousAnswers.questions.find(item => item.id == 9):{}
            if(qustionNo9Answer && qustionNo9Answer.answers){
                qustionNo9Answer.answers.forEach((item) => {
                    if(item.choice_id == 61 || item.choice_id == 63){
                        question.fields.question_text = `I have a supportive friends / colleagues.`
                    }
                })
            }
        }
        if(currentQuestionId == 69){
            const idsToIndex = [
                {
                    id: [417, 418, 419],
                    indexToUpdate: 2
                },
                {
                    id: [420, 421, 422],
                    indexToUpdate: 3
                },
                {
                    id: [423, 424, 425],
                    indexToUpdate: 4
                },
                {
                    id: [426, 427, 428],
                    indexToUpdate: 5
                },
                {
                    id: [429, 430, 431],
                    indexToUpdate: 6
                },
            ]
            setBiologicalRelativesOptions(question.fields.options)
            const questionNo69Answer = previousAnswers.questions?previousAnswers.questions.find(item => item.id == 69):[]
            if(questionNo69Answer && questionNo69Answer.answers.length>0){
                questionNo69Answer.answers.forEach(answer => {
                    idsToIndex.forEach(idToIndex => {
                        if(idToIndex.id.some((id) => id == answer.choice_id)){
                            question.fields.options[idToIndex.indexToUpdate].showSubQuestion = true
                        }
                    })
                })
            }
        }
        if(currentQuestionId == 89){
            const questionNo89Answer = previousAnswers.questions?previousAnswers.questions.find(item => item.id == 3):[]
            let multipleChoiceAnswersCopy = []
            if(questionNo89Answer && questionNo89Answer.answers.length>0){
                questionNo89Answer.answers.forEach((answer => {
                    if(answer.choice_id == 8){
                        // question.fields.options[0].disabled = true
                        multipleChoiceAnswersCopy.push({
                            choice_id: question.fields.options[0].id
                        })
                    }
                    else if(answer.choice_id == 9){
                        // question.fields.options[1].disabled = true
                        multipleChoiceAnswersCopy.push({
                            choice_id: question.fields.options[1].id
                        })
                    }
                    else if(answer.choice_id == 10){
                        // question.fields.options[2].disabled = true
                        multipleChoiceAnswersCopy.push({
                            choice_id: question.fields.options[2].id
                        })
                    }
                    else if(answer.choice_id == 11){
                        // question.fields.options[3].disabled = true
                        multipleChoiceAnswersCopy.push({
                            choice_id: question.fields.options[3].id
                        })
                    }
                    else if(answer.choice_id == 12){
                        // question.fields.options[4].disabled = true
                        multipleChoiceAnswersCopy.push({
                            choice_id: question.fields.options[4].id
                        })
                    }
                    else if(answer.choice_id == 13){
                        // question.fields.options[5].disabled = true
                        multipleChoiceAnswersCopy.push({
                            choice_id: question.fields.options[5].id
                        })
                    }
                    else if(answer.choice_id == 14){
                        // question.fields.options[6].disabled = true
                        multipleChoiceAnswersCopy.push({
                            choice_id: question.fields.options[6].id
                        })
                    }
                    else if(answer.choice_id == 15){
                        // question.fields.options[7].disabled = true
                        multipleChoiceAnswersCopy.push({
                            choice_id: question.fields.options[7].id
                        })
                    }
                    else if(answer.choice_id == 16){
                        // question.fields.options[8].disabled = true
                        multipleChoiceAnswersCopy.push({
                            choice_id: question.fields.options[8].id
                        })
                    }
                    else if(answer.choice_id == 17){
                        // question.fields.options[9].disabled = true
                        multipleChoiceAnswersCopy.push({
                            choice_id: question.fields.options[9].id
                        })
                    }
                    else if(answer.choice_id == 18){
                        // question.fields.options[10].disabled = true
                        multipleChoiceAnswersCopy.push({
                            choice_id: question.fields.options[10].id
                        })
                    }
                    else if(answer.choice_id == 19){
                        // question.fields.options[11].disabled = true
                        multipleChoiceAnswersCopy.push({
                            choice_id: question.fields.options[11].id
                        })
                    }
                    else if(answer.choice_id == 20){
                        // question.fields.options[12].disabled = true
                        multipleChoiceAnswersCopy.push({
                            choice_id: question.fields.options[12].id
                        })
                    }
                    else if(answer.choice_id == 21){
                        // question.fields.options[13].disabled = true
                        multipleChoiceAnswersCopy.push({
                            choice_id: question.fields.options[13].id
                        })
                    }
                    else if(answer.choice_id == 22){
                        // question.fields.options[14].disabled = true
                        multipleChoiceAnswersCopy.push({
                            choice_id: question.fields.options[14].id
                        })
                    }
                    else if(answer.other_id == 23){
                        // question.fields.otherDisabled = true
                        multipleChoiceAnswersCopy.push({
                            other_id: 559
                        })
                    }
                }))
            }
            setMultipleChoiceAnswers(multipleChoiceAnswersCopy)
        }
        if(currentQuestionId == 90){
            const questionNo89Answer = previousAnswers.questions?previousAnswers.questions.find(item => item.id == 89):[]
            if(questionNo89Answer && questionNo89Answer.answers.length>0){
                const options = []
                questionNo89Answer.answers.forEach((answer => {
                    if(answer.choice_id == 543){
                        options.push(question.fields.options[0])
                    }
                    else if(answer.choice_id == 544){
                        options.push(question.fields.options[1])
                    }
                    else if(answer.choice_id == 545){
                        options.push(question.fields.options[2])
                    }
                    else if(answer.choice_id == 546){
                        options.push(question.fields.options[3])
                    }
                    else if(answer.choice_id == 547){
                        options.push(question.fields.options[4])
                    }
                    else if(answer.choice_id == 548){
                        options.push(question.fields.options[5])
                    }
                    else if(answer.choice_id == 549){
                        options.push(question.fields.options[6])
                    }
                    else if(answer.choice_id == 550){
                        options.push(question.fields.options[7])
                    }
                    else if(answer.choice_id == 551){
                        options.push(question.fields.options[8])
                    }
                    else if(answer.choice_id == 552){
                        options.push(question.fields.options[9])
                    }
                    else if(answer.choice_id == 553){
                        options.push(question.fields.options[10])
                    }
                    else if(answer.choice_id == 554){
                        options.push(question.fields.options[11])
                    }
                    else if(answer.choice_id == 555){
                        options.push(question.fields.options[12])
                    }
                    else if(answer.choice_id == 556){
                        options.push(question.fields.options[13])
                    }
                    else if(answer.choice_id == 557){
                        options.push(question.fields.options[14])
                    }
                    else if(answer.other_id == 559){
                        options.push(question.fields.options[15])
                    }

                }))
                question.fields.options = options
            }
        }
        if(currentQuestionId == 91){
            const questionNo89Answer = previousAnswers.questions?previousAnswers.questions.find(item => item.id == 89):[]
            if(questionNo89Answer && questionNo89Answer.answers.length>0){
                const options = []
                options.push()
                questionNo89Answer.answers.forEach((answer => {
                    if(answer.choice_id == 543){
                        options.push(question.fields.options[0])
                    }
                    else if(answer.choice_id == 544){
                        options.push(question.fields.options[1])
                    }
                    else if(answer.choice_id == 545){
                        options.push(question.fields.options[2])
                    }
                    else if(answer.choice_id == 546){
                        options.push(question.fields.options[3])
                    }
                    else if(answer.choice_id == 547){
                        options.push(question.fields.options[4])
                    }
                    else if(answer.choice_id == 548){
                        options.push(question.fields.options[5])
                    }
                    else if(answer.choice_id == 549){
                        options.push(question.fields.options[6])
                    }
                    else if(answer.choice_id == 550){
                        options.push(question.fields.options[7])
                    }
                    else if(answer.choice_id == 551){
                        options.push(question.fields.options[8])
                    }
                    else if(answer.choice_id == 552){
                        options.push(question.fields.options[9])
                    }
                    else if(answer.choice_id == 553){
                        options.push(question.fields.options[10])
                    }
                    else if(answer.choice_id == 554){
                        options.push(question.fields.options[11])
                    }
                    else if(answer.choice_id == 555){
                        options.push(question.fields.options[12])
                    }
                    else if(answer.choice_id == 556){
                        options.push(question.fields.options[13])
                    }
                    else if(answer.choice_id == 557){
                        options.push(question.fields.options[14])
                    }
                    // else if(answer.choice_id == 559){
                    //     options.push(question.fields.options[15])
                    // }
                }))
                options.push(question.fields.options[15])
                question.fields.options = options
            }
        }
        if(currentQuestionId == 92){
            const questionNo91Answer = previousAnswers.questions?previousAnswers.questions.find(item => item.id == 91):[]
            console.log(questionNo91Answer,'tesss')
            if(questionNo91Answer && questionNo91Answer.answers.length>0){
                const options = []
                questionNo91Answer.answers.forEach((answer => {
                    if(answer.choice_id == 594){
                        options.push(question.fields.options[0])
                    }
                    else if(answer.choice_id == 595){
                        options.push(question.fields.options[1])
                    }
                    else if(answer.choice_id == 596){
                        options.push(question.fields.options[2])
                    }
                    else if(answer.choice_id == 597){
                        options.push(question.fields.options[3])
                    }
                    else if(answer.choice_id == 598){
                        options.push(question.fields.options[4])
                    }
                    else if(answer.choice_id == 599){
                        options.push(question.fields.options[5])
                    }
                    else if(answer.choice_id == 600){
                        options.push(question.fields.options[6])
                    }
                    else if(answer.choice_id == 601){
                        options.push(question.fields.options[7])
                    }
                    else if(answer.choice_id == 602){
                        options.push(question.fields.options[8])
                    }
                    else if(answer.choice_id == 603){
                        options.push(question.fields.options[9])
                    }
                    else if(answer.choice_id == 604){
                        options.push(question.fields.options[10])
                    }
                    else if(answer.choice_id == 605){
                        options.push(question.fields.options[11])
                    }
                    else if(answer.choice_id == 606){
                        options.push(question.fields.options[12])
                    }
                    else if(answer.choice_id == 607){
                        options.push(question.fields.options[13])
                    }
                    else if(answer.choice_id == 608){
                        options.push(question.fields.options[14])
                    }
                    else if(answer.other_id == 609){
                        options.push(question.fields.options[15])
                    }
                }))
                question.fields.options = options
            }
        }
        if(currentQuestionId == 118){
            const questionNo117Answer = previousAnswers.questions?previousAnswers.questions.find(item => item.id == 117):[]
            if(questionNo117Answer && questionNo117Answer.answers.length>0){
                const options = []
                questionNo117Answer.answers.forEach((answer => {
                    if(answer.choice_id == 1469){
                        options.push(question.fields.options[0])
                    }
                    else if(answer.choice_id == 1470){
                        options.push(question.fields.options[1])
                    }
                    else if(answer.choice_id == 1471){
                        options.push(question.fields.options[2])
                    }
                    else if(answer.choice_id == 1472){
                        options.push(question.fields.options[3])
                    }
                    else if(answer.choice_id == 1473){
                        options.push(question.fields.options[4])
                    }
                    else if(answer.choice_id == 1474){
                        options.push(question.fields.options[5])
                    }
                    else if(answer.choice_id == 1475){
                        options.push(question.fields.options[6])
                    }
                    else if(answer.choice_id == 1476){
                        options.push(question.fields.options[7])
                    }
                    else if(answer.choice_id == 1477){
                        options.push(question.fields.options[8])
                    }
                    else if(answer.choice_id == 1478){
                        options.push(question.fields.options[9])
                    }
                    else if(answer.choice_id == 1479){
                        options.push(question.fields.options[10])
                    }
                    else if(answer.choice_id == 1480){
                        options.push(question.fields.options[11])
                    }
                    else if(answer.choice_id == 1481){
                        options.push(question.fields.options[12])
                    }
                    else if(answer.choice_id == 1482){
                        options.push(question.fields.options[13])
                    }
                    else if(answer.choice_id == 1483){
                        // options.push(question.fields.options[14])
                    }
                }))
                question.fields.options = options
            }
        }
        setQuestionDetails({ ...question })
        prefillValues(question)
    }

    const prefillValues = (questionDetails) => {
        let index = questionContent.questions?questionContent.questions.findIndex(item => item.id == questionID):-1
        if(index!=-1){
            setUpdate(true)
            setCurrentQuestionNumber(index+1)
            if(questionDetails.fields.question_type == 'matrix'){
                if(questionDetails.fields.choices){
                    let answers = {}
                    if(questionDetails.fields.noneText){
                        if(questionContent.questions[index].answers[0].choice_id == questionDetails.fields.noneChoiceId){
                            answers[questionDetails.fields.noneText] = questionContent.questions[index].answers[0]
                        }
                    }
                    questionDetails.fields.choices.forEach((data, i) => {
                        questionContent.questions[index].answers.forEach((item) => {
                            if(data.row_id == item.row_id){
                                answers[data.choice_text] = item
                            }
                            if(data.other_id){
                                setMatrixOthers(data.text)
                            }
                        })
                    })
                    setMatrixSelectedState(answers)
                }
                else{
                    setMatrixSelectedState({col: questionContent.questions[index].answers[0]})
                    setMatrixOthers(questionContent.questions[index].answers[0].text)
                }
            }
            else if(questionDetails.fields.question_type === 'multiple_choice'){
                // setMultipleChoiceAnswers(questionContent.questions[index].answers)
                const multipleChoiceAnswersCopy = []
                const optionIds = {}
                questionDetails.fields.options.forEach(item => {
                    optionIds[item.id] = true
                    if(item.other_id){
                        optionIds[item.other_id] = true
                        setMultipleChoiceOthers(item.text)
                    }
                })
                questionContent.questions[index].answers.forEach(item => {
                    if(optionIds[item.choice_id]){
                        multipleChoiceAnswersCopy.push(item)
                    }
                })
                setMultipleChoiceAnswers(multipleChoiceAnswersCopy)
            }
            else if(questionDetails.fields.question_type === 'multiple_questions'){
                setMultipleQuestionAnswer(questionContent.questions[index].answers)
            }
            else if(questionDetails.fields.question_type === 'single_choice'){
                setSingleChoiceOthers(questionContent.questions[index].answers[0].text)
                setSingleChoiceAnswer(questionContent.questions[index].answers[0])
                if(questionContent.questions[index].answers[1]){
                    setSingleChoiceOthers(questionContent.questions[index].answers[1].text)
                }
            }
            else if(questionDetails.fields.question_type === 'multiple_input' || questionID == 124){
                console.log(questionDetails)
                const answers = questionContent.questions[index].answers
                const multipleInputAnswerCopy = {}
                answers.forEach(({other_id, choice_id, text}) => {
                    if(questionID == 90 || questionID == 92){
                        let checkIdExist = false
                        questionDetails.fields.options.forEach((options) => {
                            if(checkIdExist) return;
                            options.options.forEach(option => {
                                if(option.id == other_id){
                                    checkIdExist = true
                                }
                            })
                        })
                        if(checkIdExist)
                            multipleInputAnswerCopy[other_id] = text
                    }
                    else{
                        multipleInputAnswerCopy[choice_id] = text
                    }
                })
                console.log('helloo')
                setMultipleInputAnswer(multipleInputAnswerCopy)
            }
            else if(questionID == 61){
                const answers = questionContent.questions[index].answers
                const medicationAnswersCopy = {}
                answers.forEach(({choice_id, text}) => {
                    medicationAnswersCopy[choice_id] = text
                })
                setMedicationAnswers(medicationAnswersCopy)
                setMedicationListToShow(medicationOptionsList.slice(0, answers.length/3))
            }
            else if(questionID == 124){
                const answers = questionContent.questions[index].answers
                setWhatMedicationOptions(whatMedicationsList.slice(0, answers.length))
            }
            else if(questionID == 125){
                const answers = questionContent.questions[index].answers
                const helpfulAnswerCopy = {}
                const radioIds = [
                    {
                        "id": "1536",
                        "index": 0
                    },
                    {
                        "id": "1537",
                        "index": 0
                    },
                    {
                        "id": "1538",
                        "index": 0
                    },
                    {
                        "id": "1539",
                        "index": 1
                    },
                    {
                        "id": "1540",
                        "index": 1
                    },
                    {
                        "id": "1541",
                        "index": 1
                    },
                    {
                        "id": "1542",
                        "index": 2
                    },
                    {
                        "id": "1543",
                        "index": 2
                    },
                    {
                        "id": "1544",
                        "index": 2
                    },
                    {
                        "id": "1545",
                        "index": 3
                    },
                    {
                        "id": "1546",
                        "index": 3
                    },
                    {
                        "id": "1547",
                        "index": 3
                    },
                    {
                        "id": "1548",
                        "index": 4
                    },
                    {
                        "id": "1549",
                        "index": 4
                    },
                    {
                        "id": "1550",
                        "index": 4
                    }
                ]
                const multipleChoiceRadioAnswerCopy = []
                answers.forEach(({choice_id, text}) => {
                    if(text){
                        helpfulAnswerCopy[choice_id] = text
                    } else{
                        radioIds.forEach(({id, index}) => {
                            if(id == choice_id){
                                multipleChoiceRadioAnswerCopy[index] = {
                                    choice_id: id
                                }
                            }
                        })
                    }
                })
                // const helpfulAnswerCopyLength = helpfulAnswerCopy.length
                setHelpfulOptions(helpfulOptionsList.slice(0, (answers.length - 1)/2+1))
                setMultipleChoiceInput(helpfulAnswerCopy)
                setMultipleChoiceRadioAnswer(multipleChoiceRadioAnswerCopy)
                // setMedicationAnswers(helpfulAnswerCopy)
            }
            else if(questionID == 58){
                const answers = questionContent.questions[index].answers
                answers.forEach(({choice_id, text}) => {
                    if(choice_id == 339){
                        setFeet(text)
                    }
                    if(choice_id == 340){
                        setInches(text)
                    }
                })
            }
            else if(questionID == 69){
                const answers = questionContent.questions[index].answers
                // const radioIds = [417, 418, 419, 420, 421, 422, 423, 424, 425, 426, 427, 428, 429, 430, 431]
                const radioIds = [
                    // {
                    //     id: 415,
                    //     index: 0
                    // },
                    // {
                    //     id: 416,
                    //     index: 1
                    // },
                    {
                        "id": 417,
                        "index": 2
                    },
                    {
                        "id": 418,
                        "index": 2
                    },
                    {
                        "id": 419,
                        "index": 2
                    },
                    {
                        "id": 420,
                        "index": 3
                    },
                    {
                        "id": 421,
                        "index": 3
                    },
                    {
                        "id": 422,
                        "index": 3
                    },
                    {
                        "id": 423,
                        "index": 4
                    },
                    {
                        "id": 424,
                        "index": 4
                    },
                    {
                        "id": 425,
                        "index": 4
                    },
                    {
                        "id": 426,
                        "index": 5
                    },
                    {
                        "id": 427,
                        "index": 5
                    },
                    {
                        "id": 428,
                        "index": 5
                    },
                    {
                        "id": 429,
                        "index": 6
                    },
                    {
                        "id": 430,
                        "index": 6
                    },
                    {
                        "id": 431,
                        "index": 6
                    }
                ]
                const multipleChoiceRadioAnswerCopy = []
                const multipleChoiceAnswersCopy = []
                answers.forEach((answer) => {
                    let radioIdPresent = false
                    radioIds.forEach(({id, index}) => {
                        if(id == answer.choice_id){
                            multipleChoiceRadioAnswerCopy[index] = {
                                choice_id: id
                            }
                            radioIdPresent = true
                        }
                    })
                    if(!radioIdPresent){
                        multipleChoiceAnswersCopy.push(answer)
                    }
                })
                setMultipleChoiceAnswers(multipleChoiceAnswersCopy)
                setMultipleChoiceRadioAnswer(multipleChoiceRadioAnswerCopy)
            }
            else{
                setOPenendedText(questionContent.questions[index].answers[0].text)
            }
        }
        else if(questionContent.questions){
            for(let i=questionContent.questions.length-1; i>=0; i--){
                const {currentQuestionIndex} = findQuestion(questionsAndChoices, questionContent.questions[i].id)
                if(questionsAndChoices[currentQuestionIndex].pk < questionDetails.pk){
                    if(i == questionContent.questions.length-1)
                        setCurrentQuestionNumber(i+2)
                    else
                        setCurrentQuestionNumber(i+2)
                    break;
                }
            }
        }
    }

    useEffect(() => {
        const allquestions = JSON.parse(JSON.stringify(questionsAndChoices))
        const { question, currentQuestionIndex } = findQuestion(allquestions, questionID)
        setQuestionIndex(currentQuestionIndex)
        updateQuestionDetails(question, questionContent, questionID)
        if (questionID == 549097412) {
            getParagraphDetails(question)
        }
        return (() => {
            setMatrixSelectedState({})
        })
    }, [questionID])

    const multipleChoiceAnswersOnChange = (checkboxStatus, choice_id) => {
        let multipleChoiceAnswersCopy = [...multipleChoiceAnswers]
        if (checkboxStatus) {
            if(choice_id == 4 && questionID == 2){
                questionDetails.fields.otherOPtionId = 5
                questionDetails.fields.otherOptionText = `say more, if you'd like`
                multipleChoiceAnswersCopy.push({
                    other_id: 5
                })
                setQuestionDetails({ ...questionDetails })
            }
            if (questionDetails.fields.noneId == choice_id) {
                multipleChoiceAnswersCopy = [{
                    choice_id: choice_id
                }]
            }
            else if (choice_id == questionDetails.fields.otherOPtionId) {
                if (otherIdTextbox.some(item => item.question_id == questionID)) {
                    let textboxObject = otherIdTextbox.find(item => item.question_id == questionID)
                    multipleChoiceAnswersCopy.push(
                        {
                            choice_id: choice_id
                        },
                        {
                            other_id: textboxObject.other_id
                        })
                } else {
                    multipleChoiceAnswersCopy.push({
                        other_id: choice_id
                    })
                }
                if (questionDetails.fields.noneId) {
                    const noneIndex = multipleChoiceAnswersCopy.findIndex(item => item.choice_id == questionDetails.fields.noneId)
                    if (noneIndex != -1)
                        multipleChoiceAnswersCopy.splice(noneIndex, 1)
                }
            }
            else {
                multipleChoiceAnswersCopy.push({
                    choice_id: choice_id
                })
                if (questionDetails.fields.noneId) {
                    const noneIndex = multipleChoiceAnswersCopy.findIndex(item => item.choice_id == questionDetails.fields.noneId)
                    if (noneIndex != -1)
                        multipleChoiceAnswersCopy.splice(noneIndex, 1)
                }
            }
        }
        else {
            if(choice_id == 4 && questionID == 2){
                questionDetails.fields.otherOPtionId = ''
                questionDetails.fields.otherOptionText = ``
                setQuestionDetails({ ...questionDetails })
                const indexToDelete = multipleChoiceAnswersCopy.findIndex(item => item.choice_id == 5)
                if(indexToDelete != -1)
                    multipleChoiceAnswersCopy.splice(indexToDelete, 1)
                // console.log({questionDetails})
            }
            if (choice_id == questionDetails.fields.otherOPtionId) {

                if (otherIdTextbox.some(item => item.question_id == questionID)) {
                    let textboxObject = otherIdTextbox.find(item => item.question_id == questionID)

                    multipleChoiceAnswersCopy.splice(multipleChoiceAnswersCopy.findIndex(item => item.choice_id == choice_id), 1)
                    multipleChoiceAnswersCopy.splice(multipleChoiceAnswersCopy.findIndex(item => item.other_id == textboxObject.other_id), 1)

                } else {
                    multipleChoiceAnswersCopy.splice(multipleChoiceAnswersCopy.findIndex(item => item.other_id == choice_id), 1)
                }
            }
            else {
                multipleChoiceAnswersCopy.splice(multipleChoiceAnswersCopy.findIndex(item => item.choice_id == choice_id), 1)
            }
        }
        setMultipleChoiceAnswers(multipleChoiceAnswersCopy)
    }

    const multipleChoiceRadioAnswersOnChange = (choice_id, index) => {
        const multipleChoiceRadioAnswerCopy = [...multipleChoiceRadioAnswer]
        multipleChoiceRadioAnswerCopy[index] = {choice_id}
        setMultipleChoiceRadioAnswer(multipleChoiceRadioAnswerCopy)
    }

    const singleChoiceAnswerOnChange = (id) => {
        if (id == questionDetails.fields.otherOPtionId) {
            setSingleChoiceAnswer({ other_id: id })
        }
        else {
            setSingleChoiceOthers('')
            setSingleChoiceAnswer({ choice_id: id })
        }
    }

    useEffect(() => {
        if(isfirstrender && !isfirstrender.current){
            saveProgress(questionContent);
        }
        else {
            isfirstrender.current = false
        }
    }, [questionContent])


    const saveProgress = (updatedQuestionContent) => {
        let content = addSequence(updatedQuestionContent)
        let obj = {
            response_id: response_id,
            ...content
        };
        if(localStorage.getItem('surveyType') == 'corporate'){
            service.post(`/public/surveys/${cmrID}/submit`, obj, {
                survey_type: 'CURRENT'
            }).then(res => {
                // toast.success(t('Survey Progress has been saved.'));
            });     
        }
        else{
            service.post(`/surveys/${cmrID}/submit`, obj, {
                survey_type: 'CURRENT'
            }).then(res => {
                // toast.success(t('Survey Progress has been saved.'));
            });
        }
    };
    
    const nextPage = () => {

        let questionContentCopy = JSON.parse(JSON.stringify(questionContent));
        // saveProgress(questionContentCopy);

        if (questionID == 61) {
            if (Object.keys(medicationAnswers).length === medicationListToShow.length * 3) {
                setError(false);
                setErrorMsg('');
                const answer = [];
                for (const key in medicationAnswers) {
                    answer.push({
                        choice_id: key,
                        text: medicationAnswers[key]
                    });
                }
                nextRoute(questionContentCopy, questionID, history, answer, questionIndex);
                updateAnswers(questionContentCopy, currentQuestionNumber, {
                    id: questionID,
                    answers: answer
                }, update);
                setQuestionContent(questionContentCopy);
                setOPenendedText('');
            } else {
                setError(true);
            }
        }
        // ALCOHOL
        else if (questionID == 58) {
            if (feet !== undefined && inches !== undefined) {
                if (feet < 1 || feet > 9) {
                    setError(true);
                    setErrorMsg('Feet should be in the range of 1-9');
                    return;
                } else if (inches < 0 || inches > 11) {
                    setError(true);
                    setErrorMsg('Inches should be in the range of 0-11');
                    return;
                }
                setError(false);
                setErrorMsg('');
                const answer = [
                    {
                        choice_id: 339,
                        text: feet
                    },
                    {
                        choice_id: 340,
                        text: inches
                    }
                ];
                nextRoute(questionContentCopy, questionID, history, answer, questionIndex);
                updateAnswers(questionContentCopy, currentQuestionNumber, {
                    id: questionID,
                    answers: answer
                }, update);
                setQuestionContent(questionContentCopy);
                setOPenendedText('');
            } else {
                setError(true);
            }
        } else if (questionDetails.fields.question_type === 'multiple_choice' || questionID == 69 || questionID == 125) {
            if (multipleChoiceAnswers.length > 0 || multipleChoiceRadioAnswer.length > 0) {
                setError(false);
                setnoneError(false);
                setErrorMsg('');
                if (questionDetails.fields.otherOptionText) {
                    let otherIndex;
                    if (otherIdTextbox.some(item => item.question_id == questionID)) {
                        let textboxObject = otherIdTextbox.find(item => item.question_id == questionID);
                        otherIndex = multipleChoiceAnswers.findIndex(item => item.other_id == textboxObject.other_id);
                    } else {
                        otherIndex = multipleChoiceAnswers.findIndex(item => item.other_id == questionDetails.fields.otherOPtionId);
                    }
                    if (otherIndex != -1) {
                        multipleChoiceAnswers[otherIndex].text = multipleChoiceOthers;
                    }
                }
                const multipleChoiceRadioAnswerCopy = multipleChoiceRadioAnswer.filter(Boolean);
                const multipleChoiceInputAnswer = [];
                Object.keys(multipleChoiceInput).forEach(id => {
                    multipleChoiceInputAnswer.push({
                        choice_id: id,
                        text: multipleChoiceInput[id]
                    });
                });
                let multipleChoiceCopy = [...multipleChoiceAnswers, ...multipleChoiceRadioAnswerCopy, ...multipleChoiceInputAnswer];
                nextRoute(questionContentCopy, questionID, history, multipleChoiceCopy, questionIndex);
                setMultipleChoiceAnswers([]);
                setMultipleChoiceRadioAnswer([]);
                setMultipleChoiceInput({});
                setMultipleChoiceOthers('');
                updateAnswers(questionContentCopy, currentQuestionNumber, {
                    id: questionID,
                    answers: multipleChoiceCopy
                }, update);
                setQuestionContent(questionContentCopy);
            } else {
                setError(true);
            }
        } else if (questionID == 124) {
            for (const key in multipleInputAnswer) {
                if (!multipleInputAnswer[key]) {
                    delete multipleInputAnswer[key];
                }
            }
            if (Object.keys(multipleInputAnswer).length == whatMedicationOptions.length) {
                setError(false);
                setErrorMsg('');
                const answer = [];
                for (const key in multipleInputAnswer) {
                    answer.push({
                        choice_id: key,
                        text: multipleInputAnswer[key]
                    });
                }
                nextRoute(questionContentCopy, questionID, history, answer, questionIndex);
                updateAnswers(questionContentCopy, currentQuestionNumber, {
                    id: questionID,
                    answers: answer
                }, update);
                setMultipleInputAnswer({});
                setQuestionContent(questionContentCopy);
                setOPenendedText('');
            } else {
                setError(true);
            }
        } else if (questionDetails.fields.question_type === 'multiple_input') {
            let isAgeGreaterThan100 = false;
            for (const key in multipleInputAnswer) {
                if (!multipleInputAnswer[key]) {
                    delete multipleInputAnswer[key];
                } else if ((questionID == 92 || questionID == 90) && multipleInputAnswer[key] > 100) {
                    isAgeGreaterThan100 = true;
                }
            }
            const ageFirstUsedLastUsedCopy = ageFirstUsedLastUsed.filter(Boolean);
            if (!isAgeGreaterThan100 && Object.keys(multipleInputAnswer).length == questionDetails.fields.options.length * questionDetails.fields.noOfSubInputs) {
                for (const age of ageFirstUsedLastUsedCopy) {
                    if (age) {
                        if (age[1] < age[0]) {
                            setError(true);
                            setErrorMsg('Age first used should be smaller than age last used');
                            return;
                        }
                    }
                }
                setError(false);
                setErrorMsg('');
                const answer = [];
                if (questionDetails.fields.replaceOther) {
                    for (const key in multipleInputAnswer) {
                        answer.push({
                            other_id: key,
                            text: multipleInputAnswer[key]
                        });
                    }
                } else {
                    for (const key in multipleInputAnswer) {
                        answer.push({
                            choice_id: key,
                            text: multipleInputAnswer[key]
                        });
                    }

                }
                setMultipleInputAnswer({});
                setQuestionContent(questionContentCopy);
                setOPenendedText('');
                nextRoute(questionContentCopy, questionID, history, answer, questionIndex);
                updateAnswers(questionContentCopy, currentQuestionNumber, {
                    id: questionID,
                    answers: answer
                }, update);
            } else {
                if (isAgeGreaterThan100) {
                    setErrorMsg('age should not be more than 100');
                }
                setError(true);
            }
        } else if (questionDetails.fields.question_type === 'single_choice') {
            if (Object.keys(singleChoiceAnswer).length > 0) {
                if (questionID == 59 && singleChoiceOthers) {
                    if (singleChoiceOthers < 10 || singleChoiceOthers > 999) {
                        setError(true);
                        setErrorMsg('Weight should be between 10 and 999 pounds');
                        return;
                    }
                }
                if (singleChoiceAnswer.other_id) {
                    if (singleChoiceOthers) {
                        setError(false);
                        setErrorMsg('');
                        const singleChoiceAnswerCopy = { ...singleChoiceAnswer };
                        singleChoiceAnswerCopy.text = singleChoiceOthers;
                        singleChoiceNext(singleChoiceAnswerCopy);
                        setSingleChoiceAnswer({});
                        setSingleChoiceOthers('');
                    } else {
                        setError(true);
                    }
                } else {
                    setError(false);
                    setErrorMsg('');
                    singleChoiceNext({ ...singleChoiceAnswer });
                    setSingleChoiceAnswer({});
                    setSingleChoiceOthers('');
                }
            } else {
                setError(true);
            }
        } else {
            if (openEndedText && !(questionID == 7 && (openEndedText > 100 || openEndedText < 10))) {
                setError(false);
                setErrorMsg('');
                nextRoute(questionContentCopy, questionID, history, [{ text: openEndedText }], questionIndex);
                updateAnswers(questionContentCopy, currentQuestionNumber, {
                    id: questionID,
                    answers: [{ text: openEndedText }]
                }, update);
                setQuestionContent(questionContentCopy);
                setOPenendedText('');
                
            } else {
                if (questionID == 7 && openEndedText > 100) {
                    setErrorMsg('age should not be more than 100');
                } else {
                    if (questionID == 7 && openEndedText < 10) {
                        setErrorMsg('age should not be less than 10');
                    }
                }
                setError(true);
            }
        }
    };
    

    const singleChoiceNext = (singleChoiceAnswerCopy) => {
        singleChoiceAnswerCopy = [singleChoiceAnswerCopy]
        if (questionDetails.fields.hideRadio) {
            if (singleChoiceOthers) {
                singleChoiceAnswerCopy.push({
                    other_id: questionDetails.fields.otherOPtionId,
                    text: singleChoiceOthers
                })
            }
        }
        if (questionID == 549097351) {
            singleChoiceAnswerCopy[0].row_id = questionDetails.fields.row_id
        }
        let questionContentCopy = JSON.parse(JSON.stringify(questionContent))
        setUpdate(false)
        nextRoute(questionContentCopy, questionID, history, singleChoiceAnswerCopy, questionIndex)
        setSingleChoiceOthers('')
        updateAnswers(questionContentCopy, currentQuestionNumber, {
            id: questionID,
            answers: singleChoiceAnswerCopy
        }, update)
        // props.dispatch({
        //     type: `UPDATE_CONTENT`,
        //     payload: questionContentCopy
        // })
        setQuestionContent(questionContentCopy)
    }

    // useEffect(() => {
    //     setQuestionContent(props.content.questions)
    // }, [])

    const prevPage = () => {
        setParagraphText('')
        setError(false)
        setErrorMsg('')
        setMultipleInputAnswer({})
        const sequenceNo = questionDetails.fields.sequenceId
        let previousSequence=0, previousSequenceId
        questionContent.questions && questionContent.questions.forEach(item => {
            const sort_order = item.sort_order
            if(sort_order<sequenceNo && sort_order>previousSequence){
                previousSequence = sort_order
                previousSequenceId = item.id
            }
        })
        history.push(`/questions/${previousSequenceId}`)
        // let index = questionContent.questions ? questionContent.questions.findIndex(item => item.id == questionID) : -1
        // if (index == -1) {
        //     history.push(`/questions/${questionContent.questions[questionContent.questions.length - 1].id}`)

        // }
        // else {
        //     history.push(`/questions/${questionContent.questions[index - 1].id}`)
        // }
    }

      // Function to copy the CMR ID to the clipboard
    const copyToClipboard = () => {
        const cmrId = localStorage.getItem('surveyType') === 'corporate' ? localStorage.getItem('cmrLabelCorporate') : '';
        if (cmrId) {
            navigator.clipboard.writeText(cmrId).then(() => {
                toast.success('CMR ID Copied to clipboard')
            }, (err) => {
                console.error('Failed to copy text: ', err);
            });
        }
    }

    const setSingleChoiceOthersText = (e) => {
        if(questionID == 59){
            if((e.target.value.slice(-1) == '.' || parseInt(e.target.value) != e.target.value) && e.target.value != ''){
                return;
            }
            singleChoiceAnswerOnChange(341)
        }
        setSingleChoiceOthers(e.target.value)
    }

    console.log({multipleInputAnswer})

    const allowedQuestionIds = [3, 4, 5, 10, 11, 12, 13, 24, 26, 55, 69, 143, 68, 71, 89, 91, 105, 117, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155, 156, 107, 169, 170, 171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 192, 108, 181, 182, 183, 184, 185, 186, 187, 188, 189, 190, 191, 192, 118, 119, 120, 121];

    return (
        <Container>
            <div className='cmr-label'>{localStorage.getItem('surveyType') == 'corporate' &&  <span    style={{ cursor: 'pointer' }} onClick={copyToClipboard} title="Click here to copy CMR ID">CMR ID : {localStorage.getItem('cmrLabelCorporate')}</span>}</div>
            {questionDetails ? <div>
                <div className="p-2">
                    {questionDetails.fields.bannerText && <h4 style={{marginBottom: '.5rem'}}>{questionDetails.fields.bannerText}</h4>}
                    <p dangerouslySetInnerHTML={{ __html: `${t(questionDetails.fields.question_text)}` }}></p>
                    {questionDetails.fields.question_type === 'multiple_choice' && <div className="bg-white container mb-3" style={{ borderRadius: '.125rem' }}>
                        <Row className={otherStatus ? "top" : "bottom"}>
                            {questionDetails.fields.options.map((item, i) => <React.Fragment>
                            {item && !item.isSubQuestion && !item.type && <Col style={{height:'35px'}} md={12} sm={12}       className={
                                allowedQuestionIds.includes(parseInt(questionDetails.fields.question_id, 10))
                                    ? "bottom-spacing"
                                    : ""
                                }
                            xs={questionDetails.fields.rowSize ? 12 / questionDetails.fields.rowSize : 12} key={i}>
                                {!item.hide && <FormControlLabel
                                        style={{marginBottom:'5px'}}
                                    control={<Checkbox name={item.text} color="primary" choice_id={item.id}  question_id={questionID} onChange={(e) => multipleChoiceAnswersOnChange(e.target.checked, item.id)} checked={multipleChoiceAnswers.find(data => data.choice_id == item.id) ? true : false} disabled={item.disabled} />}
                                    label={questionDetails.fields.question_id === "549097340" ? <div style={{ display: 'flex', paddingTop: '.75rem' }}><p style={{ fontSize: '.875rem', fontFamily: 'sans-serif', color: 'black' }}><b>{t(item.text.split(':')[0])} {item.id == 3622509768 ? '' : ':'}</b></p><p style={{ paddingLeft: '.625rem', fontSize: '.875rem', paddingTop: '.125rem' }}>{t(item.text.split(':')[1])}</p></div> : t(item.text)}
                                />}
                            </Col>}
                            {item && item.isSubQuestion && <React.Fragment>
                                <p style={{ marginTop: questionDetails.fields.question_id == 70 && '8px'}} className='nested-question sub-question' >{t(item.question)}</p>
                                {item.options.map(subOption => (
                                    // <Row>
                                        <Col xs={12}>
                                            <FormControlLabel
                                                style={{marginBottom:'5px'}}
                                            control={<Radio name={subOption.text} color="primary" onChange={(e) => multipleChoiceRadioAnswersOnChange(subOption.id, i)} checked={multipleChoiceRadioAnswer[i] ? subOption.id == multipleChoiceRadioAnswer[i].choice_id: false} />}
                                            label={t(subOption.text)}
                                            />
                                        </Col>
                                    // </Row>
                                ))}
                            </React.Fragment>}
                                {item && item.type == 'input' && <Col style={{
                                display: (questionDetails.fields.question_id == 71 && 'flex'),
                                alignItems: (questionDetails.fields.question_id == 71 && 'center')
                                }} xs={questionDetails.fields.rowSize ? 12 / questionDetails.fields.rowSize : 4} key={i}>
                                <FormControlLabel style={{textWrap: (questionDetails.fields.question_id == 71 && 'nowrap')}}
                                    control={<Checkbox name={item.text} value={item.id} onChange={(e) => multipleChoiceAnswersOnChange(e.target.checked, item.id)} checked={multipleChoiceAnswers.find(data => data.choice_id == item.id) ? true : false} color="primary" />}
                                    label={t(item.text)}
                                />
                                <TextField style={{ paddingBottom: '2%' }} name={item.text} type="text" placeholder={`${t('Type your answer...')}`} value={multipleChoiceInput[item.id]} onChange={e => multipleChoiceInputOnChange(item.id, e.target.value)} />
                            </Col>}
                            </React.Fragment>)}
                        </Row>
                        {questionDetails.fields.otherOptionText && <div style={{
                                display: (
                                    (questionDetails.fields.question_id != 2 && 'flex') ||
                                    (questionDetails.fields.question_id == 2 && 'flex')
                                ),
                                alignItems: (
                                    (questionDetails.fields.question_id != 2 && 'self-start') ||
                                    (questionDetails.fields.question_id == 2 && 'center')
                                )
                            }}>
                            <FormControlLabel
                            style={{marginLeft: questionDetails.fields.question_id == 2 && '1px',marginBottom:'5px'}}
                                control={<Checkbox style={{display: questionDetails.fields.question_id == 2 && 'none'}} disabled={questionDetails.fields.otherDisabled} name={questionDetails.fields.otherOptionText} value={questionDetails.fields.otherOPtionId} onChange={(e) => multipleChoiceAnswersOnChange(e.target.checked, questionDetails.fields.otherOPtionId)} checked={multipleChoiceAnswers.find(item => (item.other_id == questionDetails.fields.otherOPtionId || item.choice_id == questionDetails.fields.otherOPtionId)) ? true : false} color="primary" />}
                                label={t(questionDetails.fields.otherOptionText)}
                            />
                            <br />
                            <TextField style={{ paddingBottom: '2%' }} name={questionDetails.fields.otherOptionText} type="text" placeholder={`${t('Type your answer...')}`} value={multipleChoiceOthers} onChange={e => setMultipleChoiceOthers(e.target.value)} />
                        </div>}
                    </div>}
                    {questionDetails.fields.question_type === 'single_choice' && <div className="bg-white container mb-3" style={{ borderRadius: '.125rem' }}>
                        {paragraphText && t(paragraphText)}
                        <Row style={{alignItems: 'center'}} className='radio-button-container slider-question'>
                           <span className='start-text'>{questionDetails.fields.startText && questionDetails.fields.startText}</span>
                           {questionDetails.fields.options.map((item, i) => <Col style={{height:'35px'}} className={
                                allowedQuestionIds.includes(parseInt(questionDetails.fields.question_id, 10))
                                    ? "radio-buttons bottom-spacing"
                                    : "radio-buttons"
                                } xs={questionDetails.fields.rowSize ? 12 / questionDetails.fields.rowSize : 12} key={i}>
                                <FormControlLabel
                                    style={{marginBottom:'5px'}}
                                    control={<Radio name={item.text} color="primary" onChange={(e) => singleChoiceAnswerOnChange(item.id)} checked={singleChoiceAnswer.choice_id == item.id} value={singleChoiceOthers} />}
                                    label={t(item.text)}
                                />
                            </Col>)}
                            <span className='end-text'>{questionDetails.fields.endText && questionDetails.fields.endText}</span>
                        </Row>
                        {questionDetails.fields.containsOther && <div style={{
                                display: (questionDetails.fields.question_id == 59 && 'flex') || (questionDetails.fields.question_id == 10 && 'flex') || (questionDetails.fields.question_id == 119 && 'flex'),
                                gap: questionDetails.fields.question_id == 59 && '10px',
                                flexDirection: questionDetails.fields.question_id == 59 && 'row-reverse',
                                alignItems: (
                                    (questionDetails.fields.question_id == 10 && 'flex-start') ||
                                    (questionDetails.fields.question_id == 119 && 'self-start')
                                ),
                                justifyContent: questionDetails.fields.question_id == 59 && 'flex-end',
                                marginLeft: questionDetails.fields.question_id == 59 && '10px',
                                position: (questionDetails.fields.question_id == 13 && 'relative') ||
                                            (questionDetails.fields.question_id == 10 && 'relative') ||
                                            (questionDetails.fields.question_id == 134 && 'relative') ||
                                            (questionDetails.fields.question_id == 119 && 'relative'),
                                left: (questionDetails.fields.question_id == 13 && '11px') ||
                                        (questionDetails.fields.question_id == 10 && '12px') ||
                                        (questionDetails.fields.question_id == 134 && '12px') ||
                                        (questionDetails.fields.question_id == 119 && '12px'),
                                bottom: (questionDetails.fields.question_id == 13 && '14px') ||
                                        (questionDetails.fields.question_id == 10 && '13px') ||
                                        (questionDetails.fields.question_id == 134 && '13px') ||
                                        (questionDetails.fields.question_id == 119 && '13px')
                                }}>
                                {console.log({questionDetails})}
                            {!questionDetails.fields.hideRadio ? <FormControlLabel
                            style={{marginBottom:'5px'}}
                                control={<Radio style={{ display: questionDetails.fields.question_id == 59 && 'none' }} name={questionDetails.fields.otherOptionText} onChange={() => singleChoiceAnswerOnChange(questionDetails.fields.otherOPtionId)} color="primary" checked={singleChoiceAnswer.other_id == questionDetails.fields.otherOPtionId} />}
                                label={t(questionDetails.fields.otherOptionText)}
                            /> : <InputLabel className="inoutLable" style={{ padding: '.625rem 0 .3125rem 0', fontSize: '.75rem' }}>{t(questionDetails.fields.otherOptionText)}</InputLabel>}
                            {!questionDetails.fields.hideRadio && <br />}
                            <TextField className="noRadioText" style={{ paddingBottom: '2%' }} name={questionDetails.fields.otherOptionText} type="text" placeholder={`${t('Type your answer...')}`} value={singleChoiceOthers} onChange={e => setSingleChoiceOthersText(e)} />
                        </div>}
                    </div>}
                    {questionDetails.fields.question_type == 'open_ended' && <div className="bg-white container mb-3" style={{ borderRadius: '.125rem' }}>
                        <div className="spineer-input age-container" style={{ display: "flex" }}>
                            <TextField style={{ width: '12.5rem' }} type="number" InputProps={{
                                inputProps: {
                                    max: 100, min: 1
                                }
                            }} placeholder={`${t('Type your answer...')}`} value={openEndedText} onChange={e => setOpenEndedTextFunc(e)} />
                            {/* <div style={{ fontSize: '.875rem', color: '#055ce5', marginLeft: '.625rem' }}>(1, 2, 3, etc)</div> */}
                        </div>
                    </div>
                    }
                    {questionID == 124 && <WhatMedication setListToShow={setWhatMedicationOptions} listToShow={whatMedicationOptions} multipleInputAnswer={multipleInputAnswer} setMultipleInputAnswer={setMultipleInputAnswer} />}
                    {questionID == 58 && <div className="bg-white container mb-3" style={{ borderRadius: '.125rem' }}>
                        <div className="spineer-input age-container" style={{ display: "flex", alignItems: 'end', paddingBottom: '2%' }}>
                            <TextField style={{ width: '7.5rem' }} type="number" InputProps={{
                                inputProps: {
                                    max: 100, min: 1
                                }
                            }} placeholder={`${t('feet')}`} value={feet} onChange={e => setFeet(e.target.value)} />
                            <div style={{ marginLeft: '.625rem', marginRight: '1rem', color: '#055ce5', fontSize: '0.875rem' }}>feet</div>
                            <TextField style={{ width: '7.5rem' }} type="number" InputProps={{
                                inputProps: {
                                    max: 11, min: 0
                                }
                            }} placeholder={`${t('inches')}`} value={inches} onChange={e => setInches(e.target.value)} />
                            <div style={{ marginLeft: '.625rem', color: '#055ce5', fontSize: '0.875rem' }}>inches</div>
                        </div>
                    </div>}
                    {questionID == 61 && <Medication questionID={questionID} history={history} answers={medicationAnswers} setAnswers={setMedicationAnswers} listToShow={medicationListToShow} setListToShow={setMedicationListToShow} />}
                    {questionID == 69 && <BiologicalRelatives options={biologicalRelativesOptions} setOptions={setBiologicalRelativesOptions} multipleChoiceAnswersOnChange={multipleChoiceAnswersOnChange} multipleChoiceAnswers={multipleChoiceAnswers} multipleChoiceRadioAnswer={multipleChoiceRadioAnswer} setMultipleChoiceRadioAnswer={setMultipleChoiceRadioAnswer} />}
                    {questionID == 125 && <Helpful options={helpfulOptions} setOptions={setHelpfulOptions} multipleChoiceInput={multipleChoiceInput} setMultipleChoiceInput={setMultipleChoiceInput} multipleChoiceRadioAnswer={multipleChoiceRadioAnswer} setMultipleChoiceRadioAnswer={setMultipleChoiceRadioAnswer} />}
                    {questionDetails.fields.question_type == 'multiple_input' && <MultipleInput options={questionDetails.fields.options} multipleInputAnswer={multipleInputAnswer} setMultipleInputAnswer={setMultipleInputAnswer} questionID={questionID} ageFirstUsedLastUsed={ageFirstUsedLastUsed} />}
                    {questionID == 'completion' && <CompleteSurvey questionContent={questionContent} prevPage={prevPage} />}
                    {questionID != 'completion' && <div className="bg-white container mb-3" style={{ borderRadius: '.125rem' }}>
                        {error && !errorMsg && <p style={{ color: 'red', padding: '.625rem 0' }}>{flag && questionID == '553981634' ? `${t('Pick a different answer for at least ONE time period.')}` : questionDetails.fields.question_type === 'matrix' && questionID != '549097354' ? `${t('Please choose ONE answer per row')}` : questionDetails.fields.question_type === 'matrix' && questionID == '549097354' ? `${t('Please answer the question.')}` : questionDetails.fields.otherOPtionId == '3654004081' ? `${t('Please list medication or check a different box.')}` : `${t('Please answer the question.')}`}<br /></p>}
                        {noneError && <p style={{ color: 'red', padding: '.625rem 0' }}>{t('Unselect none to select other options')}<br /></p>}
                        {error && errorMsg && <p style={{ color: 'red', padding: '.625rem 0' }}>{errorMsg}</p>}
                        <div className='px-2 py-3'>
                            {/* <p style={{ fontSize: '.75rem', lineHeight: '1.17', color: '#afafaf', marginBottom: '0', paddingTop: '.625rem' }}>{t('Powered by')}<img src={process.env.LOGO_URI} style={{ marginLeft: '.625rem' }} className="footer-img-logo" /></p> */}
                            <div className="button-footer-wrapper">
                                <Button style={{ backgroundColor: 'transparent', color: '#5e6265', border: 'none' }} onClick={prevPage} type='button' >{t('Previous')}</Button>
                                <Button style={{ marginLeft: '.625rem', backgroundColor: '#055ce5', color: 'white', borderRadius: '.125rem', border: 'none' }} onClick={nextPage} type='button' >{t('Next')}</Button>
                            </div>
                        </div>
                    </div>}
                </div>
            </div> : null}
        </Container>
    )
}

const mapStateToProps = state => {
    return {
        cmrID: state.question.cmrID,
        response_id: state.question.response_id,
        content: state.question.content
    }
}

export default connect(mapStateToProps)(QuestionsById)
