import React, { useState,useEffect } from 'react'
import { Container, Row, Col, Label, Button } from 'reactstrap'
import MaterialInput from '../../helperComponents/MaterialInput'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import compris from '../../assests/images/001 Compris TM clr.png'
import { connect } from 'react-redux'
import NProgress from 'nprogress'
import { Formik , Field, FieldArray} from 'formik'
import { updateAnswers } from './constants'
import { useTranslation } from 'react-i18next';
import CustomModal from '../../helperComponents/modal'
import men from '../../assests/images/surveyAssests/Men.png'
import women from '../../assests/images/surveyAssests/women.png'

import tran from '../../assests/images/surveyAssests/transgender.png'
import othergender from '../../assests/images/surveyAssests/othergender.png'

import nonbinary from '../../assests/images/surveyAssests/nonbinary.png'


function Page1({
    questionContent,
    history,
    ...props
}) {

    const [error, setError] = useState(false)

    const { t } = useTranslation()

    const nextPage = (values) => {
        if(values.gender && values.age && values.preferToBeCalled ){
            setError(false)
            let questionContentCopy = JSON.parse(JSON.stringify(questionContent))
            updateAnswers(questionContentCopy, 1, {
                id: 549097446,
                answers: [
                    {choice_id: values.gender}
                ]
            }, questionContentCopy.questions.findIndex(item => item.id == 549097446)!=-1?true:false )
            updateAnswers(questionContentCopy, 2, {
                    id: 549097380,
                    answers: [
                        {text: values.age}
                    ]
            }, questionContentCopy.questions.findIndex(item => item.id == 549097380)!=-1?true:false)
            updateAnswers(questionContentCopy, 3, {
                    id: 549097429,
                    answers: [
                        {choice_id: values.preferToBeCalled}
                    ]
            }, questionContentCopy.questions.findIndex(item => item.id == 549097429)!=-1?true:false)
            props.dispatch({
                type: `UPDATE_CONTENT`,
                payload: questionContentCopy
            })
            history.push(`/surveyQuestions/page2`)
        }
        else{
            setError(true)
        }
    }
                
    function isInputNumber(evt){
                
        var ch = String.fromCharCode(evt.which);
        
        if(!(/[0-9]/.test(ch))){
            evt.preventDefault();
        }
    }

    const [ageError, setAgeError] = useState(false)

    function checkAge(age){
        if(age.length > 2){
            return true
        }
        else if(age.length == 1){
            return true
        }
        return false
    }
    

    return (
        <div>
            <Container>
            <Formik
                initialValues={{
                    gender: questionContent.questions?questionContent.questions.findIndex(item => item.id == 549097446)!=-1?questionContent.questions[questionContent.questions.findIndex(item => item.id == 549097446)].answers[0].choice_id:'':'',
                    age: questionContent.questions?questionContent.questions.findIndex(item => item.id == 549097380)!=-1?questionContent.questions[questionContent.questions.findIndex(item => item.id == 549097380)].answers[0].text:'':'',
                    preferToBeCalled: questionContent.questions?questionContent.questions.findIndex(item => item.id == 549097429)!=-1?questionContent.questions[questionContent.questions.findIndex(item => item.id == 549097429)].answers[0].choice_id:'':''
                }}
                onSubmit={(values) => {
                    if(checkAge(values.age)){
                        setAgeError(true)
                    }
                    else
                    {
                        nextPage(values)
                        setAgeError(false)
                    }
                }}  
            >{({
                handleSubmit,
                values,
                resetForm,
                setFieldValue,
            }) => (
                <form autoComplete="false" onSubmit={handleSubmit}>
                <div className="bg-white container mb-3" style={{borderRadius: '2px'}}>
                    {/* <div className="p-2" style={{display: 'flex', justifyContent: 'space-between'}}>
                        <div style={{fontSize: '12px', lineHeight: '1.25', backgroundColor: '#eeeeee'}} className='p-2'>Questions 1-3</div>
                        <div style={{fontSize: '12px', lineHeight: '1.25'}} className='p-2'>Fields marked with are mandatory</div>
                    </div> */}
                    <div className="p-2">
                        <p>1. {t('Age')}</p>
                        <Field  onkeypress={isInputNumber} component={MaterialInput} name="age" type="text"/>
                        { ageError ? <div className="error">{t('Invalid age')}</div> : "" }
                    </div>
                </div>
                <div className="bg-white container mb-3" style={{borderRadius: '2px'}}>
                    <div className='p-2'>
                        <div className="pb-3">2. {t('Gender')}</div>
                        <Row style={{padding: '0 15px'}} className='pb-2 gender-wrapper'>
                            <Col style={{border: 'solid 1px #b9b9b9', borderRadius: '5px',marginRight: '10px', justifyContent: 'center', alignItems: 'center', cursor: 'pointer'}} className={values.gender == '3622508533'?'border-active':''} onClick={()=>setFieldValue('gender', 3622508533, true)} >
                                <span className="image-Ui"><img src={men}/></span>
                                <div>{t('male')}</div>
                            </Col>
                            <Col style={{border: 'solid 1px #b9b9b9', borderRadius: '5px',marginRight: '10px', justifyContent: 'center', alignItems: 'center', cursor: 'pointer'}} className={values.gender == '3622508534'?'border-active':''} onClick={()=>setFieldValue('gender', 3622508534, true)} >
                                <span className="image-Ui"><img src={women}/></span>
                                <div>{t('female')}</div>
                            </Col>
                            <Col style={{border: 'solid 1px #b9b9b9', borderRadius: '5px',marginRight: '10px', justifyContent: 'center', alignItems: 'center', cursor: 'pointer'}} className={values.gender == '3622508535'?'border-active':''} onClick={()=>setFieldValue('gender', 3622508535, true)} >
                                <span className="image-Ui"><img src={tran}/></span>
                                <div>{t('transgender')}</div>
                            </Col>
                            <Col style={{border: 'solid 1px #b9b9b9', borderRadius: '5px',marginRight: '10px', justifyContent: 'center', alignItems: 'center', cursor: 'pointer'}} className={values.gender == '3622508540'?'border-active':''} onClick={()=>setFieldValue('gender', 3622508540, true)} >
                                <span className="image-Ui"><img src={nonbinary}/></span>
                                <div>{t('non-binary')}</div>
                            </Col>
                            <Col style={{border: 'solid 1px #b9b9b9', borderRadius: '5px',marginRight: '10px', justifyContent: 'center', alignItems: 'center', cursor: 'pointer'}} className={values.gender == '3622508536'?'border-active':''} onClick={()=>setFieldValue('gender', 3622508536, true)} >
                                <span className="image-Ui"><img src={othergender}/></span>
                                <div>{t('other')}</div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="bg-white container mb-3" style={{borderRadius: '2px'}}>
                    <div className="p-2">
                        <p>3. {t('Pronoun')}</p>
                        <RadioGroup row aria-label="position" name="position" onChange={e => setFieldValue('preferToBeCalled', e.target.value, true)}>
                            <FormControlLabel
                                value='3622508265'
                                control={<Radio color="primary" checked={values.preferToBeCalled == 3622508265  ?true:false} />}
                                label={t('he, him, his')}
                            /><br />
                            <FormControlLabel
                                value='3622508266'
                                control={<Radio color="primary" checked={values.preferToBeCalled == 3622508266?true:false} />}
                                label={t('she, her, hers')}
                            /><br />
                            <FormControlLabel
                                value='3622508267'
                                control={<Radio color="primary" checked={values.preferToBeCalled == 3622508267?true:false} />}
                                label={t('they, them, theirs')}
                            />
                        </RadioGroup>
                    </div>
                </div>
                <div className="bg-white container mb-3" style={{borderRadius: '2px'}}>
                    {error && <p style={{color: 'red',padding:'10px 0'}}>{t('Please answer all the questions')}<br /></p>}
                    <div className='px-2 py-3' style={{display: 'flex', justifyContent: 'space-between'}}>
                        <p style={{fontSize: '12px', lineHeight: '1.17', color: '#afafaf',marginBottom:'0',paddingTop:'10px'}}>{t('Powered by')} <a href="https://www.compriscare.com/" target="_blank"><img src={process.env.LOGO_URI} style={{marginLeft: '10px'}} className="footer-img-logo" /></a></p>
                        <div className="button-footer-wrapper">
                            <Button style={{backgroundColor: 'transparent', color: '#5e6265', border: 'none'}} disabled >{t('Previous')}</Button>
                            <Button style = {{marginLeft: '10px', backgroundColor: '#055ce5', color: 'white', borderRadius: '2px', border: 'none'}} type="submit" >{t('Next')}</Button>
                        </div>
                    </div>
                </div>
                </form>
            )}
            </Formik>
            </Container>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        questionContent : state.question.content,
    }
}

export default connect(mapStateToProps)(Page1)
