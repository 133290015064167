import service from '../../interceptor'
import { PROFILE , CLEAR_REDUCER , INDEX_CHANGE } from '../type'
import { APIS_URL} from '../../utils/apiEndPoints'


export const clearReducer = () => {
    return {
        type: CLEAR_REDUCER,
     
    }
}

export const navChange = (data) => {
    return {
        type: INDEX_CHANGE,
        payload : data
    }
}

export const getProfile = () => dispatch => {
    return new Promise( (resolve, reject) => {
        service.get(APIS_URL.PROFILE).then( (res) => {
            dispatch({
                type : PROFILE,
                payload : res.content.profile
            })
            resolve(res)
        })
    })
}