import React, { useState, useEffect } from 'react'
import { Container } from 'reactstrap'
import '../../styles/_question-sidebar.scss'
import user from '../../assests/images/surveyAssests/user.svg'
import Slider from "react-slick";
import addiction from '../../assests/images/sidebar/addiction.svg'
import LangDropdown from '../../helperComponents/langDropdown'
import health from '../../assests/images/sidebar/health.svg'
import others from '../../assests/images/sidebar/others.png'
import { useTranslation } from 'react-i18next';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import service from '../../surveyInterceptor'
import { connect } from 'react-redux'
import { toast } from 'react-toastify' 
import { questionsAndChoices, findQuestion, nextRoute } from '../QuestionsLong/constants'

const totalGeneralQuestions = 26
const totalAddictionQuestions = 28
const totalDeaddictionQuestions = 12
const totalOtherquestions = 14

function Sidebar({
    questionContent,
    cmrID,
    questionID,
    updateProgressTo100,
    ...props
}) {

    const settings = {
        dots: true,   
        infinite: true,
        cssEase: "linear",
        autoplaySpeed: 7000,
        nav : false,
        autoplay : true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: false,
        prevArrow: false
    };

    const { t, i18n } = useTranslation()


    const [progress, setProgress] = useState()
    const [generalQuestionsCompleted, setGeneralQuestionsCompleted] = useState(0)
    const [addictionQuestionsCompleted, setAddictionQuestionsCompleted] = useState(0)
    const [deaddictionQuestionsCompleted, setDeaddictionQuestionsCompleted] = useState(0)
    const [othersQuestionsCompleted, setOthersQuestionsCompleted] = useState(0)
    const [activeCategory, setActiveCategory] = useState(1)

    useEffect(() => {
        const totalQuestions = questionsAndChoices.length -1
        if(questionID == 'page1'){
            setProgress(0)
        }
        else if(questionID == 'page2'){
            setProgress((3/totalQuestions * 100).toFixed(0))
            setGeneralQuestionsCompleted(3)
        }
        else if(questionID == 'page3'){
            setProgress((6/totalQuestions* 100).toFixed(0))
            setGeneralQuestionsCompleted(6)
        }
        else if(questionID == 'page4'){
            setProgress((10/totalQuestions* 100).toFixed(0))
            setGeneralQuestionsCompleted(10)
        }
        else if (questionID == 549097381){
            setGeneralQuestionsCompleted(9)
        }   
        else if(questionID == 'completion'){
            setProgress((67/totalQuestions* 100).toFixed(0))
            setGeneralQuestionsCompleted(24)
            setAddictionQuestionsCompleted(23)
            setDeaddictionQuestionsCompleted(12)
            setOthersQuestionsCompleted(14)
            setActiveCategory(4)
        }
        else{

            const {question, currentQuestionIndex} = questionContent && questionContent.questions?findQuestion(questionsAndChoices, questionContent.questions[questionContent.questions.length-1].id):0
            
            const currentActiveQuestion = findQuestion(questionsAndChoices, questionID)

            setProgress((currentQuestionIndex/totalQuestions* 100).toFixed(0))

            if(currentQuestionIndex && currentQuestionIndex<=26){
                setGeneralQuestionsCompleted(currentQuestionIndex)
            }
            else if(currentQuestionIndex && currentQuestionIndex <= 54){
                setGeneralQuestionsCompleted(26)
                setAddictionQuestionsCompleted(currentQuestionIndex-26)
            }
            else if(currentQuestionIndex && currentQuestionIndex <= 66){
                setGeneralQuestionsCompleted(26)
                setAddictionQuestionsCompleted(28)
                setDeaddictionQuestionsCompleted(currentQuestionIndex-54)
            }
            else{
                setGeneralQuestionsCompleted(26)
                setAddictionQuestionsCompleted(28)
                setDeaddictionQuestionsCompleted(12)
                setOthersQuestionsCompleted(currentQuestionIndex-66)
            }
            if(currentActiveQuestion && currentActiveQuestion.currentQuestionIndex<=26){
                setActiveCategory(1)
            }
            else if(currentActiveQuestion && currentActiveQuestion.currentQuestionIndex <= 53){
                setActiveCategory(2)
            }
            else if(currentActiveQuestion && currentActiveQuestion.currentQuestionIndex <= 64){
                setActiveCategory(3)
            }
            else{
                setActiveCategory(4)
            }
        }
        if(questionID == 'completion'){
            setGeneralQuestionsCompleted(26)
            setAddictionQuestionsCompleted(28)
            setDeaddictionQuestionsCompleted(12)
            setOthersQuestionsCompleted(14)
            setProgress(100)
        }
    }, [questionID, updateProgressTo100])
    
    const saveProgress = () => {
        if(i18n.language == 'en'){
            service.defaults.headers.post['Language-ISO'] = 'en'
        }
        else
        {
            service.defaults.headers.post['Language-ISO'] = 'es'
        }
        if(localStorage.getItem('surveyType') == 'public'){
            let cmrId = localStorage.getItem('cmrLabelPublic')
            service.post(`/public/surveys/${cmrId}/submit`, questionContent,{
                survey_type : 'CURRENT'
            }).then(res =>{
                toast.success(t('Survey Progress has been saved.'))
            })
        }
        else if(localStorage.getItem('surveyType') == 'corporate'){
            let cmrId = localStorage.getItem('cmrLabelCorporate')
            service.post(`/public/surveys/${cmrId}/submit`, questionContent,{
                survey_type : 'CURRENT'
            }).then(res =>{
                toast.success(t('Survey Progress has been saved.'))
            })
        }
        else {
            service.post(`surveys/${cmrID}/submit`, questionContent,{
                survey_type : 'CURRENT'
            }).then(res =>{
                toast.success(t('Survey Progress has been saved.'))
            })
        }
    }

    return (
        <div className="survey-sidebar" style={{backgroundColor: '#ffffff'}}>
            <div>
                <Container style={{paddingTop: '20px', paddingBottom: '0'}}>
                    <div style={{textAlign:"center"}}>
                    <div className="language-dropdown-onboarding survey">
                        <LangDropdown/>
                    </div>
                        <h3 style={{textAlign: 'center'}}>{t('Assessment progress')}</h3>
                        {
                            <CircularProgressbar value={progress} text={`${progress}%`} />
                        }
                    </div>
                    <div>
                        <div className={`space-between ${activeCategory == 1?'active-category':''}`}>
                            <p>
                                <img className="img-size" src={user} className="margin-right-10px" />
                                {t('General')}
                            </p>
                            <div className="active-rectangle">{generalQuestionsCompleted}/{totalGeneralQuestions}</div>
                        </div>
                        <div className={`space-between ${activeCategory == 2?'active-category':''}`}>
                            <p>
                                <img className="img-size" src={addiction} className="margin-right-10px" />
                                {t('Substances')}
                            </p>
                            <div className="rectangle">{addictionQuestionsCompleted}/{totalAddictionQuestions}</div>
                        </div>
                        <div className={`space-between ${activeCategory == 3?'active-category':''}`}>
                            <p>
                                <img className="img-size"  className="margin-right-10px" src={health} />
                                {/* <span className="heart margin-right-10px"></span> */}
                                {t('History')} 
                            </p>
                            <div className="rectangle">{deaddictionQuestionsCompleted}/{totalDeaddictionQuestions}</div>
                        </div>

                        <div className={`space-between ${activeCategory == 4?'active-category':''}`}>
                            <p>
                                <img className="img-size"  className="margin-right-10px" src={others} />
                                {t('Support')} 
                            </p>
                            <div className="rectangle">{othersQuestionsCompleted}/{totalOtherquestions}</div>
                        </div>
                    </div>
                </Container>
            </div>  
            <Container style={{margin: '3% 0',textAlign:'center'}}>
                {
                    localStorage.getItem('surveyType') == 'corporate' &&
                    <div className="cmr-text">
                        <span title={t('The CMR is your unique ID and will be needed if you return to finish your assessment. This code will be on your report')} style={{color:'rgb(5, 92, 229)'}}>{t('Write down your CMR')}: { localStorage.getItem('cmrLabelCorporate')}</span>
                        <p>{t('Save this CMR for future references')}</p>
                    </div>
                }
                <button className="save-progress-button" onClick={saveProgress} >{t('Save Progress')}</button>
            </Container>
            <Container style={{ paddingBottom: '10px'}}>
            <Slider className="login-laptop" {...settings}>
                <div className="review-slider">
                    <p style={{fontSize: '14px', lineHeight: '1.29', color: '#d1cece',marginBottom:'0'}}>{t('What people say about us?')}</p>
                    <div style={{display: 'flex'}}>
                        <div style={{fontFamily: 'EngraversMT', fontSize: '52px', lineHeight: '1.17', color: '#055ce5'}}>"</div>
                        <div style={{fontSize: '13px', lineHeight: '1.29', paddingTop: '10px', paddingLeft: '10px'}}>{t('The questions reminded me of how much my substance use has affected my life.')}.</div>
                    </div>
                    <p style={{fontSize: '12px', lineHeight: '1.29', color: '#d1cece', textAlign: 'right',paddingTop:'10px'}}>- {t('Anonymous')}</p>
                </div>
                <div className="review-slider">
                    <p style={{fontSize: '14px', lineHeight: '1.29', color: '#d1cece',marginBottom:'0'}}>{t('What people say about us?')}</p>
                    <div style={{display: 'flex'}}>
                        <div style={{fontFamily: 'EngraversMT', fontSize: '52px', lineHeight: '1.17', color: '#055ce5'}}>"</div>
                        <div style={{fontSize: '13px', lineHeight: '1.29', paddingTop: '10px', paddingLeft: '10px'}}>{t('It was easy to fill out because it was mostly check marks.')}.</div>
                    </div>
                    <p style={{fontSize: '12px', lineHeight: '1.29', color: '#d1cece', textAlign: 'right',paddingTop:'10px'}}>- {t('Anonymous')}</p>
                </div>
                <div className="review-slider">
                    <p style={{fontSize: '14px', lineHeight: '1.29', color: '#d1cece',marginBottom:'0'}}>{t('What people say about us?')}</p>
                    <div style={{display: 'flex'}}>
                        <div style={{fontFamily: 'EngraversMT', fontSize: '52px', lineHeight: '1.17', color: '#055ce5'}}>"</div>
                        <div style={{fontSize: '13px', lineHeight: '1.29', paddingTop: '10px', paddingLeft: '10px'}}>{t('This will help me understand my illness and what to do about it.')}.</div>
                    </div>
                    <p style={{fontSize: '12px', lineHeight: '1.29', color: '#d1cece', textAlign: 'right',paddingTop:'10px'}}>- {t('Anonymous')}</p>
                </div>
            </Slider>
            </Container>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        questionContent : state.question.content,
        cmrID: state.question.cmrID
    }
}

export default connect(mapStateToProps)(Sidebar)
