import React, { useEffect, useState } from 'react';
import {Button,Label} from 'reactstrap'
import Slider from "react-slick";
import CustomModal from '../../helperComponents/modal'
import { toast } from 'react-toastify';
import laptop from '../../assests/images/laptop.png';
import loginlogo from '../../assests/images/loginLogo.png'
import { Formik , Field} from 'formik'
import Language from '../../helperComponents/langDropdown'
import { useTranslation } from 'react-i18next'; 
import {APIS_URL} from '../../utils/apiEndPoints'
import service from '../../interceptor'
import Timer from './timer'
import { Link } from 'react-router-dom'
import CustomInput from '../../helperComponents/Input'
import {loginValidation} from './validation'
import BottomContent from './bottomContent'




const Login = ({
    history, 
    ...props
}) => { 


    const { t, i18n } = useTranslation();


    const [creds, useCreds] = useState({})

    const [seconds, setSeconds] = React.useState(15);

    const [values,setValues] = useState({})

    const [auth,showAuth] = useState(false)


    const [disable,setDisable] = useState(false)


    useEffect(() => {
        if(auth){
            if (seconds > 0) {
                setTimeout(() => setSeconds(seconds - 1), 1000);
            } else {
                setDisable(true)
            }
        }
    });

    const changeHandler = (e) => {
        var container = document.getElementsByClassName("input-wrap")[0];
        container.onkeyup = function(e) {
           var target = e.srcElement || e.target;
           var maxLength = parseInt(target.attributes["maxlength"].value, 10);
           var myLength = target.value.length;
           if (myLength >= maxLength) {
              var next = target;
              while (next = next.nextElementSibling) {
                    if (next == null)
                       break;
                    if (next.tagName.toLowerCase() === "input") {
                       next.focus();
                       break;
                    }
              }
           }
           // Move to previous field if empty (user pressed backspace)
           else if (myLength === 0) {
              var previous = target;
              while (previous = previous.previousElementSibling) {
                    if (previous == null)
                       break;
                    if (previous.tagName.toLowerCase() === "input") {
                       previous.focus();
                       break;
                    }
              }
           }
        }
    }

    const resedOTP = () => {
        let obj = {
            token : creds.token
        }
        service.post('/auth/2fa/resend', obj).then( (res) => {
            toast.success(t('OTP Sent Successfully'))
            setSeconds(15)
            setDisable(false)
        })
    }
    
    const ConfirmCode = (e) => {
        e.preventDefault()
        var elem = document.getElementsByClassName('otpField')
        var names = [];
        for (var i = 0; i < elem.length; ++i) {
            if (typeof elem[i].attributes.class !== "undfined") {
                if(elem[i].attributes.class.value === "otpField"){
                    names.push(elem[i].value);
                }
            }
        }    
        let obj = {
            otp : names.join(''),
            ...creds,
            ...values
        }
        service.post(APIS_URL.LOGIN, obj).then( (res) => {
            localStorage.setItem('usertoken',res.content.access_token)
            localStorage.setItem('refresh',res.content.refresh_token)
            localStorage.setItem('profile', JSON.stringify(res.content.profile))
            history.push('/assessments')
        })
    }

    const handleLogin = (values) => {
        setValues(values)
        service.post(APIS_URL.LOGIN,values).then( (res) => {
            localStorage.setItem('usertoken',res.content.access_token)
            localStorage.setItem('refresh',res.content.refresh_token)
            localStorage.setItem('profile', JSON.stringify(res.content.profile))
            history.push('/assessments')
        }).catch( (err) => {
            if(err.response.data['code'] == 'otp-token-required'){
                showAuth(true)  
                creds.token = err.response.data.content.token
            }
        })
    }

    useEffect( () => {
        if(localStorage.getItem('usertoken')){
            history.push('/assessments')
        }
    },[])

    const settings = {
        dots: true,   
        nav : false,
        speed: 500,
        autoPlay : true,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: false,
        prevArrow: false
    };

  return (  
      <div className="flex-login-block">
            <div className="login-block common-style">
                <div className="login-langDropdown">
                    <Language/>
                </div>
                <div className="form-controls">
                    <a href="https://www.compriscare.com/" style={{maxWidth:'250px',margin:'0 auto', display:'block'}} target="_blank"><img src={process.env.LOGO_URI}/></a> 
                    <Formik
                        validationSchema={loginValidation}
                        initialValues={{ email: '', password : "" }}
                        onSubmit={(values, actions) => {
                            handleLogin(values)
                        }}  
                        >
                        {props => (
                            <form onSubmit={props.handleSubmit}>
                                    <Label className="email">{t('Email Address')}</Label>
                                    <Field component={CustomInput} placeholder={`${t('Enter you email id')}`} name="email" type="text"/>
                                    <Label className="password">{t('Password')}</Label>
                                    <Field component={CustomInput} placeholder={`${t('Enter your password')}`} name="password" type="password"/>
                                <Link to="/forgotPassword" className="forgot-password">{t('Forgot password?')}</Link>
                                <Button color="primary">Login</Button>
                                <span className="dont-account text-center">{t("Don't have an account?")} <Link to="/clinic-provider-signup">{t('Sign up')}</Link></span>
                            </form>
                    )}
                </Formik>
                    <div className="static-text">
                            <ul>
                                <li>
                                    <a href=" https://www.compriscare.com/privacy-policy-terms" target="_blank">{t('Privacy Policy')}</a>
                                </li>
                                <li>
                                    <a href="https://www.compriscare.com/disclaimer" target="_blank">{t('Disclaimer')}</a>
                                </li>
                            </ul>
                    </div>
                </div>
            </div>
            <div className="right-slider common-style"> 
                <Slider className="login-laptop" {...settings}>
                    <div className="slider-text-wrapper">
                        <span className="image-laptop"> 
                            <img src={laptop}/>
                        </span>
                        <h2>{t('Compris')}</h2>
                        <p>{t('Committed to helping healthcare providers better serve those suffering with addictions')}</p>
                    </div>

                    <div className="slider-text-wrapper">
                        <span className="image-laptop"> 
                            <img src={laptop}/>
                        </span>
                        <h2>{t('Automating Addiction Assessments')}</h2>
                        <p>{t('Improving Productivity and Guiding Telehealth')}</p>
                    </div>
                    <div className="slider-text-wrapper">
                        <span className="image-laptop"> 
                            <img src={laptop}/>
                        </span>
                        <h2>{t('Compris Profile')}</h2>
                        <p>{t('Creating the new gold standard for addiction assessment and chronic illness management')}</p>
                    </div>
              </Slider>
            </div>
            <CustomModal className="two-way-form" state={auth} setState={showAuth}>
                <form onSubmit={ConfirmCode}>
                    <div className="enter-code">
                        <h3>{t('Enter OTP')}</h3>
                        <div class="input-wrap text-center">
                            <input type="text" className="otpField" maxLength="1" size="1" name="otp[]" min="1" max="1" onChange={() => changeHandler()} />
                            <input type="text" className="otpField" maxLength="1" size="1" name="otp[]" min="1" max="1" onChange={() => changeHandler()}/>
                            <input type="text" className="otpField" maxLength="1" size="1" name="otp[]" min="1" max="1" onChange={() => changeHandler()}/>
                            <input type="text" className="otpField" maxLength="1" size="1" name="otp[]" min="1" max="1" onChange={() => changeHandler()}/>
                        </div>
                        <p className="second-time">{seconds == 0 ? "" : seconds}</p>
                        {disable ? <a href="#" style={{display:'block',textAlign:'center',paddingTop:'20px'}} onClick={ () => resedOTP() }>{t('Resend OTP')}</a> : ""}
                    </div>
                    <div className="otp-btn-block">
                        <Button color="primary">{t('Login')}</Button>
                        <Button onClick={ (e) => showAuth(false)}>{t('Cancel')}</Button>
                    </div>
                </form>
            </CustomModal>
            
      </div>
  );
}


export default Login;   