import React, { useState, useEffect, memo, Fragment  } from 'react'
import { Button, Container } from 'reactstrap';
import { getProfile} from '../redux/actions/onboarding'
import { assesmentList , assesmentFilter } from '../redux/actions/assesmentAction'
import {connect} from 'react-redux'
import {clearReducer} from '../redux/actions/onboarding'
import { allClinicsList } from '../redux/actions/clinicMasterAction'
import logo from '../assests/images/logo.jpg'
import {DebounceInput} from 'react-debounce-input';
import compris from '../assests/images/compros.png'
import userProfile from '../assests/images/userProfile.png'
import defaultImage from '../assests/images/default.png'
import {withRouter} from 'react-router-dom'
    import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Input } from 'reactstrap';
import CustomDropdown from '../helperComponents/dropdown'
import service from '../interceptor';
import Notification from './notification'
import { APIS_URL } from '../utils/apiEndPoints';

function Header({
    history,
    dispatch,
    getProfileInfo,
    filter,
    activeIndex,
    getAllClinics,
    clearReducer,
    setAssesmentFilter,
    getAssesmentList,
    clinicsList,
    profileDetails : {
        username ,
        email ,
        image_url,
        is_superuser,
        is_active,
        clinic_provider,
        job_title,
        is_org_admin
    },
    location : {
        pathname
    },
    profileDetails,
    ...props
}) {


    const [notification,setNotification] = useState(false)

    const [clinic, activeClinic] = useState()

    
    const [toggle, setToggle] = useState(false)


    const [lang,setLang] = useState(localStorage.getItem('i18nextLng'))


    const { t, i18n } = useTranslation();

    // Get Profile Info
    useEffect( () => {        
        getProfileInfo()
    },[])

    useEffect( ()=>{
        if(search == ''){
            let obj = {
                page : 1,
                per_page_items : 25,
                search : ''
            }
            getAllClinics(obj)
        }
    },[search])

    const [search,setSearchText] = useState('')

    useEffect( () => {
         if(toggle){ document.body.classList = "show-sidebar"}
         else
         {document.body.classList = ""}
    }, [toggle])


    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);
        setLang(lang)
    }

    const logOut =  () => {
        history.push('/')
        localStorage.clear()
        window.location.reload()
    }

    const updateClinic = (name,id,city) => {
        let obj = {
            name : name, 
            city : city
        }
        filter.clinic_id = id
        setAssesmentFilter(filter)
        getAssesmentList(filter)
        activeClinic(obj)
    }


    useEffect( () => {
        let obj = {
            page : 1,
            per_page_items : 25,
            search : search
        }
        getAllClinics(obj)
    },[search, filter])
    
    useEffect( () => {
    },[activeIndex])

    // Clinic Drodpown
    const [dropdownOpen, setDropdownOpen] = useState(false);
    
    const [language,setLanguage] = useState(false)


    // Profile Dropdown
    const [profile, SetProfile] = useState(false);

    // Toggle Clinic Dropdown
    const toggleDroddown = () => {
        setDropdownOpen(prevState => !prevState);
        if(search){
            let obj = {
                page : 1,
                per_page_items : 25,
                search : ''
            }
            getAllClinics(obj)
            setSearchText('')
        }
    }

    // Toggle Profile Dropdown
    const toggleProfile = () => SetProfile(prevState => !prevState);

    return (
        <header className="header-section">
            <Button onClick={ () => setToggle(!toggle)} className="toggle d-block d-lg-none"> 
                <span></span>
                <span></span>
                <span></span>
            </Button>
                <div className="right-header-block">
                    <div className="logo">
                        { is_superuser ?  <img className="provider-image-size" src={process.env.LOGO_URI}/> : clinic_provider && clinic_provider.logo_url ? <img className="provider-image-size" src={clinic_provider.logo_url}/> : <img style={{cursor:'pointer'}} onClick={ () => history.push('/clinicProfile') } title="Insert YOUR clinic / organization logo here." className="default-image" src={defaultImage}/>}
                    </div> 
                    <div className="header-control-wrapper">
                            <Dropdown  className="top-header-clinic clinic-block" isOpen={dropdownOpen} toggle={toggleDroddown}>
                                {
                                    pathname == '/assessments' ? 
                                    <Fragment>
                                        <DropdownToggle caret>
                                                {clinic ? `${clinic.name} ${clinic.city ? `(${clinic.city})` : ""}` : clinicsList && clinicsList.length > 0 && is_superuser ? clinicsList && `${clinicsList && clinicsList[0] && clinicsList[0].name} ${clinicsList && clinicsList[0] && clinicsList[0].city ? clinicsList && `(${clinicsList[0].city})` : ""}` : clinicsList && `${clinicsList && clinicsList[1] && clinicsList[1] && clinicsList[1].name} ${clinicsList && clinicsList[1] && clinicsList[1].city ? clinicsList && `(${clinicsList[1].city})` : ""}` }
                                            </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem header>
                                                <DebounceInput debounceTimeout={400} minLength={3} className="form-control" onChange={ (e) => setSearchText(e.target.value) } placeholder={`${t('Select the Clinic')}`} type="text"/>
                                            </DropdownItem>
                                            {
                                                clinicsList && clinicsList.length > 0 && clinicsList.map( (item, index) => {
                                                    return <DropdownItem onClick={ () => updateClinic(item.name,item.id,item.city)}>{`${item.name} ${item.city ? ` (${item.city})` : ""}`}</DropdownItem>
                                                })
                                            }
                                        </DropdownMenu>
                                    </Fragment> : ""
                                }
                            </Dropdown>
                                <div className={ lang == 'en' ? "language-dropdown notif" : "language-dropdown notif esp"}>
                                    <div className="notification">
                                        <Notification/>
                                    </div>
                                    <CustomDropdown className="dropdown-header-lang" state={language} text={`${lang == 'en' ? `${t('English - US')}` : `${t('Spanish - ESP')}`}`} header={t("Select Language")}  setState={setLanguage}>
                                        <DropdownItem onClick={ () => changeLanguage('en') }>
                                            <span>{t('English - US')}</span>
                                            {lang == 'en' ? `${t('Active Language')}` : ""}
                                        </DropdownItem>
                                        <DropdownItem className="esp" onClick={ () => changeLanguage('esp') }>
                                            <span>{t('Spanish - ESP')}</span>
                                            {lang == 'en' ? '' : `${t('Active Language')}`}
                                        </DropdownItem>
                                    </CustomDropdown>
                                </div>
                            {
                                Object.keys(profileDetails).length > 0 && 
                                <div className="user-profile-block user-profile">
                                    
                                    <Dropdown className="clinic-block" isOpen={profile} toggle={toggleProfile}>
                                            <DropdownToggle>
                                                <span className="user-image">
                                                    <img src={userProfile}/>
                                                </span>
                                            </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem header>
                                                <div className="user-info">
                                                    <img src={userProfile}/>
                                                    <div className="content-box">
                                                        <h3>{email}</h3>
                                                        <span>
                                                            {is_superuser ? "Super Admin" : is_org_admin ? `${t('Provider Admin')}` :`${t('Clinician')}`}
                                                        </span>
                                                    </div>
                                                </div>
                                            </DropdownItem>
                                            <DropdownItem onClick={ () => logOut() }>{t('Logout')}</DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>

                                </div>
                            }
                    </div>
                </div>
        </header>
    )
}

const mapStateToProps = state => {
    return {
        profileDetails : state.onboarding.profileDetails,
        filter : state.assesment.assesmentFilter,
        clinicsList : state.clinicReducer.clinicsList.clinics, 
        activeIndex: state.onboarding.index,
        ...state
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getProfileInfo : () => dispatch(getProfile()),
        clearReducer : () => dispatch(clearReducer()),
        setAssesmentFilter : (obj) => dispatch(assesmentFilter(obj)),
        setAssesmentFilter : (obj) => dispatch(assesmentFilter(obj)),
        getAllClinics : (obj) => dispatch(allClinicsList(obj)),
        getAssesmentList : (obj) => dispatch(assesmentList(obj)),
    }
} 

const localtionRouter = withRouter(Header);

export default connect(mapStateToProps,mapDispatchToProps)(memo(localtionRouter))
