import { FETCH_BILLING_LIST , BILLING_FILTERS } from '../type'

const initialState = {
    billingList : {},
    billingFilters : {
        invoice_date : '',
        paid_date : '',
        plan_name : '',
        per_page_items : 10,
        clinic_provider_name : ''
    }
}

const billingReducer = (state = initialState,action) => {
        switch(action.type){
            case FETCH_BILLING_LIST : 
            return { 
                ...state,
                billingList : action.payload,
            }
            case BILLING_FILTERS :
            return {
                ...state,
                billingFilters : action.payload
            }
            default : 
            return state
        }
}

export { 
    billingReducer
}