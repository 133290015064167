import { FETCH_CLINIC_MASTER , FETCH_CLINIC_LIST , FETCH_ADMIN_CLINIC_LIST } from '../type'
import service from '../../interceptor'
import { APIS_URL} from '../../utils/apiEndPoints'


// Fetch Clinics  List
export const clinicMasterList = reqBody => dispatch => {
    return new Promise( (resolve, reject) => {
        let  { page , per_page_items, search } = reqBody
        service.get(`${APIS_URL.CLINIC_MASTER}?page=${page}&per_page_items=${per_page_items}&search=${search}`).then( (res) => {
            dispatch({
                type : FETCH_CLINIC_MASTER,
                payload : res.content
            })
            resolve(res)
        })
    })
}

// Fetch Clinics 
export const allClinicsList = reqBody => dispatch => {
    return new Promise( (resolve, reject) => { 
        let  { page , per_page_items, search  } = reqBody
        service.get(`${APIS_URL.ALL_CLINICS}?page=${page}&per_page_items=${per_page_items}&search=${search ? search : ''}`).then( (res) => {
            dispatch({
                type : FETCH_CLINIC_LIST,
                payload : res.content
            })
            let customObj = {
                name : 'All Clinics',
                id : 'all'
            }
            let allCLinics = res.content.clinics.unshift(customObj)
            resolve(allCLinics)
        })
    })
}


// Fetch for details


export const allClinicsListDetails = reqBody => dispatch => {
    return new Promise( (resolve, reject) => { 
        let  { page , per_page_items , is_admin , clinic_provider_id } = reqBody
        service.get(`${APIS_URL.ALL_CLINICS}?page=${page}&per_page_items=${per_page_items}&is_admin=${is_admin}&clinic_provider_id=${clinic_provider_id ? clinic_provider_id : ""}`).then( (res) => {
            dispatch({
                type : FETCH_ADMIN_CLINIC_LIST,
                payload : res.content
            })
            resolve(res)
        })
    })
}
