import * as yup from 'yup'; // for everything


// Username validatioj
const loginValidation = () => {
    return yup.object().shape({
        email: yup.string().email('This is not a valid email.').required('Email is required').trim(),
        password: yup.string().required('Password is required').trim()
    })
}


// Username validatioj
const forgotPasswordValidation = () => {
    return yup.object().shape({
        email: yup.string().email('This is not a valid email.').required('Email is required').trim(),
    })
}


export {
    loginValidation,
    forgotPasswordValidation
}