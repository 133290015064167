import React, { useState } from 'react'
import { Formik , Field } from 'formik'
import { Container, Row, Col, Button, Table } from 'reactstrap'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MaterialInput from '../../helperComponents/MaterialInput'
import compris from '../../assests/images/001 Compris TM clr.png'
import { connect } from 'react-redux'
import { updateAnswers, emptyAnswerArray } from './constants'

import { useTranslation } from 'react-i18next';
import one from '../../assests/images/religion/1.png'
import two from '../../assests/images/religion/2.svg'
import three from '../../assests/images/religion/3.svg'
import four from '../../assests/images/religion/4.svg'
import five from '../../assests/images/religion/5.svg'
import six from '../../assests/images/religion/6.png'
import seven from '../../assests/images/religion/7.svg'
import eight from '../../assests/images/religion/8.svg'
import nine from '../../assests/images/religion/9.svg'
import eleven from '../../assests/images/religion/11.svg'

function Page3({
    questionContent,
    history,
    ...props
}) {

    const [error, setError] = useState(false)

    const { t } = useTranslation()

    const nextPage = (values) => {
        if(values.housing && values.highestEducationLevel && values.religiousPreference ){
            if((values.highestEducationLevel.other_id && !values.others) || (values.religiousPreference.other_id && !values.religiousOthers)){
                setError(true)
            }
            else{
                setError(false)
                let questionContentCopy = JSON.parse(JSON.stringify(questionContent))
                if(values.religiousPreference.other_id){
                    values.religiousPreference.text = values.religiousOthers
                }
                if(values.highestEducationLevel.other_id){
                    values.highestEducationLevel.text = values.others
                }
                updateAnswers(questionContentCopy, 7, {
                    id: 549097445,
                    answers: [
                        values.highestEducationLevel
                    ]
                }, questionContentCopy.questions.findIndex(item => item.id == 549097445)!=-1?true:false)
                updateAnswers(questionContentCopy, 8, {
                    id: 549097423,
                    answers: [
                        values.religiousPreference
                    ]
                }, questionContentCopy.questions.findIndex(item => item.id == 549097423)!=-1?true:false)
                updateAnswers(questionContentCopy, 9, {
                    id: 549097390,
                    answers: [{
                        choice_id: values.housing,
                        row_id: 3622507680
                    }]
                }, questionContentCopy.questions.findIndex(item => item.id == 549097390)!=-1?true:false)
                if(values.housing == 3622507677 || values.housing == 3622507678){
                    emptyAnswerArray({549097381: true}, questionContentCopy)
                    history.push(`/questions/page4`)
                }
                else{
                    history.push(`/questions/549097381`)
                }
                props.dispatch({
                    type: `UPDATE_CONTENT`,
                    payload: questionContentCopy
                })
            }
        }
        else{
            setError(true)
        }
    }
    const prevPage = () => {
        history.push(`/questions/page2`)
    }
    return (
        <div>
            <Container>
            <Formik
                initialValues={{
                    highestEducationLevel: questionContent.questions?questionContent.questions.findIndex(item => item.id == 549097445)!=-1?questionContent.questions[questionContent.questions.findIndex(item => item.id == 549097445)].answers[0]:'':'',
                    religiousPreference: questionContent.questions?questionContent.questions.findIndex(item => item.id == 549097423)!=-1?questionContent.questions[questionContent.questions.findIndex(item => item.id == 549097423)].answers[0]:'':'',
                    religiousOthers: questionContent.questions?questionContent.questions.findIndex(item => item.id == 549097423)!=-1?questionContent.questions[questionContent.questions.findIndex(item => item.id == 549097423)].answers[0].text:'':'',
                    others: questionContent.questions?questionContent.questions.findIndex(item => item.id == 549097445)!=-1?questionContent.questions[questionContent.questions.findIndex(item => item.id == 549097445)].answers[0].text:'':'',
                    housing: questionContent.questions?questionContent.questions.findIndex(item => item.id == 549097390)!=-1?questionContent.questions[questionContent.questions.findIndex(item => item.id == 549097390)].answers[0].choice_id:'':'',
                }}
                onSubmit={(values, actions) => {
                    nextPage(values)
                }}  
            >{({
                handleSubmit,
                values,
                resetForm,
                setFieldValue,
            }) => (
                    <form autoComplete="false" onSubmit={handleSubmit}>
                        <div className="bg-white container mb-3" style={{borderRadius: '2px'}}>
                            <div className="p-2">
                                <p>7. {t('Religious preference')}</p>
                                    <Row className="religion-preferece">
                                        <Col xs={6} sm={2} md={2} style={{border: 'solid 1px #b9b9b9', borderRadius: '5px',marginRight: '10px', justifyContent: 'center', alignItems: 'center', cursor: 'pointer'}} className={values.religiousPreference.choice_id == '3622508126'?'border-active religion':'religion'} onClick={()=>setFieldValue('religiousPreference', {choice_id: 3622508126}, true)}>
                                            <div className="image-block">
                                                <img src={three}/>
                                            </div>
                                            <label>{t('Protestant Christian')}</label>
                                        </Col>
                                        <Col xs={6} sm={2} md={2} style={{border: 'solid 1px #b9b9b9', borderRadius: '5px',marginRight: '10px', justifyContent: 'center', alignItems: 'center', cursor: 'pointer'}} className={values.religiousPreference.choice_id == '3622508128'?'border-active religion':'religion'} onClick={()=>setFieldValue('religiousPreference', {choice_id: 3622508128}, true)}>
                                            <div className="image-block">
                                                <img src={eight}/>
                                            </div>
                                            <label>{t('Jewish')}</label>
                                        </Col>
                                        <Col xs={6} sm={2} md={2} style={{border: 'solid 1px #b9b9b9', borderRadius: '5px',marginRight: '10px', justifyContent: 'center', alignItems: 'center', cursor: 'pointer'}} className={values.religiousPreference.choice_id == '3622508129'?'border-active religion':'religion'} onClick={()=>setFieldValue('religiousPreference', {choice_id: 3622508129}, true)}>                                        
                                            <div className="image-block">
                                                <img src={one}/>
                                            </div>
                                            <label>{t('Buddhist')}</label>
                                        </Col>
                                        <Col xs={6} sm={2} md={2} style={{border: 'solid 1px #b9b9b9', borderRadius: '5px',marginRight: '10px', justifyContent: 'center', alignItems: 'center', cursor: 'pointer'}} className={values.religiousPreference.choice_id == '3622508131'?'border-active religion':'religion'} onClick={()=>setFieldValue('religiousPreference', {choice_id: 3622508131}, true)}>
                                            <div className="image-block">
                                                <img src={five}/>
                                            </div>
                                            <label>{t('Spiritual')}</label>
                                        </Col>
                                        <Col xs={6} sm={2} md={2} style={{border: 'solid 1px #b9b9b9', borderRadius: '5px',marginRight: '10px', justifyContent: 'center', alignItems: 'center', cursor: 'pointer'}} className={values.religiousPreference.choice_id == '3622508133'?'border-active religion':'religion'} onClick={()=>setFieldValue('religiousPreference', {choice_id: 3622508133}, true)}>
                                            <div className="image-block">
                                                <img src={six}/>
                                            </div>
                                            <label>{t('Atheist')}</label>
                                        </Col>
                                        <Col xs={6} sm={2} md={2} style={{border: 'solid 1px #b9b9b9', borderRadius: '5px',marginRight: '10px', justifyContent: 'center', alignItems: 'center', cursor: 'pointer'}} className={values.religiousPreference.choice_id == '3622508127'?'border-active religion':'religion'} onClick={()=>setFieldValue('religiousPreference', {choice_id: 3622508127}, true)}>
                                            <div className="image-block">
                                                <img src={two}/>
                                            </div>
                                            <label>{t('Catholic Christian')}</label>
                                        </Col>
                                        <Col xs={6} sm={2} md={2} style={{border: 'solid 1px #b9b9b9', borderRadius: '5px',marginRight: '10px', justifyContent: 'center', alignItems: 'center', cursor: 'pointer'}} className={values.religiousPreference.choice_id == '3622508130'?'border-active religion':'religion'} onClick={()=>setFieldValue('religiousPreference', {choice_id: 3622508130}, true)}>                                            
                                            <div className="image-block">
                                                <img src={seven}/>
                                            </div>
                                            <label>{t('Muslim')}</label>
                                        </Col>
                                        <Col xs={6} sm={2} md={2} style={{border: 'solid 1px #b9b9b9', borderRadius: '5px',marginRight: '10px', justifyContent: 'center', alignItems: 'center', cursor: 'pointer'}} className={values.religiousPreference.choice_id == '3622508136'?'border-active religion':'religion'} onClick={()=>setFieldValue('religiousPreference', {choice_id: 3622508136}, true)}>
                                            <div className="image-block">
                                                <img src={eleven}/>
                                            </div>
                                            <label>{t('Hindu')}</label>
                                        </Col>
                                        <Col xs={6} sm={2} md={2} style={{border: 'solid 1px #b9b9b9', borderRadius: '5px',marginRight: '10px', justifyContent: 'center', alignItems: 'center', cursor: 'pointer'}} className={values.religiousPreference.choice_id == '3622508132'?'border-active religion':'religion'} onClick={()=>setFieldValue('religiousPreference', {choice_id: 3622508132}, true)}>                                            
                                            <div className="image-block">
                                                <img src={four}/>
                                            </div>
                                            <label>{t('Agnostic')}</label>
                                        </Col>
                                        <Col xs={6} sm={2} md={2} style={{border: 'solid 1px #b9b9b9', borderRadius: '5px',marginRight: '10px', justifyContent: 'center', alignItems: 'center', cursor: 'pointer'}} className={values.religiousPreference.choice_id == '3622507827'?'border-active religion':'religion'} onClick={()=>setFieldValue('religiousPreference', {choice_id: 3622507827}, true)}>
                                            <div className="image-block">
                                                <img src={nine}/>
                                            </div>
                                            <label>{t('None or prefer not to answer')}</label>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <FormControlLabel
                                            className="other-names"
                                            control={<Radio color="primary" checked={values.religiousPreference.other_id == '3622508135'?true:false} onChange={()=>setFieldValue('religiousPreference', {other_id: 3622508135}, true)} />}
                                            label={t('Other (please list):')}
                                        />
                                    </Row>
                                <Field component={MaterialInput} name="religiousOthers" type="text" id="other" />
                            </div>
                        </div>
                        <div className="bg-white container mb-3" style={{borderRadius: '2px'}}>
                        <div className='p-2'>
                                <p>8. {t('Highest education level')}</p>
                                <RadioGroup row aria-label="position" name="highest-education-level" onChange={e => {
                                    if(e.target.value == 3622508543)
                                        setFieldValue('highestEducationLevel', {other_id: e.target.value}, true)
                                    else{
                                        setFieldValue('highestEducationLevel', {choice_id: e.target.value, row_id: 3622508521}, true)
                                    }
                                }}>
                                    <Table borderless>
                                        <thead style={{backgroundColor: '#f9f9f9', fontSize: '12px', lineHeight: '1.25'}}>
                                        <tr>
                                            <td align="center">{t('8th grade or less')}</td>
                                            <td align="center">{t('Some High School')}</td>
                                            <td align="center">{t('High School or GED')}</td>
                                            <td align="center">{t('Some college')}</td>
                                            <td align="center">{t('Trade school')}</td>
                                            <td align="center">{t('Associate')}</td>
                                            <td align="center">{t('Bachelor')}</td>
                                            <td align="center">{t('Master')}</td>
                                            <td align="center">{t('JD')}</td>
                                            <td align="center">{t('PhD')}</td>
                                            <td align="center">{t('MD/DO')}</td>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td align="center">
                                                    <Radio color="primary" value='3622508522' checked={values.highestEducationLevel.choice_id == 3622508522?true:false} />
                                                </td>
                                                <td align="center">
                                                    <Radio color="primary" value='3622508523' checked={values.highestEducationLevel.choice_id == 3622508523?true:false} />
                                                </td>
                                                <td align="center">
                                                    <Radio color="primary" value='3622508524' checked={values.highestEducationLevel.choice_id == 3622508524?true:false} />
                                                </td>
                                                <td align="center">
                                                    <Radio color="primary" value='3622508525' checked={values.highestEducationLevel.choice_id == 3622508525?true:false} />
                                                </td>
                                                <td align="center">
                                                    <Radio color="primary" value='3622508526' checked={values.highestEducationLevel.choice_id == 3622508526?true:false} />
                                                </td>
                                                <td align="center">
                                                    <Radio color="primary" value='3622508527' checked={values.highestEducationLevel.choice_id == 3622508527?true:false} />
                                                </td>
                                                <td align="center">
                                                    <Radio color="primary" value='3622508528' checked={values.highestEducationLevel.choice_id == 3622508528?true:false} />
                                                </td>
                                                <td align="center">
                                                    <Radio color="primary" value='3622508529' checked={values.highestEducationLevel.choice_id == 3622508529?true:false} />
                                                </td>
                                                <td align="center">
                                                    <Radio color="primary" value='3622508531' checked={values.highestEducationLevel.choice_id == 3622508531?true:false} />
                                                </td>
                                                <td align="center">
                                                    <Radio color="primary" value='3622508530' checked={values.highestEducationLevel.choice_id == 3622508530?true:false} />
                                                </td>
                                                <td align="center">
                                                    <Radio color="primary" value='3622508532' checked={values.highestEducationLevel.choice_id == 3622508532?true:false} />
                                                </td>

                                            </tr>
                                        </tbody>
                                    </Table>
                                    {/* <FormControlLabel
                                        value='3622508543'
                                        control={<Radio color="primary" checked={values.highestEducationLevel.other_id == 3622508543?true:false} />}
                                        label="If others ,please specify"
                                    />
                                    <Field component={MaterialInput} name="others" type="text" placeholder="Type your answer..." /> */}
                                </RadioGroup>
                            </div>
                        </div>
                        <div className="bg-white container mb-3" style={{borderRadius: '2px'}}>
                            <div className="p-2">
                                <p>9. {t('Living situation')}</p>
                                <RadioGroup row aria-label="position" name="housing" onChange={e => setFieldValue('housing', e.target.value, true)}>
                                    <Table borderless>
                                        <thead style={{backgroundColor: '#f9f9f9', fontSize: '12px', lineHeight: '1.25'}}>
                                        <tr>
                                            <td align="center">{t('own')}</td>
                                            <td align="center">{t('rent')}</td>
                                            <td align="center">{t('live with family')}</td>
                                            <td align="center">{t('group home')}</td>
                                            <td align="center">{t('temporary stay')}</td>
                                            <td align="center">{t('homeless')}</td>
                                            <td align="center">{t('no place to live after treatment or jail')}</td>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td align="center">
                                                    <Radio color="primary" value='3622507679' checked={values.housing == 3622507679?true:false} />
                                                </td>
                                                <td align="center">
                                                    <Radio color="primary" value='3622507681' checked={values.housing == 3622507681?true:false} />
                                                </td>
                                                <td align="center">
                                                    <Radio color="primary" value='3622507674' checked={values.housing == 3622507674?true:false} />
                                                </td>
                                                <td align="center">
                                                    <Radio color="primary" value='3622507787' checked={values.housing == 3622507787?true:false} />
                                                </td>
                                                <td align="center">
                                                    <Radio color="primary" value='3622507788' checked={values.housing == 3622507788?true:false} />
                                                </td>
                                                <td align="center">
                                                    <Radio color="primary" value='3622507677' checked={values.housing == 3622507677?true:false} />
                                                </td>
                                                <td align="center">
                                                    <Radio color="primary" value='3622507678' checked={values.housing == 3622507678?true:false} />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </RadioGroup>
                            </div>
                        </div>
                        <div className="bg-white container mb-3" style={{borderRadius: '2px'}}>
                            {error && <p style={{color: 'red',padding:'10px 0'}}>{t('Please answer all the questions')}<br /></p>}
                            <div className='px-2 py-3' style={{display: 'flex', justifyContent: 'space-between'}}>
                                <p style={{fontSize: '12px', lineHeight: '1.17', color: '#afafaf',marginBottom:'0',paddingTop:'10px'}}>{t('Powered by')} <a href="https://www.compriscare.com/" target="_blank"><img src={process.env.LOGO_URI} style={{marginLeft: '10px'}} className="footer-img-logo" /></a></p>
                                <div className="button-footer-wrapper">
                                    <Button style={{backgroundColor: 'transparent', color: '#5e6265', border: 'none'}} onClick={prevPage} type='button'>{t('Previous')}</Button>
                                    <Button style = {{marginLeft: '10px', backgroundColor: '#055ce5', color: 'white', borderRadius: '2px', border: 'none'}} >{t('Next')}</Button>
                                </div>
                            </div>
                        </div>
                    </form>
                    )
                }
            </Formik>
            </Container>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        questionContent : state.question.content,
    }
}


export default connect(mapStateToProps)(Page3)
