// Clinician
const users = [ 
    {
        label : "Provider Assessments",
        link  : "/assessments"
    },
    {   
        label : "Security",
        link  : "/security"
    },
    {
        label : "Notifications",
        link  : "/notification"
    },
    {
        label : "Referral",
        link  : "/referral"
    },
    {
        label : "Feedback",
        link  : "/feedback"
    },
    {
        label : "Clinic Profile",
        link  : "/clinicProfile"
    },
] 

// Org Admin
const orgAdmin = [ 
    {
        label : "Provider Assessments",
        link  : "/assessments"
    },
    {
        label : "Provider Admin",
        link  : "/users"
    },
    {
        label : "Feedback",
        link  : "/feedback"
    },
    {
        label : "Billing Details",
        link  : "/billingDetails"
    },
    // {
    //     label : "Membership",
    //     link  : "/memberShipView"
    // },
    {
        label : "Referral",
        link  : "/referral"
    },
    {
        label : "Security",
        link  : "/security"
    },
    {
        label : "Notifications",
        link  : "/notification"
    },
    {
        label : "Clinic Profile",
        link  : "/clinicProfile"
    },
] 



const superAdmin = [ 
    {
        label : "Provider Assessments",
        link  : "/assessments"
    },
    {
        label : "Unlinked Assessments",
        link  : "/shortAssessments"
    },
    {
        label : "Corporate Links",
        link  : "/corpLinks"
    },
    {
        label : "Clinics master",
        link  : "/clinicMaster"
    },
    {
        label : "Super Admin",
        link  : "/users"
    },
    {
        label : "Security",
        link  : "/security"
    },
    {
        label : "Notifications",
        link  : "/notification"
    },
    {
        label : "Feedback",
        link  : "/feedback"
    },
    {
        label : "Billing details",
        link  : "/billingDetails"
    },
    {
        label : "Referral",
        link  : "/referral"
    },
]

export {
    users,
    superAdmin,
    orgAdmin
}