import React, { useState, useEffect } from 'react'
import { questionsAndChoices, findQuestion, updateAnswers, nextRoute } from './constants'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import compris from '../../assests/images/001 Compris TM clr.png'
import { Container, Row, Col, Label, Button, Table, FormGroup } from 'reactstrap'
import Checkbox from '@material-ui/core/Checkbox';
import { useTranslation } from 'react-i18next';
import Data from './data'
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux'

function MatrixWithCheckbox({
    questionContent,
    questionID,
    history,
    ...props
}) {
    const [matrixSelectedIds, setMatrixSelectedState] = useState([])
    const [error, setError] = useState(false)
    const [matrixOthers, setMatrixOthers] = useState('')
    const [questionDetails, setQuestionDetails] = useState()
    const [questionIndex, setQuestionIndex] = useState()
    const [currentQuestionNumber, setCurrentQuestionNumber] = useState()
    const [update, setUpdate] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    

    const { t } = useTranslation()


    useEffect(()=>{
        const allquestions = JSON.parse(JSON.stringify(questionsAndChoices))
        const {question, currentQuestionIndex} = findQuestion(allquestions, questionID)
        setQuestionIndex(currentQuestionIndex)
        updateQuestionDetails(question, questionContent, questionID)
    }, [questionID])

    const updateQuestionDetails = (question, previousAnswers, currentQuestionId) => {
        if(currentQuestionId == 553973451){
            let choices = []
            let symptoms = previousAnswers.questions?previousAnswers.questions.find(item => item.id == 549097340).answers:[]
            symptoms.forEach(item => {
                if(item.choice_id == 3622508668){
                    choices.push(question.fields.choices[0])
                }
                else if(item.choice_id == 3622508768){
                    choices.push(question.fields.choices[1])
                }
                else if(item.choice_id == 3622508868){
                    choices.push(question.fields.choices[2])
                }
                else if(item.choice_id == 3622508968){
                    choices.push(question.fields.choices[3])
                }
                else if(item.choice_id == 3622509068){
                    choices.push(question.fields.choices[4])
                }
                else if(item.choice_id == 3622509168){
                    choices.push(question.fields.choices[5])
                }
                else if(item.choice_id == 3622509268){
                    choices.push(question.fields.choices[6])
                }
                else if(item.choice_id == 3622509368){
                    choices.push(question.fields.choices[7])
                }
                else if(item.choice_id == 3622509468){
                    choices.push(question.fields.choices[8])
                }
                else if(item.choice_id == 3622509568){
                    choices.push(question.fields.choices[9])
                }
                else if(item.choice_id == 3622509668){
                    choices.push(question.fields.choices[10])
                }
            })
            question.fields.choices = choices
        }
        else if(questionID == 553981634){
            let choices = []
            question.fields.containsOther = false
            let questionNo26Answer = previousAnswers.questions?previousAnswers.questions.find(item => item.id == 580243889).answers:[]
            let tobaccoAdded = false
            questionNo26Answer.forEach(item => {
                // if(!tobaccoAdded && (item.choice_id == 3821196634 || item.choice_id == 3983757573)){
                //     tobaccoAdded = true
                //     choices.push(question.fields.choices[0])
                // }
                // else if(item.choice_id == 3821196620){
                //     choices.push(question.fields.choices[1])
                // }
                if(item.choice_id == 3821196621){
                    choices.push(question.fields.choices[2])
                }
                else if(item.choice_id == 3821196622){
                    choices.push(question.fields.choices[3])
                }
                else if(item.choice_id == 3821196623){
                    choices.push(question.fields.choices[4])
                }
                else if(item.choice_id == 3821196624){
                    choices.push(question.fields.choices[6])
                }
                else if(item.choice_id == 3821196625){
                    choices.push(question.fields.choices[5])
                }
                else if(item.choice_id == 3821196626){
                    choices.push(question.fields.choices[7])
                }
                else if(item.choice_id == 3821196627){
                    choices.push(question.fields.choices[8])
                }
                else if(item.choice_id == 3821196628){
                    choices.push(question.fields.choices[9])
                }
                else if(item.choice_id == 3821196633){
                    choices.push(question.fields.choices[11])
                }
                else if(item.choice_id == 3821196629){
                    choices.push(question.fields.choices[10])
                }
                else if(item.choice_id == 3821196630){
                    choices.push(question.fields.choices[12])
                }
                else if(item.choice_id == 3821196631){
                    choices.push(question.fields.choices[13])
                }
                else if(item.other_id == 3821196643){
                    question.fields.containsOther = true
                    choices.push(question.fields.choices[14])
                }
            })
            let questionNo37Answer = previousAnswers.questions?previousAnswers.questions.find(item => item.id == 553973451).answers:[]
            let rows = []
            let symptons = {
                past30: 0,
                one: 0,
                four: 0,
                oneYear: 0,
                fiveplus: 0,
              }
              questionNo37Answer.forEach(item => {
                if(item.choice_id == 3653547374 && item.row_id != 3844672728){
                    symptons.past30+=1
                }
                else if(item.choice_id == 3653547375  && item.row_id != 3844672728){
                    symptons.one+=1
                }
                else if(item.choice_id == 3653547376  && item.row_id != 3844672728){
                    symptons.four+=1
                }
                else if(item.choice_id == 3653547377  && item.row_id != 3844672728){
                    symptons.oneYear+=1
                }
                else if(item.choice_id == 3653696915  && item.row_id != 3844672728){
                    symptons.fiveplus+=1
                }
              })
              if(symptons.past30 > 0){
                  let text = symptons.past30 > 1 ? "Symptoms" : "Symptom"
                  rows.push({
                      id:   question.fields.rowText[0].id,
                      text: `${symptons.past30} ${t(text)} </br>(${t(question.fields.rowText[0].text)})`
                  })
              }
              if(symptons.one > 0){
                  let text = symptons.one > 1 ? "Symptoms" : "Symptom"
                  rows.push({
                      id: question.fields.rowText[1].id,
                      text: `${symptons.one} ${t(text)}  </br>(${t(question.fields.rowText[1].text)})`
                  })
              }
              if(symptons.four > 0){
                let text = symptons.four > 1 ? "Symptoms" : "Symptom"
                  rows.push({
                      id: question.fields.rowText[2].id,
                      text: `${symptons.four} ${t(text)}  </br>(${t(question.fields.rowText[2].text)})`
                  })
              }
              if(symptons.oneYear > 0){
                let text = symptons.oneYear > 1 ? "Symptoms" : "Symptom"
                
                  rows.push({
                      id: question.fields.rowText[3].id,
                      text: `${symptons.oneYear} ${t(text)}  </br>(${t(question.fields.rowText[3].text)})`
                  })
              }
              if(symptons.fiveplus > 0){
                let text = symptons.fiveplus > 1 ? "Symptoms" : "Symptom"
                  rows.push({
                      id: question.fields.rowText[4].id,
                      text: `${symptons.fiveplus} ${t(text)}  </br>(${t(question.fields.rowText[4].text)})`
                  })
              }
            question.fields.choices = choices
            question.fields.rowText = rows
        }
        setQuestionDetails({...question})
        prefillValues(question)
    }

    const prefillValues = (questionDetails) => {
        let index = questionContent.questions?questionContent.questions.findIndex(item => item.id == questionID):-1
        if(index!=-1){
            setUpdate(true)
            setCurrentQuestionNumber(index+1)
            const selectedMatrix = []
            questionContent.questions[index].answers.forEach(item => {
                if(questionDetails.fields.choices.some(({row_id}) => row_id == item.row_id)){
                    selectedMatrix.push(item)
                }
                if(item.other_id){
                    setMatrixOthers(item.text)
                }
            })
            setMatrixSelectedState(selectedMatrix)
        }
        else if(questionContent.questions){
            for(let i=questionContent.questions.length-1; i>=0; i--){
                const {currentQuestionIndex} = findQuestion(questionsAndChoices, questionContent.questions[i].id)
                if(questionsAndChoices[currentQuestionIndex].pk < questionDetails.pk){
                    if(i == questionContent.questions.length-1)
                        setCurrentQuestionNumber(i+2)
                    else
                        setCurrentQuestionNumber(i+2)
                    break;
                }
            }
        }
    }

    const matrixOnChange = (checkboxStatus, row_id, choice_id) => {
        let multipleChoiceAnswersCopy = [...matrixSelectedIds] || []
            if(checkboxStatus){
                if(questionID == 553973451){
                    if(row_id == 3844672728){
                        multipleChoiceAnswersCopy = [...multipleChoiceAnswersCopy.filter(item => item.choice_id != choice_id), {choice_id, row_id}]
                    }
                    else {
                        multipleChoiceAnswersCopy =  [...multipleChoiceAnswersCopy.filter(item => !(item.row_id == 3844672728 && item.choice_id == choice_id)), {choice_id, row_id}]
                    }
                    
                }
                else if(questionDetails.fields.noneId == row_id && questionDetails.fields.noneChoiceId == choice_id){
                    multipleChoiceAnswersCopy = [{
                        choice_id,
                        row_id
                    }]
                }
                else{
                    multipleChoiceAnswersCopy.push({
                        choice_id,
                        row_id
                    })
                    if(questionDetails.fields.noneId){
                        const noneIndex = multipleChoiceAnswersCopy.findIndex(item => item.choice_id == questionDetails.fields.noneChoiceId && item.row_id == questionDetails.fields.noneId)
                        if(noneIndex != -1)
                            multipleChoiceAnswersCopy.splice(noneIndex, 1)
                    }
                }
            }
            else{
                multipleChoiceAnswersCopy.splice(multipleChoiceAnswersCopy.findIndex(item => item.choice_id == choice_id && item.row_id == row_id), 1)
            }
            setMatrixSelectedState(multipleChoiceAnswersCopy)
    }

    const nextPage = () => {
        if(matrixSelectedIds.length > 0){
            if(questionID == '553981633' || questionID == '553981634'){
                let selectedTimeIds = {}
                matrixSelectedIds.forEach((el) => {
                    selectedTimeIds[el.choice_id] = 1
                })
                let flag = true
                questionDetails.fields.rowText.forEach((el) => {
                    if(!selectedTimeIds[el.id]){
                        flag = false
                    }
                })
                if(flag){
                    saveData()
                }
                else{
                    setError(true)
                    setErrorMsg(t(`${ questionID == '553973451' ? "Please check at least ONE box for each timeline (column)" : "Please choose at least ONE answer per time period." }`))
                }
            }
            else if(questionID == '553973451'){
                let selectedTimeIds = {}
                matrixSelectedIds.forEach((el) => {
                    selectedTimeIds[el.row_id] = 1
                })
                let flag = true
                questionDetails.fields.choices.forEach((el) => {
                    if(!selectedTimeIds[el.row_id]){
                        flag = false
                    }
                })
                if(flag){
                    saveData()
                }
                else{
                    setError(true)
                    setErrorMsg(t(`${ questionID == '553973451' ? "Please choose at least ONE answer per row" : "Please choose at least one answer per time period" }`))
                }
            }
            else{
                saveData()
            }
        }
        else if(matrixSelectedIds.length > 0){
            if(questionID == '553981633' || questionID == '553973451' || questionID == '553981634'){
                let selectedTimeIds = {}
                matrixSelectedIds.forEach((el) => {
                    selectedTimeIds[el.choice_id] = 1
                })
                let flag = true
                questionDetails.fields.rowText.forEach((el) => {
                    if(!selectedTimeIds[el.id]){
                        flag = false
                    }
                })
                if(flag){
                    saveData()
                }
                else{
                    setError(true)
                    setErrorMsg(t(`${ questionID == '553973451' ? "Please check at least ONE box for each timeline (column)" : "Please choose at least one answer per time period" }`))
                }
            }
            else{
                saveData()
            }
        }
        else{
            setError(true)
            setErrorMsg(`${questionDetails.fields.question_type === 'matrix'?`${t('Please choose at least ONE answer.')}`:`${t('Please answer the question')}`}`)
        }
    }

    const saveData = () => {
        setError(false)
        let questionContentCopy = JSON.parse(JSON.stringify(questionContent))
        const matrixCopy = [...matrixSelectedIds]
        if(matrixOthers){
            matrixCopy.push({
                other_id: questionDetails.fields.otherOPtionId,
                text: matrixOthers
            })
        }
        setUpdate(false)
        nextRoute(questionContentCopy, questionID, history, Object.values(matrixCopy), questionIndex)
        setMatrixOthers('')
        updateAnswers(questionContentCopy, currentQuestionNumber, {
            id: questionID,
            answers: Object.values(matrixCopy)
        }, update)
        props.dispatch({
            type: `UPDATE_CONTENT`,
            payload: questionContentCopy
        }, setMatrixSelectedState([]))
    }

    const prevPage = () => {
        setError(false)
        if(questionIndex === 14){
            history.push(`/questions/page4`)
        }
        else{
            // history.push(`/questions/${questionsAndChoices[questionIndex-1].fields.question_id}`)
            let index = questionContent.questions?questionContent.questions.findIndex(item => item.id == questionID):-1
            if(index == -1){
                history.push(`/questions/${questionContent.questions[currentQuestionNumber-2].id}`)
            }
            else{
                history.push(`/questions/${questionContent.questions[index-1].id}`)
            }
        }
    }

    return (
        <Container>
            {questionDetails?<div>
                {questionDetails.fields.question_type === 'matrix' && <div className="bg-white container mb-3" style={{borderRadius: '2px'}}>
                    <div className="p-2" style={{overflow: 'auto'}}>
                        <p dangerouslySetInnerHTML={{__html: `${currentQuestionNumber}. ${t(questionDetails.fields.question_text)}`}}></p>
                        <Table borderless striped>
                            <thead style={{fontSize: '12px', lineHeight: '1.25'}}>
                            <tr>
                                {questionDetails.fields.choices?<td></td>:null}
                                {questionDetails.fields.rowText.map((item, i) => <td align="center" key={i} dangerouslySetInnerHTML={{__html: t(item.text)}}></td>)}
                            </tr>
                            </thead>
                            <tbody>
                                {questionDetails.fields.choices?questionDetails.fields.choices.map((item, i) => 
                                    <tr key={i}>
                                        <td>{t(item.choice_text)}</td>
                                        {questionDetails.fields.rowText.map((data, j) => <td align="center" key={j}><Checkbox checked={matrixSelectedIds.find(elem => elem.choice_id == data.id && elem.row_id == item.row_id)?true:false} color="primary" onChange={(e) => matrixOnChange(e.target.checked, item.row_id, data.id)} name={item.choice_text} /></td>)}
                                    </tr>
                                ):null}
                            </tbody>
                        </Table>
                        {questionDetails.fields.noneText && <div>
                            <FormControlLabel
                                control={<Checkbox checked={matrixSelectedIds.find(elem => elem.choice_id == questionDetails.fields.noneChoiceId && elem.row_id == questionDetails.fields.noneId)?true:false} color="primary" onChange={(e) => matrixOnChange(e.target.checked, questionDetails.fields.noneId, questionDetails.fields.noneChoiceId)} name={questionDetails.fields.noneText} />}
                                label={t(questionDetails.fields.noneText)}
                            />
                        </div> }
                        {questionDetails.fields.containsOther && <FormGroup>
                            <Label>{t(questionDetails.fields.otherOptionText)}</Label><br />
                            <TextField style={{paddingBottom: '2%'}} name={questionDetails.fields.otherOptionText} type="text" placeholder={`${t('Type your answer...')}`} onChange={e => setMatrixOthers(e.target.value)} />
                        </FormGroup>}
                    </div>
                    {questionID == 556181583 || questionID == 556181824 ||  questionID == 556181470 || questionID == 553981633 || questionID == 556167203 || questionID == 556181583 || questionID == 556181518 ? <Data/> : ""  }
                </div>
            }
            <div className="bg-white container mb-3" style={{borderRadius: '2px'}}>
                    {error && <p style={{color: 'red',padding:'10px 0'}}>{t(errorMsg)}<br /></p>}
                    <div className='px-2 py-3' style={{display: 'flex', justifyContent: 'space-between'}}>
                        <p style={{fontSize: '12px', lineHeight: '1.17', color: '#afafaf',marginBottom:'0',paddingTop:'10px'}}>{t('Powered by')}<img src={process.env.LOGO_URI} style={{marginLeft: '10px'}} className="footer-img-logo" /></p>
                        <div className="button-footer-wrapper">
                            <Button style={{backgroundColor: 'transparent', color: '#5e6265', border: 'none'}} onClick={prevPage} type='button' >{t('Previous')}</Button>
                            <Button style = {{marginLeft: '10px', backgroundColor: '#055ce5', color: 'white', borderRadius: '2px', border: 'none'}} onClick={nextPage} type='button' >{t('Next')}</Button>
                        </div>
                    </div>
                </div>
            </div>:null}
        </Container>
    )
}

const mapStateToProps = state => {
    return {
        questionContent : state.question.content,
    }
}

export default connect(mapStateToProps)(MatrixWithCheckbox)
