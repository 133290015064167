import React, { useState } from 'react'
import {NavLink,Link,withRouter} from 'react-router-dom'
import {orgAdmin , superAdmin, users} from '../appLayout/_navLable'
import { connect } from 'react-redux'
import footerLogo from '../assests/images/compros.png'
import {navChange} from '../redux/actions/onboarding'
import { useTranslation } from 'react-i18next';

function Sidebar({
    profileDetails  : {
        is_superuser,
        is_org_admin
    },
    history,
    history : {
        location  : {
            pathname
        }
    },
    ...props
}) {

    const [active,setIndex] = useState(0)


    const { t, i18n } = useTranslation();


    const handleActive = (index,url) => {
        document.body.classList = ""
        setIndex(index)
        props.dispatch(navChange(active))
        history.push(url)
    }

    return (
        <aside className="sidebar">
            <div className="parent-block">
                <ul>
                    {
                       is_superuser ?   superAdmin.map( (item, index) => {
                                            return <li className={item.link == pathname ? "active" : ""} onClick={ () => handleActive(index , item.link) }><Link  to="#">{t(item.label)}</Link></li>
                                        }) :
                       is_org_admin  ?   orgAdmin.map( (item, index) => {
                                            return <li className={item.link == pathname ? "active" : ""} onClick={ () => handleActive(index, item.link) }><Link  to="#"  >{t(item.label)}</Link></li>
                                         }) :
                                         users.map( (item, index) => {
                                            return <li className={item.link == pathname ? "active" : ""} onClick={ () => handleActive(index, item.link) }><Link  to="#"  >{t(item.label)}</Link></li>
                                         })

                    }
                </ul>
                <div className="logo-brand">
                    <a href="http://www.compriscare.com/" target="_blank">
                        <span>{`${t('Powered by')}`}</span>
                        <img className="footer-logo" src={process.env.LOGO_URI}/>
                    </a>
                </div>
                <div className="powered-by">
                    <h3><Link to="/faqs">{`${t('Need help ? FAQs')}`}</Link></h3>
                </div>
            </div>
        </aside>
    )
}

const mapStateToProps = state => {
    return {
        profileDetails : state.onboarding.profileDetails,
        ...state
    }
}

const sideBarComp = withRouter(Sidebar)

export default connect(mapStateToProps)(sideBarComp)
