import * as yup from 'yup'; // for everything
// Username validatioj

const registerValidation = () => {

    return yup.object().shape({
        name:  yup.string().required('Required').trim(),
        owner_name:  yup.string().required('Required').trim(),
        role:  yup.string().required('Required').trim(),
        monthly_intakes:  yup.string().required('Required').trim(),
        city:  yup.string().required('Required').trim(),
        state:  yup.string().required('Required').trim(),
        address1:  yup.string().required('Required').trim(),
        domain_name : yup.string().required('Required').trim(),
        phone_country:  yup.string().required('Required').trim(),
        zipcode : yup.string().required('Required').trim(),
        country : yup.string().required('Required').trim(),
        phone:  yup.string().required('Required').trim(),
        email :  yup.string().email('Invalid email').required('Required').trim(),
    })
}

export {
    registerValidation
}