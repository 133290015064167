
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { withRouter} from 'react-router-dom'
import { useTranslation } from 'react-i18next'; 



const BottomContent2 = ({
    history,
    location : {
        pathname
    },
    ...props
}) => {
    const { t, i18n } = useTranslation();
    return ( <div className={`powered-by-block no-pagination`}>
                <h3>{`${t('Designed and Developed by ')}`}<a href="https://www.neenopal.com/" target="_blank"> Neenopal Inc</a></h3>
             </div>
        )
}

const mapStateToProps = state => {
    return {
        ...state
    }
}

export default connect(mapStateToProps)(withRouter(BottomContent2))