const initialState={
    content: {},
    cmrID: '',
    response_id: ''
}

const questionReducer = (state = initialState,action) => {
    switch(action.type){
        case `UPDATE_CONTENT` :
            return { 
                ...state,
                content : action.payload,
            }
        case `CMR_ID`:
            

            return {
                ...state,
                cmrID: action.payload
            }
        case `RESPONSE_ID`:
            return {
                ...state,
                response_id: action.payload
            }
        default : 
        return state
    }
}

export { 
    questionReducer
}