import React, { Fragment, useEffect, useState } from 'react'
import { Formik , Field, FieldArray} from 'formik'
import { registerValidation } from './validation'
import CustomInput from '../../helperComponents/Input'
import countryCode from '../clinicProfile/country_code'
import {treatemnt, Insurance , countries} from './options'
import SelectComponent from '../../helperComponents/Select'
import logo from '../../assests/images/compros.png'
import { Label, Button , Row, Col } from 'reactstrap'
import service from '../../interceptor'
import {APIS_URL} from '../../utils/apiEndPoints'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'; 
import CustomModal from '../../helperComponents/modal'

function Register({
    history,
    ...props
}) {
    

    const { t , i18n } = useTranslation();

    const handleRegister = (values) => {    
        if(values.treatment_offered.length <=0){
            toast.error(t('Please select at least one treatment'))
        }
        else if(values.insurance_accepted.length <=0)
        {   
            toast.error(t('Please select at least one insurance'))
        }
        else
        {
            service.post(APIS_URL.CLINIC_MASTER,values).then( (res) => {
                toast.success(t("Kindly check your email for instructions to access your portal. (If you don't see it, please check your spam folder.)"))
                window.location.href = "https://www.compriscare.com/thanks-for-joining-us"
                // history.push('/')
                // setPopup(true)
            })
        }
    }   



    // const [popup, setPopup] = useState(true)

    const [codes,setCodes] = useState()

    useEffect( () => {
        let Countyvalues = countryCode.map( (item,index) => {
            return {
                label : `${item.dial_code} (${item.code})`,
                value : item.code
            }
        })
        setCodes(Countyvalues)
    },[])


    return (
        <Fragment>
                <div className="form-wrapper-outer">
                    <div className="form-wrapper-children">
                        <div className="header-text">
                            <img src={logo} alt=""/>
                            <h2>{t('HEALTHCARE / SERVICE PROVIDER CONTACT FORM')}</h2>
                            <h3>{t("Welcome! We're very excited to work with you.")}</h3>
                        </div>

                        <div className="form-wrapper">
                            <div className="header-text-wrapper">
                                <h2>{t('Contact Information')}</h2>
                                <span>{t('Fields marked with * are mandatory')}</span>
                            </div>
                        </div>
                        
                        <Formik
                            validationSchema={registerValidation}
                            initialValues={{                                
                                name:"",
                                owner_name:"",
                                role:"",
                                treatment_offered: [],
                                other_treatment:"",
                                insurance_accepted: [],
                                other_insurance:"",
                                monthly_intakes:"",
                                others:"",
                                comments:"",
                                city:"",
                                phone_country : "",
                                state:"",
                                domain_name : "",
                                country:"",
                                address1:"",
                                address2:"",
                                zipcode:"",
                                phone:"",
                                email : '',
                            }}
                            onSubmit={(values, actions) => {
                                handleRegister(values)
                            }}  
                            >
                            {props => (
                                <form onSubmit={props.handleSubmit}>
                                    <Row>
                                        <Col md={6} sm={6} xs={12} lg={6}>
                                            <div className="form-control-wrapper">
                                                <Label>{t('Enter Your Name *')}</Label>
                                                <Field type="text" name="owner_name" component={CustomInput} />
                                            </div>
                                        </Col>
                                        <Col md={6} sm={6} xs={12} lg={6}>
                                            <div className="form-control-wrapper">
                                                <Label>{t('Enter Your Company / Clinic Name *')}</Label>
                                                <Field type="text" name="name" component={CustomInput} />
                                            </div>
                                        </Col>

                                        <Col md={12} sm={12} xs={12} lg={12}>
                                            <div className="form-control-wrapper">
                                                <Label>{t('Company / Clinic Address (line 1) *')}</Label>
                                                <Field type="text" name="address1" component={CustomInput} />
                                            </div>
                                        </Col>

                                        <Col md={12} sm={12} xs={12} lg={12}>
                                            <div className="form-control-wrapper">
                                                <Label>{t('Company / Clinic Address (line 2)')}</Label>
                                                <Field type="text" name="address2" component={CustomInput} />
                                            </div>
                                        </Col>

                                        <Col md={6} sm={6} xs={12} lg={6}>
                                            <div className="form-control-wrapper">
                                                <Label>{t('Your City / Town *')}</Label>
                                                <Field type="text" name="city" component={CustomInput} />
                                            </div>
                                        </Col>
                                        <Col md={6} sm={6} xs={12} lg={6}>
                                            <div className="form-control-wrapper">
                                                <Label>{t('State / Province *')}</Label>
                                                <Field type="text" name="state" onChange={ (value) => props.setFieldValue('state', value.value,true) }  component={SelectComponent} options={countries} />
                                            </div>
                                        </Col>

                                        <Col md={6} sm={6} xs={12} lg={6}>
                                            <div className="form-control-wrapper">
                                                <Label>{t('Country *')}</Label>
                                                <Field type="text" name="country" component={CustomInput} />
                                            </div>
                                        </Col>


                                        <Col md={6} sm={6} xs={12} lg={6}>
                                            <div className="form-control-wrapper">
                                                <Label>{t('Zip or postal code *')}</Label>
                                                <Field type="text" name="zipcode" component={CustomInput} />
                                            </div>
                                        </Col>
                                        <Col md={6} sm={6} xs={12} lg={6}>
                                            <Row>
                                                <Col lg={5} sm={5} md={5} xs={5} >
                                                    <Label className="phone">{t('Country Code *')}</Label>
                                                    <Field   onChange={ (value) => props.setFieldValue('phone_country',value.value,true) } component={SelectComponent} options={codes}  name="phone_country" type="text"/>
                                                </Col>
                                                <Col lg={7} sm={7} md={7} xs={7} >
                                                    <Label className="phone">{t('Contact number *')}</Label>
                                                    <Field  component={CustomInput}  name="phone" type="text"/>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6} sm={6} xs={12} lg={6}>
                                            <div className="form-control-wrapper">
                                                <Label>{t('Email Address *')}</Label>
                                                <Field type="text" name="email" component={CustomInput} />
                                            </div>
                                        </Col>
                                    </Row>
                            
                                    <div className="form-wrapper">
                                        <div className="header-text-wrapper">
                                            <h2>{t('Website')}</h2>
                                        </div>
                                    </div>

                                    <Row>
                                        <Col md={6} sm={6} xs={12} lg={6}>
                                            <div className="form-control-wrapper">
                                                <Label>{t('Company / Clinic Website')}</Label>
                                                <Field type="text" name="domain_name" component={CustomInput} />
                                            </div>
                                        </Col>
                                    </Row>

                                    <div className="form-wrapper">
                                        <div className="header-text-wrapper">
                                            <h2>{t('Role')}</h2>
                                        </div>
                                    </div>
                                    <Row>
                                        <Col md={6} sm={6} xs={12} lg={6}>
                                            <div className="form-control-wrapper">
                                                <Label>{t('Enter your role in the Company / Clinic')}</Label>
                                                <Field type="text" name="role" component={CustomInput} />
                                            </div>
                                        </Col>
                                    </Row>

                                    {/* <div className="form-wrapper">
                                        <div className="header-text-wrapper">
                                            <h2>Assessment Sharing</h2>
                                            <span>You can add multiple People</span>
                                        </div>
                                    </div> */}
{/* 
                                    <FieldArray
                                        name="users"
                                        render={arrayHelpers => (
                                        <div>
                                            {props.values.users && props.values.users.length > 0 ? (
                                            props.values.users.map((friend, index) => (
                                                <div className="form-array-wrapper" key={index}>
                                                    <div className="form-control-wrapper">
                                                        <Label>Name</Label>
                                                        <Field component={CustomInput}  name={`users[${index}].name`} />
                                                    </div>
                                                    <div className="form-control-wrapper">
                                                        <Label>Email</Label>
                                                        <Field component={CustomInput}  name={`users[${index}].email`} />
                                                    </div>
                                                    <div className="form-button-wrapper">
                                                        {
                                                            props.values.users.length > 1 &&
                                                            <Button color="primary" type="button" onClick={() => arrayHelpers.remove(index)}>
                                                            <i style={{paddingRight:"10px"}} class="fa fa-minus" aria-hidden="true"></i>Remove this Person</Button>
                                                        }
                                                        <Button color="primary" type="button" onClick={() => arrayHelpers.insert(index, '')}>
                                                            <i style={{paddingRight:"10px"}} class="fa fa-plus" aria-hidden="true"></i>Add Another Person
                                                        </Button>
                                                    </div>
                                            </div>
                                        ))
                                        ) : (
                                        <Button color="primary" type="button" onClick={() => arrayHelpers.push('')}>
                                            Add Another Person
                                        </Button>
                                        )}
                                        </div>
                                        )}
                                    /> */}

                                    <div className="mrker">
                                         <div className="marker-content">
                                                <span>{t('WELL DONE!')}</span>
                                                <h2>{t("You're almost there!")}</h2>
                                                <p>{t('Just a few more questions and you will be done with the signup')}</p>
                                         </div>
                                    </div>

                                    <div className="form-wrapper">
                                        <div className="header-text-wrapper">
                                            <h2>{t('Treatment / Services Offered')}</h2>
                                            <span>{t('Please Check all that apply')}</span>
                                        </div>
                                    </div>
                                    <Row>   
                                        {
                                            treatemnt.map( (item,index) => {
                                                return      <Col md={4} sm={4} xs={12}>
                                                                <div className="checkbox register-checkbox">
                                                                    <Field type="checkbox" id={`${item.name}`} name={`treatment_offered`} value={item.name} />
                                                                    <label htmlFor={`${item.name}`}>
                                                                        <span>{t(item.name)}</span>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                            })
                                        }
                                    </Row>
                                    <Row>
                                        <Col md={6} sm={6} xs={12} lg={6}>
                                            <div className="form-control-wrapper">
                                                <Label>{t('Others (please specify)')}</Label>
                                                <Field type="text" name="other_treatment" component={CustomInput} />
                                            </div>
                                        </Col>
                                    </Row>

                                    <div className="form-wrapper">
                                        <div className="header-text-wrapper">
                                            <h2>{t('Insurance Accepted')}</h2>
                                            <span>{t('Please Check all that apply')}</span>
                                        </div>
                                    </div>
                                    
                                    <Row>   
                                        {
                                            Insurance.map( (item,index) => {
                                                return      <Col md={6} sm={6} xs={12}>
                                                                <div className="checkbox register-checkbox">
                                                                    <Field type="checkbox" id={`${item.name}`} name={`insurance_accepted`} value={item.name} />
                                                                    <label htmlFor={`${item.name}`}>
                                                                        <span>{t(item.name)}</span>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                            })
                                        }
                                    </Row>
                                    <Row>
                                        <Col md={12} sm={12} xs={12} lg={12}>
                                            <div className="form-control-wrapper">
                                                <Label>{t('Others (please specify)')}</Label>
                                                <Field type="text" name="other_insurance" component={CustomInput} />
                                            </div>
                                        </Col>
                                    </Row>


                                    <div className="form-wrapper">
                                        <div className="header-text-wrapper">
                                            <h2>{t('Monthly Intakes')}</h2>
                                        </div>
                                    </div>
                                    <Row>
                                        <Col md={12} sm={12} xs={12} lg={12}>
                                            <div className="form-control-wrapper">
                                                <Label>{t("Estimated number of monthly intakes* (If known, this helps us anticipate your monthly needs. Otherwise, any 'ballpark' number is fine.")}</Label>
                                                <Field type="text" name="monthly_intakes" component={CustomInput} />
                                            </div>
                                        </Col>
                                    </Row>

                                    <div className="form-wrapper">
                                        <div className="header-text-wrapper">
                                            <h2>{t('Other')}</h2>
                                        </div>
                                    </div>
                                    <Row>
                                        <Col md={12} sm={12} xs={12} lg={12}>
                                            <div className="form-control-wrapper">
                                                <Label>{t('If there are other providers in your clinic who will need access to assessments, please add their names and emails here. (They will receive a welcome email and their own access portal.)')}</Label>
                                                <Field type="text" name="others" component={CustomInput} />
                                            </div>
                                        </Col>

                                        <Col md={12} sm={12} xs={12} lg={12}>
                                            <div className="form-control-wrapper">
                                                <Label>{t('Questions or Comments')}</Label>
                                                <Field type="textarea" name="comments" component={CustomInput} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <span className="compris-text">{t('Compris is a proprietary diagnostic tool, By signing on to use it, you are acknowledging this awareness.')}</span>
                                
                                    <Button className="primary submit-btn">{t('Submit Details')}</Button>
                                </form>
                        )}
                    </Formik>
{/* 
                    <CustomModal state={popup} setState={setPopup}>
                        <div className="message-box">
                            <p>Kindly check your email for instructions to access your portal. (If you don't see it, please check your spam folder.)</p>
                        </div>
                    </CustomModal> */}

                    </div>
                </div>
        </Fragment>   
    )
}
export default Register
