import React from 'react'
import Select from 'react-select';  
import { useTranslation } from 'react-i18next'; 


const SelectComponent = ({
    field, 
    form: { touched, errors },
    ...props
  }) => {

    const { t, i18n } = useTranslation();


    return (<div className="form-group">
        <Select
            invalid={errors[field.name] ? true : false}
            defaultValue={props.defaultValue}          
            value={props.value}
            placeholder={t("Select")}
            onChange={props.onChange}
            options={props.options}
      />
      {touched[field.name] && errors[field.name] && <div className="error">{t(errors[field.name])} </div>}
    </div> )
  }

export default SelectComponent