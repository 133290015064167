import React , {useState} from 'react'
import { DropdownItem } from 'reactstrap'
import CustomDropdown from './dropdown'
import { useTranslation } from 'react-i18next';
import  usLogo from '../assests/images/flag.svg'

export default function LangDropdown() {

    const [render,setRender] = useState(true)

    const [language,setLanguage] = useState(false)

    const [lang, updateLang] = useState(localStorage.getItem('i18nextLng'))


    const { t, i18n } = useTranslation();


    const setLanguageMiddleware = (lng) => {
        i18n.changeLanguage(lng);
        setRender(!render)
        updateLang(lng)
    }

    return (
        <div>
              <div className={ lang == 'en' ? "language-dropdown notif" : "language-dropdown notif esp"}>
                                <CustomDropdown align={'start'} state={language} text={`${lang == 'en' ?  `${t('English - US')}` :  `${t('Spanish - ESP')}`}`} header={t("Select Language")}  setState={setLanguage}>
                                        <DropdownItem onClick={ () => setLanguageMiddleware('en') }>
                                            {lang == 'en' ? `${t('Active Language')}` : ""}
                                            <span>{t('English - US')}</span>
                                        </DropdownItem>
                                        {/* <DropdownItem className="esp" onClick={ () => setLanguageMiddleware('esp') }>
                                            {lang == 'en' ? '' : `${t('Active Language')}`}
                                            <span>{t('Spanish - ESP')}</span>
                                        </DropdownItem> */}
                                    </CustomDropdown>
                        </div>    
        </div>
    )
}
