export const OPEN_TYPE = 'OPEN_TYPE'
export const PROFILE  = 'PROFILE'
export const ASSESMENT_FILTER = 'ASSESMENT_FILTER'
export const FETCH_CLINIC_MASTER = 'FETCH_CLINIC_MASTER'
export const FETCH_CLINIC_LIST =  'FETCH_CLINIC_LIST'
export const SHORT_ASSESMENT_FILTER = 'SHORT_ASSESMENT_FILTER'
export const FETCH_ASSESMENT_LIST = 'FETCH_ASSESMENT_LIST'
export const FETCH_ADMIN_CLINIC_LIST = 'FETCH_ADMIN_CLINIC_LIST'
export const FETCH_BILLING_LIST = 'FETCH_BILLING_LIST'
export const CLEAR_REDUCER = 'CLEAR_REDUCER'
export const BILLING_FILTERS = 'BILLING_FILTERS'
export const INDEX_CHANGE = 'INDEX_CHANGE'