import React, { useState, useEffect } from 'react'
import Checkbox from '@material-ui/core/Checkbox';
import Slider from '@material-ui/core/Slider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Container, Row, Col, Label, Button, Table, FormGroup } from 'reactstrap'
import { connect } from 'react-redux'
import compris from '../../assests/images/001 Compris TM clr.png'
import { updateAnswers, emptyAnswerArray } from './constants'
import { useTranslation } from 'react-i18next';


function DeathSliderQuestion({
    questionContent,
    history,
    ...props
}) {

    const [siblingSliderDeath, setSiblingSliderDeath] = useState(0)
    const [childrenSliderDeath, setChildrenSliderDeath] = useState(0)
    const [grandparentSliderDeath, setGrandParentSliderDeath] = useState(0)
    const [auntOrUncleSliderDeath, setAuntOrUncleSliderDeath] = useState(0)
    const [cousinSliderDeath, setCousinSliderDeath] = useState(0)
    const [error, setError] = useState(false)
    const [multipleChoiceAnswers, setMultipleChoiceAnswers] = useState([])
    const [previousSliderQuestionAnswer, setPreviousSliderQuestionAnswer] = useState([])

    const questionNo = questionContent.questions?questionContent.questions.findIndex(item => item.id == 587379847):-1


    const { t } = useTranslation()


    useEffect(()=>{
        let index = questionContent.questions?questionContent.questions.findIndex(item => item.id == 587379847):-1
        setPreviousSliderQuestionAnswer(questionContent.questions?questionContent.questions.find(item => item.id == 549097387).answers:[])
        if(index != -1){
            let checkboxAnswers = []
            questionContent.questions[index].answers.forEach(item => {
                if(item.choice_id == 3845567605){
                    setSiblingSliderDeath(1)
                }
                else if(item.choice_id == 3845567606){
                    setSiblingSliderDeath(2)
                }
                else if(item.choice_id == 3845567607){
                    setSiblingSliderDeath(3)
                }
                else if(item.choice_id == 3845567608){
                    setChildrenSliderDeath(1)
                }
                else if(item.choice_id == 3845567609){
                    setChildrenSliderDeath(2)
                }
                else if(item.choice_id == 3845567610){
                    setChildrenSliderDeath(3)
                }
                else if(item.choice_id == 3845567611){
                    setGrandParentSliderDeath(1)
                }
                else if(item.choice_id == 3845567612){
                    setGrandParentSliderDeath(2)
                }
                else if(item.choice_id == 3845567613){
                    setGrandParentSliderDeath(3)
                }
                else if(item.choice_id == 3845567614){
                    setAuntOrUncleSliderDeath(1)
                }
                else if(item.choice_id == 3845567615){
                    setAuntOrUncleSliderDeath(2)
                }
                else if(item.choice_id == 3845567616){
                    setAuntOrUncleSliderDeath(3)
                }
                else if(item.choice_id == 3845567617){
                    setCousinSliderDeath(1)
                }
                else if(item.choice_id == 3845567618){
                    setCousinSliderDeath(2)
                }
                else if(item.choice_id == 3845567619){
                    setCousinSliderDeath(3)
                }
                else{
                    checkboxAnswers.push(item)
                }
            })
            setMultipleChoiceAnswers([...checkboxAnswers])
        }
    }, [])

    const multipleChoiceAnswersOnChange = (checkboxStatus, choice_id) => {
        let multipleChoiceAnswersCopy = [...multipleChoiceAnswers]
        if(checkboxStatus){
            if(choice_id == 3622507397){
                multipleChoiceAnswersCopy = [{
                    choice_id: choice_id
                }]
                setSiblingSliderDeath(0)
                setChildrenSliderDeath(0)
                setGrandParentSliderDeath(0)
                setAuntOrUncleSliderDeath(0)
                setCousinSliderDeath(0)
            }
            else{
                multipleChoiceAnswersCopy.push({
                    choice_id: choice_id
                })
                const noneIndex = multipleChoiceAnswersCopy.findIndex(item => item.choice_id == 3622507397)
                if(noneIndex != -1)
                    multipleChoiceAnswersCopy.splice(noneIndex, 1)
            }
        }
        else{
            multipleChoiceAnswersCopy.splice(multipleChoiceAnswersCopy.findIndex(item => item.choice_id === choice_id), 1)
        }
        setMultipleChoiceAnswers(multipleChoiceAnswersCopy)
    }

    const marks = [
        {
          value: 0,
          label: "0"
        },
        {
          value: 1,
          label: "1"
        },
        {
          value: 2,
          label: "2"
        },
        {
          value: 3,
          label: "3+"
        }
    ];

    function valueLabelFormat(value) {
        return marks.find((mark) => mark.value === value).label;
    }

    const nextPage = () => {
        if(multipleChoiceAnswers.length>0 || siblingSliderDeath>0 || childrenSliderDeath>0 || grandparentSliderDeath>0 || auntOrUncleSliderDeath>0 || cousinSliderDeath>0){
            setError(false)
            let answerCopy = [...multipleChoiceAnswers]
            let questionContentCopy = JSON.parse(JSON.stringify(questionContent))
            if(siblingSliderDeath> 0){
                switch (siblingSliderDeath){
                    case 1: 
                        answerCopy.push({choice_id: 3845567605});
                        break;
                    case 2: 
                        answerCopy.push({choice_id: 3845567606});
                        break;
                    case 3: 
                        answerCopy.push({choice_id: 3845567607});
                        break;
                }
            }
            if(childrenSliderDeath> 0){
                switch (childrenSliderDeath){
                    case 1: 
                        answerCopy.push({choice_id: 3845567608});
                        break;
                    case 2: 
                        answerCopy.push({choice_id: 3845567609});
                        break;
                    case 3: 
                        answerCopy.push({choice_id: 3845567610});
                        break;
                }
            }
            if(grandparentSliderDeath> 0){
                switch (grandparentSliderDeath){
                    case 1: 
                        answerCopy.push({choice_id: 3845567611});
                        break;
                    case 2: 
                        answerCopy.push({choice_id: 3845567612});
                        break;
                    case 3: 
                        answerCopy.push({choice_id: 3845567613});
                        break;
                }
            }
            if(auntOrUncleSliderDeath> 0){
                switch (auntOrUncleSliderDeath){
                    case 1: 
                        answerCopy.push({choice_id: 3845567614});
                        break;
                    case 2: 
                        answerCopy.push({choice_id: 3845567615});
                        break;
                    case 3: 
                        answerCopy.push({choice_id: 3845567616});
                        break;
                }
            }
            if(cousinSliderDeath> 0){
                switch (cousinSliderDeath){
                    case 1: 
                        answerCopy.push({choice_id: 3845567617});
                        break;
                    case 2: 
                        answerCopy.push({choice_id: 3845567618});
                        break;
                    case 3: 
                        answerCopy.push({choice_id: 3845567619});
                        break;
                }
            }
            const index = questionContentCopy.questions.findIndex(item => item.id == 587379847)
            let update = false
            if(index!=-1){
                update = true
            }
            updateAnswers(questionContentCopy, questionNo==-1?questionContent.questions?questionContent.questions.length+1:0:questionNo+1, {
                id: 587379847,
                answers: answerCopy
            }, update)
            const questionNo25Answer = questionContent.questions.find(item => item.id == 580243889).answers
            if(questionNo25Answer[0].choice_id == 3821196632){
                emptyAnswerArray({549097420: true, 549097421: true, 549097391: true, 549097389: true, 553973451: true, 553981633: true, 556167203: true, 556181470: true, 556181518: true, 556181583: true, 556181824: true, 554010038: true, 555422217: true}, questionContentCopy)
                history.push(`/surveyQuestions/549097448`)
            }
            else{
                const questionNo26Answer = questionContent.questions.find(item => item.id == 554023749).answers
                const rxOpiates = questionNo26Answer.find(item => item.row_id == 3653851638)
                if(questionNo26Answer.every(item => item.choice_id == 3653851651)){
                    emptyAnswerArray({549097420: true, 549097421: true, 549097391: true, 549097389: true, 553973451: true, 553981633: true, 556167203: true, 556181470: true, 556181518: true, 556181583: true, 556181824: true, 554010038: true, 555422217: true}, questionContentCopy)
                    history.push(`/surveyQuestions/549097448`) //skip to question number 46 
                }
                else if(rxOpiates?rxOpiates.choice_id == 3653851651?true:false:true){
                    emptyAnswerArray({549097420: true, 549097421: true, 549097391: true}, questionContentCopy)
                    history.push(`/surveyQuestions/549097389`) //skip to question 36
                }
                else{
                    history.push(`/surveyQuestions/549097420`)
                }
            }
            props.dispatch({
                type: `UPDATE_CONTENT`,
                payload: questionContentCopy
            })
        }
        else{
            setError(true)
        }
    }

    const prevPage = () => {
        history.push(`/surveyQuestions/549097387`)
    }

    const updateSlider = (name, value) => {
        name(value)
        const noneIndex = multipleChoiceAnswers.findIndex(item => item.choice_id == 3622507397)
        if(noneIndex != -1)
            multipleChoiceAnswers.splice(noneIndex, 1)
    }

    return (
        <div className="slider-container-block">
            <Container>
                <div className="bg-white container mb-3" style={{borderRadius: '2px'}}>
                    <div className="p-2">
                        <p>{questionNo==-1?questionContent.questions?questionContent.questions.length+1:0:questionNo+1}. {t('Any BIOLOGICAL RELATIVES died from a substance problem other than tobacco/nicotine? (Check all that apply)')}</p>
                        <Row>
                            {previousSliderQuestionAnswer.find(item => item.choice_id == 3622507682)? <Col>
                                <FormControlLabel
                                    control={<Checkbox name='father' color="primary" onChange={(e) => multipleChoiceAnswersOnChange(e.target.checked, 3845567603)} checked={multipleChoiceAnswers.find(data => data.choice_id == 3845567603)?true:false} />}
                                    label={t('father')}
                                />
                            </Col>: null}
                            {previousSliderQuestionAnswer.find(item => item.choice_id == 3622507683)? <Col>
                                <FormControlLabel
                                    control={<Checkbox name='father' color="primary" onChange={(e) => multipleChoiceAnswersOnChange(e.target.checked, 3845567604)} checked={multipleChoiceAnswers.find(data => data.choice_id == 3845567604)?true:false} />}
                                    label={t('mother')}
                                />
                            </Col>: null}
                        </Row>
                        {previousSliderQuestionAnswer.find(item => item.choice_id == 3622507684 || item.choice_id == 3622507685 || item.choice_id == 3622507686)?<Row>
                            <Col md={6}>
                                <p className="MuiFormControlLabel-label">{t('siblings')}</p>
                                    <Slider
                                        value={siblingSliderDeath}
                                        valueLabelFormat={valueLabelFormat}
                                        aria-labelledby="discrete-slider-restrict"
                                        step={null}
                                        valueLabelDisplay="auto"
                                        marks={marks}
                                        onChange={(e, newValue) => updateSlider(setSiblingSliderDeath, newValue)}
                                        max={3}
                                    />
                            </Col>
                        </Row>: null}
                        {previousSliderQuestionAnswer.find(item => item.choice_id == 3622507687 || item.choice_id == 3622507688 || item.choice_id == 3622507689)?<Row>
                            <Col md={6}>
                                <p className="MuiFormControlLabel-label">{t('children')}</p>
                                    <Slider
                                        value={childrenSliderDeath}
                                        valueLabelFormat={valueLabelFormat}
                                        aria-labelledby="discrete-slider-restrict"
                                        step={null}
                                        valueLabelDisplay="auto"
                                        marks={marks}
                                        onChange={(e, newValue) => updateSlider(setChildrenSliderDeath, newValue)}
                                        max={3}
                                    />
                            </Col>
                        </Row>: null}
                        {previousSliderQuestionAnswer.find(item => item.choice_id == 3622507690 || item.choice_id == 3622507691 || item.choice_id == 3622507692)?<Row>
                            <Col md={6}>
                                <p className="MuiFormControlLabel-label">{t('grandparent')}</p>
                                    <Slider
                                        value={grandparentSliderDeath}
                                        valueLabelFormat={valueLabelFormat}
                                        aria-labelledby="discrete-slider-restrict"
                                        step={null}
                                        valueLabelDisplay="auto"
                                        marks={marks}
                                        onChange={(e, newValue) => updateSlider(setGrandParentSliderDeath, newValue)}
                                        max={3}
                                    />
                            </Col>
                        </Row>: null}
                        {previousSliderQuestionAnswer.find(item => item.choice_id == 3622507693 || item.choice_id == 3622507694 || item.choice_id == 3622507695)?<Row>
                            <Col md={6}>
                                <p className="MuiFormControlLabel-label">{t('an aunt or uncle')}</p>
                                    <Slider
                                        value={auntOrUncleSliderDeath}
                                        valueLabelFormat={valueLabelFormat}
                                        aria-labelledby="discrete-slider-restrict"
                                        step={null}
                                        valueLabelDisplay="auto"
                                        marks={marks}
                                        onChange={(e, newValue) => updateSlider(setAuntOrUncleSliderDeath, newValue)}
                                        max={3}
                                    />
                            </Col>
                        </Row>: null}
                        {previousSliderQuestionAnswer.find(item => item.choice_id == 3622507696 || item.choice_id == 3622507697 || item.choice_id == 3622507698)?<Row>
                            <Col md={6}>
                                <p className="MuiFormControlLabel-label">{t('a cousin, niece or nephew')}</p>
                                    <Slider
                                        value={cousinSliderDeath}
                                        valueLabelFormat={valueLabelFormat}
                                        aria-labelledby="discrete-slider-restrict"
                                        step={null}
                                        valueLabelDisplay="auto"
                                        marks={marks}
                                        onChange={(e, newValue) => updateSlider(setCousinSliderDeath, newValue)}
                                        max={3}
                                    />
                            </Col>
                        </Row>: null}
                        <Row>
                            <Col>
                                <FormControlLabel
                                    control={<Checkbox name='none' color="primary" onChange={(e) => multipleChoiceAnswersOnChange(e.target.checked, 3622507397)} checked={multipleChoiceAnswers.find(data => data.choice_id == 3622507397)?true:false} />}
                                    label={t("none or don't know")}
                                />
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="bg-white container mb-3" style={{borderRadius: '2px'}}>
                    {error && <p style={{color: 'red',padding:'10px 0'}}>{t(`Please answer the question`)}<br /></p>}
                    <div className='px-2 py-3' style={{display: 'flex', justifyContent: 'space-between'}}>
                        <p style={{fontSize: '12px', lineHeight: '1.17', color: '#afafaf',marginBottom:'0',paddingTop:'10px'}}>{t('Powered by')} <a href="https://www.compriscare.com/" target="_blank"><img src={process.env.LOGO_URI} style={{marginLeft: '10px'}} className="footer-img-logo" /></a></p>
                        <div className="button-footer-wrapper">
                            <Button style={{backgroundColor: 'transparent', color: '#5e6265', border: 'none'}} onClick={prevPage} type='button' >{t('Previous')}</Button>
                            <Button style = {{marginLeft: '10px', backgroundColor: '#055ce5', color: 'white', borderRadius: '2px', border: 'none'}} onClick={nextPage} type='button' >{t('Next')}</Button>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        questionContent : state.question.content,
    }
}

export default connect(mapStateToProps)(DeathSliderQuestion)
