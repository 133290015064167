import React , {useState } from 'react'
import { Row, Col,Button } from 'reactstrap'
import Sidebar from './Sidebar'
import Page1 from './Page1'
import Page2 from './Page2'
import Page3 from './Page3'
import Page4 from './Page4'
import QuestionsById from './QuestionsById'
import { connect } from 'react-redux'
import QuestionNo32 from './QuestionNo32' 
import CompleteSurvey from './CompleteSurvey'
import ScrollToTop from './ScrollToTop'
import MatrixWithCheckbox from './MatrixWithCheckbox.js'
import DeathSliderQuestion from './DeathSliderQuestion'
import MatrixWithInputs from './MatrixWithInputs'
import EngaedSlider from './EngagedSlider'

function Questions({
    history,
    questionContent,
    ...props
}) {

    const [state,setState] =  useState(false)
    const [updateProgressTo100, setUpdateProgressTo100] = useState(false)

    // refresh issue fixed
    if(Object.keys(questionContent).length === 0){
        history.push(`/assessment`)
    }

    const questionID = props.match.params.questionId
    return (
        <div className={`${state ? "showLeft" : "hideLeft"}`} style={{backgroundColor: '#f3f3f3',overflow:'hidden'}}>
            <ScrollToTop />
            <div>
                <Button onClick={ () => setState(!state)} className={`${state ? "d-lg-block show toogle-btn" : "d-lg-block toogle-btn"}`}>
                    <span></span>
                    <span></span>
                    <span></span>
                </Button>
                <div><Sidebar questionID={questionID} updateProgressTo100={updateProgressTo100} /></div>
                <div className="sidebar-left">
                    {questionID === 'page1' ? <Page1 history={history} />:
                    questionID === 'page2' ? <Page2 history={history} />:
                    questionID === 'page3' ? <Page3 history={history} />:
                    questionID == 549097351 ? <EngaedSlider history={history}/> :
                    questionID === 'page4' ? <Page4 history={history} />:
                    questionID == 549097387 ? <QuestionNo32 history={history} />:
                    questionID == 553973451 || questionID==553981633  || questionID == 553981634 ? <MatrixWithCheckbox history={history} questionID={questionID} />:
                    questionID == 'completion' ? <CompleteSurvey history={history} setUpdateProgressTo100={setUpdateProgressTo100} />:
                    questionID == 587379847 ? <DeathSliderQuestion history={history} />:
                    questionID == 555422217 ? <MatrixWithInputs questionID={questionID} history={history} />:
                    <QuestionsById questionID={questionID} history={history} />}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        questionContent : state.question.content,
    }
}

export default connect(mapStateToProps)(Questions)
