import React from 'react'
import {Row,Col} from 'reactstrap'
import { useTranslation } from 'react-i18next';

function Data(){
    
    const { t, i18n } = useTranslation();

    return ( 
        <div>
            <div className="sliderCategory d-sm-none d-md-none d-block bg-white  categories-block"> 
            <Row>    
                    <Col md={12} xs={12} sm={12}>
                        <label className="label"><span>1</span> {t('used too much')}</label>
                    </Col>                
                    <Col md={12} xs={12} sm={12}>
                    <   label className="label"><span>2</span> {t("couldn't stop")}</label>
                    </Col>  
                    <Col md={12} xs={12} sm={12}>
                        <label className="label"><span>3</span> {t('too much time')}</label>
                    </Col>
                    <Col md={12} xs={12} sm={12}>
                        <label className="label"><span>4</span> {t('strong cravings')}</label>
                    </Col> 
                    <Col md={12} xs={12} sm={12}>
                        <label className="label"><span>5</span> {t('ignored duties')}</label>
                    </Col>
                    <Col md={12} xs={12} sm={12}>
                        <label className="label"><span>6</span> {t('worried others')}</label>
                    </Col>
                    <Col md={12} xs={12} sm={12}>
                        <label className="label"><span>7</span> {t('lost interests')}</label>
                    </Col>     
                    <Col md={12} xs={12} sm={12}>
                        <label className="label"><span>8</span> {t('unsafe situations')}</label>
                    </Col>
                    <Col md={12} xs={12} sm={12}>
                        <label className="label"><span>9</span> {t('despite feeling bad')}</label>
                    </Col>
                    <Col md={12} xs={12} sm={12}>
                        <label className="label"><span>10</span> {t('needed more and more')}</label>
                    </Col>
                    <Col md={12} xs={12} sm={12}>
                        <label className="label"><span>11</span> {t('withdrawal if stopped')}</label>
                    </Col>  
                </Row>
                </div> 
                <div className="sliderCategory d-sm-block d-none  categories-block"> 
                    <div className="flex-wrap">
                        <div className="flex-child">
                            <div className="block-child">
                                <label className="label"><span>1</span> {t('used too much')}</label>
                            </div>
                            <div className="block-child">
                                <label className="label"><span>2</span> {t("couldn't stop")}</label>
                            </div>
                            <div className="block-child">
                                <label className="label"><span>3</span> {t('too much time')}</label>
                            </div>
                            <div className="block-child">
                                <label className="label"><span>4</span> {t('strong cravings')}</label>
                            </div>
                        </div>
                        <div className="flex-child">
                            
                                <div className="block-child">
                                    <label className="label"><span>5</span> {t('ignored duties')}</label>
                                </div>
                                <div className="block-child">
                                <label className="label"><span>6</span> {t('worried others')}</label>
                                </div>
                                <div className="block-child">
                                    <label className="label"><span>7</span> {t('lost interests')}</label>
                                </div>
                        </div>
                        <div className="flex-child">
                        <div className="block-child">
                                <label className="label"><span>8</span> {t('unsafe situations')}</label>
                                </div>
                                <div className="block-child">
                                <label className="label"><span>9</span> {t('despite feeling bad')}</label>
                                </div>
                                <div className="block-child">
                                <label className="label"><span>10</span> {t('needed more and more')}</label>
                                </div>
                                <div className="block-child">
                                <label className="label"><span>11</span> {t('withdrawal if stopped')}</label>
                                </div>  
                        </div>
                    </div>
                </div>
             
        </div>
    )
}

export default Data