import React from 'react'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import { useTranslation } from 'react-i18next';

function CustomDropdown({
    state,
    setState,
    align,
    children,
    className,
    image,
    text,
    header,
    ...props
}) {
    const { t, i18n } = useTranslation();
    return (
        <Dropdown align={align} className={`clinic-block more-dropdown ${className}`} isOpen={state} toggle={() => setState(!state)}>
                <DropdownToggle caret>
                    {image ? <img style={{paddingRight:"5px"}}  src={image}/> : ""}
                    {text ? text :`${t('More')}`}
                <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
            </DropdownToggle>
            <DropdownMenu>
                <DropdownItem header>
                    { header ? header :`${t('More Options')}`}
                    <span onClick={ () => setState(false) } style={{float:"right",color:"#0356E7",cursor:"pointer",fontSize:"12px",textTransform:"uppercase"}}>{t('Hide')}</span>
                </DropdownItem>
                { children }
            </DropdownMenu>
        </Dropdown>
    )
}

export default CustomDropdown
