import React, { useState } from 'react'
import { Formik , Field } from 'formik'
import { Container, Row, Col, Button } from 'reactstrap'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MaterialInput from '../../helperComponents/MaterialInput'
import compris from '../../assests/images/001 Compris TM clr.png'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next';
import Checkbox from '@material-ui/core/Checkbox';
import { updateAnswers } from './constants'

function Page2({
    questionContent,
    history,
    ...props
}) {

    const [error, setError] = useState(false)

    const { t } = useTranslation()


    const nextPage = (values) => {
        if(values.relationshipStatus && values.sexualPreference && values.race.length>0 ){
            setError(false)
            const index = values.race.findIndex(item => item.other_id == 3622508114)
            if(index != -1){
                values.race[index].text = values.others
            }
            let questionContentCopy = JSON.parse(JSON.stringify(questionContent))
            updateAnswers(questionContentCopy, 4, {
                id: 549097372,
                answers: [
                    {choice_id: values.relationshipStatus}
                ]
            }, questionContentCopy.questions.findIndex(item => item.id == 549097372)!=-1?true:false)
            updateAnswers(questionContentCopy, 5, {
                id: 549097400,
                answers: [
                    {choice_id: values.sexualPreference}
                ]
            }, questionContentCopy.questions.findIndex(item => item.id == 549097400)!=-1?true:false)
            updateAnswers(questionContentCopy, 6, {
                id: 549097422,
                answers: values.race
            }, questionContentCopy.questions.findIndex(item => item.id == 549097422)!=-1?true:false)
            props.dispatch({
                type: `UPDATE_CONTENT`,
                payload: questionContentCopy
            })
            history.push(`/questions/page3`)
        }
        else{
            setError(true)
        }
    }
    const prevPage = () => {
        history.push(`/questions/page1`)
    }

    const raceOnChange = (e, setField, values) => {
        if(e.target.checked){
            let raceCopy = [...values.race]
            if(e.target.value == 3622508125){
                raceCopy = [{choice_id: 3622508125}]
            }
            else{
                const noneIndex = raceCopy.findIndex(item => item.choice_id == 3622508125)
                if(noneIndex != -1)
                    raceCopy.splice(noneIndex, 1)
                if(e.target.value == 3622508114){
                    raceCopy.push({other_id: e.target.value})
                }else{
                    raceCopy.push({choice_id: e.target.value})
                }
            }
            setField('race', raceCopy, true)
        }
        else{
            const raceCopy = [...values.race]
            if(e.target.value == 3622508114){
                raceCopy.splice(raceCopy.findIndex(item => item.other_id == e.target.value), 1)
            }
            else{
                raceCopy.splice(raceCopy.findIndex(item => item.choice_id == e.target.value), 1)
            }
            setField('race', raceCopy, true)
        }
    }

    return (
        <div>
            <Container>
            <Formik
                initialValues={{
                    relationshipStatus: questionContent.questions?questionContent.questions.findIndex(item => item.id == 549097372)!=-1?questionContent.questions[questionContent.questions.findIndex(item => item.id == 549097372)].answers[0].choice_id:'':'',
                    sexualPreference: questionContent.questions?questionContent.questions.findIndex(item => item.id == 549097400)!=-1?questionContent.questions[questionContent.questions.findIndex(item => item.id == 549097400)].answers[0].choice_id:'':'',
                    race: questionContent.questions?questionContent.questions.findIndex(item => item.id == 549097422)!=-1?questionContent.questions[questionContent.questions.findIndex(item => item.id == 549097422)].answers:[]:[],
                    others: questionContent.questions?questionContent.questions.findIndex(item => item.id == 549097422)!=-1?questionContent.questions[questionContent.questions.findIndex(item => item.id == 549097422)].answers.find(item => item.other_id == 3622508114)?questionContent.questions[questionContent.questions.findIndex(item => item.id == 549097422)].answers.find(item => item.other_id == 3622508114).text:[]:[]:[]
                }}
                onSubmit={(values, actions) => {
                    nextPage(values)
                }}  
            >{({
                handleSubmit,
                values,
                resetForm,
                setFieldValue,
            }) => (
                    <form autoComplete="false" onSubmit={handleSubmit}>
                        <div className="bg-white container mb-3" style={{borderRadius: '2px'}}>
                            {/* <div className="p-2" style={{display: 'flex', justifyContent: 'space-between'}}>
                                <div style={{fontSize: '12px', lineHeight: '1.25', backgroundColor: '#eeeeee'}} className='p-2'>Questions 4-6</div>
                                <div style={{fontSize: '12px', lineHeight: '1.25'}} className='p-2'>Fields marked with <span style={{color: '#055ce5'}}>*</span> are mandatory</div>
                            </div> */}
                            <div className='p-2'>
                                <p>4. {t('Relationship status')}</p>
                                <RadioGroup row aria-label="position" name="relationship-status" onChange={e => setFieldValue('relationshipStatus', e.target.value, true)}>
                                    <FormControlLabel
                                        value='3622507516'
                                        control={<Radio color="primary" checked={values.relationshipStatus == 3622507516?true:false} />}
                                        label={t("single")}
                                    /><br />
                                    <FormControlLabel
                                        value='3622507517'
                                        control={<Radio color="primary" checked={values.relationshipStatus == 3622507517?true:false} />}
                                        label={t("recently single")}
                                    /><br />
                                    <FormControlLabel
                                        value='3622507518'
                                        control={<Radio color="primary" checked={values.relationshipStatus == 3622507518?true:false} />}
                                        label={t("partnered")}
                                    />
                                </RadioGroup>
                            </div>
                        </div>
                        <div className="bg-white container mb-3" style={{borderRadius: '2px'}}>
                            <div className="p-2">
                                <p>5. {t('Sexual Identity')}</p>
                                <RadioGroup row aria-label="position" name="sexual-preferance" onChange={e => setFieldValue('sexualPreference', e.target.value, true)}>
                                    <Row>
                                        <Col md={4} sm={4} xs={12}>
                                            <FormControlLabel
                                                value='3622507827'
                                                control={<Radio color="primary" checked={values.sexualPreference == 3622507827?true:false} />}
                                                label={t("heterosexual (cis)")}
                                            />
                                        </Col>
                                        <Col md={4} sm={4} xs={12}>
                                            <FormControlLabel
                                                value='3622507828'
                                                control={<Radio color="primary" checked={values.sexualPreference == 3622507828?true:false} />}
                                                label={t("lesbian")}
                                            />
                                        </Col>
                                        <Col md={4} sm={4} xs={12}>
                                            <FormControlLabel
                                                value='3622507829'
                                                control={<Radio color="primary" checked={values.sexualPreference == 3622507829?true:false} />}
                                                label={t("gay")}
                                            />
                                        </Col>
                                        <Col md={4} sm={4} xs={12}>
                                            <FormControlLabel
                                                value='3622507830'
                                                control={<Radio color="primary" checked={values.sexualPreference == 3622507830?true:false} />}
                                                label={t("bisexual")}
                                            />
                                        </Col>
                                        <Col md={4} sm={4} xs={12}>
                                            <FormControlLabel
                                                value='3622507831'
                                                control={<Radio color="primary" checked={values.sexualPreference == 3622507831?true:false} />}
                                                label={t("queer")}
                                            />
                                        </Col>
                                        <Col md={4} sm={4} xs={12}>
                                            <FormControlLabel
                                                value='3622507833'
                                                control={<Radio color="primary" checked={values.sexualPreference == 3622507833?true:false} />}
                                                label={t("intersexual")}
                                            />
                                        </Col>
                                        <Col md={4} sm={4} xs={12}>
                                            <FormControlLabel
                                                value='3622507832'
                                                control={<Radio color="primary" checked={values.sexualPreference == 3622507832?true:false} />}
                                                label={t("asexual")}
                                            />
                                        </Col>
                                   
                                        <Col md={4} sm={4} xs={12}>
                                            <FormControlLabel
                                                value='3622507836'
                                                control={<Radio color="primary" checked={values.sexualPreference == 3622507836?true:false} />}
                                                label={t("prefer not to answer")}
                                            />
                                        </Col>
                                    </Row>
                                </RadioGroup>
                            </div>
                        </div>
                        <div className="bg-white container mb-3" style={{borderRadius: '2px'}}>
                            <div className="p-2">
                                <p>6. {t('Race / Ethinicity')}</p>
                                <Row>
                                    <Col>
                                        <FormControlLabel
                                            value='3622508108'
                                            control={<Checkbox color="primary" onChange={e => raceOnChange(e, setFieldValue, values)} checked={values.race.find(item => item.choice_id == 3622508108)?true:false} />}
                                            label={t('White')}
                                        />
                                    </Col>
                                    <Col>
                                        <FormControlLabel
                                            value='3622508116'
                                            control={<Checkbox color="primary" onChange={e => raceOnChange(e, setFieldValue, values)} checked={values.race.find(item => item.choice_id == 3622508116)?true:false} />}
                                            label={t('Native Hawaian / Pacific Islander')}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormControlLabel
                                            value='3622508109'
                                            control={<Checkbox color="primary" onChange={e => raceOnChange(e, setFieldValue, values)} checked={values.race.find(item => item.choice_id == 3622508109)?true:false} />}
                                            label={t('Hispanic / Latinx')}
                                        />
                                    </Col>
                                    <Col>
                                        <FormControlLabel
                                            value='3622508113'
                                            control={<Checkbox color="primary" onChange={e => raceOnChange(e, setFieldValue, values)} checked={values.race.find(item => item.choice_id == 3622508113)?true:false} />}
                                            label={t('First Nations, Metis, or Inuit')}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormControlLabel
                                            value='3622508110'
                                            control={<Checkbox color="primary" onChange={e => raceOnChange(e, setFieldValue, values)} checked={values.race.find(item => item.choice_id == 3622508110)?true:false} />}
                                            label={t('Black / African American')}
                                        />
                                    </Col>
                                    <Col>
                                        <FormControlLabel
                                            value='3622508115'
                                            control={<Checkbox color="primary" onChange={e => raceOnChange(e, setFieldValue, values)} checked={values.race.find(item => item.choice_id == 3622508115)?true:false} />}
                                            label={t('Other Indigenous')}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormControlLabel
                                            value='3622508111'
                                            control={<Checkbox color="primary" onChange={e => raceOnChange(e, setFieldValue, values)} checked={values.race.find(item => item.choice_id == 3622508111)?true:false} />}
                                            label={t('Asian')}
                                        />
                                    </Col>
                                    <Col>
                                        <FormControlLabel
                                            value='3622508125'
                                            control={<Checkbox color="primary" onChange={e => raceOnChange(e, setFieldValue, values)} checked={values.race.find(item => item.choice_id == 3622508125)?true:false} />}
                                            label={t('prefer not to answer')}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormControlLabel
                                            value='3622508112'
                                            control={<Checkbox color="primary" onChange={e => raceOnChange(e, setFieldValue, values)} checked={values.race.find(item => item.choice_id == 3622508112)?true:false} />}
                                            label={t('American Indian or Alaska Native')}
                                        />
                                    </Col>
                                    <Col>
                                        <FormControlLabel
                                            value='3622508114'
                                            control={<Checkbox color="primary" onChange={e => {raceOnChange(e, setFieldValue, values);document.getElementById('other').focus()}} checked={values.race.find(item => item.other_id == 3622508114)?true:false} />}
                                            label={t('Other (please list):')}
                                        />
                                    </Col>
                                </Row>
                                <Field component={MaterialInput} name="others" type="text" id="other" />
                            </div>
                        </div>
                        <div className="bg-white container mb-3" style={{borderRadius: '2px'}}>
                            {error && <p style={{color: 'red',padding:'10px 0'}}>{t('Please answer all the questions')}<br /></p>}
                            <div className='px-2 py-3' style={{display: 'flex', justifyContent: 'space-between'}}>
                                <p style={{fontSize: '12px', lineHeight: '1.17', color: '#afafaf',marginBottom:'0',paddingTop:'10px'}}>{t('Powered by')} <a href="https://www.compriscare.com/" target="_blank"><img src={process.env.LOGO_URI} style={{marginLeft: '10px'}} className="footer-img-logo" /></a></p>
                                <div className="button-footer-wrapper">
                                    <Button style={{backgroundColor: 'transparent', color: '#5e6265', border: 'none'}} onClick={prevPage} type='button' >{t('Previous')}</Button>
                                    <Button style = {{marginLeft: '10px', backgroundColor: '#055ce5', color: 'white', borderRadius: '2px', border: 'none'}} >{t('Next')}</Button>
                                </div>
                            </div>
                        </div>
                    </form>
                )
                }
            </Formik>
            </Container>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        questionContent : state.question.content,
    }
}

export default connect(mapStateToProps)(Page2)
