import React , {useState } from 'react'
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { helpfulOptionsList } from './constants'
import { Container, Row, Col } from 'reactstrap'
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

function Helpful({
    options,
    setOptions,
    multipleChoiceInput,
    setMultipleChoiceInput,
    setMultipleChoiceRadioAnswer,
    multipleChoiceRadioAnswer
}) {
    const [medicationsAdded, setMedicationsAdded] = useState(0)
    const { t } = useTranslation();

    const addAnother = () => {
        setOptions([...options, helpfulOptionsList[medicationsAdded + 1]])
        setMedicationsAdded(medicationsAdded+1)
    }

    const onRadioClick = (id, index) => {
        const multipleChoiceRadioAnswerCopy = [...multipleChoiceRadioAnswer]
        multipleChoiceRadioAnswerCopy[index] = {choice_id: id}
        setMultipleChoiceRadioAnswer(multipleChoiceRadioAnswerCopy)
    }

    const onInputChange = (id, value) => {
        const multipleChoiceInputCopy = [...multipleChoiceInput]
        multipleChoiceInputCopy[id] = value
        setMultipleChoiceInput(multipleChoiceInputCopy)
    }

    console.log({options, multipleChoiceInput})

    return (
        <div className="p-2">
            <div className="bg-white container px-3 py-3 mb-3" style={{ borderRadius: '.125rem' }}>
                {options.map((list, index) => list.map(options => (
                    <div>
                        {options.type == 'input' ? <div style={{display: 'flex', alignItems: 'baseline', gap: '10px'}} >
                           <span className='sub-question'> {t(options.question)} </span>
                            <TextField style={{ width: '7.5rem' }} type="string" onChange={e => onInputChange(options.id, e.target.value)} value={multipleChoiceInput[options.id]} />
                        </div>:<Row>
                            <Col xs={12}>
                                <FormControlLabel
                                control={<Radio name={options.text} color="primary" onChange={(e) => onRadioClick(options.id, index)} checked={multipleChoiceRadioAnswer[index] ? options.id == multipleChoiceRadioAnswer[index].choice_id: false} />}
                                label={t(options.text)}
                                />
                            </Col>
                        </Row>}
                    </div>
                )))}
                <Button className='add-button' variant="text" disabled={medicationsAdded>=4} onClick={addAnother} style={{}}>ADD ANOTHER MEDICATION</Button>
            </div>
        </div>
    )
}


export default Helpful
