import axios from 'axios';
import NProgress from 'nprogress'
import { toast } from 'react-toastify'


export const findQuestion = (arr, id) => {
  for(let i=0; i<arr.length; i++){
    if(arr[i].fields.question_id == id){
      return {question: {...arr[i]}, currentQuestionIndex: i}
    }
  }
} 



export const emptyAnswerArray = (obj, previousAnswers) => {
  try {
    let previousAnswersLength = previousAnswers.questions.length
    for(let i=0; i<previousAnswersLength; i++){
      if(obj[Number(previousAnswers.questions[i].id)]){
        // previousAnswers.questions[i].answers = []
        previousAnswers.questions.splice(i, 1)
        --i
        --previousAnswersLength
      }
    }
  }
  catch (e) {
    
  }
}

export const updateAnswers = (arr, index, object, update) => {
  // const index = arr.questions.findIndex(item => item.id == id) 
  // debugger;
  if(update){
    arr.questions[index-1] = object
  }
  else{
    arr.questions.splice(index-1, 0, object)
  }
}

export const nextRoute = (previousAnswers, currentQuestionId, history, currentAnswer, questionIndex) => {
  if(currentQuestionId == 553741432){
    if(currentAnswer.find(item => item.row_id == 3652080821).choice_id == 3652080822){
        emptyAnswerArray({'555286632': true}, previousAnswers)
        history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+2].fields.question_id}`)
    }
    else{
        history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+1].fields.question_id}`)
    }
  }
  else if(currentQuestionId == 549097381){
    history.push(`/surveyQuestions/page4`)
  }
  else if(currentQuestionId == 549097424){
    const index = currentAnswer.findIndex(item => item.choice_id == 3698124025)
    if(index != -1){
      const qustionNo15Answer = previousAnswers.questions.find(item => item.id == 553741432).answers
      if(qustionNo15Answer[0].choice_id == 3652080822 && qustionNo15Answer[1].choice_id == 3652080822 && qustionNo15Answer[2].choice_id == 3652080822){
        emptyAnswerArray({553784163: true}, previousAnswers)
        history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+2].fields.question_id}`)
      }
      else{
        history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+1].fields.question_id}`)
      }
    }
    else{
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+1].fields.question_id}`)
    }
  }
  else if(currentQuestionId == 549097426){
    const questionNo20Answer = previousAnswers.questions.find(item => item.id == 549097425).answers
    if(currentAnswer.findIndex(item => item.choice_id == 3622508204) != -1 && questionNo20Answer.findIndex(item => item.choice_id == 3622508180) != -1){
      emptyAnswerArray({553847965: true, 553850707: true, 605335957: true, 605336487: true}, previousAnswers)
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+5].fields.question_id}`)
    }
    else{
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+1].fields.question_id}`)
    }
  }
  else if(currentQuestionId == 553847965){
    if(currentAnswer[0].choice_id == 3652748928){
      emptyAnswerArray({553850707: true, 605335957: true, 605336487: true}, previousAnswers)
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+4].fields.question_id}`)
    }
    else if(currentAnswer[0].choice_id == 3815501932){
      emptyAnswerArray({553850707: true}, previousAnswers)
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+2].fields.question_id}`)
    }
    else{
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+1].fields.question_id}`)
    }
  }
  else if(currentQuestionId == 553850707){
    const questionNo22Answer = previousAnswers.questions.find(item => item.id == 553847965).answers
    if(questionNo22Answer[0].choice_id == 3815501931){
      emptyAnswerArray({605335957: true, 605336487: true}, previousAnswers)
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+3].fields.question_id}`)
    }
    else{
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+1].fields.question_id}`)
    }
  }
  // for question number 16
  else if(currentQuestionId == 555286632){
    let count = 0
    currentAnswer.forEach(item => {
      if(item.choice_id == 3662031697){
        count+=1
      }
      else if(item.choice_id == 3662031698){
        count+=1
      }
      else if(item.choice_id == 3662031699){
        count+=1
      }
    })
    if(count == 3){
      emptyAnswerArray({553831140: true}, previousAnswers)
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+2].fields.question_id}`)
    }
    else{
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+1].fields.question_id}`)
    }
  }
  // for 1sdt part of question number 26
  else if(currentQuestionId == 580243889){
    if(currentAnswer[0].choice_id == 3821196632){
      emptyAnswerArray({584283669: true, 554023749: true, 555274472: true, 555274672: true, 556214603: true, 549097447: true, 549097415: true}, previousAnswers)
      history.push(`/surveyQuestions/549097387`)
    }
    else{
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+1].fields.question_id}`)
    }
  }
  // for question number 26
  else if(currentQuestionId == 554023749){
    if(currentAnswer.every(item => item.choice_id == 3653851651 || !item.choice_id || item.other_id)){
      emptyAnswerArray({555274472: true, 555274672: true, 556214603: true, 549097447: true, 549097415: true, 584283669: true}, previousAnswers)
      history.push(`/surveyQuestions/549097387`)
    }
    else if(previousAnswers.questions[1].answers[0].text<30 || currentAnswer.every(item => item.choice_id == 3653851651 || item.choice_id == 3653851652)){
      const tobacoAnswer = currentAnswer.find(item => item.row_id == 3653851647)
      const vapingAnswer = currentAnswer.find(item => item.row_id == 3668005941)
      if((!vapingAnswer || vapingAnswer.choice_id == 3653851651) && (!tobacoAnswer || tobacoAnswer.choice_id == 3653851651)){
        emptyAnswerArray({584283669: true, 549097447: true, 549097415: true, 549097490: true, 549097491: true}, previousAnswers)
        history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+6].fields.question_id}`)
      }
      else if(!tobacoAnswer || tobacoAnswer.choice_id == 3653851651){
        emptyAnswerArray({584283669: true, 549097447: true, 549097415: true}, previousAnswers)
        history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+4].fields.question_id}`)
      }
      else{
        emptyAnswerArray({584283669: true}, previousAnswers)
        history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+2].fields.question_id}`)
      }
    }
    else{
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+1].fields.question_id}`)
    }
  }
  else if(currentQuestionId == 584283669){
    const questionNo26Answer = previousAnswers.questions.find(item => item.id == 554023749).answers
    const tobacoAnswer = questionNo26Answer.find(item => item.row_id == 3653851647)
    const vapingAnswer = questionNo26Answer.find(item => item.row_id == 3668005941)
    if((!vapingAnswer || vapingAnswer.choice_id == 3653851651) && (!tobacoAnswer || tobacoAnswer.choice_id == 3653851651)){
        emptyAnswerArray({549097447: true, 549097415: true, 549097490: true, 549097491: true}, previousAnswers)
        history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+5].fields.question_id}`)
    }
    else if(tobacoAnswer && tobacoAnswer.choice_id != 3653851651){
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+1].fields.question_id}`)
    }
    else{
        emptyAnswerArray({549097447: true, 549097415: true}, previousAnswers)
        history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+3].fields.question_id}`)
    }
  }
  else if(currentQuestionId == 549097447){
    if(currentAnswer.every(item => item.choice_id == 3622508548)){
      
      const questionNo26Answer = previousAnswers.questions.find(item => item.id == 554023749).answers
      const vapingAnswer = questionNo26Answer.find(item => item.row_id == 3668005941)
      if(!vapingAnswer || vapingAnswer.choice_id == 3653851651){
        emptyAnswerArray({549097415: true, 549097490: true, 549097491: true}, previousAnswers)
        history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+4].fields.question_id}`)
      }
      else{
        emptyAnswerArray({549097415: true}, previousAnswers)
        const questionNo26Answer = previousAnswers.questions.find(item => item.id == 554023749).answers
        const vapingAnswer = questionNo26Answer.find(item => item.row_id == 3668005941)
        if(!vapingAnswer || vapingAnswer.choice_id == 3653851651){
          emptyAnswerArray({549097490: true, 549097491: true}, previousAnswers)
          history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+4].fields.question_id}`)
        }
        else{
          history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+2].fields.question_id}`)
        }
      }
    }
    // else if(!previousAnswers.questions.find(item => item.id == 580243889).answers.find(item => item.choice_id == 3821196634)){
    //   const questionNo26Answer = previousAnswers.questions.find(item => item.id == 554023749).answers
    //   const vapingAnswer = questionNo26Answer.find(item => item.row_id == 3668005941)
    //   if(!vapingAnswer || vapingAnswer.choice_id == 3653851651){
    //     emptyAnswerArray({549097490: true, 549097491: true}, previousAnswers)
    //     history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+4].fields.question_id}`)
    //   }
    //   else{
    //     history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+2].fields.question_id}`)
    //   }
    // }
    else{
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+1].fields.question_id}`)
    }
  }
  else if(currentQuestionId == 549097415){
    const questionNo26Answer = previousAnswers.questions.find(item => item.id == 554023749).answers
    const vapingAnswer = questionNo26Answer.find(item => item.row_id == 3668005941)
    if(!vapingAnswer || vapingAnswer.choice_id == 3653851651){
      emptyAnswerArray({549097490: true, 549097491: true}, previousAnswers)
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+3].fields.question_id}`)
    }
    else{
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+1].fields.question_id}`)
    }
  }
  else if(currentQuestionId == 549097490){
    if(currentAnswer.every(item => item.choice_id == 3961378364)){
      emptyAnswerArray({549097491: true}, previousAnswers)
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+2].fields.question_id}`)
    }
    else{
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+1].fields.question_id}`)
    }
  }
  else if(currentQuestionId == 555274672){
    if(currentAnswer.every(item => item.choice_id == 3661958035)){
      emptyAnswerArray({556214603: true}, previousAnswers)
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+2].fields.question_id}`)
    }
    else{
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+1].fields.question_id}`)
    } 
  }
  else if(currentQuestionId == 549097387){
    if(currentAnswer[0].choice_id == 3622507699){
      
      const questionNo25Answer = previousAnswers.questions.find(item => item.id == 580243889).answers
      
      if(questionNo25Answer[0].choice_id == 3821196632){
        emptyAnswerArray({587379847: true, 549097420: true, 549097421: true, 549097391: true, 549097389: true, 553973451: true, 553981633: true, 556167203: true, 556181470: true, 556181518: true, 556181583: true, 556181824: true, 554010038: true, 555422217: true}, previousAnswers)
        history.push(`/surveyQuestions/549097448`)
      }
      else{
        const questionNo26Answer = previousAnswers.questions.find(item => item.id == 554023749).answers
        const rxOpiates = questionNo26Answer.find(item => item.row_id == 3653851638)
        if(questionNo26Answer.every(item => item.choice_id == 3653851651 || item.other_id)){
          emptyAnswerArray({587379847: true, 549097420: true, 549097421: true, 549097391: true, 549097389: true, 553973451: true, 553981633: true, 556167203: true, 556181470: true, 556181518: true, 556181583: true, 556181824: true, 554010038: true, 555422217: true}, previousAnswers)
          history.push(`/surveyQuestions/549097448`) //skip to question number 46 
        }
        else if(rxOpiates?rxOpiates.choice_id == 3653851651?true:false:true){
          emptyAnswerArray({587379847: true, 549097420: true, 549097421: true, 549097391: true}, previousAnswers)
          history.push(`/surveyQuestions/549097389`) //skip to question 36
          
        }
        else{
          history.push(`/surveyQuestions/549097420`)
        }
      }
    }
    else{
      history.push(`/surveyQuestions/587379847`)
    }
  }
  //death slider question
  else if(currentQuestionId == 587379847){
    const questionNo25Answer = previousAnswers.questions.find(item => item.id == 580243889).answers
    if(questionNo25Answer[0].choice_id == 3821196632){
      emptyAnswerArray({549097420: true, 549097421: true, 549097391: true, 549097389: true, 553973451: true, 553981633: true, 556167203: true, 556181470: true, 556181518: true, 556181583: true, 556181824: true, 554010038: true, 555422217: true}, previousAnswers)
      history.push(`/surveyQuestions/549097448`)
    }
    else{
      const questionNo26Answer = previousAnswers.questions.find(item => item.id == 554023749).answers
      const rxOpiates = questionNo26Answer.find(item => item.row_id == 3653851638)
      if(questionNo26Answer.every(item => item.choice_id == 3653851651 || item.other_id)){
        emptyAnswerArray({549097420: true, 549097421: true, 549097391: true, 549097389: true, 553973451: true, 553981633: true, 556167203: true, 556181470: true, 556181518: true, 556181583: true, 556181824: true, 554010038: true, 555422217: true}, previousAnswers)
        history.push(`/surveyQuestions/549097448`) //skip to question number 46 
      }
      else if(rxOpiates?rxOpiates.choice_id == 3653851651?true:false:true){
        emptyAnswerArray({549097420: true, 549097421: true, 549097391: true}, previousAnswers)
        history.push(`/surveyQuestions/549097389`) //skip to question 36
      }
      else{
        history.push(`/surveyQuestions/549097420`)
      }
    }
  }
  // condition for question number 34
  else if(currentQuestionId == 549097421){
    const questionNo26Answer = previousAnswers.questions.find(item => item.id == 554023749).answers
    let skip35 = true
    questionNo26Answer.forEach(item => {
      if(item.row_id == 3653851640){
        skip35 = false
      }
    })
    if(skip35){
      emptyAnswerArray({549097391: true}, previousAnswers)
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+2].fields.question_id}`)
    }
    else{
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+1].fields.question_id}`)
    }
  }
  //condition for question number 36
  else if(currentQuestionId == 549097389){
    const questionNo26Answer = previousAnswers.questions.find(item => item.id == 554023749).answers
    if(questionNo26Answer.every(item => item.row_id == 3653851647 || item.row_id == 3668005941 || item.choice_id == 3653851651 || item.other_id)){
      emptyAnswerArray({553973451: true, 553981633: true, 556167203: true, 556181470: true, 556181518: true, 556181583: true, 556181824: true}, previousAnswers)
      history.push(`/surveyQuestions/554010038`)//skip to 44
    }
    else{
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+1].fields.question_id}`)
    }
  }
  //condition for question number 37
  else if(currentQuestionId == 553973451){
    let symptons = {
      past30: 0,
      one: 0,
      four: 0,
      oneYear: 0,
      fiveplus: 0,
    }
    currentAnswer.forEach(item => {
      if(item.choice_id == 3653547374){
          symptons.past30+=1
      }
      else if(item.choice_id == 3653547375){
          symptons.one+=1
      }
      else if(item.choice_id == 3653547376){
          symptons.four+=1
      }
      else if(item.choice_id == 3653547377){
          symptons.oneYear+=1
      }
      else if(item.choice_id == 3653696915){
          symptons.fiveplus+=1
      }
    })
    if(currentAnswer.every(item => item.row_id == 3844672728) || Object.values(symptons).every(item => item<2)){
      emptyAnswerArray({553981633: true, 556167203: true, 556181470: true, 556181518: true, 556181583: true, 556181824: true}, previousAnswers)
      const questionNo25Answer = previousAnswers.questions.find(item => item.id == 580243889).answers
      if(questionNo25Answer.some(({choice_id}) => choice_id == 3821196621)){
        history.push(`/surveyQuestions/556181825`)
      }
      else{
        emptyAnswerArray({556181825: true}, previousAnswers)
        history.push(`/surveyQuestions/554010038`)
      }
    }
    else if(currentAnswer.every(item => item.row_id == 3844672728)){
      history.push(`/questions/${questionsAndChoices[questionIndex+2].fields.question_id}`)
    }
    else{
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+1].fields.question_id}`)
    }
  }
  //condition for question number 38
  else if(currentQuestionId == 553981633){
    const questionNo37Answer = previousAnswers.questions.find(item => item.id == 553973451).answers
    let symptons = {
      past30: 0,
      one: 0,
      four: 0,
      oneYear: 0,
      fiveplus: 0,
    }
    questionNo37Answer.forEach(item => {
      if(item.choice_id == 3653547374){
          symptons.past30+=1
      }
      else if(item.choice_id == 3653547375){
          symptons.one+=1
      }
      else if(item.choice_id == 3653547376){
          symptons.four+=1
      }
      else if(item.choice_id == 3653547377){
          symptons.oneYear+=1
      }
      else if(item.choice_id == 3653696915){
          symptons.fiveplus+=1
      }
    })
    if(currentAnswer.every(item => (item.choice_id!= 3653596742  && item.choice_id != 3653596750 && item.choice_id != 3653596743 && item.choice_id != 3653596744) || item.other_id)){
      emptyAnswerArray({556167203: true, 556181470: true, 556181518: true, 556181583: true}, previousAnswers)
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+5].fields.question_id}`)
    }
    else if(symptons.past30<2 && symptons.one<2 && symptons.four<2 && symptons.oneYear<2 && symptons.fiveplus<2){
      emptyAnswerArray({556167203: true, 556181470: true, 556181518: true, 556181583: true, 556181824: true}, previousAnswers)
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+6].fields.question_id}`)
    }
    else if(symptons.past30<2 && symptons.one<2 && symptons.four<2 && symptons.oneYear<2){
      emptyAnswerArray({556167203: true, 556181470: true, 556181518: true, 556181583: true}, previousAnswers)
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+5].fields.question_id}`)
    }
    else if(currentAnswer.every(item => (item.choice_id!= 3653596742  && item.choice_id != 3653596750 && item.choice_id != 3653596743) || item.other_id)){
      emptyAnswerArray({556167203: true, 556181470: true, 556181518: true}, previousAnswers)
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+4].fields.question_id}`)
    }
    else if(symptons.past30<2 && symptons.one<2 && symptons.four<2){
      emptyAnswerArray({556167203: true, 556181470: true, 556181518: true}, previousAnswers)
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+4].fields.question_id}`)
    }
    else if(currentAnswer.every(item => (item.choice_id!= 3653596742  && item.choice_id != 3653596750) || item.other_id)){
      emptyAnswerArray({556167203: true, 556181470: true}, previousAnswers)
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+3].fields.question_id}`)
    }
    else if(symptons.past30<2 && symptons.one<2){
      emptyAnswerArray({556167203: true, 556181470: true}, previousAnswers)
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+3].fields.question_id}`)
    }
    else if(currentAnswer.every(item => item.choice_id!= 3653596742 || item.other_id)){
      emptyAnswerArray({556167203: true}, previousAnswers)
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+2].fields.question_id}`)
    }
    else if(symptons.past30<2){
      emptyAnswerArray({556167203: true}, previousAnswers)
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+2].fields.question_id}`)
    }
    else{
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+1].fields.question_id}`)
    }
  }
  //condition for question number 39
  else if(currentQuestionId == 556167203){
    const questionNo38Answer = previousAnswers.questions.find(item => item.id == 553981633).answers
    const questionNo37Answer = previousAnswers.questions.find(item => item.id == 553973451).answers
    let symptons = {
      past30: 0,
      one: 0,
      four: 0,
      oneYear: 0,
      fiveplus: 0,
    }
    questionNo37Answer.forEach(item => {
      if(item.choice_id == 3653547374){
          symptons.past30+=1
      }
      else if(item.choice_id == 3653547375){
          symptons.one+=1
      }
      else if(item.choice_id == 3653547376){
          symptons.four+=1
      }
      else if(item.choice_id == 3653547377){
          symptons.oneYear+=1
      }
      else if(item.choice_id == 3653696915){
          symptons.fiveplus+=1
      }
    })
    if(questionNo38Answer.every(item => (item.choice_id!= 3653596750  && item.choice_id != 3653596743 && item.choice_id != 3653596744 && item.choice_id != 3653693369) || item.other_id)){
      emptyAnswerArray({556181470: true, 556181518: true, 556181583: true, 556181824: true}, previousAnswers)
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+5].fields.question_id}`)
    }
    else if(symptons.one<2 && symptons.four<2 && symptons.oneYear<2 && symptons.fiveplus<2){
      emptyAnswerArray({556181470: true, 556181518: true, 556181583: true, 556181824: true}, previousAnswers)
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+5].fields.question_id}`)
    }
    else if(questionNo38Answer.every(item => (item.choice_id!= 3653596750  && item.choice_id != 3653596744 && item.choice_id != 3653596743) || item.other_id)){
      emptyAnswerArray({556181470: true, 556181518: true, 556181583: true}, previousAnswers)
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+4].fields.question_id}`)
    }
    else if(symptons.one<2 && symptons.four<2 && symptons.oneYear<2){
      emptyAnswerArray({556181470: true, 556181518: true, 556181583: true}, previousAnswers)
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+4].fields.question_id}`)
    }
    else if(questionNo38Answer.every(item => (item.choice_id!= 3653596750  && item.choice_id != 3653596743) || item.other_id)){
      emptyAnswerArray({556181470: true, 556181518: true}, previousAnswers)
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+3].fields.question_id}`)
    }
    else if(symptons.one<2 && symptons.four<2){
      emptyAnswerArray({556181470: true, 556181518: true}, previousAnswers)
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+3].fields.question_id}`)
    }
    else if(questionNo38Answer.every(item => item.choice_id!= 3653596750 || item.other_id)){
      emptyAnswerArray({556181470: true}, previousAnswers)
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+2].fields.question_id}`)
    }
    else if(symptons.one<2){
      emptyAnswerArray({556181470: true}, previousAnswers)
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+2].fields.question_id}`)
    }
    else{
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+1].fields.question_id}`)
    }
  }
  //condition for question number 40
  else if(currentQuestionId == 556181470){
    const questionNo38Answer = previousAnswers.questions.find(item => item.id == 553981633).answers
    const questionNo37Answer = previousAnswers.questions.find(item => item.id == 553973451).answers
    let symptons = {
      past30: 0,
      one: 0,
      four: 0,
      oneYear: 0,
      fiveplus: 0,
    }
    questionNo37Answer.forEach(item => {
      if(item.choice_id == 3653547374){
          symptons.past30+=1
      }
      else if(item.choice_id == 3653547375){
          symptons.one+=1
      }
      else if(item.choice_id == 3653547376){
          symptons.four+=1
      }
      else if(item.choice_id == 3653547377){
          symptons.oneYear+=1
      }
      else if(item.choice_id == 3653696915){
          symptons.fiveplus+=1
      }
    })
    if(questionNo38Answer.every(item => (item.choice_id!= 3653596743  && item.choice_id != 3653596744 && item.choice_id != 3653693369) || item.other_id)){
      emptyAnswerArray({556181518: true, 556181583: true, 556181824: true}, previousAnswers)
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+4].fields.question_id}`)
    }
    else if(symptons.four<2 && symptons.oneYear<2 && symptons.fiveplus<2){
      emptyAnswerArray({556181518: true, 556181583: true, 556181824: true}, previousAnswers)
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+4].fields.question_id}`)
    }
    else if(questionNo38Answer.every(item => (item.choice_id!= 3653596743  && item.choice_id != 3653596744) || item.other_id)){
      emptyAnswerArray({556181518: true, 556181583: true}, previousAnswers)
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+3].fields.question_id}`)
    }
    else if(symptons.four<2 && symptons.oneYear<2){
      emptyAnswerArray({556181518: true, 556181583: true}, previousAnswers)
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+3].fields.question_id}`)
    }
    else if(questionNo38Answer.every(item => item.choice_id!= 3653596743 || item.other_id)){
      emptyAnswerArray({556181518: true}, previousAnswers)
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+2].fields.question_id}`)
    }
    else if(symptons.four<2){
      emptyAnswerArray({556181518: true}, previousAnswers)
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+2].fields.question_id}`)
    }
    else{
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+1].fields.question_id}`)
    }
  }
  // condition for question number 41
  else if(currentQuestionId == 556181518){
    const questionNo38Answer = previousAnswers.questions.find(item => item.id == 553981633).answers
    const questionNo37Answer = previousAnswers.questions.find(item => item.id == 553973451).answers
    let symptons = {
      past30: 0,
      one: 0,
      four: 0,
      oneYear: 0,
      fiveplus: 0,
    }
    questionNo37Answer.forEach(item => {
      if(item.choice_id == 3653547374){
          symptons.past30+=1
      }
      else if(item.choice_id == 3653547375){
          symptons.one+=1
      }
      else if(item.choice_id == 3653547376){
          symptons.four+=1
      }
      else if(item.choice_id == 3653547377){
          symptons.oneYear+=1
      }
      else if(item.choice_id == 3653696915){
          symptons.fiveplus+=1
      }
    })
    if(questionNo38Answer.every(item => (item.choice_id != 3653596744 && item.choice_id != 3653693369) || item.other_id)){
      emptyAnswerArray({556181583: true, 556181824: true}, previousAnswers)
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+3].fields.question_id}`)
    }
    else if(symptons.oneYear<2 && symptons.fiveplus<2){
      emptyAnswerArray({556181583: true, 556181824: true}, previousAnswers)
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+3].fields.question_id}`)
    }
    else if(questionNo38Answer.every(item => item.choice_id != 3653596744 || item.other_id)){
      emptyAnswerArray({556181583: true}, previousAnswers)
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+2].fields.question_id}`)
    }
    else if(symptons.oneYear<2){
      emptyAnswerArray({556181583: true}, previousAnswers)
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+2].fields.question_id}`)
    }
    else{
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+1].fields.question_id}`)
    }
  }
  //condition for 42
  else if(currentQuestionId == 556181583){
    const questionNo38Answer = previousAnswers.questions.find(item => item.id == 553981633).answers
    const questionNo37Answer = previousAnswers.questions.find(item => item.id == 553973451).answers
    let symptons = {
      past30: 0,
      one: 0,
      four: 0,
      oneYear: 0,
      fiveplus: 0,
    }
    questionNo37Answer.forEach(item => {
      if(item.choice_id == 3653547374){
          symptons.past30+=1
      }
      else if(item.choice_id == 3653547375){
          symptons.one+=1
      }
      else if(item.choice_id == 3653547376){
          symptons.four+=1
      }
      else if(item.choice_id == 3653547377){
          symptons.oneYear+=1
      }
      else if(item.choice_id == 3653696915){
          symptons.fiveplus+=1
      }
    })
    if(questionNo38Answer.every(item => item.choice_id != 3653693369 || item.other_id)){
      emptyAnswerArray({556181824: true}, previousAnswers)
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+2].fields.question_id}`)
    }
    else if(symptons.fiveplus<2){
      emptyAnswerArray({556181824: true}, previousAnswers)
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+2].fields.question_id}`)
    }
    else{
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+1].fields.question_id}`)
    }
  }
  else if(currentQuestionId === 556181824){
    const questionNo25Answer = previousAnswers.questions.find(item => item.id == 580243889).answers
    if(questionNo25Answer.some(({choice_id}) => choice_id == 3821196621)){
      history.push(`/surveyQuestions/556181825`)
    }
    else{
      history.push(`/surveyQuestions/554010038`)
    }
  }
  else if(currentQuestionId == 549097340){
    if(currentAnswer[0].choice_id == 3622509768){
      emptyAnswerArray({553973451: true, 553981633: true, 556167203: true, 556181470: true, 556181518: true, 556181583: true, 556181824: true, 556181825: true}, previousAnswers)
      history.push('/surveyQuestions/554010038')
    }
    else{
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+1].fields.question_id}`)
    }
  }
  //condition for 44
  else if(currentQuestionId == 554010038){
    if(currentAnswer.every(item => item.choice_id == 3653767317)){
      emptyAnswerArray({555422217: true}, previousAnswers)
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+2].fields.question_id}`)
    }
    else{
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+1].fields.question_id}`)
    }
  }
  // condition for 46
  else if(currentQuestionId == 549097448){
    const questionNo25Answer = previousAnswers.questions.find(item => item.id == 580243889).answers
    if(questionNo25Answer[0].choice_id == 3821196632){
        emptyAnswerArray({549097449: true, 549097360: true, 549097350: true, 554051873: true, 549097349: true, 549097358: true, 549097354: true}, previousAnswers)
        history.push(`/surveyQuestions/549097411`)
    }
    else{
      const questionNo26Answer = previousAnswers.questions.find(item => item.id == 554023749).answers
      if(questionNo26Answer.every(item => item.choice_id == 3653851651 || !item.choice_id || item.other_id)){
        emptyAnswerArray({549097449: true, 549097360: true, 549097350: true, 554051873: true, 549097349: true, 549097358: true, 549097354: true}, previousAnswers)
        history.push(`/surveyQuestions/549097411`)
      }
      else if(currentAnswer.some(item => item.choice_id == 3654276419)){
        emptyAnswerArray({549097449: true}, previousAnswers)
        history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+2].fields.question_id}`)
      }
      else{
        history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+1].fields.question_id}`)
      }
    }
  }
  else if(currentQuestionId == 549097411){
    const questionNo25Answer = previousAnswers.questions.find(item => item.id == 580243889).answers
    if(questionNo25Answer[0].choice_id == 3821196632){
        emptyAnswerArray({549097395: true, 549097443: true, 549097431: true, 549097427: true, 549097362: true}, previousAnswers)
        history.push(`/surveyQuestions/553825076`)
    }
    else{
      const questionNo26Answer = previousAnswers.questions.find(item => item.id == 554023749).answers
      if(questionNo26Answer.every(item => item.choice_id == 3653851651 || !item.choice_id || item.other_id)){
        emptyAnswerArray({549097395: true, 549097443: true, 549097431: true, 549097427: true, 549097362: true}, previousAnswers)
        history.push(`/surveyQuestions/553825076`)
      }
      else{
        history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+1].fields.question_id}`)
      }
    }
  }
  //condition for 59
  else if(currentQuestionId == 553825076){
    const questionNo25Answer = previousAnswers.questions.find(item => item.id == 580243889).answers
    if(questionNo25Answer[0].choice_id == 3821196632){
      emptyAnswerArray({549097355: true, 549097357: true, 549097356: true, 549097373: true, 549097385: true}, previousAnswers)
      history.push(`/surveyQuestions/549097439`)
    }
    else{
      const questionNo26Answer = previousAnswers.questions.find(item => item.id == 554023749).answers
      if(questionNo26Answer.every(item => item.choice_id == 3653851651 || !item.choice_id || item.other_id)){
        const questionNo27Answer = previousAnswers.questions.find(item => item.id == 580243889).answers
        let count = 0
        if(questionNo27Answer){
          questionNo27Answer.forEach(item => {
            if(item.choice_id == 3821196622 || item.choice_id == 3821196628){
              count++
            }
          })
        }
        if(count == 2){
          emptyAnswerArray({549097355: true, 549097357: true, 549097356: true, 549097373: true}, previousAnswers)
          history.push(`/surveyQuestions/549097385`)
        }
        else{
          emptyAnswerArray({549097355: true, 549097357: true, 549097356: true, 549097373: true, 549097385: true}, previousAnswers)
          history.push(`/surveyQuestions/549097439`)
        }
      }
      else{
        history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+1].fields.question_id}`)
      }
    }
  }
  // condition for question number 60
  else if(currentQuestionId == 549097355){
    if(currentAnswer[0].choice_id == 3861345814){
      emptyAnswerArray({549097357: true, 549097356: true, 549097373: true}, previousAnswers)
      history.push(`/surveyQuestions/549097385`)
    }
    else{
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+1].fields.question_id}`)
    }
  }
  // for last page
  else if(currentQuestionId == 549097351){
    history.push(`/surveyQuestions/completion`)
  }
  else if(currentQuestionId == 586886313){
    if(currentAnswer[0].choice_id == 3866486056){
      emptyAnswerArray({549097360: true}, previousAnswers)
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+2].fields.question_id}`)
    }
    else
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+1].fields.question_id}`)
  }
  else if(currentQuestionId == 586889293){
    if(currentAnswer[0].choice_id == 3866484385){
      emptyAnswerArray({549097431: true}, previousAnswers)
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+2].fields.question_id}`)
    }
    else
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+1].fields.question_id}`)
  }
  else if(currentQuestionId == 549097427){
    const questionNo49Answer = previousAnswers.questions.find(item => item.id == 586889293).answers
    if(questionNo49Answer[0].choice_id == 3866484385){
      emptyAnswerArray({549097431: true}, previousAnswers)
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+2].fields.question_id}`)
    }
    else
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+1].fields.question_id}`)
  }
  else if(currentQuestionId == 549097373){
    const questionNo27Answer = previousAnswers.questions.find(item => item.id == 580243889).answers
    let count = 0
    if(questionNo27Answer){
      questionNo27Answer.forEach(item => {
        if(item.choice_id == 3821196622 || item.choice_id == 3821196628){
          count++
        }
      })
    }
    if(count == 2){
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+1].fields.question_id}`)
    }
    else {
      emptyAnswerArray({549097385: true}, previousAnswers)
      history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+2].fields.question_id}`)
    }
  }
  else{
    history.push(`/surveyQuestions/${questionsAndChoices[questionIndex+1].fields.question_id}`)
  }
}


export const generateReport = (id, reqBody) => {
  return new Promise((resolve, reject) => {
    NProgress.start()
        axios.post(`${process.env.API_URL}public/surveys/${id}/download`, reqBody,{
        headers: {
          'Content-type': 'application/json',
        },
        responseType: 'blob'
        }).then( (res) => {
          NProgress.done()
          var binaryData = [];
          binaryData.push(res.data);
          var fileURL = URL.createObjectURL(new Blob(binaryData, {type: "application/pdf"}))
          window.open(fileURL,"_self");
          resolve(1)
      }).catch ( (e) => {
          toast.error('Please check your internet connection and submit the assessment again')
          NProgress.done()   
          reject(e)
    })
  })
}

export const paragraphs = {
  NURTURING_PARAGRAPH: {
    text: 'I am practical, down to earth, and prefer simple solutions. I am also sensitive, caring, and try to make others happy. I don’t like to argue or fight or be around people who do. I may have a hard time saying "no" if someone asks for my help.',
    text2 : 'Nurturing Paragraph',
    condition: `(answer[1].choice_id == 3622508803 || answer[1].choice_id == 3622508804) && (answer[2].choice_id == 3622508813 || answer[2].choice_id == 3622508814)`
  },
  RELATIONSHIP_PARAGRAPH: {
    text: "I have deep feelings and strong ideas about what is right and wrong. I am sensitive and can easily get my feelings hurt. I am usually very good at knowing why people feel the way they do. I don't like to argue or fight or be around people who do. I prefer to think of new ways to help others.",
    text2 : 'Relationship Paragraph',
    condition: `(answer[1].choice_id == 3622508811 || answer[1].choice_id == 3622508812) && (answer[2].choice_id == 3622508813 || answer[2].choice_id == 3622508814)`
  },
  PRACTICAL_PARAGRAPH: {
    text: 'I am practical, realistic and down to earth. I make most decisions based on what makes good sense, not how other people feel about it. I like clear, simple directions, and use my experience to solve problems. I am good at remembering important facts and details.',
    text2: 'Practical Paragraph',
    condition: `(answer[1].choice_id == 3622508803 || answer[1].choice_id == 3622508804) && (answer[2].choice_id == 3622508805 || answer[2].choice_id == 3622508806)`
  },
  COMPETENT_PARAGRAPH: {
    text: "I make most decisions based on what makes sense, not how people feel about it. I am smart and learn most things easily, which helps me succeed. I can usually see a better way of doing something, and others often look to me to lead them. I like solving problems and set high standards for myself and others.",
    text2: 'Practical Paragraph',
    condition: `(answer[1].choice_id == 3622508811 || answer[1].choice_id == 3622508812) && (answer[2].choice_id == 3622508805 || answer[2].choice_id == 3622508806)`
  }
}

export const questionsAndChoices = [
  {
    "model": "comprisapp.questions",
    "pk": 102,
    "fields": {
      "question_id": "549097386",
      "question_text": "COMPRIS MR# (obtain from your provider before taking the assessment)",
      "survey_id": "294219921",
      "question_type": "open_ended",
      "created_at": "2020-11-21T14:10:12.438Z",
      "updated_at": "2020-11-21T14:10:12.438Z"
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 103,
    "fields": {
      "question_id": "549097380",
      "question_text": "AGE",
      "survey_id": "294219921",
      "question_type": "open_ended",
      "created_at": "2020-11-21T14:10:12.465Z",
      "updated_at": "2020-11-21T14:10:12.465Z"
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 104,
    "fields": {
      "question_id": "549097446",
      "question_text": "GENDER",
      "survey_id": "294219921",
      "question_type": "single_choice",
      "created_at": "2020-11-21T14:10:12.491Z",
      "updated_at": "2020-11-21T14:10:12.491Z"
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 105,
    "fields": {
      "question_id": "549097429",
      "question_text": "Prefer to be called",
      "survey_id": "294219921",
      "question_type": "single_choice",
      "created_at": "2020-11-21T14:10:12.649Z",
      "updated_at": "2020-11-21T14:10:12.649Z"
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 106,
    "fields": {
      "question_id": "549097372",
      "question_text": "Relationship STATUS",
      "survey_id": "294219921",
      "question_type": "single_choice",
      "created_at": "2020-11-21T14:10:12.752Z",
      "updated_at": "2020-11-21T14:10:12.752Z"
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 107,
    "fields": {
      "question_id": "549097400",
      "question_text": "Sexual PREFERENCE",
      "survey_id": "294219921",
      "question_type": "single_choice",
      "created_at": "2020-11-21T14:10:12.856Z",
      "updated_at": "2020-11-21T14:10:12.856Z"
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 108,
    "fields": {
      "question_id": "549097422",
      "question_text": "RACE / ETHNICITY (check ALL that apply)",
      "survey_id": "294219921",
      "question_type": "multiple_choice",
      "created_at": "2020-11-21T14:10:13.096Z",
      "updated_at": "2020-11-21T14:10:13.096Z"
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 109,
    "fields": {
      "question_id": "549097423",
      "question_text": "Religious PREFERENCE",
      "survey_id": "294219921",
      "question_type": "single_choice",
      "created_at": "2020-11-21T14:10:13.334Z",
      "updated_at": "2020-11-21T14:10:13.334Z"
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 110,
    "fields": {
      "question_id": "549097445",
      "question_text": "Highest EDUCATION level",
      "survey_id": "294219921",
      "question_type": "matrix",
      "created_at": "2020-11-21T14:10:13.630Z",
      "updated_at": "2020-11-21T14:10:13.630Z"
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 110.5,
    "fields": {
      "question_id": "549097390",
      "question_text": "Current HOUSING",
      "survey_id": "294219921",
      "question_type": "matrix",
      "created_at": "2020-11-21T14:10:14.367Z",
      "updated_at": "2020-11-21T14:10:14.367Z"
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 111,
    "fields": {
      "question_id": "549097381",
      "question_text": "Number (Including self) in current HOUSEHOLD",
      "survey_id": "294219921",
      "question_type": "open_ended",
      "created_at": "2020-11-21T14:10:13.918Z",
      "updated_at": "2020-11-21T14:10:13.918Z"
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 112,
    "fields": {
      "question_id": "549097432",
      "question_text": "Combined household income",
      "survey_id": "294219921",
      "question_type": "matrix",
      "created_at": "2020-11-21T14:10:13.944Z",
      "updated_at": "2020-11-21T14:10:13.944Z"
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 113,
    "fields": {
      "question_id": "549097367",
      "question_text": "INSURANCE (check ALL that apply)",
      "survey_id": "294219921",
      "question_type": "multiple_choice",
      "created_at": "2020-11-21T14:10:14.158Z",
      "updated_at": "2020-11-21T14:10:14.158Z"
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 115,
    "fields": {
      "question_id": "549097399",
      "question_text": "Current Work",
      "survey_id": "294219921",
      "question_type": "matrix",
      "created_at": "2020-11-21T14:10:14.576Z",
      "updated_at": "2020-11-21T14:10:14.576Z"
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 116,
    "fields": {
      "question_id": "553741432",
      "question_text": "Ever been (more than 30 days)",
      "survey_id": "294219921",
      "question_type": "matrix",
      "created_at": "2020-11-21T14:10:14.733Z",
      "updated_at": "2020-11-21T14:10:14.733Z",
      choices: [
        {
          choice_text: 'Unemployed other than school or caregiving',
          row_id: 3652080819,
        },
        {
          choice_text: 'homeless',
          row_id: 3652080820,
        },
        {
          choice_text: 'arrested and held',
          row_id: 3652080821
        }
      ],
      rowText: [
        {
          text: 'no',
          id: 3652080822,
        },
        {
          text: 'for less than 6 months',
          id: 3652080823
        },
        {
          text: '6-11 months',
          id: 3652080824
        },
        {
          text: '1-2 yrs',
          id: 3652080825
        },
        {
          text: '3-5 yrs',
          id: 3652080826
        },
        {
          text: '6-9 yrs',
          id: 3652080827
        },
        {
          text: '10-19 yrs',
          id: 3652080828
        },
        {
          text: '20+ yrs',
          id: 3652080829
        },
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 117,
    "fields": {
      "question_id": "555286632",
      "question_text": "When were you being held in a facility (check ALL that apply)",
      "survey_id": "294219921",
      "question_type": "multiple_choice",
      "created_at": "2020-11-21T14:10:15.427Z",
      "updated_at": "2020-11-21T14:10:15.427Z",
      rowSize: 1,
      containsOther: false,
      options: [
        {
          text: 'within the past 30 days',
          id: 3662031697
        },
        {
          text: '1-3 months ago',
          id: 3662031698
        },
        {
          text: '4-11 months ago',
          id: 3662031699
        },
        {
          text: '1-2 years ago',
          id: 3662031700
        },
        {
          text: '3-4 years ago',
          id: 3662031701
        },
        {
          text: '5+ years ago',
          id: 3662031702
        }
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 118,
    "fields": {
      "question_id": "553831140",
      "question_text": "During the PAST 12 MONTHS, how true were the following statements for your household?",
      "survey_id": "294219921",
      "question_type": "matrix",
      "created_at": "2020-11-21T14:10:15.610Z",
      "updated_at": "2020-11-21T14:10:15.610Z",
      choices: [
        {
          choice_text: 'we worried our food would run out before we got money to buy more',
          row_id: 3652636446
        },
        {
          choice_text: "the food we bought didn't last, and we didn't have money to get more",
          row_id: 3652636447
        }
      ],
      rowText: [
        {
          text: 'often true',
          id: 3652636448
        },
        {
          text: `sometimes true`,
          id: 3652636449
        },
        {
          text: `never true`,
          id: 3652636450
        }
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 119,
    "fields": {
      "question_id": "549097424",
      "question_text": "Any LIFE TRAUMA? (check ALL that apply)",
      "survey_id": "294219921",
      "question_type": "multiple_choice",
      "created_at": "2020-11-21T14:10:15.796Z",
      "updated_at": "2020-11-21T14:10:15.796Z",
      noneId: 3698124025,
      containsOther: true,
      otherOPtionId: 3622508171,
      otherOptionText: 'Other (please specify):',
      options: [
        {
          text: 'accident / injury',
          id: 3622508157
        },
        {
          text: 'currently no transportion options',
          id: 3622508172
        },
        {
          text: `parent in jail or prison`,
          id: 3815475772
        },
        {
          text: 'crime / violence',
          id: 3622508156
        },
        {
          text: 'loss of a child',
          id: 3622508159
        },
        {
          text: 'orphanage',
          id: 3622508169
        },
        {
          text: 'natural disaster / fire',
          id: 3622508154
        },
        {
          text: 'loss of a loved one',
          id: 3622508160
        },
        {
          text: 'foster care',
          id: 3622508170
        },
        {
          text: 'war / terrorism',
          id: 3622508155
        },
        {
          text: 'divorce',
          id: 3622508161
        },
        {
          text: 'physical abuse',
          id: 3622508165
        },
        {
          text: 'life-altering illness',
          id: 3622508158
        },
        {
          text: 'chronic loneliness',
          id: 3622508162
        },
        {
          text: 'emotional abuse',
          id: 3622508166
        },
        {
          text: `currently wheelchair aided`,
          id: 3815475770
        },
        {
          text: 'poverty',
          id: 3622508163
        },
        {
          text: 'sexual abuse',
          id: 3622508167
        },
        {
          text: `currently bed confined`,
          id: 3815475771
        },
        {
          text: 'not enough food',
          id: 3622508164
        },
        {
          text: 'none',
          id: 3698124025
        },
      ] 
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 120,
    "fields": {
      "question_id": "553784163",
      "question_text": "Did substance misuse LEAD TO or RESULT FROM:",
      "survey_id": "294219921",
      "question_type": "matrix",
      "created_at": "2020-11-21T14:10:16.290Z",
      "updated_at": "2020-11-21T14:10:16.291Z",
      choices: [
        {
          choice_text: `unemployment`,
          row_id: 3652345098
        },
        {
          choice_text: `homelessness`,
          row_id: 3652345096
        },
        {
          choice_text: `jail or detention`,
          row_id: 3652345097
        },
        {
          choice_text: "life trauma",
          row_id: 3652538182
        }
      ],
      rowText: [
        {
          text: `no`,
          id: 3652345099
        },
        {
          text: `substance misuse led to`,
          id: 3652345100
        },
        {
          text: `substance misuse resulted from`,
          id: 3652345101
        },
        {
          text: `substance misuse led to AND got worse from`,
          id: 3652345102
        }
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 121,
    "fields": {
      "question_id": "549097425",
      "question_text": "Any COMPULSIONS other than drugs or alcohol? (check ALL that apply)",
      "survey_id": "294219921",
      "question_type": "multiple_choice",
      "created_at": "2020-11-21T14:10:16.740Z",
      "updated_at": "2020-11-21T14:10:16.740Z",
      rowSize: 3,
      noneId: 3622508180,
      containsOther: true,
      otherOPtionId: 3622508188,
      otherOptionText: 'Other:',
      options: [
        {
          text: 'OCD (obsessive-compulsive)',
          id: 3622508175
        },
        {
          text: `sex`,
          id: 3622508178
        },
        {
          text: `video games`,
          id: 3622508181
        },
        {
          text: `eating`,
          id: 3622508176
        },
        {
          text: `exercise`,
          id: 3622508183
        },
        {
          text: `social media`,
          id: 3622508182
        },
        {
          text: `gambling`,
          id: 3622508177
        },
        {
          text: `spending`,
          id: 3622508179
        },
        {
          text: `none`,
          id: 3622508180
        },
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 122,
    "fields": {
      "question_id": "549097426",
      "question_text": "Any BRAIN ILLNESSES / INJURY? (check ALL that apply)",
      "survey_id": "294219921",
      "question_type": "multiple_choice",
      "created_at": "2020-11-21T14:10:17.028Z",
      "updated_at": "2020-11-21T14:10:17.028Z",
      rowSize: 3,
      noneId: 3622508204,
      containsOther: true,
      otherOPtionId: 3622508213,
      otherOptionText: 'Other:',
      options: 
      [
        {
          text: `anxiety or panic`,
          id: 3622508194
        },
        {
          text: `bipolar`,
          id: 3622508206
        },
        {
          text: `autism spectrum`,
          id: 3622508201
        },
        {
          text: `depression`,
          id: 3622508195
        },
        {
          text: `schizo-affective`,
          id: 3622508198
        },
        {
          text: `PTSD`,
          id: 3622508202
        },
        {
          text: `ADHD (includes ADD)`,
          id: 3622508196
        },
        {
          text: `schizophrenia`,
          id: 3622508199
        },
        {
          text: `TBI (traumatic brain injury)`,
          id: 3622508203
        },
        {
          text: `oppositional or conduct`,
          id: 3622508205
        },
        {
          text: `personality`,
          id: 3622508200
        },
        {
          text: `thinking or memory difficulty`,
          id: 3622508197
        },
        {hide: true},
        {
          text: `dissociative`,
          id: 3622508207
        },
        {
          text: `none`,
          id: 3622508204
        }
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 123,
    "fields": {
      "question_id": "553847965",
      "question_text": "Ever been prescribed MEDICATION or hospitalized for a compulsion or brain illness/injury?",
      "survey_id": "294219921",
      "question_type": "single_choice",
      "created_at": "2020-11-21T14:10:17.446Z",
      "updated_at": "2020-11-21T14:10:17.446Z",
      hideRadio: true,
      containsOther: true,
      otherOPtionId: 3652748930,
      otherOptionText: 'List prescribed medication (if known)',
      options: [
        {
          text: `no`,
          id: 3652748928
        },
        {
          text: `yes, prescribed medication, but not hospitalized`,
          id: 3815501931
        },
        {
          text: `yes, hospitalized, but not prescribed medication`,
          id: 3815501932
        },
        {
          text: `yes, hospitalized and prescribed medication`,
          id: 3652748929
        },
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 124,
    "fields": {
      "question_id": "553850707",
      "question_text": "Are you STILL TAKING <strong>medication</strong> for a compulsion or brain illness/injury ?",
      "survey_id": "294219921",
      "question_type": "single_choice",
      "created_at": "2020-11-21T14:10:17.549Z",
      "updated_at": "2020-11-21T14:10:17.549Z",
      containsOther: true,
      otherOPtionId: 3652768046,
      otherOptionText: 'Yes, still taking the following medication (list if known):',
      options: [
        {
          text: `no, never or only briefly took it.`,
          id: 3652768044
        },
        {
          text: `no, stopped on my own.`,
          id: 3652843252
        },
        {
          text: `no, stopped with my provider's agreement.`,
          id: 3652768045
        },
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 124.1,
    "fields": {
      "question_id": 605335957,
      "question_text": "NUMBER of times HOSPITALIZED for a <strong>compulsion</strong> or <strong>brain illness/injury</strong>?",
      "survey_id": 294219921,
      "question_type": "matrix",
      "created_at": "2020-12-19T17:06:54.230Z",
      "updated_at": "2020-12-19T17:06:54.230Z",
      row_id: 3983201709,
      rowText: [
        {id: 3983201711, text: '1'},
        {text: '2', id: 3983201712},
        {text: '3', id: 3983201713},
        {text: "4-5", id: 3983201714},
        {text: "6-9", id: 3983201715},
        {text: "10 or more", id: 3983201716}
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 124.2,
    "fields": {
      "question_id": 605336487,
      "question_text": "Most recent time HOSPITALIZED for a <strong>compulsion</strong> or <strong>brain illness</strong>?",
      "survey_id": 294219921,
      "question_type": "matrix",
      "created_at": "2020-12-19T17:06:54.230Z",
      "updated_at": "2020-12-19T17:06:54.230Z",
      row_id: 3983205280,
      rowText: [
        {text: "currently hospitalized", id: 3983205283},
        {text: "past 30 days", id: 3983205284},
        {text: "1-3 months ago", id: 3983205285},
        {text: "4-11 months ago", id: 3983205286},
        {text: "1-2 yrs ago", id: 3983205287},
        {text: "3-4 yrs ago", id: 3983205288},
        {text: "5-9 yrs ago", id: 3983218772},
        {text: "10+ years ago", id: 3983218773},
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 125,
    "fields": {
      "question_id": "549097441",
      "question_text": "How much do you agree with the following statements?",
      "survey_id": "294219921",
      "question_type": "matrix",
      "created_at": "2020-11-21T14:10:17.679Z",
      "updated_at": "2020-11-21T14:10:17.679Z",
      choices: [
        {
          choice_text: `I am content`,
          row_id: 3622508459
        },
        {
          choice_text: `I often don't think things through before I speak`,
          row_id: 3622508460
        },
        {
          choice_text: `I would like to skydive, or already have`,
          row_id: 3622508461
        },
        {
          choice_text: `I am happy`,
          row_id: 3622508462
        },
        {
          choice_text: `I often involve myself in situations that I later regret`,
          row_id: 3622508463
        },
        {
          choice_text: `I enjoy new and exciting experiences even if they are unconventional`,
          row_id: 3622508464
        },
        {
          choice_text: `I have faith that my future holds great promise`,
          row_id: 3622508465
        },
        {
          choice_text: `It's frightening to feel dizzy or faint`,
          row_id: 3622508466
        },
        {
          choice_text: `I like doing things that frighten me a little`,
          row_id: 3622508467
        },
        {
          choice_text: `It frightens me to feel my heart beat change`,
          row_id: 3622508468
        },
        {
          choice_text: `I usually act without stopping to think`,
          row_id: 3622508469
        },
        {
          choice_text: `I would like to learn to drive a motorcycle, or already have`,
          row_id: 3622508482
        },
      ],
      rowText: [
        {
          text: `Strongly Disagree`,
          id: 3622508470
        },
        {
          text: `Disagree`,
          id: 3622508471
        },
        {
          text: `Agree`,
          id: 3622508472
        },
        {
          text: `Strongly Agree`,
          id: 3622508473
        }
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 126,
    "fields": {
      "question_id": "549097442",
      "question_text": "How much do you agree with the following statements?",
      "survey_id": "294219921",
      "question_type": "matrix",
      "created_at": "2020-11-21T14:10:19.017Z",
      "updated_at": "2020-11-21T14:10:19.017Z",
      choices: [
        {
          choice_text: `I feel proud of my accomplishments`,
          row_id: 3622508495
        },
        {
          choice_text: `I get scared when I'm too nervous`,
          row_id: 3622508496
        },
        {
          choice_text: `Generally, I am an impulsive person`,
          row_id: 3622508497
        },
        {
          choice_text: `I am interested in experience for its own sake even if it is illegal`,
          row_id: 3622508498
        },
        {
          choice_text: `I feel that I'm a failure`,
          row_id: 3622508499
        },
        {
          choice_text: `I get scared when I experience unusual body sensations`,
          row_id: 3622508500
        },
        {
          choice_text: `I would enjoy hiking long distances in wild and uninhabited territory`,
          row_id: 3622508501
        },
        {
          choice_text: `I feel pleasant`,
          row_id: 3622508502
        },
        {
          choice_text: `It scares me when I'm unable to focus on a task`,
          row_id: 3622508503
        },
        {
          choice_text: `I feel I have to be manipulative to get what I want`,
          row_id: 3622508504
        },
        {
          choice_text: `I am very enthusiastic about my future`,
          row_id: 3622508505
        },
      ],
      rowText: [
        {
          text: `Strongly Disagree`,
          id: 3622508483
        },
        {
          text: `Disagree`,
          id: 3622508484
        },
        {
          text: `Agree`,
          id: 3622508485
        },
        {
          text: `Strongly Agree`,
          id: 3622508486
        }
      ]
    }
  },
  {	
    "model": "comprisapp.questions",	
    "pk": 126.5,	
    "fields": {	
      "question_id": "580243889",	
      "question_text": "Check any SUBSTANCES you have used MORE than a few times.",	
      "survey_id": "294219921",	
      "question_type": "multiple_choice",	
      "created_at": "2020-12-11T07:40:42.139Z",	
      "updated_at": "2020-12-11T07:40:42.139Z",
      containsOther: true,
      otherOptionText: `Other (please list):`,
      otherOPtionId: '3821196643',
      noneId: 3821196632,
      options: [
        {text: "smoking tobacco", id: "3821196634"},
        {text: "Rx benzos (anxiety)", id: "3821196623"},
        {text: "heroin", id: "3821196628"},
        {text: "smokeless tobacco (chew / snuff)", id: 3983757573},
        {text: "Rx ADHD (stimulants)", id: "3821196624"},
        {text: "ecstasy / MDMA", id: "3821196633"},
        {text: "vaping", id: "3821196620"},
        {text: "Rx barbiturates (sedatives)", id: "3821196625"},
        {text: "crystal meth", id: "3821196629"},
        {text: "alcohol", id: "3821196621"},
        {text: "marijuana", id: "3821196626"},
        {text: "hallucinogens", id: "3821196630"},
        {text: "Rx opiates (pain)", id: "3821196622"},
        {text: "cocaine / crack", id: "3821196627"},
        {text: "inhalants (non-medical)", id: "3821196631"},
        {hide : true},
        {hide : true},
        {text: "none", id: "3821196632"},
      ]	
    }	
  },
  {
    "model": "comprisapp.questions",
    "pk": 127,
    "fields": {
      "question_id": "554023749",
      "question_text": "For HOW LONG did you use the following substances?",
      "survey_id": "294219921",
      "question_type": "matrix",
      "created_at": "2020-11-21T14:10:20.314Z",
      "updated_at": "2020-11-21T14:10:20.314Z",
      containsOther: true,
      otherOPtionId: 3653851627,
      otherOptionText: 'other',
      choices: [
        {
          choice_text: `tobacco`,
          row_id: 3653851647
        },
        {
          choice_text: `vaping`,
          row_id: 3668005941
        },
        {
          choice_text: `alcohol`,
          row_id: 3653858053
        },
        {
          choice_text: `Rx opiates (pain)`,
          row_id: 3653851638
        },
        {
          choice_text: `Rx benzos (anxiety)`,
          row_id: 3653851646
        },
        {
          choice_text: `Rx ADHD (stimulants)`,
          row_id: 3653851648
        },
        {
          choice_text: `Rx barbiturates (sedatives)`,
          row_id: 3653851649
        },
        {
          choice_text: `marijuana`,
          row_id: 3653851650
        },
        {
          choice_text: `cocaine / crack`,
          row_id: 3653851639
        },
        {
          choice_text: `heroin`,
          row_id: 3653851640
        },
        {
          choice_text: `ecstasy /MDMA`,
          row_id: 3653851641
        },
        {
          choice_text: `crystal meth`,
          row_id: 3653851642
        },
        {
          choice_text: `hallucinogens`,
          row_id: 3653851643
        },
        {
          choice_text: `inhalants`,
          row_id: 3653851644
        },
        {
          choice_text: `other`,
          row_id: 3653851645
        }
      ],
      rowText: [
        {
          text: `tried briefly`,
          id: 3653851651
        },
        {
          text: `for 2-4 weeks`,
          id: 3653851652
        },
        {
          text: `less than 6 months`,
          id: 3653851653
        },
        {
          text: `6-11 months`,
          id: 3653851655
        },
        {
          text: `1-2 yrs`,
          id: 3653851654
        },
        {
          text: `3-5 yrs`,
          id: 3653858054
        },
        {
          text: `6-9 yrs`,
          id: 3653858055
        },
        {
          text: `10-19 yrs`,
          id: 3653858056
        },
        {
          text: `20+ yrs`,
          id: 3653858057
        },
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 127.5,
    "fields": {
      "question_id": "584283669",
      "question_text": "Check ALL SUBSTANCES used for AT LEAST 3 MONTHS before AGE 30 (skip if none):",
      "survey_id": "294219921",
      "question_type": "multiple_choice",
      "created_at": "2020-12-13T13:16:07.127Z",
      "updated_at": "2020-12-13T13:16:07.127Z",
      containsOther: true,
      otherOPtionId: 3849141640,
      otherOptionText: `Other (please specify)`,
      options: [
        {text: "tobacco", id: "3849133070"},
        {text: "vaping", id: "3849133058"},
        {text: "alcohol", id: "3849133059"},
        {text: "Rx opiates (pain)", id: "3849133060"},
        {text: "Rx benzos (anxiety)", id: "3849133061"},
        {text: "Rx ADHD (stimulants)", id: "3849133071"},
        {text: "Rx barbiturates (sedatives)", id: "3849133062"},
        {text: "marijuana", id: "3849133063"},
        {text: "cocaine / crack", id: "3849133064"},
        {text: "heroin", id: "3849133065"},
        {text: "ecstasy / MDMA", id: "3849133069"},
        {text: "crystal meth", id: "3849133066"},
        {text: "hallucinogens", id: "3849133067"},
        {text: "inhalants (non-medical)", id: "3849133072"}
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 128,
    "fields": {
      "question_id": "549097447",
      "question_text": "How much tobacco (# of cigarettes OR time chewing / snuff) on a typical day?",
      "survey_id": "294219921",
      "question_type": "matrix",
      "created_at": "2020-11-21T14:10:29.289Z",
      "updated_at": "2020-11-21T14:10:29.289Z",
      // containsOther: true,
      otherOPtionId: 3815523960,
      otherOptionText: 'Other (please specify)',
      choices: [
        {
          choice_text: `in the past 30 days`,
          row_id: 3622508544
        },
        {
          choice_text: `1-3 months ago`,
          row_id: 3622508545
        },
        {
          choice_text: `4-11 months ago`,
          row_id: 3622508546
        },
        {
          choice_text: `1-4 years ago`,
          row_id: 3622508547
        },
        {
          choice_text: `5+ years ago`,
          row_id: 3653737632
        },
      ],
      rowText: [
        {
          text: `none`,
          id: 3622508548
        },
        {
          text: `1-4 cigs OR 30 min chew/snuff`,
          id: 3815555159
        },
        {
          text: `5-10 cigs OR 31-60 min chew/snuff`,
          id: 3622508549
        },
        {
          text: `11-20 cigs OR 1.5-2.5 hrs chew/snuff`,
          id: 3622508550
        },
        {
          text: `21-30 cigs OR 3-4.5 hrs chew/snuff`,
          id: 3622508551
        },
        {
          text: `31+ cigs OR 4.5+ hrs chew/snuff`,
          id: 3622508552
        },
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 129,
    "fields": {
      "question_id": "549097415",
      "question_text": "HOW SOON after waking?",
      "survey_id": "294219921",
      "question_type": "matrix",
      "created_at": "2020-11-21T14:10:29.974Z",
      "updated_at": "2020-11-21T14:10:29.974Z",
      choices: [
        {
          choice_text: `in the past 30 days`,
          row_id: 3622508003
        },
        {
          choice_text: `1-3 months ago`,
          row_id: 3622507997
        },
        {
          choice_text: `4-11 months ago`,
          row_id: 3622507998
        },
        {
          choice_text: `1-4 years ago`,
          row_id: 3622507996
        },
        {
          choice_text: `5+ years ago`,
          row_id: 3653739734
        },
      ],
      rowText: [
        {
          text: `within 5 minutes`,
          id: 3622508004
        },
        {
          text: `6-30 minutes`,
          id: 3622508000
        },
        {
          text: `31-60 minutes`,
          id: 3622508001
        },
        {
          text: `after 60 minutes`,
          id: 3622508002
        },
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 130,
    "fields": {
      "question_id": 549097490,
      "question_text": "How many mg/ml (JUUL reference) vaped on a typical day?",
      "survey_id": 294219921,
      "question_type": "matrix",
      "created_at": "2020-12-19T17:06:54.230Z",
      "updated_at": "2020-12-19T17:06:54.230Z",
      choices: [
        {choice_text: "in the past 30 days", row_id: 3961378351},
        {choice_text: "1-3 months ago", row_id: 3961378352},
        {choice_text: "4-11 months ago", row_id: 3961378353},
        {choice_text: "1-4 years ago", row_id: 3961378354},
        {choice_text: "5+ years ago", row_id: 3961378355}
      ],
      rowText: [
        {text: "none", id: 3961378364},
        {text: "1mg/ml (0.1%)", id: 3961378365},
        {text: "2mg/ml (0.2%)", id: 3961378366},
        {text: "3-4mg/ml (0.3-0.4%)", id: 3961378367},
        {text: "5-6mg/ml (0.5-0.6%)", id: 3961378368},
        {text: "7mg+/ml (0.7", id: 3961378369}
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 131,
    "fields": {
      "question_id": 549097491,
      "question_text": "HOW SOON after waking?",
      "survey_id": 294219921,
      "question_type": "matrix",
      "created_at": "2020-12-19T17:06:54.230Z",
      "updated_at": "2020-12-19T17:06:54.230Z",
      choices: [
        {choice_text: "in the past 30 days", row_id: 3961387639},
        {choice_text: "1-3 months ago", row_id: 3961387638},
        {choice_text: "4-11 months ago", row_id: 3961387642},
        {choice_text: "1-4 years ago", row_id: 3961387641},
        {choice_text: "5+ years ago", row_id: 3961387640}
      ],
      rowText: [
        {
          text: "within 5 minutes",
          id: 3961387647
        },
        {
          text: "6-30 minutes", 
          id: 3961387644
        },
        {
          text: "31-60 minutes", 
          id: 3961387645
        },
        {
          text: "after 60 minutes", 
          id: 3961387646
        }
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 132,
    "fields": {
      "question_id": "555274672",
      "question_text": "During the past 30 DAYS, how often did you use",
      "survey_id": "294219921",
      "question_type": "matrix",
      "created_at": "2020-11-21T14:10:26.758Z",
      "updated_at": "2020-11-21T14:10:26.758Z",
      containsOther: true,
      otherOPtionId: 3661958003,
      otherOptionText: 'Other (please list):',
      choices: [
        {
          choice_text: `tobacco`,
          row_id: 3661958021
        },
        {
          choice_text: `vaping`,
          row_id: 3668010003
        },
        {
          choice_text: `alcohol`,
          row_id: 3661958014
        },
        {
          choice_text: `Rx opiates (pain)`,
          row_id: 3661958024
        },
        {
          choice_text: `Rx benzos (anxiety)`,
          row_id: 3661958025
        },
        {
          choice_text: `Rx barbiturates (sedatives)`,
          row_id: 3661958027
        },
        {
          choice_text: `Rx ADHD (stimulants)`,
          row_id: 3661958026
        },
        {
          choice_text: `marijuana`,
          row_id: 3661958022
        },
        {
          choice_text: `cocaine / crack`,
          row_id: 3661958015
        },
        {
          choice_text: `heroin`,
          row_id: 3661958016
        },
        {
          choice_text: `crystal meth`,
          row_id: 3661958018
        },
        {
          choice_text: `ecstasy /MDMA`,
          row_id: 3661958017
        },
        {
          choice_text: `hallucinogens`,
          row_id: 3661958019
        },
        {
          choice_text: `inhalants`,
          row_id: 3661958020
        },
        {
          choice_text: `other`,
          row_id: 3661956317
        }
      ],
      rowText: [
        {
          text: `never`,
          id: 3661958035
        },
        {
          text: `less than  weekly`,
          id: 3662330539
        },
        {
          text: `weekly`,
          id: 3661958033
        },
        {
          text: ` 2-3x    per week`,
          id: 3661958034
        },
        {
          text: `daily or    most days`,
          id: 3661958036
        },
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 132.1,
    "fields": {
      "question_id": "556214603",
      "question_text": "The LAST 7 DAYS included (check ALL that apply)",
      "survey_id": "294219921",
      "question_type": "multiple_choice",
      "created_at": "2020-11-21T14:10:28.838Z",
      "updated_at": "2020-11-21T14:10:28.838Z",
      noneId: 3667173323,
      containsOther: true,
      otherOPtionId: 3667173324,
      otherOptionText: 'Others (please specify)',
      options: [
        {
          text: `tobacco`,
          id: 3667158448
        },
        {
          text: `Rx ADHD (stimulants)`,
          id: 3667158453
        },
        {
          text: `ecstasy / MDMA`,
          id: 3667158458
        },
        {
          text: `vaping`,
          id: 3667158449
        },
        {
          text: `Rx barbiturates (sedatives)`,
          id: 3667158454
        },
        {
          text: `crystal meth`,
          id: 3667173320
        },
        {
          text: `alcohol`,
          id: 3667158450
        },
        {
          text: `marijuana`,
          id: 3667158455
        },
        {
          text: `hallucinogens`,
          id: 3667173321
        },
        {
          text: `Rx opiates (pain)`,
          id: 3667158451
        },
        {
          text: `cocaine / crack`,
          id: 3667158456
        },
        {
          text: `inhalants`,
          id: 3667173322
        },
        {
          text: `Rx benzos (anxiety)`,
          id: 3667158452
        },
        {
          text: `heroin`,
          id: 3667158457
        },
        {
          text: `none`,
          id: 3667173323
        },
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 132.15,
    "fields": {
      "question_id": 549097492,
      "question_text": "1-3 months ago, how often were you using:",
      "survey_id": 294219921,
      "question_type": "matrix",
      "created_at": "2020-12-19T17:06:53.780Z",
      "updated_at": "2020-12-19T17:06:53.780Z",
      containsOther: true,
      otherOptionText: 'Other',
      otherOPtionId: 3961554533,
      choices: [
        {choice_text: "tobacco", row_id: 3961554558},
        {choice_text: "vaping", row_id: 3961554550},
        {choice_text: "alcohol", row_id: 3961554551},
        {choice_text: "Rx opiates (pain)", row_id: 3961554561},
        {choice_text: "Rx benzos (anxiety)", row_id: 3961554564},
        {choice_text: "Rx ADHD (stimulants)", row_id: 3961554562},
        {choice_text: "Rx barbiturates (sedatives)", row_id: 3961554563},
        {choice_text: "marijuana", row_id: 3961554559},
        {choice_text: "cocaine / crack", row_id: 3961554552},
        {choice_text: "heroin", row_id: 3961554553},
        {choice_text: "ecstasy /MDMA", row_id: 3961554554},
        {choice_text: "crystal meth", row_id: 3961554555},
        {choice_text: "hallucinogens", row_id: 3961554556},
        {choice_text: "inhalants", row_id: 3961554557},
        {choice_text: "other", row_id: 3961554560}
      ],
      rowText: [
        {text: "never", id: 3961554547},
        {text: "monthly or less", id: 3961554544},
        {text: "2-3x/month", id: 3961554546},
        {text: "weekly", id: 3961554549},
        {text: "2-3x/week", id: 3961554548},
        {text: "daily or most days", id: 3961554545}
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 132.2,
    "fields": {
      "question_id": "555274472",
      "question_text": "In the past 4-11 MONTHS, how often did you use",
      "survey_id": "294219921",
      "question_type": "matrix",
      "created_at": "2020-11-21T14:10:24.214Z",
      "updated_at": "2020-11-21T14:10:24.214Z",
      containsOther: true,
      otherOPtionId: 3661956297,
      otherOptionText: 'other',
      choices: [
        {
          choice_text: `tobacco`,
          row_id: 3661956315
        },
        {
          choice_text: `vaping`,
          row_id: 3668007527
        },
        {
          choice_text: `alcohol`,
          row_id: 3661956308
        },
        {
          choice_text: `Rx opiates (pain)`,
          row_id: 3661956318
        },
        {
          choice_text: `Rx benzos (anxiety)`,
          row_id: 3661956319
        },
        {
          choice_text: `Rx ADHD (stimulants)`,
          row_id: 3661956320
        },
        {
          choice_text: `Rx barbiturates (sedatives)`,
          row_id: 3661956321
        },
        {
          choice_text: `marijuana`,
          row_id: 3661956316
        },
        {
          choice_text: `cocaine / crack`,
          row_id: 3661956309
        },
        {
          choice_text: `heroin`,
          row_id: 3661956310
        },
        {
          choice_text: `ecstasy /MDMA`,
          row_id: 3661956311
        },
        {
          choice_text: `crystal meth`,
          row_id: 3661956312
        },
        {
          choice_text: `hallucinogens`,
          row_id: 3661956313
        },
        {
          choice_text: `inhalants`,
          row_id: 3661956314
        },
        {
          choice_text: `other`,
          row_id: 3661956317
        }
      ],
      rowText: [
        {
          text: `never`,
          id: 3661956329
        },
        {
          text: `monthly   or less`,
          id: 3662322005
        },
        {
          text: `2-3x   per month`,
          id: 3661956327
        },
        {
          text: `weekly`,
          id: 3661956328
        },
        {
          text: `2-3x    per week`,
          id: 3661956330
        },
        {
          text: `daily or    most days`,
          id: 3661956326
        },
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 132.3,
    "fields": {
      "question_id": 549097493,
      "question_text": "1-4 years ago, how often were you using",
      "survey_id": 294219921,
      "question_type": "matrix",
      "created_at": "2020-12-19T17:06:53.780Z",
      "updated_at": "2020-12-19T17:06:53.780Z",
      containsOther: true,
      otherOPtionId: 3955322362,
      otherOptionText: 'Other',
      choices: [
        {choice_text: "tobacco", row_id: 3955322387},
        {choice_text: "vaping", row_id: 3955322379},
        {choice_text: "alcohol", row_id: 3955322380},
        {choice_text: "Rx opiates (pain)", row_id: 3955322390},
        {choice_text: "Rx benzos (anxiety)", row_id: 3955322393},
        {choice_text: "Rx ADHD (stimulants)", row_id: 3955322391},
        {choice_text: "Rx barbiturates (sedatives)", row_id: 3955322392},
        {choice_text: "marijuana", row_id: 3955322388},
        {choice_text: "cocaine / crack", row_id: 3955322381},
        {choice_text: "heroin", row_id: 3955322382},
        {choice_text: "ecstasy /MDMA", row_id: 3955322383},
        {choice_text: "crystal meth", row_id: 3955322384},
        {choice_text: "hallucinogens", row_id: 3955322385},
        {choice_text: "inhalants", row_id: 3955322386},
        {choice_text: "other", row_id: 3955322389},
      ],
      rowText: [
        {text: "never", id: 3955322376},
        {text: "monthly or less", id: 3955322373},
        {text: "2-3x/month", id: 3955322375},
        {text: "weekly", id: 3955322378},
        {text: "2-3x/week", id: 3955322377},
        {text: "daily or most days", id: 3955322374}
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 133,
    "fields": {
      "question_id": "549097387",
      "question_text": "Any BIOLOGICAL RELATIVES with a substance problem other than tobacco/nicotine? (Check all that apply)",
      "survey_id": "294219921",
      "question_type": "multiple_choice",
      "created_at": "2020-11-21T14:10:30.688Z",
      "updated_at": "2020-11-21T14:10:30.688Z",
      options: [
        {text: "father", id: "3622507682"},
        {text: "mother", id: "3622507683"},
        {text: "a sibling", id: "3622507684"},
        {text: "2 siblings", id: "3622507685"},
        {text: "3+ siblings", id: "3622507686"},
        {text: "a child", id: "3622507687"},
        {text: "2 children", id: "3622507688"},
        {text: "3+ children", id: "3622507689"},
        {text: "a grandparent", id: "3622507690"},
        {text: "2 grandparents", id: "3622507691"},
        {text: "3+ grandparents", id: "3622507692"},
        {text: "an aunt or uncle", id: "3622507693"},
        {text: "2 aunts or uncles", id: "3622507694"},
        {text: "3+ aunts or uncles", id: "3622507695"},
        {text: "a cousin, niece or nephew", id: "3622507696"},
        {text: "2 cousins, nieces or nephews", id: "3622507697"},
        {text: "3+ cousins, nieces or nephews", id: "3622507698"},
        {text: "none or don't know", id: "3622507699"}
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 133.5,
    "fields": {
      "question_id": "587379847",
      "question_text": "Any BIOLOGICAL RELATIVES died from a substance problem other than tobacco/nicotine? (check ALL that apply)",
      "survey_id": "294219921",
      "question_type": "multiple_choice",
      "created_at": "2020-12-19T17:06:54.520Z",
      "updated_at": "2020-12-19T17:06:54.520Z"
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 134,
    "fields": {
      "question_id": "549097420",
      "question_text": "Ever take prescribed OPIATES for an injury? (check ALL that apply)",
      "survey_id": "294219921",
      "question_type": "multiple_choice",
      "created_at": "2020-11-21T14:10:31.194Z",
      "updated_at": "2020-11-21T14:10:31.194Z",
      rowSize: 2,
      containsOther: true,
      otherOPtionId: 3622508072,
      otherOptionText: 'Others injury',
      noneId: 3622508071,
      options: [
        {
          text: `no`,
          id: 3622508071
        },
        {
          text: `vehicle`,
          id: 3622508086
        },
        {
          text: `lifting, bending or using equipment`,
          id: 3622508079
        },
        {
          text: `by another person(s)`,
          id: 3622508105
        },
        {
          text: `fall`,
          id: 3622508080
        },
        {
          text: `self-inflicted`,
          id: 3622508106
        },
        {
          text: `sports-related`,
          id: 3622508081
        },
        {
          text: `combat / war`,
          id: 3622508107
        },
        {
          text: `work-related`,
          id: 3622508082
        },
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 135,
    "fields": {
      "question_id": "549097421",
      "question_text": "Ever take prescribed OPIATES for surgery or a chronic condition? (check ALL that apply)",
      "survey_id": "294219921",
      "question_type": "multiple_choice",
      "created_at": "2020-11-21T14:10:31.486Z",
      "updated_at": "2020-11-21T14:10:31.486Z",
      rowSize: 1,
      containsOther: true,
      otherOPtionId: 3622508104,
      noneId: 3622508088,
      otherOptionText: 'Other chronic condition (list here, plus surgical procedure):',
      options: [
        {
          text: `no`,
          id: 3622508088
        },
        {
          text: `surgical procedure`,
          id: 3622508089
        },
        {
          text: `back pain`,
          id: 3662549204
        },
        {
          text: `headaches`,
          id: 3622508090
        },
        {
          text: `joint pain / arthritis`,
          id: 3622508091
        },
        {
          text: `nerve pain / neuropathy`,
          id: 3622508092
        },
        {
          text: `muscle pain / fibromyalgia`,
          id: 3622508093
        },
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 136,
    "fields": {
      "question_id": "549097391",
      "question_text": "Did opiate use lead to:",
      "survey_id": "294219921",
      "question_type": "matrix",
      "created_at": "2020-11-21T14:10:31.725Z",
      "updated_at": "2020-11-21T14:10:31.725Z",
      choices: [
        {
          choice_text: `taking more than prescribed?`,
          row_id: 3652080831
        },
        {
          choice_text: `heroin use?`,
          row_id: 3652080830
        },
      ],
      rowText: [
        {
            text: `No`,
            id: 3622507406
        },
        {
            text: `Yes`,
            id: 3622507407
        }
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 137,
    "fields": {
      "question_id": "549097389",
      "question_text": "Any of the following experiences? (check ALL that apply)",
      "survey_id": "294219921",
      "question_type": "multiple_choice",
      "created_at": "2020-11-21T14:10:31.807Z",
      "updated_at": "2020-11-21T14:10:31.807Z",
      rowSize: 1,
      noneId: 3622507668,
      options: [
        {
          text: `IV or IM drug injection misuse`,
          id: 3622507659
        },
        {
          text: `needle exchange program`,
          id: 3622507660
        },
        {
          text: `shared or dirty needles`,
          id: 3622507661
        },
        {
          text: `injection site infection`,
          id: 3622507664
        },
        {
          text: `unsafe sexual partner(s)`,
          id: 3622507666
        },
        {
          text: `sexually transmitted illness (STI)`,
          id: 3622507667
        },
        {
          text: `none`,
          id: 3622507668
        },
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 137.1,
    "fields": {
      "question_id": "549097340",
      "question_text": "Check if you ever had the following SYMPTOMS:",
      "survey_id": "294219921",
      "question_type": "multiple_choice",
      noneId: 3622509768,
      rowSize: 1,
      options: [
        {
          text: `used too much: used more or longer than intended at times`,
          id: 3622508668
        },
        {
          text: `used too often: spent a lot of time using, affected, or hungover`,
          id: 3622508768
        },
        {
          text: `couldn’t stop: tried and failed more than once to cut down or stop`,
          id: 3622508868
        },
        {
          text: `strong cravings: experienced strong desire or craving to use`,
          id: 3622508968
        },
        {
          text: `got in the way: using interfered with taking care of home, family, job or school`,
          id: 3622509068
        },
        {
          text: `worried others: kept using despite concerns of family or friends`,
          id: 3622509168
        },
        {
          text: `used instead of: using took the place of other valued interests or pleasures`,
          id: 3622509268
        },
        {
          text: `risky behavior: did risky things more than once while using, like swimming, 
          using machinery, walking in unsafe areas or having unsafe sex`,
          id: 3622509368
        },
        {
          text: `feeling bad: kept using while feeling depressed or anxious, having
          memory blackouts or added health risks`,
          id: 3622509468
        },
        {
          text: `needed more: needed to use more to get the same or less effects`,
          id: 3622509568
        },
        {
          text: `withdrawal: if I stopped, I couldn’t sleep, felt shaky, irritable, anxious, depressed, restless, sick, sweaty, or would see/hear things`,
          id: 3622509668
        },
        {
          text: `none of the above`,
          id: 3622509768
        },
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 138,
    "fields": {
      "question_id": "553973451",
      "question_text": "<b>When</b> were you having these SYMPTOMS ?",
      "survey_id": "294219921",
      "question_type": "matrix",
      "created_at": "2020-11-21T14:10:32.041Z",
      "updated_at": "2020-11-21T14:10:32.041Z",
      // noneText: `None of the above`,
      // noneId: 3844672728,
      // noneChoiceId: 3653547374,
      choices: [
        {
          choice_text: `used too much`,
          row_id: 3653547362
        },
        {
          choice_text: `used too often`,
          row_id: 3653547364
        },
        {
          choice_text: `couldn’t stop`,
          row_id: 3653547363
        },
        {
          choice_text: `strong cravings`,
          row_id: 3653547365
        },
        {
          choice_text: `got in the way`,
          row_id: 3653547366
        },
        {
          choice_text: `worried others`,
          row_id: 3653547367
        },
        {
          choice_text: `used instead of`,
          row_id: 3653547368
        },
        {
          choice_text: `risky behavior`,
          row_id: 3653547369
        },
        {
          choice_text: `feeling bad`,
          row_id: 3653547370
        },
        {
          choice_text: `needed more`,
          row_id: 3653547371
        },
        {
          choice_text: `withdrawal`,
          row_id: 3653547372
        },
        {
          choice_text: `None of the above`,
          row_id: 3844672728
        },
      ],
      rowText: [
        {
          text: `past 30 days`,
          id: 3653547374
        },
        {
          text: `1-3 months ago`,
          id: 3653547375
        },
        {
          text: `4-11 months ago`,
          id: 3653547376
        },
        {
          text: `1-4 years ago`,
          id: 3653547377
        },
        {
          text: `5+ years ago`,
          id: 3653696915
        },
      ]
    }
  },
  // {
  //   "model": "comprisapp.questions",
  //   "pk": 146,
  //   "fields": {
  //     "question_id": "553981634",
  //     "question_text": "Check those substances that led to most of the symptoms for each time period:",
  //     "survey_id": "294219921",
  //     "question_type": "matrix",
  //     containsOther: true,
  //     otherOPtionId: 365300006,
  //     "otherOptionText": `Other (please list):`,
  //     choices: [
  //       {
  //         choice_text: `tobacco`,
  //         row_id: 3653596771
  //       },
  //       {
  //         choice_text: `vaping`,
  //         row_id: 3653596772
  //       },
  //       {
  //         choice_text: `alcohol`,
  //         row_id: 3653596773
  //       },
  //       {
  //         choice_text: `opiates (pain)`,
  //         row_id: 3653596774
  //       },
  //       {
  //         choice_text: `benzos (anti-anxiety)`,
  //         row_id: 3653596775
  //       },
  //       {
  //         choice_text: `barbiturates (sedatives)`,
  //         row_id: 3653596777
  //       },
  //       {
  //         choice_text: `ADHD (stimulants)`,
  //         row_id: 3653596776
  //       },
  //       {
  //         choice_text: `marijuana`,
  //         row_id: 3653596778
  //       },
  //       {
  //         choice_text: `cocaine / crack`,
  //         row_id: 3653596779
  //       },
  //       {
  //         choice_text: `heroin`,
  //         row_id: 3653596780
  //       },
  //       {
  //         choice_text: `crystal meth`,
  //         row_id: 3653596782
  //       },
  //       {
  //         choice_text: `ecstasy /MDMA`,
  //         row_id: 3653596781
  //       },
  //       {
  //         choice_text: `hallucinogens`,
  //         row_id: 3653596783
  //       },
  //       {
  //         choice_text: `inhalants`,
  //         row_id: 3653596784
  //       },
  //       {
  //         choice_text: `other`,
  //         row_id: 3653596785
  //       }
  //     ],
  //     rowText: [
  //       {
  //         text: `past 30 days`,
  //         id:  3653693370
  //       },
  //       {
  //         text: `1-3 months ago`,
  //         id: 3653693371
  //       },
  //       {
  //         text: `4-11 months ago`,
  //         id: 3653693372
  //       },
  //       {
  //         text: `1-4 years ago`,
  //         id: 3653693373
  //       },
  //       {
  //         text: `5+ years ago`,
  //         id: 3653693374
  //       },
  //     ]
  //   }
  // },
  {
    "model": "comprisapp.questions",
    "pk": 139,
    "fields": {
      "question_id": "553981633",
      "question_text": "Which substances had <b>two or more</b> of these SYMPTOMS during what time ? ",
      "survey_id": "294219921",
      "question_type": "matrix",
      "created_at": "2020-11-21T14:10:33.678Z",
      "updated_at": "2020-11-21T14:10:33.678Z",
      containsOther: true,
      otherOPtionId: 3653596751,
      otherOptionText: `Other`,
      choices: [
        {
          choice_text: `alcohol`,
          row_id: 3653596763
        },
        {
          choice_text: `opiates (pain)`,
          row_id: 3653627817
        },
        {
          choice_text: `benzos (anti-anxiety)`,
          row_id: 3653596762
        },
        {
          choice_text: `ADHD (stimulants)`,
          row_id: 3653596764
        },
        {
          choice_text: `barbiturates (sedatives)`,
          row_id: 3653596765
        },
        {
          choice_text: `marijuana`,
          row_id: 3653596766
        },
        {
          choice_text: `cocaine / crack`,
          row_id: 3653627818
        },
        {
          choice_text: `heroin`,
          row_id: 3653627819
        },
        {
          choice_text: `ecstasy /MDMA`,
          row_id: 3653627820
        },
        {
          choice_text: `crystal meth`,
          row_id: 3653627821
        },
        {
          choice_text: `hallucinogens`,
          row_id: 3653627822
        },
        {
          choice_text: `inhalants`,
          row_id: 3653627823
        },
        {
          choice_text: `other`,
          row_id: 3653627824
        }
      ],
      rowText: [
        {
          text: `past 30 days`,
          id: 3653596742
        },
        {
          text: `1-3 months ago`,
          id: 3653596750
        },
        {
          text: `4-11 months ago`,
          id: 3653596743
        },
        {
          text: `1-4 years ago`,
          id: 3653596744
        },
        {
          text: `5+ years ago`,
          id: 3653693369
        },
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 140,
    "fields": {
      "question_id": "556167203",
      "question_text": "NUMBER OF SYMPTOMS for each substance during<strong> the past 30 days</strong>?",
      "survey_id": "294219921",
      "question_type": "matrix",
      "created_at": "2020-11-21T14:10:35.496Z",
      "updated_at": "2020-11-21T14:10:35.496Z",
      containsOther: true,
      otherOPtionId: 3666858859,
      otherOptionText: `Other`,
      choices: [
        {
          choice_text: `alcohol`,
          row_id: 3666858879
        },
        {
          choice_text: `opiates (pain)`,
          row_id: 3666858871
        },
        {
          choice_text: `benzos (anti-anxiety)`,
          row_id: 3666858878
        },
        {
          choice_text: `ADHD (stimulants)`,
          row_id: 3666858880
        },
        {
          choice_text: `barbiturates (sedatives)`,
          row_id: 3666858881
        },
        {
          choice_text: `marijuana`,
          row_id: 3666858882
        },
        {
          choice_text: `cocaine / crack`,
          row_id: 3666858872
        },
        {
          choice_text: `heroin`,
          row_id: 3666858873
        },
        {
          choice_text: `ecstasy /MDMA`,
          row_id: 3666858874
        },
        {
          choice_text: `crystal meth`,
          row_id: 3666858875
        },
        {
          choice_text: `hallucinogens`,
          row_id: 3666858876
        },
        {
          choice_text: `inhalants`,
          row_id: 3666858877
        },
        {
          choice_text: `other`,
          row_id: 3666858883
        }
      ],
      rowText: [
        {
          text: `2-3`,
          id: 3666858884
        },
        {
          text: `4-5`,
          id: 3666858885
        },
        {
          text: `6 or more`,
          id: 3666858886
        }
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 141,
    "fields": {
      "question_id": "556181470",
      "question_text": "NUMBER OF SYMPTOMS for each substance <strong>1-3 months ago</strong>? ",
      "survey_id": "294219921",
      "question_type": "matrix",
      "created_at": "2020-11-21T14:10:36.581Z",
      "updated_at": "2020-11-21T14:10:36.581Z",
      containsOther: true,
      otherOPtionId: 3666946086,
      otherOptionText: `Other`,
      choices: [
        {
          choice_text: `alcohol`,
          row_id: 3666945788
        },
        {
          choice_text: `opiates (pain)`,
          row_id: 3666945780
        },
        {
          choice_text: `benzos (anti-anxiety)`,
          row_id: 3666945787
        },
        {
          choice_text: `ADHD (stimulants)`,
          row_id: 3666945789
        },
        {
          choice_text: `barbiturates (sedatives)`,
          row_id: 3666945790
        },
        {
          choice_text: `marijuana`,
          row_id: 3666945791
        },
        {
          choice_text: `cocaine / crack`,
          row_id: 3666945781
        },
        {
          choice_text: `heroin`,
          row_id: 3666945782
        },
        {
          choice_text: `ecstasy /MDMA`,
          row_id: 3666945783
        },
        {
          choice_text: `crystal meth`,
          row_id: 3666945784
        },
        {
          choice_text: `hallucinogens`,
          row_id: 3666945785
        },
        {
          choice_text: `inhalants`,
          row_id: 3666945786
        },
        {
          choice_text: `other`,
          row_id: 3666945792
        }
      ],
      rowText: [
        {
          text: `2-3`,
          id: 3666945793
        },
        {
          text: `4-5`,
          id: 3666945794
        },
        {
          text: `6 or more`,
          id: 3666945795
        }
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 142,
    "fields": {
      "question_id": "556181518",
      "question_text": "NUMBER OF SYMPTOMS for each substance <strong>4-11 months ago</strong>?",
      "survey_id": "294219921",
      "question_type": "matrix",
      "created_at": "2020-11-21T14:10:37.678Z",
      "updated_at": "2020-11-21T14:10:37.678Z",
      containsOther: true,
      otherOPtionId: 3666945769,
      otherOptionText: `Other`,
      choices: [
        {
          choice_text: `alcohol`,
          row_id: 3666946105
        },
        {
          choice_text: `opiates (pain)`,
          row_id: 3666946097
        },
        {
          choice_text: `benzos (anti-anxiety)`,
          row_id: 3666946104
        },
        {
          choice_text: `ADHD (stimulants)`,
          row_id: 3666946106
        },
        {
          choice_text: `barbiturates (sedatives)`,
          row_id: 3666946107
        },
        {
          choice_text: `marijuana`,
          row_id: 3666946108
        },
        {
          choice_text: `cocaine / crack`,
          row_id: 3666946098
        },
        {
          choice_text: `heroin`,
          row_id: 3666946099
        },
        {
          choice_text: `ecstasy /MDMA`,
          row_id: 3666946100
        },
        {
          choice_text: `crystal meth`,
          row_id: 3666946101
        },
        {
          choice_text: `hallucinogens`,
          row_id: 3666946102
        },
        {
          choice_text: `inhalants`,
          row_id: 3666946103
        },
        {
          choice_text: `other`,
          row_id: 3666946109
        }
      ],
      rowText: [
        {
          text: `2-3`,
          id: 3666946110
        },
        {
          text: `4-5`,
          id: 3666946111
        },
        {
          text: `6 or more`,
          id: 3666946112
        }
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 143,
    "fields": {
      "question_id": "556181583",
      "question_text": "NUMBER OF SYMPTOMS for each substance <strong>1-4 years ago</strong>?",
      "survey_id": "294219921",
      "question_type": "matrix",
      "created_at": "2020-11-21T14:10:38.767Z",
      "updated_at": "2020-11-21T14:10:38.767Z",
      containsOther: true,
      otherOPtionId: 3666946438,
      otherOptionText: `Other`,
      choices: [
        {
          choice_text: `alcohol`,
          row_id: 3666946457
        },
        {
          choice_text: `opiates (pain)`,
          row_id: 3666946449
        },
        {
          choice_text: `benzos (anti-anxiety)`,
          row_id: 3666946456
        },
        {
          choice_text: `ADHD (stimulants)`,
          row_id: 3666946458
        },
        {
          choice_text: `barbiturates (sedatives)`,
          row_id: 3666946459
        },
        {
          choice_text: `marijuana`,
          row_id: 3666946460
        },
        {
          choice_text: `cocaine / crack`,
          row_id: 3666946450
        },
        {
          choice_text: `heroin`,
          row_id: 3666946451
        },
        {
          choice_text: `ecstasy /MDMA`,
          row_id: 3666946452
        },
        {
          choice_text: `crystal meth`,
          row_id: 3666946453
        },
        {
          choice_text: `hallucinogens`,
          row_id: 3666946454
        },
        {
          choice_text: `inhalants`,
          row_id: 3666946455
        },
        {
          choice_text: `other`,
          row_id: 3666946461
        }
      ],
      rowText: [
        {
          text: `2-3`,
          id: 3666946462
        },
        {
          text: `4-5`,
          id: 3666946463
        },
        {
          text: `6 or more`,
          id: 3666946464
        }
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 144,
    "fields": {
      "question_id": "556181824",
      "question_text": "NUMBER OF SYMPTOMS for each substance <strong>5+ years ago</strong>? ",
      "survey_id": "294219921",
      "question_type": "matrix",
      "created_at": "2020-11-21T14:10:39.848Z",
      "updated_at": "2020-11-21T14:10:39.848Z",
      containsOther: true,
      otherOPtionId: 3666946768,
      otherOptionText: `Other`,
      choices: [
        {
          choice_text: `alcohol`,
          row_id: 3666946787
        },
        {
          choice_text: `opiates (pain)`,
          row_id: 3666946779
        },
        {
          choice_text: `benzos (anti-anxiety)`,
          row_id: 3666946786
        },
        {
          choice_text: `ADHD (stimulants)`,
          row_id: 3666946788
        },
        {
          choice_text: `barbiturates (sedatives)`,
          row_id: 3666946789
        },
        {
          choice_text: `marijuana`,
          row_id: 3666946790
        },
        {
          choice_text: `cocaine / crack`,
          row_id: 3666946780
        },
        {
          choice_text: `heroin`,
          row_id: 3666946781
        },
        {
          choice_text: `ecstasy /MDMA`,
          row_id: 3666946782
        },
        {
          choice_text: `crystal meth`,
          row_id: 3666946783
        },
        {
          choice_text: `hallucinogens`,
          row_id: 3666946784
        },
        {
          choice_text: `inhalants`,
          row_id: 3666946785
        },
        {
          choice_text: `other`,
          row_id: 3666946791
        }
      ],
      rowText: [
        {
          text: `2-3`,
          id: 3666946792
        },
        {
          text: `4-5`,
          id: 3666946793
        },
        {
          text: `6 or more`,
          id: 3666946794
        }
      ]
    }
  },
  // Placed
  {
    "model": "comprisapp.questions",
    "pk": 144.1,
    "fields": {
      "question_id": "556181825",
      "question_text": "How often did you have 5 or more alcohol-containing drinks within a few hours?",
      "survey_id": "294219921",
      "question_type": "matrix",
      "created_at": "2020-11-21T14:10:29.974Z",
      "updated_at": "2020-11-21T14:10:29.974Z",
      choices: [
        {
          choice_text: `in the past 30 days`,
          row_id: 100001
        },
        {
          choice_text: `1-3 months ago`,
          row_id: 100002
        },
        {
          choice_text: `4-11 months ago`,
          row_id: 100003
        },
        {
          choice_text: `1-4 years ago`,
          row_id: 100004
        },
        {
          choice_text: `5+ years ago`,
          row_id: 100005
        },
      ],
      rowText: [
        {
          text: `never`,
          id: 4622508820
        },
        {
          text: `monthly or less`,
          id: 4622508821
        },
        {
          text: `2-3 times/month`,
          id: 4622508822
        },
        {
          text: `weekly`,
          id: 4622508823
        },
        {
          text: `2-3 times/week`,
          id: 4622508824
        },
         {
          text: `daily or most days`,
          id: 4622508825
        },
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 145,
    "fields": {
      "question_id": "554010038",
      "question_text": "Likely have, or at one time had, the following substance problem",
      "survey_id": "294219921",
      "question_type": "matrix",
      "created_at": "2020-11-21T14:10:40.991Z",
      "updated_at": "2020-11-21T14:10:40.991Z",
      containsOther: true,
      otherOPtionId: 3653767293,
      otherOptionText: `Other`,
      choices: [
        {
          choice_text: `tobacco`,
          row_id: 3653767313
        },
        {
          choice_text: `vaping`,
          row_id: 3668018437
        },
        {
          choice_text: `alcohol`,
          row_id: 3653771371
        },
        {
          choice_text: `opiates (pain)`,
          row_id: 3653767304
        },
        {
          choice_text: `benzos (anti-anxiety)`,
          row_id: 3653767312
        },
        {
          choice_text: `ADHD (stimulants)`,
          row_id: 3653767314
        },
        {
          choice_text: `barbiturates (sedatives)`,
          row_id: 3653767315
        },
        {
          choice_text: `marijuana`,
          row_id: 3653767316
        },
        {
          choice_text: `cocaine / crack`,
          row_id: 3653767305
        },
        {
          choice_text: `heroin`,
          row_id: 3653767306
        },
        {
          choice_text: `ecstasy /MDMA`,
          row_id: 3653767307
        },
        {
          choice_text: `crystal meth`,
          row_id: 3653767308
        },
        {
          choice_text: `hallucinogens`,
          row_id: 3653767309
        },
        {
          choice_text: `inhalants`,
          row_id: 3653767310
        },
        {
          choice_text: `other`,
          row_id: 3653767311
        }
      ],
      rowText: [
        {
          text: `no`,
          id: 3653767317
        },
        {
          text: `unsure`,
          id: 3653767318
        },
        {
          text: `yes - mild`,
          id: 3653767319
        },
        {
          text: `yes - moderate`,
          id: 3662621737
        },
        {
          text: `yes - severe`,
          id: 3662621738
        }
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 146,
    "fields": {
      "question_id": "555422217",
      "question_text": "AGE FIRST and LAST USED each substance, and WHEN it may have become a <strong>problem</strong> or <strong>dependence</strong>?",
      "survey_id": "294219921",
      "question_type": "matrix",
      "created_at": "2020-11-21T14:10:43.100Z",
      "updated_at": "2020-11-21T14:10:43.100Z",
      // "containsOther": true,
      // "otherOPtionId": 3662615686,
      // "otherOptionText": `Other (please list):`,
      choices: [
        {
          choice_text: `tobacco`,
          row_id: 3662615706
        },
        {
          choice_text: `vaping`,
          row_id: 3668022115
        },
        {
          choice_text: `alcohol`,
          row_id: 3662615697
        },
        {
          choice_text: `opiates (pain)`,
          row_id: 3662615698
        },
        {
          choice_text: `benzos (anti-anxiety)`,
          row_id: 3662615705
        },
        {
          choice_text: `barbiturates (sedatives)`,
          row_id: 3662615707
        },
        {
          choice_text: `ADHD (stimulants)`,
          row_id: 3662615708
        },
        {
          choice_text: `marijuana`,
          row_id: 3662615709
        },
        {
          choice_text: `cocaine / crack`,
          row_id: 3662615699
        },
        {
          choice_text: `heroin`,
          row_id: 3662615700
        },
        {
          choice_text: `crystal meth`,
          row_id: 3662615701
        },
        {
          choice_text: `ecstasy /MDMA`,
          row_id: 3662615702
        },
        {
          choice_text: `hallucinogens`,
          row_id: 3662615703
        },
        {
          choice_text: `inhalants`,
          row_id: 3662615704
        },
        {
          choice_text: `other`,
          row_id: 3662615710
        }
      ],
      rowText: [
        {
          text: `age first used`,
          id: 3662615711
        },
        {
          text: `age when likely became a problem or dependence`,
          id: 3662615712
        },  
        {
          text: `age last used`,
          id: 3662615713
        },
        {
          text: 'never was a problem',
          id: 3662615714
        }
      ]
    }
  },
  {
    "model": "comprisapp.choices",
    "pk": 154.5,
    "fields": {
      "question_id": 549097411,
      "question_text": `Check which one (A or B) you're MORE OFTEN like, and how strongly:`,
      "survey_id": 294219921,
      "created_at": "2020-12-19T17:06:53.146Z",
      "updated_at": "2020-12-19T17:06:53.146Z",
      question_type: 'multiple_questions',
      questions: [
        [
          {
            "questionText": "I get my energy from being around others, think outloud, and prefer lots of people in my life.",
            "choices": [
              {
                "text": "agree",
                "id": 3622508801,
                "rowid" : 3622508701
              },
              {
                "text": "strongly agree",
                "id": 3622508802,
                "rowid" : 3622508701
              }
            ]
          },
          {
            "questionText": "I enjoy quiet time with myself or with a small group of close friends, and am a good listener.",
            "choices": [
              {
                "text": "agree",
                "id": 3622508809,
                "rowid" : 3622508705
              },
              {
                "text": "strongly agree",
                "id": 3622508810,
                "rowid" : 3622508705
              }
            ]
          }
        ],
        [
          {
            "questionText": "I'm realistic and down-to-earth and like practical solutions based on what's worked in the past",
            "choices": [
              {
                "text": "agree",
                "id": 3622508803,
                "rowid" : 3622508702
              },
              {
                "text": "strongly agree",
                "id": 3622508804,
                "rowid" : 3622508702
              }
            ]
          },
          {
            "questionText": "I like thinking about new ideas and possibilities and can usually see how it all fits together.",
            "choices": [
              {
                "text": "agree",
                "id": 3622508811,
                "rowid" : 3622508706
              },
              {
                "text": "strongly agree",
                "id": 3622508812,
                "rowid" : 3622508706
              }
            ]
          }
        ],
        [
          {
            "questionText": "I’m logical. I tell the truth, even if it might hurt someone's feelings.",
            "choices": [    
              {
                "text": "agree",
                "id": 3622508805,
                "rowid" : 3622508703
              },
              {
                "text": "strongly agree",
                "id": 3622508806,
                "rowid" : 3622508703
              }
            ]
          },
          {
            "questionText": "I'm sensitive to how others feel, tend to take things personally and avoid arguments or conflicts.",
            "choices": [
              {
                "text": "agree",
                "id": 3622508813,
                "rowid" : 3622508707
              },
              {
                "text": "strongly agree",
                "id": 3622508814,
                "rowid" : 3622508707
              }
            ]
          }
        ],
        [
          {
            "questionText": "I like things decided and feel best when I've got a plan and stick with it.",
            "choices": [
              {
                "text": "agree",
                "id": 3622508807,
                "rowid" : 3622508704
              },
              {
                "text": "strongly agree",
                "id": 3622508808,
                "rowid" : 3622508704
              }
            ]
          },
          {
            "questionText": "I like to keep my options open and am comfortable changing plans when necessary.",
            "choices": [
              {
                "text": "agree",
                "id": 3622508815,
                "rowid" : 3622508708
              },
              {
                "text": "strongly agree",
                "id": 3622508816,
                "rowid" : 3622508708
              }
            ]
          }
        ]
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 147,
    "fields": {
      "question_id": "549097448",
      "question_text": "Any HEALTH problems, or currently pregnant? (check ALL that apply)",
      "survey_id": "294219921",
      "question_type": "multiple_choice",
      "created_at": "2020-11-21T14:10:44.353Z",
      "updated_at": "2020-11-21T14:10:44.353Z",
      noneId: 3654276419,
      containsOther: true,
      otherOPtionId: 3622508578,
      otherOptionText: `Other (please list):`,
      options: [
        {
          text: `arthritis`,
          id: 3622508555
        },
        {
          text: `gastritis`,
          id: 3622508563
        },
        {
          text: `memory loss or dementia`,
          id: 3815580710
        },
        {
          text: `asthma`,
          id: 3622508556
        },
        {
          text: `GI bleed`,
          id: 3622508564
        },
        {
          text: `obesity`,
          id: 3622508571
        },
        {
          text: `cancer`,
          id: 3622508557
        },
        {
          text: `heart disease`,
          id: 3622508589
        },
        {
          text: `osteomyelitis`,
          id: 3622508572
        },
        {
          text: `chronic pain`,
          id: 3622508558
        },
        {
          text: `hep B`,
          id: 3622508565
        },
        {
          text: `pancreatitis`,
          id: 3622508573
        },
        {
          text: `COPD`,
          id: 3622508559
        },
        {
          text: `hep C`,
          id: 3622508566
        },
        {
          text: `pregnant (currently)`,
          id: 3815580711
        },
        {
          text: `Covid-19`,
          id: 3815580709
        },
        {
          text: `HIV`,
          id: 3622508567
        },
        {
          text: `seizures`,
          id: 3622508574
        },
        {
          text: `diabetes`,
          id: 3622508560
        },
        {
          text: `high B/P`,
          id: 3622508568
        },
        {
          text: `stroke`,
          id: 3622508575
        },
        {
          text: `DTs (withdrawal delusions)`,
          id: 3622508561
        },
        {
          text: `kidney disease`,
          id: 3622508569
        },
        {
          text: `none`,
          id: 3654276419
        },
        {
          text: `endocarditis`,
          id: 3622508562
        },
        {
          text: `liver disease`,
          id: 3622508570
        },
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 148,
    "fields": {
      "question_id": "549097449",
      "question_text": "Any HEALTH problems due to, or likely worsened from, SUBSTANCE use? (check ALL that apply)",
      "survey_id": "294219921",
      "question_type": "multiple_choice",
      "created_at": "2020-11-21T14:10:45.157Z",
      "updated_at": "2020-11-21T14:10:45.157Z",
      noneId: 3654278163,
      containsOther: true,
      otherOPtionId: 3622508614,
      otherOptionText: `Other (please specify)`,
      options: [
        {
          text: `arthritis`,
          id: 3622508590
        },
        {
          text: `gastritis`,
          id: 3622508598
        },
        {
          text: `memory loss or dementia`,
          id: 3815587687
        },
        {
          text: `asthma`,
          id: 3622508591
        },   
        {
          text: `GI bleed`,
          id: 3622508599
        },
        {
          text: `obesity`,
          id: 3622508607
        },
        {
          text: `cancer`,
          id: 3622508592
        },
        {
          text: `heart disease`,
          id: 3622508600
        },
        {
          text: `osteomyelitis`,
          id: 3622508608
        },
        {
          text: `chronic pain`,
          id: 3622508593
        },
        {
          text: `hep B`,
          id: 3622508601
        },
        {
          text: `pancreatitis`,
          id: 3622508609
        },
        {
          text: `COPD`,
          id: 3622508594
        },
        {
          text: `hep C`,
          id: 3622508602
        },
        {
          text: `pregnancy risk or effect on child`,
          id: 3815587688
        },
        {
          text: `Covid-19`,
          id: 3815587686
        },
        {
          text: `HIV`,
          id: 3622508603
        },
        {
          text: `seizures`,
          id: 3622508610
        },
        {
          text: `diabetes`,
          id: 3622508595
        },
        {
          text: `high B/P`,
          id: 3622508604
        },
        {
          text: `stroke`,
          id: 3622508611
        },
        {
          text: `DTs (withdrawal delusions)`,
          id: 3622508596
        },
        {
          text: `kidney disease`,
          id: 3622508605
        },
        {
          text: `none`,
          id: 3654278163
        },
        {
          text: `endocarditis`,
          id: 3622508597
        },
        {
          text: `liver disease`,
          id: 3622508606
        },
      ]

    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 148.5,
    "fields": {
      "question_id": "586886313",
      "question_text": "Any negative EVENTS from your substance use? (check ALL that apply)",
      "survey_id": "294219921",
      "question_type": "multiple_choice",
      "created_at": "2020-12-19T17:06:55.839Z",
      "updated_at": "2020-12-19T17:06:55.839Z",
      noneId: 3866486056,
      // containsOther: true,
      // otherOPtionId: 3622508614,
      // "otherOptionText": "Other (please list):",
      options: [
        {text: "relationships", id: "3866397261"},
        {text: "infections", id: "3866397254"},
        {text: "incarcerations", id: "3866397258"},
        {text: "injuries / accidents", id: "3866397250"},
        {text: "hospital stays", id: "3866397255"},
        {text: "job losses", id: "3622507398"},
        {text: "memory blackouts", id: "3866397251"},
        {text: "detoxes", id: "3866397256"},
        {text: "suicide attempts", id: "3866397259"},
        {text: "overdoses", id: "3866397252"},
        {text: "arrests", id: "3866397257"},
        {text: "deaths", id: "3866397263"},
        {text: "Narcan saves", id: "3866397253"},
        {text: "DUIs", id: "3866397260"},
        {text: "none", id: "3866486056"},
        {text: "ER visits", id: "3866397262"},
        {text: "other", id: "3866397233"},
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 149,
    "fields": {
      "question_id": "549097360",
      "question_text": "How many substance-related NEGATIVE EVENTS happened? (choose ONE answer per line)",
      "survey_id": "294219921",
      "question_type": "matrix",
      "created_at": "2020-11-21T14:10:45.864Z",
      "updated_at": "2020-11-21T14:10:45.864Z",
      "otherOptionText": "Other (please list):",
      choices: [
        {
          choice_text: `relationships`,
          row_id: 3622507473
        },
        {
          choice_text: `injuries or accidents`,
          row_id: 3622507490
        },
        {
          choice_text: `memory blackouts`,
          row_id: 3622507491
        },
        {
          choice_text: `overdoses`,
          row_id: 3622507474
        },
        {
          choice_text: `Narcan saves`,
          row_id: 3622507509
        },
        {
          choice_text: `ER visits`,
          row_id: 3622507475
        },
        {
          choice_text: `infections`,
          row_id: 3622507514
        },
        {
          choice_text: `hospital stays`,
          row_id: 3622507492
        },
        {
          choice_text: `detoxes`,
          row_id: 3622507515
        },
        {
          choice_text: `arrests`,
          row_id: 3622507476
        },
        {
          choice_text: `DUIs`,
          row_id: 3622507477
        },
        {
          choice_text: `job losses`,
          row_id: 3622507478
        },
        {
          choice_text: `incarcerations`,
          row_id: 3622507479
        },
        {
          choice_text: `suicide attempts`,
          row_id: 3622507480
        },
        {
          choice_text: `deaths`,
          row_id: 3622507493
        },
        {
          choice_text: `other`,
          row_id: 3622508850
        },
      ],
      rowText: [
        {
          text: '1',
          id: 3622507482
        },
        {
          text: '2',
          id: 3622507483
        },
        {
          text: '3',
          id: 3622507484
        },
        {
          text: '4-5',
          id: 3622507485
        },
        {
          text: '6-9',
          id: 3622507486
        },
        {
          text: `10 or more`,
          id: 3622507487
        }
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 150,
    "fields": {
      "question_id": "549097350",
      "question_text": "How quickly did you become dependent?",
      "survey_id": "294219921",
      "question_type": "matrix",
      "created_at": "2020-11-21T14:10:48.675Z",
      "updated_at": "2020-11-21T14:10:48.675Z",
      row_id: 3622507382,
      rowText:[
        {
          text: `day one`,
          id: 3622507377
        },
        {
          text: `within a few days`,
          id: 3622507378
        },
        {
          text: `within a few weeks`,
          id: 3622507379
        },
        {
          text: `after a few months`,
          id: 3622507380
        },
        {
          text: `it took years`,
          id: 3622507381
        },
        {
          text: `never`,
          id: 3622507403
        },
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 151,
    "fields": {
      "question_id": "554051873",
      "question_text": "Your TOLERANCE of substance use compared to others",
      "survey_id": "294219921",
      "question_type": "matrix",
      "created_at": "2020-11-21T14:10:48.882Z",
      "updated_at": "2020-11-21T14:10:48.882Z",
      choices: [
        {
          choice_text: `when you first used`,
          row_id: 3654029814
        },
        {
          choice_text: `currently`,
          row_id: 3654029815
        }
      ],
      rowText: [
        {
          text: `less`,
          id: 3654029816
        },
        {
          text: `about the same`,
          id: 3654029817
        },
        {
          text: `greater`,
          id: 3654029818
        },
        {
          text: `unsure`,
          id: 3654029819
        },
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 152,
    "fields": {
      "question_id": "549097349",
      "question_text": "Your MAIN reason for CONTINUING to use?",
      "survey_id": "294219921",
      "question_type": "matrix",
      "created_at": "2020-11-21T14:10:49.120Z",
      "updated_at": "2020-11-21T14:10:49.120Z",
      row_id: 3622507653,
      rowText:[
        {
          text: `social`,
          id: 3622507654
        },
        {
          text: `pain relief`,
          id: 3622507655
        },
        {
          text: `to escape`,
          id: 3622507656
        },
        {
          text: `to feel good`,
          id: 3622507657
        },
        {
          text: `feed cravings OR not get sick`,
          id: 3622507658
        }
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 153,
    "fields": {
      "question_id": "549097358",
      "question_text": "Any HARM from using?",
      "survey_id": "294219921",
      "question_type": "matrix",
      "created_at": "2020-11-21T14:10:49.279Z",
      "updated_at": "2020-11-21T14:10:49.279Z",
      row_id: 3622507338,
      rowText: [
        {
          text: `none / minimal`,
          id: 3622507339
        },
        {
          text: `time and money`,
          id: 3622507340
        },
        {
          text: `relationships`,
          id: 3622507341
        },
        {
          text: `health / job / legal`,
          id: 3622507342
        },
        {
          text: `everything`,
          id: 3622507343
        },
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 154,
    "fields": {
      "question_id": "549097354",
      "question_text": "Prior attempts to QUIT?",
      "survey_id": "294219921",
      "question_type": "matrix",
      "created_at": "2020-11-21T14:10:49.437Z",
      "updated_at": "2020-11-21T14:10:49.437Z",
      row_id: 3622507292,
      rowText: [
        {
          text: `no`,
          id: 3622507293
        },
        {
          text: `1`,
          id: 3622507294
        },
        {
          text: `2`,
          id: 3622507295
        },
        {
          text: `3`,
          id: 3622507296
        },
        {
          text: `4-5`,
          id: 3622507297
        },
        {
          text: `6-9`,
          id: 3622507364
        },
        {
          text: `10 or more`,
          id: 3622507365
        }
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 155,
    "fields": {
      "question_id": "549097395",
      "question_text": "Any SUCCESS, other than while hospitalized or jailed, at (choose ONE answer per line)",
      "survey_id": "294219921",
      "question_type": "matrix",
      "created_at": "2020-11-21T14:10:49.647Z",
      "updated_at": "2020-11-21T14:10:49.648Z",
      choices: [
        {
          choice_text: `cutting back`,
          row_id: 3622507773
        },
        {
          choice_text: `stopping`,
          row_id: 3622507774
        },
      ],
      rowText: [
        {
          text: `no or N/A`,
          id: 3622507775
        },
        {
          text: `a few days`,
          id: 3622507776
        },
        {
          text: `a few weeks`,
          id: 3622507777
        },
        {
          text: `a few months`,
          id: 3622507790
        },
        {
          text: `6-11 months`,
          id: 3622507791
        },
        {
          text: `1-2 years`,
          id: 3622507792
        },
        {
          text: `3-5 years`,
          id: 3622507793
        },
        {
          text: `6-9 years`,
          id: 3622507794
        },
        {
          text: `10-19 years`,
          id: 3622507795
        },
        {
          text: `20+ years`,
          id: 3622507796
        },
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 156,
    "fields": {
      "question_id": "549097443",
      "question_text": "How long has it been since you MISUSED ANY substance (including nicotine)?",
      "survey_id": "294219921",
      "question_type": "matrix",
      "created_at": "2020-11-21T14:10:50.250Z",
      "updated_at": "2020-11-21T14:10:50.250Z",
      row_id: 3622508506,
      rowText: [
        {
          text: `never misused`,
          id: 3622508514
        },
        {
          text: `less than a month`,
          id: 3622508507
        },
        {
          text: `30 days`,
          id: 3622508508
        },
        {
          text: `3 months`,
          id: 3622508509
        },
        {
          text: `4-11 months`,
          id: 3622508510
        },
        {
          text: `1-2 years`,
          id: 3622508511
        },
        {
          text: `3-4 years`,
          id: 3622508512
        },
        {
          text: `5+ years`,
          id: 3622508513
        }
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 156.5,
    "fields": {
      "question_id": "586889293",
      "question_text": "Any HELP received? (check ALL that apply)",
      "survey_id": "294219921",
      "question_type": "multiple_choice",
      "created_at": "2020-12-19T17:06:56.187Z",
      "updated_at": "2020-12-19T17:06:56.187Z",
      noneId: 3866484385,
      rowSize: 2,
      options: [
        {text: "family", id: "3866417081"},
        {text: "outpatient program", id: "3866417082"},
        {text: "friends / colleagues", id: "3866417070"},
        {text: "inpatient program", id: "3866417074"},
        {text: "support groups", id: "3866417071"},
        {text: "residential program", id: "3866417075"},
        {text: "health care provider", id: "3866417072"},
        {text: "other", id: "3866441263"},
        {text: "professional therapy", id: "3866417073"},
        {text: "none", id: "3866484385"}
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 157,
    "fields": {
      "question_id": "549097431",
      "question_text": "How much HELP? (choose ONE answer per line)",
      "survey_id": "294219921",
      "question_type": "matrix",
      "created_at": "2020-11-21T14:10:50.461Z",
      "updated_at": "2020-11-21T14:10:50.461Z",
      containsOther: true,
      otherOPtionId: 3622508305,
      otherOptionText: `Other (please specify)`,
      choices: [
        {
          choice_text: `supportive family`,
          row_id: 3622508282
        },
        {
          choice_text: "supportive friends / colleagues",
          row_id: "3622508283",
        },
        {
          choice_text: "support group(s)",
          row_id: "3622508284"
        },
        {
          choice_text: "primary care provider",
          row_id: "3622508285"
        },
        {
          choice_text: "professional therapy",
          row_id: "3622508290"
        },
        {
          choice_text: "outpatient program",
          row_id: "3622508291"
        },
        {
          choice_text: "inpatient treatment",
          row_id: "3622508292"
        },
        {
          choice_text: "residential program",
          row_id: "3622508293"
        },
        {
          choice_text: "other",
          row_id: "3667200762"
        },
        {
          choice_text: "total help time (not counting family / friends)",
          row_id: "3622508294"
        }
      ],
      rowText: [
        {
          id: "3622508296",
          text: "a few days"
        },
        {
          id: "3622508297",
          text: "a few weeks"
        },
        {
          id: "3622508298",
          text: "a few months"
        },
        {
          id: "3622508299",
          text: "6-11 months"
        },
        {
          id: "3622508300",
          text: "1-2 years"
        },
        {

          id: "3622508301",
          text: "3-5 years"
        },
        {
          id: "3622508302",
          text: "6-9 years"
        },
        {
          id: "3622508303",
          text: "10-19 years"
        },
        {
          id: "3622508304",
          text: "20+ years"
        }
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 158,
    "fields": {
      "question_id": "549097427",
      "question_text": "Any SPECIFIC THERAPIES or SUPPORT GROUPS? (check ALL that apply)",
      "survey_id": "294219921",
      "question_type": "multiple_choice",
      "created_at": "2020-11-21T14:10:53.151Z",
      "updated_at": "2020-11-21T14:10:53.151Z",
      rowSize: 2,
      noneId: 3622508229,
      containsOther: true,
      otherOPtionId: "3622508232",
      otherOptionText: `Other`,
      options: [
        {
          id: "3622508218",
          text: "individual therapy"
        },
        {
          id: "3622508225",
          text: "mindfulness (meditation, yoga, tai chi)"
        },
        {
          id: "3622508219",
          text: "relationship / family therapy",
        },
        {
          id: "3622508226",
          text: "acupuncture"
        },
        {
          id: "3622508220",
          text: "group therapy",
        },
        {
          id: "3622508227",
          text: "12-step"
        },
        {
          id: "3622508221",
          text: "motivational interviewing (MI/MET)",
        },
        {
          id: "3622508228",
          text: "SMART"
        },
        {
          id: "3622508222",
          text: "cognitive-behavioral (CBT)"
        },
        {
          id: "3622508231",
          text: "Celebrate"
        },
        {
          id: "3622508223",
          text: "dialectical-behavioral (DBT)"
        },
        {
          text: 'online support group',
          id: 3867809928
        },
        {
          id: "3622508230",
          text: "rational emotive behavior (REBT)"
        },
        {
          text: 'telehealth (phone / video)',
          id: 3867809931
        },
        {
          id: "3622508224",
          text: "contingency management (rewards)"
        },
        {
          id: "3622508229",
          text: "none"
        },
      ]
    }

  },
  {
    "model": "comprisapp.questions",
    "pk": 159,
    "fields": {
      "question_id": "549097362",
      "question_text": "Currently in (check ALL that apply)",
      "survey_id": "294219921",
      "question_type": "multiple_choice",
      "created_at": "2020-11-21T14:10:53.569Z",
      "updated_at": "2020-11-21T14:10:53.569Z",
      rowSize: 2,
      noneId: 3622507470,
      options: [
        {text: "support group", id: "3622507468"},
        {text: "court diversion program", id: "3622507513"},
        {text: "professional therapy", id: "3622507469"},
        {text: "incarcerated", id: "3622507731"},
        {text: "outpatient program", id: "3622507510"},
        {text: "probation", id: "3622507732"},
        {text: "inpatient treatment", id: "3622507511"},
        {text: "none of the above", id: "3622507470"},
        {text: "residential program", id: "3622507512"},
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 160,
    "fields": {
      "question_id": "553825076",
      "question_text": "In the PAST TWO WEEKS, how often were you bothered by",
      "survey_id": "294219921",
      "question_type": "matrix",
      "created_at": "2020-11-21T14:10:53.831Z",
      "updated_at": "2020-11-21T14:10:53.831Z",
      choices: [
        {choice_text: "little interest or pleasure in doing things", row_id: "3652596770"},
        {choice_text: "feeling down, depressed or hopeless", row_id: "3652596771"},
        {choice_text: "suicidal (hurting self) thoughts or behaviors", row_id: "3652596772"},
        {choice_text: "homicidal (hurting others) thoughts or behaviors", row_id: "3652596773"}
      ],
      rowText: [
        {text: "not at all", id: "3652596774"},
        {text: "several days", id: "3652596775"},
        {text: "more than half the days", id: "3652596776"},
        {text: "nearly every day", id: "3652596777"},
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 161,
    "fields": {
      "question_id": "549097355",
      "question_text": "Ever taken prescribed MEDICATION for a substance problem?",
      "survey_id": "294219921",
      "question_type": "multiple_choice",
      "created_at": "2020-11-21T14:10:54.288Z",
      "updated_at": "2020-11-21T14:10:54.288Z",
      noneId: 3861345814,
      rowSize: 2,
      options: [
        {text: "no", id: "3861345814"},
        {text: "naltrexone injection (Vivitrol)", id: "3861402702"},
        {text: "methadone (Dolophine)", id: "3861345815"},
        {text: "naltrexone implant", id: "3861402703"},
        
        {text: "Varenicline (Chantix)", id: "3622508863"},
        {text: "Nicotine Patch", id: "3622508864"},

        {text: "oral buprenorphine-naloxone (Suboxone, Zubsolv, Bunavail)", id: "3861402699"},
        {text: "acamprosate (Campral)", id: "3861345819"},
        {text: "oral buprenorphine (Subutex)", id: "3861345817"},
        {text: "disulfiram (Antabuse)", id: "3861345820"},
        {text: "buprenorphine injection (Sublocade)", id: "3861402700"},
        {text: "gabapentin (Neurontin)", id: "3861345821"},
        {text: "buprenorphine implant (Probuphine)", id: "3861402701"},
        {text: "other", id: "3866445283"},
        {text: "oral naltrexone (Revia, Depade)", id: "3861345818"},
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 162,
    "fields": {
      "question_id": "549097357",
      "question_text": "HOW LONG taking each prescribed MEDICATION? (ignore all others)",
      "survey_id": "294219921",
      "question_type": "matrix",
      "created_at": "2020-11-21T14:10:54.366Z",
      "updated_at": "2020-11-21T14:10:54.366Z",
      containsOther: true,
      otherOPtionId: "3622507336",
      otherOptionText: `Other (please specify)`,
      choices: [
        {choice_text: "methadone (Dolophine)", row_id: "3622507316"},
        {choice_text: "oral bup-naloxone (Suboxone, etc.)", row_id: "3622507361"},
        {choice_text: "oral buprenorphine (Subutex)", row_id: "3622507317"},
        {choice_text: "injected buprenorphine (Sublocade)", row_id: "3622507323"},
        {choice_text: "implanted buprenorphine (Probuphine)", row_id: "3622507360"},
        {choice_text: "oral naltrexone (Revia, Depade)", row_id: "3622507324"},
        {choice_text: "injected naltrexone (Vivitrol)", row_id: "3622507325"},
        {choice_text: "implanted naltrexone", row_id: "3622507326"},
        {choice_text: "acamprosate (Campral)", row_id: "3622507327"},
        {choice_text: "disulfiram (Antabuse)", row_id: "3622507332"},
        {choice_text: "gabapentin (Neurontin)", row_id: "3622507337"},
        {choice_text: "Varenicline (Chantix)", row_id: "3622508859"},
        {choice_text: "Nicotine Patch", row_id: "3622508864"},
        {choice_text: "other", row_id: "3622508860"}
      ],
      rowText: [
        {text: "a few days", id: "3622507318"},
        {text: "a few weeks", id: "3622507319"},
        {text: "a few months", id: "3622507320"},
        {text: "6-11 months", id: "3622507321"},
        {text: "1-2 yrs", id: "3622507322"},
        {text: "3-5 yrs", id: "3622507334"},
        {text: "6-9 yrs", id: "3622507335"},
        {text: "10-19 yrs", id: "3622507410"},
        {text: "20+ yrs", id: "3622507411"},
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 163,
    "fields": {
      "question_id": "549097356",
      "question_text": "Any RELAPSES while taking prescribed medication?",
      "survey_id": "294219921",
      "question_type": "matrix",
      "created_at": "2020-11-21T14:10:57.373Z",
      "updated_at": "2020-11-21T14:10:57.373Z",
      row_id: 3622507346,
      rowText: [
        {text: "none", id: "3622507311"},
        {text: "1", id: "3622507347"},
        {text: "2", id: "3622507312"},
        {text: "3", id: "3622507313"},
        {text: "4-5", id: "3622507348"},
        {text: "6-9", id: "3622507362"},
        {text: "10+", id: "3622507363"},
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 164,
    "fields": {
      "question_id": "549097373",
      "question_text": "Currently taking prescribed MEDICATION for a substance problem?",
      "survey_id": "294219921",
      "question_type": "single_choice",
      "created_at": "2020-11-21T14:10:57.583Z",
      "updated_at": "2020-11-21T14:10:57.583Z",
      containsOther: true,
      otherOPtionId: "3654004081",
      otherOptionText: `yes, I'm taking (name, amount, how often, for how long)`,
      options: [
        {
          text: `no`,
          id: 3622507521
        },
        {
          text: `yes, but don't know the name or amount taken`,
          id: 3622507522
        },
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 165,
    "fields": {
      "question_id": "549097385",
      "question_text": "If you had a serious opioid problem, how would you PREFER to manage it? (pick ONE)",
      "survey_id": "294219921",
      "question_type": "single_choice",
      "created_at": "2020-11-21T14:10:57.688Z",
      "updated_at": "2020-11-21T14:10:57.688Z",
      options: [
        {text: "long-term opioid medication", id: "3622507624"},
        {text: "first opioid medication, then detox, then long-term non-opioid medication", id: "3622507625"},
        {text: "detox and long-term non-opioid medication", id: "3622507626"},
        {text: `detox and short-term non-opioid medication until no longer needed`, id: '3622507627'},
        {text: "detox and medication-free treatment, even if harder", id: "3622507628"},
        {text: "unsure or prefer not to answer", id: "3622507629"}
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 166,
    "fields": {
      "question_id": "549097439",
      "question_text": "How much do you agree with the following statements?",
      "survey_id": "294219921",
      "question_type": "matrix",
      "created_at": "2020-11-21T14:10:57.847Z",
      "updated_at": "2020-11-21T14:10:57.848Z",
      choices: [
        {choice_text: "I eat healthy food", row_id: "3622508407"},
        {choice_text: "I don't exercise ", row_id: "3622508408"},
        {choice_text: "I have meaning and purpose", row_id: "3622508424"},
        {choice_text: "I'm a poor listener / communicator ", row_id: "3622508447"}, // 549097440
        {choice_text: "My income is stable and covers my expenses", row_id: "3622508422"},
        {choice_text: "I have a supportive community", row_id: "3622508451"},
        {choice_text: "I have a positive attitude / practice daily gratitude", row_id: "3622508426"},
        {choice_text: "I get less than 7 hours of sleep daily ", row_id: "3622508409"},
        {choice_text: "I live in a substance-free space (no nicotine or problem substances)", row_id: "3622508423"},
        {choice_text: "I have clear values / spirituality ", row_id: "3622508425"},
        {choice_text: "My living environment is unsafe", row_id: "3622508406"},
        {choice_text: "I face my fears and challenges", row_id: "3622508427"},
        {choice_text: "I have a supportive partner", row_id: "3622508449"},
      ],
      rowText: [
        {text: "Strongly Disagree", id: "3622508410"},
        {text: "Disagree", id: "3622508411"},
        {text: "Agree", id: "3622508412"},
        {text: "Strongly Agree", id: "3622508413"}
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 167,
    "fields": {
      "question_id": "549097440",
      "question_text": "How much do you agree with the following statements?",
      "survey_id": "294219921",
      "question_type": "matrix",
      "created_at": "2020-11-21T14:10:59.154Z",
      "updated_at": "2020-11-21T14:10:59.154Z",
      // containsOther: true,
      otherOPtionId: "3622508430",
      otherOptionText: `Other (please specify)`,
      choices: [
        {choice_text: "I have supportive family / friends", row_id: "3622508450"},
        {choice_text: "I don't have a trusted healthcare provider", row_id: "3622508452"},
        {choice_text: "I forgive myself and others", row_id: "3622508428"},
        {choice_text: "I'm unproductive, with very few achievements", row_id: "3622508446"},
        {choice_text: "I have a good sense of humor", row_id: "3622508429"},
        {choice_text: "I'm good at solving problems", row_id: "3622508448"},
        {choice_text: "I'm poor at managing stress ", row_id: "3622508457"},
        {choice_text: "I am open and honest", row_id: "3622508442"},
        {choice_text: "I am kind and helpful to others ", row_id: "3622508443"},
        {choice_text: "I have very few interests or hobbies", row_id: "3622508444"},
        {choice_text: "I practice mindfulness / meditation", row_id: "3622508458"},
        {choice_text: "I have positive role model(s)", row_id: "3622508441"},
        {choice_text: "I know and use my strengths ", row_id: "3622508445"},
      ],
      rowText: [
        {text: "Strongly Disagree", id: "3622508453"},
        {text: "Disagree", id: "3622508454"},
        {text: "Agree", id: "3622508455"},
        {text: "Strongly Agree", id: "3622508456"}
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 167.4,
    "fields": {
      "question_id": "549097412",
      "question_text": "How much do you AGREE with this description of you?",
      "survey_id": "294219921",
      "question_type": "single_choice",
      "created_at": "2020-11-21T14:11:00.751Z",
      "updated_at": "2020-11-21T14:11:00.751Z",
      rowSize : 4,
      options: [
        {
          "text": "strongly disagree",
          "id": 3622508817
        },
        {
          "text": "disagree",
          "id": 3622508818
        },
        {
          "text": "agree",
          "id": 3622508819
        },
        {
          "text": "strongly agree",
          "id": 3622508820
        }
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 168,
    "fields": {
      "question_id": "549097351",
      "question_text": "How much am I currently ENGAGED in my HEALTH and WELL-BEING?",
      "survey_id": "294219921",
      "question_type": "single_choice",
      "created_at": "2020-11-21T14:11:00.751Z",
      "updated_at": "2020-11-21T14:11:00.751Z",
      row_id: "3622507385",
      rowSize : 2,
      options: [
        {text: " no need", id: "3622507386"},
        {text: " no interest", id: "3622507387"},
        {text: "feel pressured", id: "3622507388"},
        {text: "feel guilty", id: "3622507389"},
        {text: "afraid to try", id: "3622507390"},
        {text: "unsure", id: "3622507391"},
        {text: "tired of it all", id: "3622507392"},
        {text: "afraid NOT to try", id: "3622507393"},
        {text: "want my life back", id: "3622507394"},
        {text: "ready / engaged", id: "3622507395"},
        {text: "never more ready / engaged", id: "3622507396"}
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 169,
    "fields": {
      "question_id": "549097352",
      "question_text": "First 3 digits of ZIP / POSTAL Code",
      "survey_id": "294219921",
      "question_type": "open_ended",
      "created_at": "2020-11-21T14:11:01.066Z",
      "updated_at": "2020-11-21T14:11:01.066Z"
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 170,
    "fields": {
      "question_id": "549097388",
      "question_text": "Your INITIALS - 1st letter of your first, middle, and last name",
      "survey_id": "294219921",
      "question_type": "open_ended",
      "created_at": "2020-11-21T14:11:01.097Z",
      "updated_at": "2020-11-21T14:11:01.097Z"
    }
  }
]