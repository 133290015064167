import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { Container, Button, Label, Modal,ModalBody, ModalHeader } from 'reactstrap'
import { useTranslation } from 'react-i18next';
import service from '../../surveyInterceptor'
import CustomModal from '../../helperComponents/modal'
import {generateReport } from './constants'
import CustomInput from '../../helperComponents/Input'
import surveySuccessImage from '../../assests/images/surveyAssests/feedbackLogo.png'
import ReactStars from "react-rating-stars-component";
import { Formik , Field} from 'formik'


function CompleteSurvey({
    questionContent,
    cmrID,
    history,
    setUpdateProgressTo100,
    ...props
}) {


    const { t, i18n } = useTranslation()

    const [type , setType] = useState('Provider')


    useEffect( () => {
        setUpdateProgressTo100(true)
        if(localStorage.getItem('surveyType') == 'public'){
            setType('Public')
        }
        else if(localStorage.getItem('surveyType') == 'corporate'){
            setType('Corp')
        }
        else
        {
            setType('Provider')
        }
    },[])

    

    const [error, setError] = useState(false)
    const [postalCode, setPostalCode] = useState()
    const [initials, setInitials] = useState()
    const [initialsError, setInitialsError] = useState(false)
    const [postalCodeError, setPostalCodeError] = useState(false)
    const [modalStatus, setModalStatus] = useState(false)
    const [rating,setRating] = useState(0)


    const [loader, setLoader] = useState(false)

    const prevPage = () => {
        history.push(`/surveyQuestions/549097351`)
    }

    const [feedState,setFeedState] = useState(false)


    const ratingChanged = (newRating) => {
        setRating(newRating)
    };


    const [feedLoad, setFeedLoad] = useState(false)


    const submitFeedback = (values) => {
        setLoader(true)
        if(localStorage.getItem('surveyType') == 'public'){    
            setLoader(true) 
            let questionContentCopy = JSON.parse(JSON.stringify(questionContent))
            questionContentCopy.questions.push(
                {
                    id: 549097352,
                    answers: [
                        {text: postalCode}
                    ]
                },
                {
                    id: 549097388,
                    answers: [
                        {text: initials}
                    ]
                }
            )
            let obj2 = {
                ...questionContentCopy
            }  
            generateReport(cmrID, obj2).then ((res) => {
                setLoader(false)
                localStorage.removeItem('surveyType')
            }).catch( (err) => {
                setLoader(false)
            })      
        }
        else if(localStorage.getItem('surveyType') == 'corporate'){   
                setLoader(true)         
                let questionContentCopy = JSON.parse(JSON.stringify(questionContent))
                    questionContentCopy.questions.push(
                        {
                            id: 549097352,
                            answers: [
                                {text: postalCode}
                            ]
                        },
                        {
                            id: 549097388,
                            answers: [
                                {text: initials}
                            ]
                        }
                    )
                    let obj2 = {
                        ...questionContentCopy
                    }   
                    generateReport(cmrID, obj2).then ((res) => {
                        setLoader(false)
                        setFeedback(false)
                        localStorage.removeItem('surveyType')
                        setFeedLoad(false)
                    }).catch( (err) => {
                        setLoader(false)
                    })                         
        }
        else
        {
                setLoader(true) 
                let questionContentCopy = JSON.parse(JSON.stringify(questionContent))
                questionContentCopy.questions.push(
                    {
                        id: 549097352,
                        answers: [
                            {text: postalCode}
                        ]
                    },
                    {
                        id: 549097388,
                        answers: [
                            {text: initials}
                        ]
                    }
                )
                let obj2 = {
                    ...questionContentCopy
                }   
                generateReport(cmrID, obj2).then ((res) => {
                    setLoader(false)
                    setFeedback(false)
                    localStorage.removeItem('surveyType')
                    setFeedLoad(false)
                }).catch( (err) => {
                    setLoader(false)

                })                        
        }
    }

    const completeSurvey = () => {
        if(localStorage.getItem('surveyType') == 'public'){
            if(postalCode && initials){
                setError(false)
                let questionContentCopy = JSON.parse(JSON.stringify(questionContent))
                questionContentCopy.questions.push(
                    {
                        id: 549097352,
                        answers: [
                            {text: postalCode}
                        ]
                    },
                    {
                        id: 549097388,
                        answers: [
                            {text: initials}
                        ]
                    }
                )
                let obj = {
                    ...questionContentCopy
                }   
                service.post(`/public/surveys/${cmrID}/complete`, obj).then(res =>{
                    toast.success(t('You have finished the survey successfully.'))     
                    submitFeedback()    
                })
            }
            else{
                setError(true)
            }
        }
        else if(localStorage.getItem('surveyType') == 'corporate'){
            if(postalCode && initials){
                setError(false)
                let questionContentCopy = JSON.parse(JSON.stringify(questionContent))
                questionContentCopy.questions.push(
                    {
                        id: 549097352,
                        answers: [
                            {text: postalCode}
                        ]
                    },
                    {
                        id: 549097388,
                        answers: [
                            {text: initials}
                        ]
                    }
                )
                let obj = {
                    ...questionContentCopy
                }   
                service.post(`/public/surveys/${cmrID}/complete`, obj).then(res =>{
                    toast.success(t('You have finished the survey successfully.'))  
                    submitFeedback()
                })
            }
            else{
                setError(true)
            }
        }
        else
        {    
            if(postalCode && initials){
                setError(false)
                let questionContentCopy = JSON.parse(JSON.stringify(questionContent))
                questionContentCopy.questions.push(
                    {
                        id: 549097352,
                        answers: [
                            {text: postalCode}
                        ]
                    },
                    {
                        id: 549097388,
                        answers: [
                            {text: initials}
                        ]
                    }
                )
                let obj = {
                    survey_type : "CURRENT",
                    ...questionContentCopy
                }   
                service.post(`surveys/${cmrID}/complete`, obj).then(res =>{
                    toast.success(t('You have successfully completed the survey. The results will be sent to your Healthcare Provider'))
                    submitFeedback()
                })
            }
            else{
                setError(true)
            }
        }
    }

    const initialsOnChange = (value) => {
        
        
        if( type == 'Provider'){
            
            if(value.length < 5){
                setInitialsError(false)
                setInitials(value)
            }
            else{
                setInitialsError(true)
            }
        }
        else
        {
            if(value.length < 4){
                setInitialsError(false)
                setInitials(value)
            }
            else{
                setInitialsError(true)
            }
        }
    }

    const postalCodeOnChange = (value) => {
        if(String(value).length < 4){
            setPostalCodeError(false)
            setPostalCode(value)
        }
        else{
            setPostalCodeError(true)
        }
    }

    const updateForm = () => {
        setFeedState(false)
        setLoader(true)       
        let questionContentCopy = JSON.parse(JSON.stringify(questionContent))
        questionContentCopy.questions.push(
            {
                id: 549097352,
                answers: [
                    {text: postalCode}
                ]
            },
            {
                id: 549097388,
                answers: [
                    {text: initials}
                ]
            }
        )
        let obj = {
            ...questionContentCopy
        }   
        generateReport(cmrID, obj).then ((res) => {
            setLoader(false)
            localStorage.removeItem('surveyType')
        })     
    }

    return (
        <div>
            <Container>
                <div className="bg-white container mb-3" style={{borderRadius: '2px'}}>
                    <div style={{padding:'20px 0'}}>
                    {/* <p style={{margin:'0'}} >{questionContent.questions?questionContent.questions.length:68}. */}
                         <p>{t('First 3 digits of ZIP / POSTAL Code')}:</p>
                        <TextField value={postalCode} name='zip-code' type='text' onChange={e => postalCodeOnChange(e.target.value)} /><br />
                        {postalCodeError?<p className='mt-1' style={{color: 'red',padding:'10px 0'}}>{t('Only 3 digits allowed')}</p>:null}
                    </div>
                </div>
                <div className="bg-white container mb-3" style={{borderRadius: '2px'}}>
                    <div style={{padding:'20px 0'}}>
                    {/* {questionContent.questions?questionContent.questions.length+1:69}.  */}
                        <p style={{margin:'0'}}>{t('Your INITIALS - 1st letter of your first, middle, and last name')}:</p>
                        <TextField value={initials} name="initials" type="text" onChange={e => initialsOnChange(e.target.value)} />
                        {initialsError?<p className='mt-1' style={{color: 'red',padding:'10px 0'}}>{t(type == 'Provider' ? 'Only 4 characters allowed' : 'Only 3 characters allowed')}</p>:null}
                    </div>
                </div>
                <div className="bg-white container mb-3" style={{borderRadius: '2px'}}>
                    {error && <p style={{color: 'red',padding:'10px 0'}}>{t(`Please answer the question`)}<br /></p>}
                    <div className='px-2 py-3'>
                        {/* <p style={{fontSize: '18px', lineHeight: '35px', color: '#000000'}}><b>{t('Assessment completed, Submit here')}.</b> <br /> */}
                        {
                            type == 'Corp' || type == 'Public' ?  <p style={{fontSize: '1rem', lineHeight: '26px', color: '#000000',maxWidth:'750px'}}>{t("By clicking “Submit”, your personal Wellness Profile will open immediately on your device IF YOUR BROWSER POP UPS ARE NOT DISABLED")}<br/>.</p> : 
                            <div className="steps-bloc-wrapper">
                               <p>{t('By clicking “Submit”, you agree to the following:')}</p>
                                <ul className="list-steps">
                                    <li>{t('your full results will be sent to your provider')}</li>
                                    <li>{t('your own personal Wellness Profile will open immediately on your device')}<br></br><span><b>{t('IF YOUR BROWSER POP UPS ARE NOT DISABLED')}</b></span></li>
                                </ul>
                            </div>
                        }                                                
                        <div className="button-footer-wrapper">
                            <Button style={{backgroundColor: 'transparent', color: '#5e6265', border: 'none'}} type='button' onClick={prevPage} >{t('Previous')}</Button>
                            <Button style = {{marginLeft: '10px', backgroundColor: '#055ce5', color: 'white', borderRadius: '2px', border: 'none'}} onClick={completeSurvey} type='button' >{t('SUBMIT ASSESSMENT HERE')}</Button>
                        </div>
                    </div> 
                    <p style={{padding:'11px 0 20px', fontSize:'13px'}}>{t('For comments or questions, please contact info@ComprisCare.com')}.</p>
                </div>``
            </Container>
            <Modal isOpen={feedState} className="survey-feedback">
                <ModalHeader toggle={() => updateForm()}>
                </ModalHeader>
                <ModalBody>
                    <Formik
                        enableReinitialize={true}
                        initialValues={{ 
                            feedback : ""
                        }}
                        onSubmit={(values, actions) => {
                            submitFeedback(values)
                        }}  
                        >
                        {props => (
                            <form onSubmit={props.handleSubmit}>
                                <div className="feedbackSuccess">
                                    <img src={surveySuccessImage}/>
                                </div>
                                <h2 className="assesment-text"><span style={{color:'#0860df'}}>{t('Well done')}!</span> {t('You have successfully completed the assessment')}</h2>
                                <Label className="name">{t('Would you like to take a minute to rate the assessment?')}?</Label>
                                <ReactStars
                                    count={5}
                                    onChange={ratingChanged}
                                    size={70}
                                
                                    classNames="starr-rating-blocks"
                                        activeColor="#ffd700"
                                        isHalf={false}
                                        emptyIcon={<i className="far fa-star"></i>}
                                        halfIcon={<i className="fa fa-star-half-alt"></i>}
                                        fullIcon={<i className="fa fa-star"></i>}
                                    />
                                    <Field component={CustomInput} required  name="feedback" type="textarea"/>      
                                    <div className="text-center referral" style={{marginTop:"40px"}}>
                                        <Button disabled={feedLoad} type="submit" style={{marginLeft:"15px"}} color="primary">{t('Submit')}</Button>
                                    </div>
                                </form>
                        )}
                    </Formik>
                </ModalBody>
            </Modal>
            <CustomModal state={loader} setState={setLoader}  >
                    <span className="loader"></span>
                    <p style={{textAlign:'center',paddingTop:'142px'}}>{t('Your Report is being Generated.')}</p>
                    <p style={{textAlign:'center',paddingTop:'2px'}}>{t('Do not close this page.')}</p>
                    <p style={{textAlign:'center',paddingTop:'2px'}}><b>{t('Please WAIT for 1-2 minutes while the timer spins.')}</b></p> 
                    <span style={{paddingTop:'20px',fontSize:'12px'}}>{t('Note : Please make sure the pop-ups in your browser are not disabled.')}</span>
            </CustomModal>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        questionContent : state.question.content,
        cmrID: state.question.cmrID
    }
}

export default connect(mapStateToProps)(CompleteSurvey)
