import axios from 'axios'
import NProgress from 'nprogress'
import { toast } from 'react-toastify'
import { APIS_URL } from './utils/apiEndPoints'
import i18n from './i18n'
import { createBrowserHistory } from 'history';

const service = axios.create({
    baseURL: process.env.API_URL
})


function checkLocalStoreItem(){
    if(localStorage.getItem('i18nextLng') == 'esp') {
        return 'es'
    }
    else
    {
        return 'en'
    }
}

const history = createBrowserHistory()

service.defaults.timeout = 100000

service.interceptors.request.use(
  config => {
    let { url } = config;
    NProgress.start()
    config.headers['Tz-Value'] = Intl.DateTimeFormat().resolvedOptions().timeZone
    checkLocalStoreItem() == 'es' ? config.headers['Language-ISO'] = 'es' :  config.headers['Language-ISO'] = 'en'
    return config
    },

    error => {
        Promise.reject(error)
    }
)


service.interceptors.response.use(
  response => {
    NProgress.done()
    return response.data
  },
  error => {
    NProgress.done()
    if(error.response.status === 500) {
        toast.error(i18n.t(error.response.data.message))
    }
    else if(error.response.status === 403) {
        toast.error(i18n.t(error.response.data.message))
    }
    else if(error.response.status === 404) {
        toast.error(i18n.t(error.response.data.message))
    }
    else if(error.response.status === 400) {
        toast.error(i18n.t(error.response.data.message))
    }
    else if(error.response.status === 409) {
        toast.error(i18n.t(error.response.data.message))
    }
    else if(error.response.status === 401){
        toast.error(i18n.t(error.response.data.message))
        localStorage.clear()
        // setTimeout( () => {
        //     window.location.reload()
        // },1000)
    }     
    return Promise.reject(error)
  }
)

export default service