import {combineReducers} from "redux";

import { assesmentReducer } from './assesmentReducer'
import { questionReducer } from './questionReducer'
import { onboardingReducer } from './onboarding'
import { clinicReducer} from './clinicReducer'
import { billingReducer } from './billingReducer'

export default combineReducers({
    assesment : assesmentReducer,
    onboarding : onboardingReducer,
    clinicReducer : clinicReducer,
    billingReducer : billingReducer,
    question: questionReducer
});