import React, { useState, useEffect } from 'react'
import Checkbox from '@material-ui/core/Checkbox';
import Slider from '@material-ui/core/Slider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Container, Row, Col, Label, Button, Table, FormGroup } from 'reactstrap'
import { connect } from 'react-redux'
import compris from '../../assests/images/001 Compris TM clr.png'
import { updateAnswers, emptyAnswerArray } from './constants'
import { useTranslation } from 'react-i18next';


function QuestionNo32({
    questionContent,
    history,
    ...props
}) {

    const [multipleChoiceAnswers, setMultipleChoiceAnswers] = useState([])
    const [siblingSlider, setSiblingSlider] = useState(0)
    const [childrenSlider, setChildrenSlider] = useState(0)
    const [grandparentSlider, setGrandParentSlider] = useState(0)
    const [auntOrUncleSlider, setAuntOrUncleSlider] = useState(0)
    const [cousinSlider, setCousinSlider] = useState(0)
    const [error, setError] = useState(false)

    const { t }  = useTranslation()


    const questionNo = questionContent.questions?questionContent.questions.findIndex(item => item.id == 549097387):-1

    useEffect(()=>{
        let index = questionContent.questions?questionContent.questions.findIndex(item => item.id == 549097387):-1
        if(index != -1){
            let checkboxAnswers = []
            questionContent.questions[index].answers.forEach(item => {
                if(item.choice_id == 3622507684){
                    setSiblingSlider(1)
                }
                else if(item.choice_id == 3622507685){
                    setSiblingSlider(2)
                }
                else if(item.choice_id == 3622507686){
                    setSiblingSlider(3)
                }
                else if(item.choice_id == 3622507687){
                    setChildrenSlider(1)
                }
                else if(item.choice_id == 3622507688){
                    setChildrenSlider(2)
                }
                else if(item.choice_id == 3622507689){
                    setChildrenSlider(3)
                }
                else if(item.choice_id == 3622507690){
                    setGrandParentSlider(1)
                }
                else if(item.choice_id == 3622507691){
                    setGrandParentSlider(2)
                }
                else if(item.choice_id == 3622507692){
                    setGrandParentSlider(3)
                }
                else if(item.choice_id == 3622507693){
                    setAuntOrUncleSlider(1)
                }
                else if(item.choice_id == 3622507694){
                    setAuntOrUncleSlider(2)
                }
                else if(item.choice_id == 3622507695){
                    setAuntOrUncleSlider(3)
                }
                else if(item.choice_id == 3622507696){
                    setCousinSlider(1)
                }
                else if(item.choice_id == 3622507697){
                    setCousinSlider(2)
                }
                else if(item.choice_id == 3622507698){
                    setCousinSlider(3)
                }
                else{
                    checkboxAnswers.push(item)
                }
            })
            setMultipleChoiceAnswers([...checkboxAnswers])
        }
    }, [])

    const multipleChoiceAnswersOnChange = (checkboxStatus, choice_id) => {
        let multipleChoiceAnswersCopy = [...multipleChoiceAnswers]
        if(checkboxStatus){
            if(choice_id == 3622507699){
                multipleChoiceAnswersCopy = [{
                    choice_id: choice_id
                }]
                setSiblingSlider(0)
                setChildrenSlider(0)
                setGrandParentSlider(0)
                setAuntOrUncleSlider(0)
                setCousinSlider(0)
            }
            else{
                multipleChoiceAnswersCopy.push({
                    choice_id: choice_id
                })
                const noneIndex = multipleChoiceAnswersCopy.findIndex(item => item.choice_id == 3622507699)
                if(noneIndex != -1)
                    multipleChoiceAnswersCopy.splice(noneIndex, 1)
            }
        }
        else{
            multipleChoiceAnswersCopy.splice(multipleChoiceAnswersCopy.findIndex(item => item.choice_id === choice_id), 1)
        }
        setMultipleChoiceAnswers(multipleChoiceAnswersCopy)
    }

    const marks = [
        {
          value: 0,
          label: "0"
        },
        {
          value: 1,
          label: "1"
        },
        {
          value: 2,
          label: "2"
        },
        {
          value: 3,
          label: "3+"
        }
    ];

    function valueLabelFormat(value) {
        return marks.find((mark) => mark.value === value).label;
    }

    const nextPage = () => {
        if(multipleChoiceAnswers.length>0 || siblingSlider>0 || childrenSlider>0 || grandparentSlider>0 || auntOrUncleSlider>0 || cousinSlider>0){
            setError(false)
            let answerCopy = [...multipleChoiceAnswers]
            let questionContentCopy = JSON.parse(JSON.stringify(questionContent))
            if(siblingSlider> 0){
                switch (siblingSlider){
                    case 1: 
                        answerCopy.push({choice_id: 3622507684});
                        break;
                    case 2: 
                        answerCopy.push({choice_id: 3622507685});
                        break;
                    case 3: 
                        answerCopy.push({choice_id: 3622507686});
                        break;
                }
            }
            if(childrenSlider> 0){
                switch (childrenSlider){
                    case 1: 
                        answerCopy.push({choice_id: 3622507687});
                        break;
                    case 2: 
                        answerCopy.push({choice_id: 3622507688});
                        break;
                    case 3: 
                        answerCopy.push({choice_id: 3622507689});
                        break;
                }
            }
            if(grandparentSlider> 0){
                switch (grandparentSlider){
                    case 1: 
                        answerCopy.push({choice_id: 3622507690});
                        break;
                    case 2: 
                        answerCopy.push({choice_id: 3622507691});
                        break;
                    case 3: 
                        answerCopy.push({choice_id: 3622507692});
                        break;
                }
            }
            if(auntOrUncleSlider> 0){
                switch (auntOrUncleSlider){
                    case 1: 
                        answerCopy.push({choice_id: 3622507693});
                        break;
                    case 2: 
                        answerCopy.push({choice_id: 3622507694});
                        break;
                    case 3: 
                        answerCopy.push({choice_id: 3622507695});
                        break;
                }
            }
            if(cousinSlider> 0){
                switch (cousinSlider){
                    case 1: 
                        answerCopy.push({choice_id: 3622507696});
                        break;
                    case 2: 
                        answerCopy.push({choice_id: 3622507697});
                        break;
                    case 3: 
                        answerCopy.push({choice_id: 3622507698});
                        break;
                }
            }
            const index = questionContentCopy.questions.findIndex(item => item.id == 549097387)
            let update = false
            if(index!=-1){
                update = true
            }
            updateAnswers(questionContentCopy, questionNo==-1?questionContent.questions?questionContent.questions.length+1:0:questionNo+1, {
                id: 549097387,
                answers: answerCopy
            }, update)
            if(answerCopy[0].choice_id == 3622507699){
                const questionNo25Answer = questionContent.questions.find(item => item.id == 580243889).answers
                if(questionNo25Answer[0].choice_id == 3821196632){
                    emptyAnswerArray({587379847: true, 549097420: true, 549097421: true, 549097391: true, 549097389: true, 553973451: true, 553981633: true, 556167203: true, 556181470: true, 556181518: true, 556181583: true, 556181824: true, 554010038: true, 555422217: true}, questionContentCopy)
                    history.push(`/surveyQuestions/549097448`)
                }
                else{
                    const questionNo26Answer = questionContent.questions.find(item => item.id == 554023749).answers
                    const rxOpiates = questionNo26Answer.find(item => item.row_id == 3653851638)
                    if(questionNo26Answer.every(item => item.choice_id == 3653851651)){
                        emptyAnswerArray({587379847: true, 549097420: true, 549097421: true, 549097391: true, 549097389: true, 553973451: true, 553981633: true, 556167203: true, 556181470: true, 556181518: true, 556181583: true, 556181824: true, 554010038: true, 555422217: true}, questionContentCopy)
                        history.push(`/surveyQuestions/549097448`) //skip to question number 46 
                    }
                    else if(rxOpiates?rxOpiates.choice_id == 3653851651?true:false:true){
                        emptyAnswerArray({549097420: true, 549097421: true, 549097391: true}, questionContentCopy)
                        history.push(`/surveyQuestions/549097389`) //skip to question 36
                    }
                    else{
                        history.push(`/surveyQuestions/549097420`)
                    }
                }
            }
            else{
                history.push(`/surveyQuestions/587379847`)
            }
            props.dispatch({
                type: `UPDATE_CONTENT`,
                payload: questionContentCopy
            })
        }
        else{
            setError(true)
        }
    }

    const prevPage = () => {
        let index = questionContent.questions?questionContent.questions.findIndex(item => item.id == 549097387):-1
        if(index == -1){
            history.push(`/surveyQuestions/${questionContent.questions[questionContent.questions.length-1].id}`)
        }
        else{
            history.push(`/surveyQuestions/${questionContent.questions[index-1].id}`)
        }
    }

    const updateSlider = (name, value) => {
        name(value)
        const noneIndex = multipleChoiceAnswers.findIndex(item => item.choice_id == 3622507699)
        if(noneIndex != -1)
            multipleChoiceAnswers.splice(noneIndex, 1)
    }

    return (
        <div className="slider-container-block">
            <Container>
                <div className="bg-white container mb-3" style={{borderRadius: '2px'}}>
                    <div className="p-2">
                        <p>{questionNo==-1?questionContent.questions?questionContent.questions.length+1:0:questionNo+1}. {t('Any BIOLOGICAL RELATIVES with a substance problem other than tobacco/nicotine? (Check all that apply)')}</p>
                        <Row>
                            <Col>
                                <FormControlLabel
                                    control={<Checkbox name='father' color="primary" onChange={(e) => multipleChoiceAnswersOnChange(e.target.checked, 3622507682)} checked={multipleChoiceAnswers.find(data => data.choice_id == 3622507682)?true:false} />}
                                    label={t('father')}
                                />
                            </Col>
                            <Col>
                                <FormControlLabel
                                    control={<Checkbox name='father' color="primary" onChange={(e) => multipleChoiceAnswersOnChange(e.target.checked, 3622507683)} checked={multipleChoiceAnswers.find(data => data.choice_id == 3622507683)?true:false} />}
                                    label={t('mother')}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <p className="MuiFormControlLabel-label">{t('siblings')}</p>
                                    <Slider
                                        value={siblingSlider}
                                        valueLabelFormat={valueLabelFormat}
                                        aria-labelledby="discrete-slider-restrict"
                                        step={null}
                                        valueLabelDisplay="auto"
                                        marks={marks}
                                        onChange={(e, newValue) => updateSlider(setSiblingSlider, newValue)}
                                        max={3}
                                    />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <p className="MuiFormControlLabel-label">{t('children')}</p>
                                    <Slider
                                        value={childrenSlider}
                                        valueLabelFormat={valueLabelFormat}
                                        aria-labelledby="discrete-slider-restrict"
                                        step={null}
                                        valueLabelDisplay="auto"
                                        marks={marks}
                                        onChange={(e, newValue) => updateSlider(setChildrenSlider, newValue)}
                                        max={3}
                                    />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <p className="MuiFormControlLabel-label">{t('grandparent')}</p>
                                    <Slider
                                        value={grandparentSlider}
                                        valueLabelFormat={valueLabelFormat}
                                        aria-labelledby="discrete-slider-restrict"
                                        step={null}
                                        valueLabelDisplay="auto"
                                        marks={marks}
                                        onChange={(e, newValue) => updateSlider(setGrandParentSlider, newValue)}
                                        max={3}
                                    />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <p className="MuiFormControlLabel-label">{t('an aunt or uncle')}</p>
                                    <Slider
                                        value={auntOrUncleSlider}
                                        valueLabelFormat={valueLabelFormat}
                                        aria-labelledby="discrete-slider-restrict"
                                        step={null}
                                        valueLabelDisplay="auto"
                                        marks={marks}
                                        onChange={(e, newValue) => updateSlider(setAuntOrUncleSlider, newValue)}
                                        max={3}
                                    />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <p className="MuiFormControlLabel-label">{t('a cousin, niece or nephew')}</p>
                                    <Slider
                                        value={cousinSlider}
                                        valueLabelFormat={valueLabelFormat}
                                        aria-labelledby="discrete-slider-restrict"
                                        step={null}
                                        valueLabelDisplay="auto"
                                        marks={marks}
                                        onChange={(e, newValue) => updateSlider(setCousinSlider, newValue)}
                                        max={3}
                                    />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormControlLabel
                                    control={<Checkbox name='none' color="primary" onChange={(e) => multipleChoiceAnswersOnChange(e.target.checked, 3622507699)} checked={multipleChoiceAnswers.find(data => data.choice_id == 3622507699)?true:false} />}
                                    label={t("none or don't know")}
                                />
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="bg-white container mb-3" style={{borderRadius: '2px'}}>
                    {error && <p style={{color: 'red',padding:'10px 0'}}>{t('Please answer the question')}<br /></p>}
                    <div className='px-2 py-3' style={{display: 'flex', justifyContent: 'space-between'}}>
                    <p style={{fontSize: '12px', lineHeight: '1.17', color: '#afafaf',marginBottom:'0',paddingTop:'10px'}}>{t('Powered by')} <a href="https://www.compriscare.com/" target="_blank"><img src={compris} style={{marginLeft: '10px'}} /></a></p>
                        <div className="button-footer-wrapper">
                            <Button style={{backgroundColor: 'transparent', color: '#5e6265', border: 'none'}} onClick={prevPage} type='button' >{t('Previous')}</Button>
                            <Button style = {{marginLeft: '10px', backgroundColor: '#055ce5', color: 'white', borderRadius: '2px', border: 'none'}} onClick={nextPage} type='button' >{t('Next')}</Button>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        questionContent : state.question.content,
    }
}

export default connect(mapStateToProps)(QuestionNo32)
