import {FETCH_CLINIC_MASTER , FETCH_CLINIC_LIST, FETCH_ADMIN_CLINIC_LIST } from '../type'

const initialState = {
    clinicDetailList : {},
    clinicMasterList : {},
    clinicsList : {}
}

const clinicReducer = (state = initialState,action) => {
    switch(action.type){
        case FETCH_CLINIC_MASTER : 
            return { 
                ...state,
                clinicMasterList : action.payload,
            }
        case FETCH_CLINIC_LIST :
            return {
                ...state,
                clinicsList : action.payload
            }
        case FETCH_ADMIN_CLINIC_LIST : 
            return {
                ...state,
                clinicDetailList : action.payload
            }
        default : 
        return state
    }
}

export { 
    clinicReducer
}