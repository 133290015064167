import React , {useState } from 'react'
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { whatMedicationsList } from './constants'

function WhatMedication({
    multipleInputAnswer,
    setMultipleInputAnswer,
    listToShow,
    setListToShow
}) {
    const [medicationsAdded, setMedicationsAdded] = useState(listToShow.length-1)
    const { t } = useTranslation();

    const addMedication = () => {
        setListToShow([...listToShow, whatMedicationsList[medicationsAdded + 1]])
        setMedicationsAdded(medicationsAdded+1)
    }

    const deleteMedication = () => {
        const lastItemInList = listToShow.slice(-1)
        const answersCopy = {...multipleInputAnswer}
        if(lastItemInList && lastItemInList[0])
            delete answersCopy[lastItemInList[0].id]
        listToShow.splice(-1)
        setMultipleInputAnswer(answersCopy)
        setListToShow([...listToShow])
        setMedicationsAdded(medicationsAdded-1)
    }

    const onTextChange = (id, e) => {
        const multipleInputAnswerCopy = {...multipleInputAnswer}
        multipleInputAnswerCopy[id] = e.target.value
        setMultipleInputAnswer(multipleInputAnswerCopy)
    }

    return (
        <div className="p-2">
            <div className="bg-white container px-3 py-3 mb-3" style={{ borderRadius: '.125rem' }}>
                {listToShow.map(list => (
                    <div>
                        <TextField style={{ width: '7.5rem' }} type="string" placeholder={`${t(list.text)}`} onChange={e => onTextChange(list.id, e)} value={multipleInputAnswer[list.id]} />
                    </div>
                ))}
                <Button className='add-button' variant="text" disabled={medicationsAdded>=4} onClick={addMedication} style={{}}>Add another medication</Button>
                <Button className='add-button' variant="text" disabled={medicationsAdded==0} onClick={deleteMedication} style={{}}>Delete medication</Button>
            </div>
        </div>
    )
}


export default WhatMedication
