import React, { useState, useEffect } from 'react'
import { questionsAndChoices, findQuestion, updateAnswers, nextRoute } from './constants'
import compris from '../../assests/images/001 Compris TM clr.png'
import { Container, Row, Col, Label, Button, Table, FormGroup } from 'reactstrap'
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next';
import Checkbox from '@material-ui/core/Checkbox';


function MatrixWithInputs({
    questionContent,
    questionID,
    history,
    ...props
}) {
    const [matrixSelectedIds, setMatrixSelectedState] = useState({})
    const [error, setError] = useState(false)

    const [lengthError, setLengthError] = useState(false)

    const [matrixOthers, setMatrixOthers] = useState('')
    const [questionDetails, setQuestionDetails] = useState()
    const [questionIndex, setQuestionIndex] = useState()
    const [currentQuestionNumber, setCurrentQuestionNumber] = useState()
    const [update, setUpdate] = useState(false)
    const [previouslyFilledAnswers, setPreviouslyFilledAnswers] = useState([])

    const { t, i18n } = useTranslation();

    useEffect(()=>{
        const allquestions = JSON.parse(JSON.stringify(questionsAndChoices))
        const {question, currentQuestionIndex} = findQuestion(allquestions, questionID)
        setQuestionIndex(currentQuestionIndex)
        updateQuestionDetails(question, questionContent, questionID)
    }, [questionID])

    const updateQuestionDetails = (question, previousAnswers, currentQuestionId) => {
        // question no 45
        if(questionID == 555422217){
            let questionNo44Answer = previousAnswers.questions?previousAnswers.questions.find(item => item.id == 554010038).answers:[]
            question.fields.containsOther = false
            let choices = []
            questionNo44Answer.forEach(item => {
                if(item.row_id == 3653767313 && item.choice_id != 3653767317){
                    choices.push(question.fields.choices[0])
                }
                else if(item.row_id == 3668018437 && item.choice_id != 3653767317){
                    choices.push(question.fields.choices[1])
                }
                else if(item.row_id == 3653771371 && item.choice_id != 3653767317){
                    choices.push(question.fields.choices[2])
                }
                else if(item.row_id == 3653767304 && item.choice_id != 3653767317){
                    choices.push(question.fields.choices[3])
                }
                else if(item.row_id == 3653767312 && item.choice_id != 3653767317){
                    choices.push(question.fields.choices[4])
                }
                else if(item.row_id == 3653767314 && item.choice_id != 3653767317){
                    choices.push(question.fields.choices[6])
                }
                else if(item.row_id == 3653767315 && item.choice_id != 3653767317){
                    choices.push(question.fields.choices[5])
                }
                else if(item.row_id == 3653767316 && item.choice_id != 3653767317){
                    choices.push(question.fields.choices[7])
                }
                else if(item.row_id == 3653767305 && item.choice_id != 3653767317){
                    choices.push(question.fields.choices[8])
                }
                else if(item.row_id == 3653767306 && item.choice_id != 3653767317){
                    choices.push(question.fields.choices[9])
                }
                else if(item.row_id == 3653767307 && item.choice_id != 3653767317){
                    choices.push(question.fields.choices[11])
                }
                else if(item.row_id == 3653767308 && item.choice_id != 3653767317){
                    choices.push(question.fields.choices[10])
                }
                else if(item.row_id == 3653767309 && item.choice_id != 3653767317){
                    choices.push(question.fields.choices[12])
                }
                else if(item.row_id == 3653767310 && item.choice_id != 3653767317){
                    choices.push(question.fields.choices[13])
                }
                else if(item.row_id == 3653767311 && item.choice_id != 3653767317){
                    choices.push(question.fields.choices[14])
                    question.fields.containsOther = true
                }
            })
            if(choices[0]){
                question.fields.choices = choices
            }
            else{
                history.push(`/surveyQuestions/549097448`)
            }
        }
        setQuestionDetails({...question})
        prefillValues(question)
    }

    const prefillValues = (questionDetails) => {
        let index = questionContent.questions?questionContent.questions.findIndex(item => item.id == questionID):-1
        if(index!=-1){
            setUpdate(true)
            setCurrentQuestionNumber(index+1)
            setPreviouslyFilledAnswers(questionContent.questions[index].answers)
            let answers = {}
            questionDetails.fields.rowText.forEach((item, i) => {
                questionDetails.fields.choices.forEach((data) =>{
                    questionContent.questions[index].answers.forEach((elem) => {
                        if(data.row_id == elem.row_id && elem.choice_id == item.id){
                            answers[`${data.choice_text} ${item.text}`] = elem
                        }
                    })
                })
            })
            setMatrixSelectedState(answers)
        }
        else if(questionContent.questions){
            for(let i=questionContent.questions.length-1; i>=0; i--){
                const {currentQuestionIndex} = findQuestion(questionsAndChoices, questionContent.questions[i].id)
                if(questionsAndChoices[currentQuestionIndex].pk < questionDetails.pk){
                    if(i == questionContent.questions.length-1)
                        setCurrentQuestionNumber(i+2)
                    else
                        setCurrentQuestionNumber(i+2)
                    break;
                }
            }
        }
    }

    const matrixOnChange = (value, name, row_id, choice_id) => {
        let matrixSelectedIdsCopy = {...matrixSelectedIds}
        matrixSelectedIdsCopy[name] = {
            row_id,
            choice_id,
            text: value
        }
        setMatrixSelectedState(matrixSelectedIdsCopy)
    }

    const nextPage = () => {
        
        const matrixCopy = [...Object.values(matrixSelectedIds)]

            function checkIsLengthError(arr){
                let count = 0
                arr.map( (item, index) => {
                    if(item.text.length > 2){
                        count++
                    }
                    else if(item.text == 0){
                        count++
                    }
                    else
                    {
                        return false
                    }
                })
                return count > 0 ? true : false
            }

            function checkAllInputAnswered(arr){
                let count = 0
                arr.forEach(item => {
                    if(!(item.choice_id ==  3662615712 || item.choice_id == 3662615714) && item.text){
                        ++count
                    }
                })
               return questionDetails.fields.choices.length*2 == count 
            }

            if(checkAllInputAnswered(matrixCopy)){  
                if(checkIsLengthError(matrixCopy)){
                    setLengthError(true)
                }
                else
                {
                    setError(false)
                    setLengthError(false)
                    let questionContentCopy = JSON.parse(JSON.stringify(questionContent))
                    setUpdate(false)
                    nextRoute(questionContentCopy, questionID, history, matrixCopy, questionIndex)
                    setMatrixOthers('')
                    updateAnswers(questionContentCopy, currentQuestionNumber, {
                        id: questionID,
                        answers: matrixCopy
                    }, update)
                    props.dispatch({
                        type: `UPDATE_CONTENT`,
                        payload: questionContentCopy
                    }, setMatrixSelectedState([]))
                }   
            }
            else{
                setError(true)
            }
    }

    const prevPage = () => {
        setError(false)
        if(questionIndex === 14){
            history.push(`/surveyQuestions/page4`)
        }
        else{
            // history.push(`/surveyQuestions/${questionsAndChoices[questionIndex-1].fields.question_id}`)
            let index = questionContent.questions?questionContent.questions.findIndex(item => item.id == questionID):-1
            if(index == -1){
                history.push(`/surveyQuestions/${questionContent.questions[currentQuestionNumber-2].id}`)
            }
            else{
                history.push(`/surveyQuestions/${questionContent.questions[index-1].id}`)
            }
        }
    }

    return (
        <Container>
            {questionDetails?<div>
                {questionDetails.fields.question_type === 'matrix' && <div className="bg-white container mb-3" style={{borderRadius: '2px'}}>
                    <div className="p-2" style={{overflow: 'auto'}}>
                        <p dangerouslySetInnerHTML={{__html: `${currentQuestionNumber}. ${t(questionDetails.fields.question_text)}`}}></p>
                        <Table borderless striped>
                            <thead style={{backgroundColor: '#f9f9f9', fontSize: '12px', lineHeight: '1.25'}}>
                            <tr>
                                {questionDetails.fields.choices?<td></td>:null}
                                {questionDetails.fields.rowText.map((item, i) => <td align="center" key={i}>{t(item.text)}</td>)}
                            </tr>
                            </thead>
                            <tbody>
                                {questionDetails.fields.choices?questionDetails.fields.choices.map((item, i) => 
                                    <tr key={i}>
                                        <td>{t(item.choice_text)}</td>
                                        {questionDetails.fields.rowText.map((data, j) => <td align="center" key={j}>
                                            {data.id == 3662615714 && <Checkbox color="primary" defaultChecked={previouslyFilledAnswers[0]?previouslyFilledAnswers.find(elem => elem.choice_id == data.id && elem.row_id == item.row_id)?Boolean(previouslyFilledAnswers.find(elem => elem.choice_id == data.id && elem.row_id == item.row_id).text): false:false} onChange={(e) => matrixOnChange(e.target.checked ? 1: 0, `${item.choice_text} ${data.text}`, item.row_id, data.id)} />}
                                            {data.id != 3662615714 && <TextField  inputProps={{ maxLength: 99, minLength : 0 }} type="number" defaultValue={previouslyFilledAnswers[0]?previouslyFilledAnswers.find(elem => elem.choice_id == data.id && elem.row_id == item.row_id)?previouslyFilledAnswers.find(elem => elem.choice_id == data.id && elem.row_id == item.row_id).text: '':''} onChange={e => matrixOnChange(e.target.value, `${item.choice_text} ${data.text}`, item.row_id, data.id)} />}
                                        </td>)}
                                    </tr>
                                ):null}
                            </tbody>
                        </Table>
                        {questionDetails.fields.containsOther && <FormGroup>
                            <Label>{questionDetails.fields.otherOptionText}</Label><br />
                            <TextField style={{paddingBottom: '2%'}} name={questionDetails.fields.otherOptionText} type="text" placeholder={`${t('Type your answer..."')}`} onChange={e => setMatrixOthers(e.target.value)} />
                        </FormGroup>}
                    </div>
                </div>}
                <div className="bg-white container mb-3" style={{borderRadius: '2px'}}>
                    {lengthError && <p style={{color: 'red',padding:'10px 0'}}>{t('Invalid Age')}<br /></p>}
                    {error && <p style={{color: 'red',padding:'10px 0'}}>{t('Please fill in all the blanks.')}<br /></p>}
                    <div className='px-2 py-3' style={{display: 'flex', justifyContent: 'space-between'}}>
                    <p style={{fontSize: '12px', lineHeight: '1.17', color: '#afafaf',marginBottom:'0',paddingTop:'10px'}}>{t('Powered by')} <a href="https://www.compriscare.com/" target="_blank"><img src={process.env.LOGO_URI} style={{marginLeft: '10px'}} className="footer-img-logo" /></a></p>
                        <div className="button-footer-wrapper">
                            <Button style={{backgroundColor: 'transparent', color: '#5e6265', border: 'none'}} onClick={prevPage} type='button' >{t('Previous')}</Button>
                            <Button style = {{marginLeft: '10px', backgroundColor: '#055ce5', color: 'white', borderRadius: '2px', border: 'none'}} onClick={nextPage} type='button' >{t('Next')}</Button>
                        </div>
                    </div>
                </div>
            </div>:null}
        </Container>
    )
}

const mapStateToProps = state => {
    return {
        questionContent : state.question.content,
    }
}

export default connect(mapStateToProps)(MatrixWithInputs)
