import React from 'react';
import {Button,Label} from 'reactstrap'
import Slider from "react-slick";
import { toast } from 'react-toastify';
import laptop from '../../assests/images/laptop.png';
import loginlogo from '../../assests/images/loginLogo.png'
import { Formik , Field} from 'formik'
import Language from '../../helperComponents/langDropdown'
import { useTranslation } from 'react-i18next'; 
import {APIS_URL} from '../../utils/apiEndPoints'
import service from '../../interceptor'
import { Link } from 'react-router-dom'
import CustomInput from '../../helperComponents/Input'
import {forgotPasswordValidation} from './validation'

const ForgotPassword = ({
    history, 
    ...props
}) => {

    const handleForgot = (values , resetForm ) => {
        service.post(APIS_URL.FORGOT, values).then( (res) => {
            toast.success(t('Email sent successfully.'))
            resetForm()
        })
    }

    const { t } = useTranslation()


  const settings = {
        dots: true,   
        nav : false,
        speed: 500,
        autoPlay : true,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: false,
        prevArrow: false
  };
  
  return (  
      <div className="flex-login-block">
           <div className="login-langDropdown">
                <Language/>
            </div>
            <div className="login-block common-style">
                <div className="form-controls">
                <a href="https://www.compriscare.com/" style={{maxWidth:'250px',margin:'0 auto', display:'block'}} target="_blank"><img src={loginlogo}/></a> 
                    <Formik
                        initialValues={{ email: ''}}
                        validationSchema={forgotPasswordValidation}
                        onSubmit={(values, actions) => {
                            handleForgot(values)
                        }}  
                        >
                        {props => (
                            <form onSubmit={props.handleSubmit}>
                                    <Label className="email">{`${t('Email Address')}`}</Label>
                                    <Field component={CustomInput} placeholder={`${t('Enter you email id')}`} name="email" type="text"/>
                                    <Link to="/" className="forgot-password">{`${t('Back to login?')}`}</Link>
                                    <Button type="submit" color="primary">{`${t('Send Email')}`}</Button>
                                    {/* <span className="dont-account text-center">Don't have an account? <a href="">Sign up</a></span> */}
                            </form>
                    )}
                </Formik>
                </div>
            </div>
            <div className="right-slider common-style"> 
                <Slider className="login-laptop" {...settings}>
                  <div className="slider-text-wrapper">
                        <span className="image-laptop"> 
                            <img src={laptop}/>
                        </span>
                        <h2>{t('Compris')}</h2>
                        <p>{t('Committed to helping healthcare providers better serve those suffering with addictions')}</p>
                    </div>

                    <div className="slider-text-wrapper">
                        <span className="image-laptop"> 
                            <img src={laptop}/>
                        </span>
                        <h2>{t('Automating Addiction Assessments')}</h2>
                        <p>{t('Improving Productivity and Guiding Telehealth')}</p>
                    </div>
                    <div className="slider-text-wrapper">
                        <span className="image-laptop"> 
                            <img src={laptop}/>
                        </span>
                        <h2>{t('Compris Profile')}</h2>
                        <p>{t('Creating the new gold standard for addiction assessment and chronic illness management')}</p>
                    </div>
                </Slider>
            </div>
      </div>
  );
};

export default ForgotPassword;   