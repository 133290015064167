import React , {useState } from 'react'
import QuestionsById from './QuestionsById'
import { connect } from 'react-redux'


function Questions({
    history,
    cmrID,
    questionContent,
    ...props
}) {

    const [state,setState] =  useState(false)

    // refresh issue fixed
    if(!cmrID){
        history.push(`/assessment`)
    }

    const questionID = props.match.params.questionId
    
    return (
        <div className={`${state ? "showLeft" : "hideLeft"}`} style={{backgroundColor: '#f3f3f3',overflow:'hidden'}}>
            <div>
                <div className="sidebar-left">
                    {
                    // questionID === 'page1' ? <Page1 history={history} />:
                    // questionID === 'page2' ? <Page2 history={history} />:
                    // questionID === 'page3' ? <Page3 history={history} />:
                    // questionID == 549097351 ? <EngaedSlider history={history}/> :
                    // questionID === 'page4' ? <Page4 history={history} />:
                    // questionID == 549097387 ? <QuestionNo32 history={history} />:
                    // questionID == 553973451 || questionID==553981633 || questionID == 553981634 ? <MatrixWithCheckbox history={history} questionID={questionID} />:
                    // questionID == 'completion' ? <CompleteSurvey history={history} setUpdateProgressTo100={setUpdateProgressTo100} />:
                    // questionID == 587379847 ? <DeathSliderQuestion history={history} />:
                    // questionID == 61 ? <Medication questionID={questionID} history={history} />:
                    <QuestionsById data={questionContent} questionID={questionID} history={history} />}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        cmrID: state.question.cmrID,
        questionContent : state.question.content,
    }
}

export default connect(mapStateToProps)(Questions)
