import React, { useState, useEffect } from 'react'
import { questionsAndChoices, findQuestion, updateAnswers, nextRoute } from './constants'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Data from './data'
import compris from '../../assests/images/001 Compris TM clr.png'
import { Container, Row, Col, Label, Button, Table, FormGroup } from 'reactstrap'
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import { connect } from 'react-redux'

function MultipleQuestions({
    questionContent,
    questionID,
    history,
    currentQuestionNumber,
    questionDetails,
    multipleQuestionAnswer,
    setMultipleAnswerOnChange,
    ...props
}) {

    const { t } = useTranslation();
    
    return (
        <div className="bg-white container mb-3" style={{borderRadius: '2px'}}>
            <div className="p-2 multi-choice">
                <p dangerouslySetInnerHTML={{__html: `${currentQuestionNumber}. ${t(questionDetails.fields.question_text)}`}}></p>
                {questionDetails.fields.questions.map((questions, questionsIndex) => (
                    <Row style={{marginBottom: '10px'}}>
                        {questions.map((question, questionIndex) => (
                            <React.Fragment>
                                <Col xs={5}>
                                    <p className="text-blocker" dangerouslySetInnerHTML={{__html: `${t(question.questionText)}`}}></p>
                                    {question.choices.map(choice => (
                                        <React.Fragment>
                                            <FormControlLabel
                                                control={<Radio name={`question${questionsIndex}`} color="primary" onChange={(e) => setMultipleAnswerOnChange(choice.id, choice.rowid, questionsIndex)} checked={multipleQuestionAnswer[questionsIndex] ? multipleQuestionAnswer[questionsIndex].choice_id == choice.id : false} value={choice.id} />}
                                                label={t(choice.text)}
                                            />
                                        </React.Fragment>
                                    ))}
                                </Col>
                                {questionIndex === 0 ? <Col xs={2}><span className="optional-text" style={{fontSize:'24px',fontWeight:'bold'}}><pre>{t('OR')}</pre></span></Col>:null}
                            </React.Fragment>
                        ))}
                    </Row>
                ))}
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        questionContent : state.question.content,
    }
}

export default connect(mapStateToProps)(MultipleQuestions)
