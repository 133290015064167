import React, { useState, useEffect } from 'react'
import { Formik, Field, FieldArray } from 'formik'
import SelectComponent from '../../helperComponents/Select'
import { Button } from 'reactstrap'
import { toast } from 'react-toastify'
import CustomInput from '../../helperComponents/Input'
import service from '../../surveyInterceptor'
import { Link, useLocation } from "react-router-dom";
import { connect } from 'react-redux'
import { DropdownItem } from 'reactstrap'
import logo from '../../assests/images/compros.png'
import CustomDropdown from '../../helperComponents/dropdown'
import { useTranslation } from 'react-i18next';
import TranslationDropdown from '../../helperComponents/langDropdown'
import landing from '../../assests/images/surveyAssests/landing.svg'
import usLogo from '../../assests/images/flag.svg'
import { questionsAndChoices, findQuestion, nextRoute } from '../Questions/constants'

function Survey({
    history,
    match,
    location: {
        search
    },
    ...props
}) {

    

    const [type, setType] = useState('public')

    const [token, setToken] = useState('')

    const { t, i18n } = useTranslation();

    const [lng, updateLang] = useState('en')




    function getParamsFromUrl(url) {
        url = decodeURI(url);
        let obj = {
            'type': 'provider'
        };
        if (url.match(/\?./)) {
            if (typeof url === 'string') {
                let params = url.split('?');
                let eachParamsArr = params[1].split('&');
                if (eachParamsArr && eachParamsArr.length) {
                    eachParamsArr.map(param => {
                        let keyValuePair = param.split('=')
                        let key = keyValuePair[0];
                        let value = keyValuePair[1];
                        obj[key] = value;
                    })
                }
                return obj;
            }
        }
        else {
            return obj
        }
    }


    useEffect(() => {
        const urlParams = getParamsFromUrl(window.location.href)
        if (urlParams.type == 'corporate') {
            setType('corporate')
            localStorage.setItem('token', urlParams.token)
            setToken(urlParams.token)
            localStorage.setItem('surveyType', 'corporate')
        }
        else if (urlParams.type == 'public') {
            setType('public')
            localStorage.setItem('surveyType', 'public')
        }
        else {
            setType('public')
            localStorage.setItem('surveyType', 'public')
        }
    }, [])


    


    useEffect(() => {
        // if(localStorage.getItem('surveyType') == 'public'){
        //     history.push('/assessment?type=public')
        // }
        // else {
        //     history.push('/assessment')
        // }
    }, [])


    const handleSurvey = (values) => {
        if (type == 'public') {
            service.post('/public/assessments?is_short=true', {
                patient_email: values.email
            }).then((res) => {
                props.dispatch({
                    type: `CMR_ID`,
                    payload: res.content.cmr_label
                })
                localStorage.setItem('cmrLabelPublic', res.content.cmr_label)
                service.post(`/public/surveys/${res.content.cmr_label}?is_short=true`).then(res => {
                    if (res.content.questions ? res.content.questions[0] ? res.content.questions[0].id == 549097386 : false : false) {
                        res.content.questions.splice(0, 1)
                    }
                    props.dispatch({
                        type: `UPDATE_CONTENT`,
                        payload: res.content
                    })

                    if (res.content.questions.length < 3) {
                        history.push(`/questions/page1`)
                    }
                    else if (res.content.questions.length < 6) {
                        history.push(`/questions/page2`)
                    }
                    else if (res.content.questions.length < 9) {
                        history.push(`/questions/page3`)
                    }
                    else if (res.content.questions.length === 9) {
                        if (res.content.questions[8].answers[0].choice_id == 3622507677 || res.content.questions[8].answers[0].choice_id == 3622507678)
                            history.push(`/questions/page4`)
                        else {
                            history.push(`/questions/549097381`)
                        }
                    }
                    else if (res.content.questions.length < 13) {
                        history.push(`/questions/page4`)
                    }
                    else {
                        const { question, currentQuestionIndex } = findQuestion(questionsAndChoices, res.content.questions[res.content.questions.length - 1].id)
                        // 
                        nextRoute(res.content, res.content.questions[res.content.questions.length - 1].id, history, res.content.questions[res.content.questions.length - 1].answers, currentQuestionIndex)
                    }
                })
            })
        }
        else if (type == 'corporate') {
            localStorage.setItem('path', window.location.href)
            service.post('/public/assessments?is_short=true', {
                token: token,
                cmr_label: values ? values.cmrid : null
            }).then((res) => {
                props.dispatch({
                    type: `CMR_ID`,
                    payload: res.content.cmr_label
                })
                localStorage.setItem('cmrLabelCorporate', res.content.cmr_label)
                service.post(`/public/surveys/${res.content.cmr_label}?type=shortSurvey`).then(res => {
                    if (res.content.questions ? res.content.questions[0] ? res.content.questions[0].id == 549097386 : false : false) {
                        res.content.questions.splice(0, 1)
                    }
                    props.dispatch({
                        type: `UPDATE_CONTENT`,
                        payload: res.content
                    })
                    if (res.content.questions.length < 3) {
                        history.push(`/questions/page1`)
                    }
                    else if (res.content.questions.length < 6) {
                        history.push(`/questions/page2`)
                    }
                    else if (res.content.questions.length < 9) {
                        history.push(`/questions/page3`)
                    }
                    else if (res.content.questions.length === 9) {
                        if (res.content.questions[8].answers[0].choice_id == 3622507677 || res.content.questions[8].answers[0].choice_id == 3622507678)
                            history.push(`/questions/page4`)
                        else {
                            history.push(`/questions/549097381`)
                        }
                    }
                    else if (res.content.questions.length < 13) {
                        history.push(`/questions/page4`)
                    }
                    else {
                        const { question, currentQuestionIndex } = findQuestion(questionsAndChoices, res.content.questions[res.content.questions.length - 1].id)
                        // 
                        nextRoute(res.content, res.content.questions[res.content.questions.length - 1].id, history, res.content.questions[res.content.questions.length - 1].answers, currentQuestionIndex)
                    }
                })
            })
        }
        else {
            service.post(`surveys/${values.cmrid}?is_short=true`).then(res => {
                if (res.content.questions ? res.content.questions[0] ? res.content.questions[0].id == 549097386 : false : false) {
                    res.content.questions.splice(0, 1)
                }
                props.dispatch({
                    type: `UPDATE_CONTENT`,
                    payload: res.content
                })
                props.dispatch({
                    type: `CMR_ID`,
                    payload: values.cmrid
                })
                if (res.content.questions.length < 3) {
                    history.push(`/questions/page1`)
                }
                else if (res.content.questions.length < 6) {
                    history.push(`/questions/page2`)
                }
                else if (res.content.questions.length < 9) {
                    history.push(`/questions/page3`)
                }
                else if (res.content.questions.length === 9) {
                    if (res.content.questions[8].answers[0].choice_id == 3622507677 || res.content.questions[8].answers[0].choice_id == 3622507678)
                        history.push(`/questions/page4`)
                    else {
                        history.push(`/questions/549097381`)
                    }
                }
                else if (res.content.questions.length < 13) {
                    history.push(`/questions/page4`)
                }
                else {
                    const { question, currentQuestionIndex } = findQuestion(questionsAndChoices, res.content.questions[res.content.questions.length - 1].id)
                    // 
                    nextRoute(res.content, res.content.questions[res.content.questions.length - 1].id, history, res.content.questions[res.content.questions.length - 1].answers, currentQuestionIndex)
                }
            })
        }
    }

    const setLanguageMiddleware = (lng) => {
        i18n.changeLanguage(lng);
        updateLang(lng)
    }

    return (
        <div>
            <div className="flex-login-block survey-wrapper">
                <div className="login-block common-style survey">
                    <div className="header-block">
                        <div className="logo-block">
                            <a href="https://www.compriscare.com/" target="_blank"><img src={process.env.LOGO_URI} /></a>
                        </div>

                        <div className="language-dropdown-onboarding">
                            <TranslationDropdown />
                        </div>
                    </div>
                    {
                        type == 'corporate' &&
                        <div className="welcome-block">
                            <h2>{t('Welcome!')}</h2>
                        </div>
                    }
                    <div className="form-controls">
                        {type == 'corporate' ? <h2>{t('')}</h2> : <h2>{t('Start the Assessment')}</h2>}
                        {
                            type == 'public' || type == 'corporate' ? "" :
                                <p>{t('Please enter your Compris Medical Record (CMR). This can be found in the email sent to you from your healthcare provider')}.</p>
                        }
                        {
                            type == 'corporate' &&
                            <div className="corporate-block-wrapper">
                                <h2>{t('First time here?')}</h2>
                            </div>
                        }
                        {
                            type == 'corporate' && <Button onClick={handleSurvey} color="primary">{t(type == 'corporate' ? "Start assessment" : "Start now")}</Button>

                        }
                        <Formik
                            initialValues={{}}
                            onSubmit={(values, actions) => {
                                handleSurvey(values)
                            }}
                        >
                            {props => (
                                <form style={{ paddingTop: '30px' }} onSubmit={props.handleSubmit}>
                                    {
                                        type == 'public' ?
                                            <React.Fragment>
                                                <label className="password">{t('Enter your Email')}</label>
                                                <Field required placeholder={`${t('Enter your email')}`} component={CustomInput} name="email" type="email" />
                                                <Button color="primary">{t('Start Now')}</Button>
                                            </React.Fragment>
                                            : type == 'corporate' ?
                                                <React.Fragment>
                                                    <h2 style={{ paddingTop: '20px', paddingBottom: '5px' }}>{t('Resume assessment')}</h2>
                                                    <label className="password">{t('Enter your ID (CMR)')}</label>
                                                    <Field required placeholder={`${t('Type or paste your code here...')}`} component={CustomInput} name="cmrid" type="text" />
                                                    <Button color="primary">{t('Resume now')}</Button>
                                                </React.Fragment> :
                                                <React.Fragment>
                                                    <label className="password">{t('Enter your CMR')}</label>
                                                    <Field required placeholder={`${t('Type or paste your code here...')}`} component={CustomInput} name="cmrid" type="text" />
                                                    <Button color="primary">{t('Start Now')}</Button>

                                                </React.Fragment>
                                    }
                                    <div className="text-block text-center">
                                        <span className="disc-text"><a target="_blank" href="https://www.compriscare.com/privacy-policy-terms">{t('Privacy Policy')}</a></span>
                                        <span className="disc-text"><a target="_blank" href="https://www.compriscare.com/disclaimer">{t('Disclaimer')}</a></span>
                                    </div>
                                    {
                                        type == 'corporate' && <p className="link-text">{t('After you start, you will see a personal code (CMR) to the left. PLEASE write down this number. You will need it later.')}</p>

                                    }
                                    {
                                        type == 'normal' &&
                                        <div className="link-to-survey">
                                            <p>{t('If you don’t have a CMR, to take or continue the assessment')}, <a href={process.env.SURVEY_PUBLIC_DOMAIN}>{t('click here')}.</a></p>
                                        </div>
                                    }
                                </form>
                            )}
                        </Formik>
                        {
                            type == 'public' &&
                            <div className="survey-public">
                                <p>{t('Your email is shared with no one. NO information identifying you is obtained')}.</p>
                            </div>
                        }
                    </div>
                </div>
                <div className="right-slider common-style">
                    <div className="right-text">
                        <img src={landing} />
                        {
                            type == 'corporate' ?
                                <div>
                                    <ul className="status-list">
                                        <li>{t('Am I well?')}</li>
                                        <li>{t('How do I get well?')}</li>
                                        <li>{t('How do I stay well?')}</li>
                                    </ul>
                                    <p>{t("This 10-20 minutes personal assessment will help you understand your risks and strengths, and offer guidance toward a healthier life. NO information identifying you is obtained. You can stop at any time and save your answers (“Save Progress” on the left side/dropdown panel). You can also go back to previous questions until you click “Submit”.")}</p>
                                    {/* <span>Clicking the “Start now” button confirms:</span>
                                <ul>
                                    <li>you are at least 18 years old or, if under 18, have a signed parent/guardian consent on file with the provider who will get your results,</li>
                                    <li>you voluntarily agree for the results to be used in a research study that contains no information personally identifying you, and</li>
                                    <li>you have read and agree with Compris, Inc.’s privacy policy and disclaimer.</li>
                                </ul>    */}
                                </div>
                                // : type == 'corporate' ?
                                // <div>
                                //     <p>{t("This 15-30-minute self-assessment is part of a personal wellness program that includes your risk of getting, managing, or recovering from a substance use problem. NO information identifying you is obtained. You can stop at any time and save your answers (“Save Progress” on the left side/dropdown panel). You can also go back to previous questions until you click “Submit”.")}.</p>
                                //     {/* <span>Clicking the “Start now” button confirms:</span>
                                //     <ul>
                                //         <li>you are at least 18 years old or, if under 18, have a signed parent/guardian consent on file with the provider who will get your results,</li>
                                //         <li>you voluntarily agree for the results to be used in a research study that contains no information personally identifying you, and</li>
                                //         <li>you have read and agree with Compris, Inc.’s privacy policy and disclaimer.</li>
                                //     </ul> */}
                                // </div>
                                :
                                <div>
                                    <ul className="status-list">
                                        <li>{t('Am I well?')}</li>
                                        <li>{t('How do I get well?')}</li>
                                        <li>{t('How do I stay well?')}</li>
                                    </ul>
                                    <p>{t("This 10-20 minutes personal assessment will help you understand your risks and strengths, and offer guidance toward a healthier life. NO information identifying you is obtained. You can stop at any time and save your answers (“Save Progress” on the left side/dropdown panel). You can also go back to previous questions until you click “Submit”.")}</p>
                                    {/* <span>Clicking the “Start now” button confirms:</span>
                                <ul>
                                    <li>you are at least 18 years old or, if under 18, have a signed parent/guardian consent on file with the provider who will get your results,</li>
                                    <li>you voluntarily agree for the results to be used in a research study that contains no information personally identifying you, and</li>
                                    <li>you have read and agree with Compris, Inc.’s privacy policy and disclaimer.</li>
                                </ul> */}
                                </div>
                        }
                        <div className="disclaimer">
                            <h3>{t('Disclaimer')}:</h3>
                            {
                                type == 'corporate' ?
                                    <div className="block-bottom">
                                        <p>{t("Clicking the “Start now” button confirms:")}</p>
                                        <ul>
                                            <li>{t('you are at least 18 years old or have permission from your parent/guardian.')}</li>
                                            <li>{t('you voluntarily agree for the results to be used in a research study that contains no information personally identifying you, and')}</li>
                                            <li>{t('you have read and agree with Compris, Inc.’s privacy policy and disclaimer')}</li>
                                        </ul>
                                    </div>
                                    :
                                    <div className="block-bottom">
                                        {
                                            
                                        }
                                        <p>{t("Clicking the “Start now” button confirms:")}</p>
                                        <ul>
                                            <li>{type == 'public' ? t('you are at least 18 years old or have permission from your parent/guardian.') : t('you are at least 18 years old or, if under 18, have a signed parent/guardian consent on file with the provider who will get your results')}</li>
                                            <li>{t('you voluntarily agree for the results to be used in a research study that contains no information personally identifying you, and')}</li>
                                            <li>{t('you have read and agree with Compris, Inc.’s privacy policy and disclaimer')}</li>
                                        </ul>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(mapDispatchToProps)(Survey)