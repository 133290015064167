import React, { useState, useEffect } from 'react'
import { questionsAndChoices, findQuestion, updateAnswers, nextRoute, paragraphs } from './constants'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Data from './data'
import compris from '../../assests/images/001 Compris TM clr.png'
import { Container, Row, Col, Label, Button, Table, FormGroup } from 'reactstrap'
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import { connect } from 'react-redux'
import MultipleQuestions from './MultipleQuestions';

function QuestionsById({
    questionContent,
    questionID,
    history,
    ...props
}) {

    const { t } = useTranslation();


    const [otherStatus, setOther] = useState(false)

    const [flag,setFlag] = useState(false)

    const [matrixSelectedIds, setMatrixSelectedState] = useState({})
    const [multipleChoiceAnswers, setMultipleChoiceAnswers] = useState([])
    const [singleChoiceAnswer, setSingleChoiceAnswer] = useState({})
    const [error, setError] = useState(false)
    const [multipleChoiceOthers, setMultipleChoiceOthers] = useState('')
    const [singleChoiceOthers, setSingleChoiceOthers] = useState('')
    const [questionDetails, setQuestionDetails] = useState()
    const [questionIndex, setQuestionIndex] = useState()
    const [matrixOthers, setMatrixOthers] = useState('')
    const [noneError, setnoneError] = useState(false)
    const [currentQuestionNumber, setCurrentQuestionNumber] = useState()
    const [update, setUpdate] = useState(false)
    const [openEndedText, setOPenendedText] = useState('')
    const [multipleQuestionAnswer, setMultipleQuestionAnswer] = useState([])
    const [paragraphText, setParagraphText] = useState('')


    const [membersCheck, setMembersCheck] = useState(false)


    useEffect( () => {
        if(questionID == 549097426 || questionID == 580243889){
            setOther(true)
        }
        else {
            setOther(false)
        }
    },[questionID])


    useEffect( () => {
        if(questionID == 549097426 || questionID == 580243889 || questionID == 549097448 ){
            setOther(true)
        }
        else {
            setOther(false)
        }
    },[questionID])




    const setOpenEndedTextFunc = (e) => {
        if(e.target.value >= 0){
            setOPenendedText(e.target.value)
        }
        else if(e.target.value  == ""){
            setOPenendedText('')
        }
        else
        {
            alert("Invalid value")
        }
    }


    function setMultipleAnswerOnChange(id, rowid, index){
        const multipleQuestionAnswerCopy = [...multipleQuestionAnswer]
        multipleQuestionAnswerCopy[index] = {choice_id: id, row_id:rowid }
        
        setMultipleQuestionAnswer(multipleQuestionAnswerCopy)
    }

    const updateQuestionDetails = (question, previousAnswers, currentQuestionId) => {
        if(currentQuestionId == 553784163){
            const qustionNo15Answer = previousAnswers.questions?previousAnswers.questions.find(item => item.id == 553741432).answers:[]
            qustionNo15Answer.map(item => {
                if(item.choice_id == 3652080822){
                    if(item.row_id == 3652080819){
                        question.fields.choices.splice(question.fields.choices.findIndex(item => item.row_id == 3652345098), 1)
                    }
                    if(item.row_id == 3652080820){
                        question.fields.choices.splice(question.fields.choices.findIndex(item => item.row_id == 3652345096), 1)
                    }
                    if(item.row_id == 3652080821){
                        question.fields.choices.splice(question.fields.choices.findIndex(item => item.row_id == 3652345097), 1)
                    }
                }
            })
            const questionNo18Answer = previousAnswers.questions?previousAnswers.questions.find(item => item.id == 549097424).answers: []
            const index = questionNo18Answer.findIndex(item => item.choice_id == 3698124025)
            if(index != -1){
                question.fields.choices.splice(question.fields.choices.findIndex(item => item.row_id == 3652538182), 1)
            }
        }
        // update choices for question number 28
        else if(questionID == 555274672){
            let choices = []
            question.fields.containsOther = false
            let questionNo26Answer = previousAnswers.questions?previousAnswers.questions.find(item => item.id == 580243889).answers:[]
            let tobaccoAdded = false
            questionNo26Answer.forEach(item => {
                if(!tobaccoAdded && (item.choice_id == 3821196634 || item.choice_id == 3983757573)){
                    tobaccoAdded = true
                    choices.push(question.fields.choices[0])
                }
                else if(item.choice_id == 3821196620){
                    choices.push(question.fields.choices[1])
                }
                else if(item.choice_id == 3821196621){
                    choices.push(question.fields.choices[2])
                }
                else if(item.choice_id == 3821196622){
                    choices.push(question.fields.choices[3])
                }
                else if(item.choice_id == 3821196623){
                    choices.push(question.fields.choices[4])
                }
                else if(item.choice_id == 3821196624){
                    choices.push(question.fields.choices[6])
                }
                else if(item.choice_id == 3821196625){
                    choices.push(question.fields.choices[5])
                }
                else if(item.choice_id == 3821196626){
                    choices.push(question.fields.choices[7]) 
                }
                else if(item.choice_id == 3821196627){
                    choices.push(question.fields.choices[8]) 
                }   
                else if(item.choice_id == 3821196628){
                    choices.push(question.fields.choices[9])
                }
                else if(item.choice_id == 3821196633){
                    choices.push(question.fields.choices[11])
                }
                else if(item.choice_id == 3821196629){
                    choices.push(question.fields.choices[10])
                }
                else if(item.choice_id == 3821196630){
                    choices.push(question.fields.choices[12])
                }
                else if(item.choice_id == 3821196631){
                    choices.push(question.fields.choices[13])
                }
                else if(item.other_id == 3821196643){
                    question.fields.containsOther = true
                    choices.push(question.fields.choices[14])
                }
            })
            question.fields.choices = choices
        }
        else if(questionID == 549097492){
            let choices = []
            question.fields.containsOther = false
            let questionNo26Answer = previousAnswers.questions?previousAnswers.questions.find(item => item.id == 580243889).answers:[]
            let tobaccoAdded = false
            questionNo26Answer.forEach(item => {
                if(!tobaccoAdded && (item.choice_id == 3821196634 || item.choice_id == 3983757573)){
                    tobaccoAdded = true
                    choices.push(question.fields.choices[0])
                }
                else if(item.choice_id == 3821196620){
                    choices.push(question.fields.choices[1])
                }
                else if(item.choice_id == 3821196621){
                    choices.push(question.fields.choices[2])
                }
                else if(item.choice_id == 3821196622){
                    choices.push(question.fields.choices[3])
                }
                else if(item.choice_id == 3821196623){
                    choices.push(question.fields.choices[4])
                }
                else if(item.choice_id == 3821196624){
                    choices.push(question.fields.choices[5])
                }
                else if(item.choice_id == 3821196625){
                    choices.push(question.fields.choices[6])
                }
                else if(item.choice_id == 3821196626){
                    choices.push(question.fields.choices[7]) 
                }
                else if(item.choice_id == 3821196627){
                    choices.push(question.fields.choices[8]) 
                }   
                else if(item.choice_id == 3821196628){
                    choices.push(question.fields.choices[9])
                }
                else if(item.choice_id == 3821196633){
                    choices.push(question.fields.choices[10])
                }
                else if(item.choice_id == 3821196629){
                    choices.push(question.fields.choices[11])
                }
                else if(item.choice_id == 3821196630){
                    choices.push(question.fields.choices[12])
                }
                else if(item.choice_id == 3821196631){
                    choices.push(question.fields.choices[13])
                }
                else if(item.other_id == 3821196643){
                    question.fields.containsOther = true
                    choices.push(question.fields.choices[14])
                }
            })
            if(choices.length>0)
            question.fields.choices = choices
            else
            history.push(`/questions/580243889`)
        }
        else if(questionID == 555274472){
            let choices = []
            question.fields.containsOther = false
            let questionNo26Answer = previousAnswers.questions?previousAnswers.questions.find(item => item.id == 580243889).answers:[]
            let tobaccoAdded = false
            questionNo26Answer.forEach(item => {
                if(!tobaccoAdded && (item.choice_id == 3821196634 || item.choice_id == 3983757573)){
                    tobaccoAdded = true
                    choices.push(question.fields.choices[0])
                }
                else if(item.choice_id == 3821196620){
                    choices.push(question.fields.choices[1])
                }
                else if(item.choice_id == 3821196621){
                    choices.push(question.fields.choices[2])
                }
                else if(item.choice_id == 3821196622){
                    choices.push(question.fields.choices[3])
                }
                else if(item.choice_id == 3821196623){
                    choices.push(question.fields.choices[4])
                }
                else if(item.choice_id == 3821196624){
                    choices.push(question.fields.choices[5])
                }
                else if(item.choice_id == 3821196625){
                    choices.push(question.fields.choices[6])
                }
                else if(item.choice_id == 3821196626){
                    choices.push(question.fields.choices[7]) 
                }
                else if(item.choice_id == 3821196627){
                    choices.push(question.fields.choices[8]) 
                }   
                else if(item.choice_id == 3821196628){
                    choices.push(question.fields.choices[9])
                }
                else if(item.choice_id == 3821196633){
                    choices.push(question.fields.choices[10])
                }
                else if(item.choice_id == 3821196629){
                    choices.push(question.fields.choices[11])
                }
                else if(item.choice_id == 3821196630){
                    choices.push(question.fields.choices[12])
                }
                else if(item.choice_id == 3821196631){
                    choices.push(question.fields.choices[13])
                }
                else if(item.other_id == 3821196643){
                    question.fields.containsOther = true
                    choices.push(question.fields.choices[14])
                }
            })
            if(choices.length>0)
            question.fields.choices = choices
            else
            history.push(`/questions/580243889`)
        }
        else if(questionID == 549097415){
            let choices = []
            let nicotineAnswer = previousAnswers.questions?previousAnswers.questions.find(item => item.id == 549097447).answers:[]
            nicotineAnswer.forEach(item => {
                if(item.choice_id != 3622508548){
                    if(item.row_id == 3622508544){
                        choices.push(question.fields.choices[0])
                    }
                    if(item.row_id == 3622508545){
                        choices.push(question.fields.choices[1])
                    }
                    else if(item.row_id == 3622508546){
                        choices.push(question.fields.choices[2])
                    }
                    else if(item.row_id == 3622508547){
                        choices.push(question.fields.choices[3])
                    }
                    else if(item.row_id == 3653737632){
                        choices.push(question.fields.choices[4])
                    }
                }
            })
            if(choices.length>0)
                question.fields.choices = choices
            else
                history.push(`/questions/555274672`)
        }
        // update rows for 38
        //update choices for 39
        else if(questionID == 556167203){
            let questionNo38Answer = previousAnswers.questions?previousAnswers.questions.find(item => item.id == 553981633).answers:[]
            let choices = []
            question.fields.containsOther = false
            questionNo38Answer.forEach(item => {
                if(item.row_id == 3653596763 && item.choice_id == 3653596742){
                    choices.push(question.fields.choices[0])
                }
                else if(item.row_id == 3653627817 && item.choice_id == 3653596742){
                    choices.push(question.fields.choices[1])
                }
                else if(item.row_id == 3653596762 && item.choice_id == 3653596742){
                    choices.push(question.fields.choices[2])
                }
                else if(item.row_id == 3653596764 && item.choice_id == 3653596742){
                    choices.push(question.fields.choices[3])
                }
                else if(item.row_id == 3653596765 && item.choice_id == 3653596742){
                    choices.push(question.fields.choices[4])
                }
                else if(item.row_id == 3653596766 && item.choice_id == 3653596742){
                    choices.push(question.fields.choices[5])
                }
                else if(item.row_id == 3653627818 && item.choice_id == 3653596742){
                    choices.push(question.fields.choices[6])
                }
                else if(item.row_id == 3653627819 && item.choice_id == 3653596742){
                    choices.push(question.fields.choices[7])
                }
                else if(item.row_id == 3653627820 && item.choice_id == 3653596742){
                    choices.push(question.fields.choices[8])
                }
                else if(item.row_id == 3653627821 && item.choice_id == 3653596742){
                    choices.push(question.fields.choices[9])
                }
                else if(item.row_id == 3653627822 && item.choice_id == 3653596742){
                    choices.push(question.fields.choices[10])
                }
                else if(item.row_id == 3653627823 && item.choice_id == 3653596742){
                    choices.push(question.fields.choices[11])
                }
                else if(item.row_id == 3653627824 && item.choice_id == 3653596742){
                    question.fields.containsOther = true
                    choices.push(question.fields.choices[12])
                }
            })
            // for rows
            let questionNo37Answer = previousAnswers.questions?previousAnswers.questions.find(item => item.id == 553973451).answers:[]
            let symptons = 0
            let rows = []
            questionNo37Answer.forEach(item => {
                if(item.choice_id == 3653547374){
                    symptons+=1
                }
            })
            if(symptons>5){
                rows = question.fields.rowText
            }
            else if(symptons>3){
                rows.push(question.fields.rowText[0], question.fields.rowText[1])
            }
            else if(symptons>1){
                rows.push(question.fields.rowText[0])
            }
            if(choices.length === 1){
                rows = [rows[rows.length-1]]
            }
            question.fields.rowText = rows
            question.fields.choices = choices
        }
        //choices for question number 40
        else if(questionID == 556181470){
            let questionNo38Answer = previousAnswers && previousAnswers.questions?previousAnswers.questions.find(item => item.id == 553981633).answers:[]
            let choices = []
            question.fields.containsOther = false
            questionNo38Answer.forEach(item => {
                if(item.row_id == 3653596763 && item.choice_id == 3653596750){
                    choices.push(question.fields.choices[0])
                }
                else if(item.row_id == 3653627817 && item.choice_id == 3653596750){
                    choices.push(question.fields.choices[1])
                }
                else if(item.row_id == 3653596762 && item.choice_id == 3653596750){
                    choices.push(question.fields.choices[2])
                }
                else if(item.row_id == 3653596764 && item.choice_id == 3653596750){
                    choices.push(question.fields.choices[3])
                }
                else if(item.row_id == 3653596765 && item.choice_id == 3653596750){
                    choices.push(question.fields.choices[4])
                }
                else if(item.row_id == 3653596766 && item.choice_id == 3653596750){
                    choices.push(question.fields.choices[5])
                }
                else if(item.row_id == 3653627818 && item.choice_id == 3653596750){
                    choices.push(question.fields.choices[6])
                }
                else if(item.row_id == 3653627819 && item.choice_id == 3653596750){
                    choices.push(question.fields.choices[7])
                }
                else if(item.row_id == 3653627820 && item.choice_id == 3653596750){
                    choices.push(question.fields.choices[8])
                }
                else if(item.row_id == 3653627821 && item.choice_id == 3653596750){
                    choices.push(question.fields.choices[9])
                }
                else if(item.row_id == 3653627822 && item.choice_id == 3653596750){
                    choices.push(question.fields.choices[10])
                }
                else if(item.row_id == 3653627823 && item.choice_id == 3653596750){
                    choices.push(question.fields.choices[11])
                }
                else if(item.row_id == 3653627824 && item.choice_id == 3653596750){
                    question.fields.containsOther = true
                    choices.push(question.fields.choices[12])
                }
            })
            // for rows
            let questionNo37Answer = previousAnswers.questions?previousAnswers.questions.find(item => item.id == 553973451).answers:[]
            let symptons = 0
            let rows = []
            questionNo37Answer.forEach(item => {
                if(item.choice_id == 3653547375){
                    symptons+=1
                }
            })
            if(symptons>5){
                rows = question.fields.rowText
            }
            else if(symptons>3){
                rows.push(question.fields.rowText[0], question.fields.rowText[1])
            }
            else if(symptons>1){
                rows.push(question.fields.rowText[0])
            }
            if(choices.length === 1){
                rows = [rows[rows.length-1]]
            }
            question.fields.rowText = rows
            question.fields.choices = choices
        }
        // choices for question number 41
        else if(questionID == 556181518){
            let questionNo38Answer = previousAnswers && previousAnswers.questions?previousAnswers.questions.find(item => item.id == 553981633).answers:[]
            let choices = []
            question.fields.containsOther = false
            questionNo38Answer.forEach(item => {
                if(item.row_id == 3653596763 && item.choice_id == 3653596743){
                    choices.push(question.fields.choices[0])
                }
                else if(item.row_id == 3653627817 && item.choice_id == 3653596743){
                    choices.push(question.fields.choices[1])
                }
                else if(item.row_id == 3653596762 && item.choice_id == 3653596743){
                    choices.push(question.fields.choices[2])
                }
                else if(item.row_id == 3653596764 && item.choice_id == 3653596743){
                    choices.push(question.fields.choices[3])
                }
                else if(item.row_id == 3653596765 && item.choice_id == 3653596743){
                    choices.push(question.fields.choices[4])
                }
                else if(item.row_id == 3653596766 && item.choice_id == 3653596743){
                    choices.push(question.fields.choices[5])
                }
                else if(item.row_id == 3653627818 && item.choice_id == 3653596743){
                    choices.push(question.fields.choices[6])
                }
                else if(item.row_id == 3653627819 && item.choice_id == 3653596743){
                    choices.push(question.fields.choices[7])
                }
                else if(item.row_id == 3653627820 && item.choice_id == 3653596743){
                    choices.push(question.fields.choices[8])
                }
                else if(item.row_id == 3653627821 && item.choice_id == 3653596743){
                    choices.push(question.fields.choices[9])
                }
                else if(item.row_id == 3653627822 && item.choice_id == 3653596743){
                    choices.push(question.fields.choices[10])
                }
                else if(item.row_id == 3653627823 && item.choice_id == 3653596743){
                    choices.push(question.fields.choices[11])
                }
                else if(item.row_id == 3653627824 && item.choice_id == 3653596743){
                    question.fields.containsOther = true
                    choices.push(question.fields.choices[12])
                }
            })
            // for rows
            let questionNo37Answer = previousAnswers.questions?previousAnswers.questions.find(item => item.id == 553973451).answers:[]
            let symptons = 0
            let rows = []
            questionNo37Answer.forEach(item => {
                if(item.choice_id == 3653547376){
                    symptons+=1
                }
            })
            if(symptons>5){
                rows = question.fields.rowText
            }
            else if(symptons>3){
                rows.push(question.fields.rowText[0], question.fields.rowText[1])
            }
            else if(symptons>1){
                rows.push(question.fields.rowText[0])
            }
            if(choices.length === 1){
                rows = [rows[rows.length-1]]
            }
            question.fields.rowText = rows
            question.fields.choices = choices
        }
        // choices for question number 42
        else if(questionID == 556181583){
            let questionNo38Answer = previousAnswers && previousAnswers.questions?previousAnswers.questions.find(item => item.id == 553981633).answers:[]
            let choices = []
            question.fields.containsOther = false
            questionNo38Answer.forEach(item => {
                if(item.row_id == 3653596763 && item.choice_id == 3653596744){
                    choices.push(question.fields.choices[0])
                }
                else if(item.row_id == 3653627817 && item.choice_id == 3653596744){
                    choices.push(question.fields.choices[1])
                }
                else if(item.row_id == 3653596762 && item.choice_id == 3653596744){
                    choices.push(question.fields.choices[2])
                }
                else if(item.row_id == 3653596764 && item.choice_id == 3653596744){
                    choices.push(question.fields.choices[3])
                }
                else if(item.row_id == 3653596765 && item.choice_id == 3653596744){
                    choices.push(question.fields.choices[4])
                }
                else if(item.row_id == 3653596766 && item.choice_id == 3653596744){
                    choices.push(question.fields.choices[5])
                }
                else if(item.row_id == 3653627818 && item.choice_id == 3653596744){
                    choices.push(question.fields.choices[6])
                }
                else if(item.row_id == 3653627819 && item.choice_id == 3653596744){
                    choices.push(question.fields.choices[7])
                }
                else if(item.row_id == 3653627820 && item.choice_id == 3653596744){
                    choices.push(question.fields.choices[8])
                }
                else if(item.row_id == 3653627821 && item.choice_id == 3653596744){
                    choices.push(question.fields.choices[9])
                }
                else if(item.row_id == 3653627822 && item.choice_id == 3653596744){
                    choices.push(question.fields.choices[10])
                }
                else if(item.row_id == 3653627823 && item.choice_id == 3653596744){
                    choices.push(question.fields.choices[11])
                }
                else if(item.row_id == 3653627824 && item.choice_id == 3653596744){
                    question.fields.containsOther = true
                    choices.push(question.fields.choices[12])
                }
            })
            // for rows
            let questionNo37Answer = previousAnswers.questions?previousAnswers.questions.find(item => item.id == 553973451).answers:[]
            let symptons = 0
            let rows = []
            questionNo37Answer.forEach(item => {
                if(item.choice_id == 3653547377){
                    symptons+=1
                }
            })
            if(symptons>5){
                rows = question.fields.rowText
            }
            else if(symptons>3){
                rows.push(question.fields.rowText[0], question.fields.rowText[1])
            }
            else if(symptons>1){
                rows.push(question.fields.rowText[0])
            }
            if(choices.length === 1){
                rows = [rows[rows.length-1]]
            }
            question.fields.rowText = rows
            question.fields.choices = choices
        }
        // choices for question number 43
        else if(questionID == 556181824){
            let questionNo38Answer = previousAnswers.questions?previousAnswers.questions.find(item => item.id == 553981633).answers:[]
            let choices = []
            question.fields.containsOther = false
            questionNo38Answer.forEach(item => {
                if(item.row_id == 3653596763 && item.choice_id == 3653693369){
                    choices.push(question.fields.choices[0])
                }
                else if(item.row_id == 3653627817 && item.choice_id == 3653693369){
                    choices.push(question.fields.choices[1])
                }
                else if(item.row_id == 3653596762 && item.choice_id == 3653693369){
                    choices.push(question.fields.choices[2])
                }
                else if(item.row_id == 3653596764 && item.choice_id == 3653693369){
                    choices.push(question.fields.choices[3])
                }
                else if(item.row_id == 3653596765 && item.choice_id == 3653693369){
                    choices.push(question.fields.choices[4])
                }
                else if(item.row_id == 3653596766 && item.choice_id == 3653693369){
                    choices.push(question.fields.choices[5])
                }
                else if(item.row_id == 3653627818 && item.choice_id == 3653693369){
                    choices.push(question.fields.choices[6])
                }
                else if(item.row_id == 3653627819 && item.choice_id == 3653693369){
                    choices.push(question.fields.choices[7])
                }
                else if(item.row_id == 3653627820 && item.choice_id == 3653693369){
                    choices.push(question.fields.choices[8])
                }
                else if(item.row_id == 3653627821 && item.choice_id == 3653693369){
                    choices.push(question.fields.choices[9])
                }
                else if(item.row_id == 3653627822 && item.choice_id == 3653693369){
                    choices.push(question.fields.choices[10])
                }
                else if(item.row_id == 3653627823 && item.choice_id == 3653693369){
                    choices.push(question.fields.choices[11])
                }
                else if(item.row_id == 3653627824 && item.choice_id == 3653693369){
                    question.fields.containsOther = true
                    choices.push(question.fields.choices[12])
                }
            })
            // for rows
            let questionNo37Answer = previousAnswers.questions?previousAnswers.questions.find(item => item.id == 553973451).answers:[]
            let symptons = 0
            let rows = []
            questionNo37Answer.forEach(item => {
                if(item.choice_id == 3653696915){
                    symptons+=1
                }
            })
            if(symptons>5){
                rows = question.fields.rowText
            }
            else if(symptons>3){
                rows.push(question.fields.rowText[0], question.fields.rowText[1])
            }
            else if(symptons>1){
                rows.push(question.fields.rowText[0])
            }
            if(choices.length === 1){
                rows = [rows[rows.length-1]]
            }
            question.fields.rowText = rows
            question.fields.choices = choices
        }
        else if(questionID == 556181825){
            const questionNo25Answer = previousAnswers.questions.find(item => item.id == 580243889).answers
            if(!questionNo25Answer.some(({choice_id}) => choice_id == 3821196621)){
                history.push(`/questions/554010038`)
            }
            const pronounAnswer = previousAnswers.questions.find(item => item.id == 549097429).answers
            if(pronounAnswer[0].choice_id == 3622508266){
                question.fields.question_text = 'How often did you have 4 or more alcohol-containing drinks within a few hours?'
            }
        }
        //choices for que4stion number 44
        else if(questionID == 554010038){
            let choices = []
            question.fields.containsOther = false
            let questionNo26Answer = previousAnswers.questions?previousAnswers.questions.find(item => item.id == 580243889).answers:[]
            let tobaccoAdded = false
            questionNo26Answer.forEach(item => {
                if(!tobaccoAdded && (item.choice_id == 3821196634 || item.choice_id == 3983757573)){
                    tobaccoAdded = true
                    choices.push(question.fields.choices[0])
                }
                else if(item.choice_id == 3821196620){
                    choices.push(question.fields.choices[1])
                }
                else if(item.choice_id == 3821196621){
                    choices.push(question.fields.choices[2])
                }
                else if(item.choice_id == 3821196622){
                    choices.push(question.fields.choices[3])
                }
                else if(item.choice_id == 3821196623){
                    choices.push(question.fields.choices[4])
                }
                else if(item.choice_id == 3821196624){
                    choices.push(question.fields.choices[5])
                }
                else if(item.choice_id == 3821196625){
                    choices.push(question.fields.choices[6])
                }
                else if(item.choice_id == 3821196626){
                    choices.push(question.fields.choices[7]) 
                }
                else if(item.choice_id == 3821196627){
                    choices.push(question.fields.choices[8]) 
                }   
                else if(item.choice_id == 3821196628){
                    choices.push(question.fields.choices[9])
                }
                else if(item.choice_id == 3821196633){
                    choices.push(question.fields.choices[10])
                }
                //
                else if(item.choice_id == 3821196629){
                    choices.push(question.fields.choices[11])
                }
                else if(item.choice_id == 3821196630){
                    choices.push(question.fields.choices[12])
                }
                else if(item.choice_id == 3821196631){
                    choices.push(question.fields.choices[13])
                }
                else if(item.other_id == 3821196643){
                    question.fields.containsOther = true
                    choices.push(question.fields.choices[14])
                }
            })
            question.fields.choices = choices
        }
        // choices for question number 45
        else if(questionID == 555422217){
            let choices = []
            question.fields.containsOther = false
            let questionNo26Answer = previousAnswers.questions?previousAnswers.questions.find(item => item.id == 554010038).answers:[]
            let tobaccoAdded = false
            questionNo26Answer.forEach(item => {
                if(!tobaccoAdded && (item.choice_id == 3821196634 || item.choice_id == 3983757573)){
                    tobaccoAdded = true
                    choices.push(question.fields.choices[0])
                }
                else if(item.choice_id == 3821196620){
                    choices.push(question.fields.choices[1])
                }
                else if(item.choice_id == 3821196621){
                    choices.push(question.fields.choices[2])
                }
                else if(item.choice_id == 3821196622){
                    choices.push(question.fields.choices[3])
                }
                else if(item.choice_id == 3821196623){
                    choices.push(question.fields.choices[4])
                }
                else if(item.choice_id == 3821196624){
                    choices.push(question.fields.choices[5])
                }
                else if(item.choice_id == 3821196625){
                    choices.push(question.fields.choices[6])
                }
                else if(item.choice_id == 3821196626){
                    choices.push(question.fields.choices[7])
                }
                else if(item.choice_id == 3821196627){
                    choices.push(question.fields.choices[8])
                }
                else if(item.choice_id == 3821196628){
                    choices.push(question.fields.choices[9])
                }
                else if(item.choice_id == 3821196633){
                    choices.push(question.fields.choices[10])
                }
                else if(item.choice_id == 3821196629){
                    choices.push(question.fields.choices[11])
                }
                else if(item.choice_id == 3821196630){
                    choices.push(question.fields.choices[12])
                }
                else if(item.choice_id == 3821196631){
                    choices.push(question.fields.choices[13])
                }
                else if(item.other_id == 3821196643){
                    question.fields.containsOther = true
                    choices.push(question.fields.choices[14])
                }
            })
            question.fields.choices = choices
        }
        // for new question no 50
        else if(currentQuestionId == 549097360){
            let questionNo49Answer = previousAnswers.questions?previousAnswers.questions.find(item => item.id == 586886313).answers:[]
            let choices = []
            questionNo49Answer.forEach(item => {
                if(item.choice_id == 3866397261){
                    choices.push(question.fields.choices[0])
                }
                else if(item.choice_id == 3866397250){
                    choices.push(question.fields.choices[1])
                }
                else if(item.choice_id == 3866397251){
                    choices.push(question.fields.choices[2])
                }
                else if(item.choice_id == 3866397252){
                    choices.push(question.fields.choices[3])
                }
                else if(item.choice_id == 3866397253){
                    choices.push(question.fields.choices[4])
                }
                else if(item.choice_id == 3866397262){
                    choices.push(question.fields.choices[5])
                }
                else if(item.choice_id == 3866397254){
                    choices.push(question.fields.choices[6])
                }
                else if(item.choice_id == 3866397255){
                    choices.push(question.fields.choices[7])
                }
                else if(item.choice_id == 3866397256){
                    choices.push(question.fields.choices[8])
                }
                else if(item.choice_id == 3866397257){
                    choices.push(question.fields.choices[9])
                }
                else if(item.choice_id == 3866397260){
                    choices.push(question.fields.choices[10])
                }
                else if(item.choice_id == 3622507398){
                    choices.push(question.fields.choices[11])
                }
                else if(item.choice_id == 3866397258){
                    choices.push(question.fields.choices[12])
                }
                else if(item.choice_id == 3866397259){
                    choices.push(question.fields.choices[13])
                }
                else if(item.choice_id == 3866397263){
                    choices.push(question.fields.choices[14])
                }
            })
            question.fields.choices = choices
        }
        // for question no 61
        else if(currentQuestionId == 549097431){
            let questionNo60Answer = previousAnswers.questions?previousAnswers.questions.find(item => item.id == 586889293).answers:[]
            question.fields.containsOther = false
            let choices = []
            questionNo60Answer.forEach(item => {
                if(item.choice_id == 3866417081){
                    choices.push(question.fields.choices[0])
                }
                else if(item.choice_id == 3866417070){
                    choices.push(question.fields.choices[1])
                }
                else if(item.choice_id == 3866417071){
                    choices.push(question.fields.choices[2])
                }
                else if(item.choice_id == 3866417072){
                    choices.push(question.fields.choices[3])
                }
                else if(item.choice_id == 3866417073){
                    choices.push(question.fields.choices[4])
                }
                else if(item.choice_id == 3866417082){
                    choices.push(question.fields.choices[5])
                }
                else if(item.choice_id == 3866417074){
                    choices.push(question.fields.choices[6])
                }
                else if(item.choice_id == 3866417075){
                    choices.push(question.fields.choices[7])
                }
                else if(item.choice_id == 3866441263){
                    choices.push(question.fields.choices[8])
                    question.fields.containsOther = true
                }
            })
            choices.push(question.fields.choices[9])
            question.fields.choices = choices
        }
        // for question 67
        else if(currentQuestionId == 549097357){
            let questionNo66Answer = previousAnswers.questions?previousAnswers.questions.find(item => item.id == 549097355).answers:[]
            question.fields.containsOther = false
            let choices = []
            questionNo66Answer.forEach(item => {
                if(item.choice_id == 3861345815){
                    choices.push(question.fields.choices[0])
                }
                else if(item.choice_id == 3861402699){
                    choices.push(question.fields.choices[1])
                }
                else if(item.choice_id == 3861345817){
                    choices.push(question.fields.choices[2])
                }
                else if(item.choice_id == 3861402700){
                    choices.push(question.fields.choices[3])
                }
                else if(item.choice_id == 3861402701){
                    choices.push(question.fields.choices[4])
                }
                else if(item.choice_id == 3861345818){
                    choices.push(question.fields.choices[5])
                }
                else if(item.choice_id == 3861402702){
                    choices.push(question.fields.choices[6])
                }
                else if(item.choice_id == 3861402703){
                    choices.push(question.fields.choices[7])
                }
                else if(item.choice_id == 3861345819){
                    choices.push(question.fields.choices[8])
                }
                else if(item.choice_id == 3861345820){
                    choices.push(question.fields.choices[9])
                }
                else if(item.choice_id == 3861345821){
                    choices.push(question.fields.choices[10])
                }
                else if(item.choice_id == 3622508863){
                    choices.push(question.fields.choices[11])
                }
                else if(item.choice_id == 3622508864){
                    choices.push(question.fields.choices[12])
                }
                else if(item.choice_id == 3866445283){
                    choices.push(question.fields.choices[13])
                    question.fields.containsOther = true
                }
            })
            question.fields.choices = choices
        }
        else if(currentQuestionId == 549097362){
            let questionNo60Answer = previousAnswers.questions?previousAnswers.questions.find(item => item.id == 586889293).answers:[]
            question.fields.containsOther = false
            let options = []
            questionNo60Answer.forEach(item => {
                if(item.choice_id == 3866417071){
                    options.push(question.fields.options[0])
                }
                else if(item.choice_id == 3866417073){
                    options.push(question.fields.options[2])
                }
                else if(item.choice_id == 3866417082){
                    options.push(question.fields.options[4])
                }
                else if(item.choice_id == 3866417074){
                    options.push(question.fields.options[6])
                }
                else if(item.choice_id == 3866417075){
                    options.push(question.fields.options[8])
                }
            })
            options.push(question.fields.options[1])
            options.push(question.fields.options[3])
            options.push(question.fields.options[5])
            options.push(question.fields.options[7])
            question.fields.options = options
        }
        // filtering choices for question number 46
        else if(currentQuestionId == 549097449){
            let questionNo46Answer = previousAnswers.questions?previousAnswers.questions.find(item => item.id == 549097448).answers:[]
            question.fields.containsOther = false
            let options = []
            questionNo46Answer.forEach(item => {
                if(item.choice_id == 3622508555){
                    options.push(question.fields.options[0])
                }
                else if(item.choice_id == 3622508563){
                    options.push(question.fields.options[1])
                }
                else if(item.choice_id == 3815580710){
                    options.push(question.fields.options[2])
                }
                else if(item.choice_id == 3622508556){
                    options.push(question.fields.options[3])
                }
                else if(item.choice_id == 3622508564){
                    options.push(question.fields.options[4])
                }
                else if(item.choice_id == 3622508571){
                    options.push(question.fields.options[5])
                }
                else if(item.choice_id == 3622508557){
                    options.push(question.fields.options[6])
                }
                else if(item.choice_id == 3622508589){
                    options.push(question.fields.options[7])
                }
                else if(item.choice_id == 3622508572){
                    options.push(question.fields.options[8])
                }
                else if(item.choice_id == 3622508558){
                    options.push(question.fields.options[9])
                }
                else if(item.choice_id == 3622508565){
                    options.push(question.fields.options[10])
                }
                else if(item.choice_id == 3622508573){
                    options.push(question.fields.options[11])
                }
                else if(item.choice_id == 3622508559){
                    options.push(question.fields.options[12])
                }
                else if(item.choice_id == 3622508566){
                    options.push(question.fields.options[13])
                }
                else if(item.choice_id == 3815580711){
                    options.push(question.fields.options[14])
                }
                else if(item.choice_id == 3815580709){
                    options.push(question.fields.options[15])
                }
                else if(item.choice_id == 3622508567){
                    options.push(question.fields.options[16])
                }
                else if(item.choice_id == 3622508574){
                    options.push(question.fields.options[17])
                }
                else if(item.choice_id == 3622508560){
                    options.push(question.fields.options[18])
                }
                else if(item.choice_id == 3622508568){
                    options.push(question.fields.options[19])
                }
                else if(item.choice_id == 3622508575){
                    options.push(question.fields.options[20])
                }
                else if(item.choice_id == 3622508561){
                    options.push(question.fields.options[21])
                }
                else if(item.choice_id == 3622508569){
                    options.push(question.fields.options[22])
                }
                else if(item.choice_id == 3622508562){
                    options.push(question.fields.options[24])
                }
                else if(item.choice_id == 3622508570){
                    options.push(question.fields.options[25])
                }
                else if(item.other_id == 3622508578){
                    question.fields.containsOther = true
                }
            })
            options.push(question.fields.options[23])
            question.fields.options = options
        }
        else if(questionID == 549097440){
            let relationshipStatus = previousAnswers.questions?previousAnswers.questions.find(item => item.id == 549097372).answers:[]
            if(relationshipStatus.length > 0){
                if(relationshipStatus[0].choice_id == 3622507516 || relationshipStatus[0].choice_id == 3622507517){
                    question.fields.choices[0].choice_text = t('I have supportive friends/colleagues')
                    // question.fields.choices[1].choice_text = 'supportive friends'
                }
            }
        }
        else if(questionID == 549097439){
            let relationshipStatus = previousAnswers.questions?previousAnswers.questions.find(item => item.id == 549097372).answers:[]
            if(relationshipStatus.length > 0){
                if(relationshipStatus[0].choice_id == 3622507516 || relationshipStatus[0].choice_id == 3622507517){
                    question.fields.choices[12].choice_text = t('I have supportive family')
                    // question.fields.choices[1].choice_text = 'supportive friends'
                }
            }
        }
        setQuestionDetails({...question})
        prefillValues(question)
      }

    useEffect(()=>{
        const allquestions = JSON.parse(JSON.stringify(questionsAndChoices))
        const {question, currentQuestionIndex} = findQuestion(allquestions, questionID)
        setQuestionIndex(currentQuestionIndex)
        updateQuestionDetails(question, questionContent, questionID)
        if(questionID == 549097412){
            getParagraphDetails(question)
        }
        return (() => {
            setMatrixSelectedState({})
        })
    }, [questionID])

    const prefillValues = (questionDetails) => {
        let index = questionContent.questions?questionContent.questions.findIndex(item => item.id == questionID):-1
        if(index!=-1){
            setUpdate(true)
            setCurrentQuestionNumber(index+1)
            if(questionDetails.fields.question_type == 'matrix'){
                if(questionDetails.fields.choices){
                    let answers = {}
                    if(questionDetails.fields.noneText){
                        if(questionContent.questions[index].answers[0].choice_id == questionDetails.fields.noneChoiceId){
                            answers[questionDetails.fields.noneText] = questionContent.questions[index].answers[0]
                        }
                    }
                    questionDetails.fields.choices.forEach((data, i) => {
                        questionContent.questions[index].answers.forEach((item) => {
                            if(data.row_id == item.row_id){
                                answers[data.choice_text] = item
                            }
                            if(data.other_id){
                                setMatrixOthers(data.text)
                            }
                        })
                    })
                    setMatrixSelectedState(answers)
                }
                else{
                    setMatrixSelectedState({col: questionContent.questions[index].answers[0]})
                    setMatrixOthers(questionContent.questions[index].answers[0].text)
                }
            }
            else if(questionDetails.fields.question_type === 'multiple_choice'){
                setMultipleChoiceAnswers(questionContent.questions[index].answers)
                questionContent.questions[index].answers.forEach(item => {
                    if(item.other_id){
                        setMultipleChoiceOthers(item.text)
                    }
                })
            }
            else if(questionDetails.fields.question_type === 'multiple_questions'){
                setMultipleQuestionAnswer(questionContent.questions[index].answers)
            }
            else if(questionDetails.fields.question_type === 'single_choice'){
                setSingleChoiceOthers(questionContent.questions[index].answers[0].text)
                setSingleChoiceAnswer(questionContent.questions[index].answers[0])
                if(questionContent.questions[index].answers[1]){
                    setSingleChoiceOthers(questionContent.questions[index].answers[1].text)
                }
            }
            else{
                setOPenendedText(questionContent.questions[index].answers[0].text)
            }
        }
        else if(questionContent.questions){
            for(let i=questionContent.questions.length-1; i>=0; i--){
                const {currentQuestionIndex} = findQuestion(questionsAndChoices, questionContent.questions[i].id)
                if(questionsAndChoices[currentQuestionIndex].pk < questionDetails.pk){
                    if(i == questionContent.questions.length-1)
                        setCurrentQuestionNumber(i+2)
                    else
                        setCurrentQuestionNumber(i+2)
                    break;
                }
            }
        }
    }

    function getParagraphDetails(){
        const answer = questionContent.questions?questionContent.questions.find(item => item.id == 549097411).answers:[]
        if(answer){
            for(let key in paragraphs){
                if(eval(paragraphs[key].condition)){
                    
                    setParagraphText(paragraphs[key].text)
                    break
                }
            }
        }
    }
    
    const matrixRadioOnChange = (name, row_id, choice_id) => {
        let matrixSelectedIdsCopy = {...matrixSelectedIds}
        if(questionDetails.fields.noneText){
            if(name == questionDetails.fields.noneText){
                matrixSelectedIdsCopy = {}
                matrixSelectedIdsCopy[name] = {
                    row_id,
                    choice_id
                }
            }
            else{
                matrixSelectedIdsCopy[name] = {
                    row_id,
                    choice_id
                }
                delete matrixSelectedIdsCopy[questionDetails.fields.noneText]
            }
        }
        else{
            matrixSelectedIdsCopy[name] = {
                row_id,
                choice_id
            }
        }
        setMatrixSelectedState({...matrixSelectedIdsCopy})
    }

    const multipleChoiceAnswersOnChange = (checkboxStatus, choice_id) => {
        let multipleChoiceAnswersCopy = [...multipleChoiceAnswers]
        if(checkboxStatus){
            if(questionDetails.fields.noneId == choice_id){
                multipleChoiceAnswersCopy = [{
                    choice_id: choice_id
                }]
            }
            else if(choice_id == questionDetails.fields.otherOPtionId){
                multipleChoiceAnswersCopy.push({
                    other_id: choice_id
                })
                if(questionDetails.fields.noneId){
                    const noneIndex = multipleChoiceAnswersCopy.findIndex(item => item.choice_id == questionDetails.fields.noneId)
                    if(noneIndex != -1)
                        multipleChoiceAnswersCopy.splice(noneIndex, 1)
                }
            }
            else{
                multipleChoiceAnswersCopy.push({
                    choice_id: choice_id
                })
                if(questionDetails.fields.noneId){
                    const noneIndex = multipleChoiceAnswersCopy.findIndex(item => item.choice_id == questionDetails.fields.noneId)
                    if(noneIndex != -1)
                        multipleChoiceAnswersCopy.splice(noneIndex, 1)
                }
            }
        }
        else{
            if(choice_id == questionDetails.fields.otherOPtionId){
                multipleChoiceAnswersCopy.splice(multipleChoiceAnswersCopy.findIndex(item => item.other_id == choice_id), 1)
            }
            else{
                multipleChoiceAnswersCopy.splice(multipleChoiceAnswersCopy.findIndex(item => item.choice_id == choice_id), 1)
            }
        }
        setMultipleChoiceAnswers(multipleChoiceAnswersCopy)
    }

    const singleChoiceAnswerOnChange = (id) => {
        if(id == questionDetails.fields.otherOPtionId){
            setSingleChoiceAnswer({other_id: id})
        }
        else{
            setSingleChoiceAnswer({choice_id: id})
        }
    }

    const nextPage = () => {
        if(openEndedText.length > 2) 
        {
            setMembersCheck(true)
            return false
        }
        if(questionDetails.fields.question_type === 'matrix'){
            if(questionID == 553981633 || questionID == 553973451 ||  questionID == 549097357){ 
                //for these questions we don't have to answer all rows
                if(Object.values(matrixSelectedIds).length > 0){
                    setError(false)
                    matrixNext()
                }
                else{
                    setError(true)
                }
            }
            else if(questionDetails.fields.choices){
                if(Object.values(matrixSelectedIds).length == questionDetails.fields.choices.length){
                    if(questionID == 549097447){
                        if(Object.values(matrixSelectedIds).every(item => item.choice_id == 3622508548)){
                            setError(true)
                            setFlag(true)
                        }
                        else{
                            setError(false)
                            setFlag(false)
                            matrixNext()    
                        }
                    }
                    else{
                        setError(false)
                        matrixNext()
                        setFlag(false)
                    }
                }
                else{
                    setError(true)
                }
            }
            else{
                if(Object.values(matrixSelectedIds).length == 1){
                    matrixNext()
                }
                else{
                    setError(true)
                }
            }
        }
        else if(questionDetails.fields.question_type === 'multiple_choice'){
            if(multipleChoiceAnswers.length>0){
                setError(false)
                setnoneError(false)
                if(questionDetails.fields.containsOther){
                    const otherIndex = multipleChoiceAnswers.findIndex(item => item.other_id == questionDetails.fields.otherOPtionId)
                    if(otherIndex != -1){
                        multipleChoiceAnswers[otherIndex].text = multipleChoiceOthers
                    }
                }
                let questionContentCopy = JSON.parse(JSON.stringify(questionContent))
                setUpdate(false)
                let multipleChoiceCopy = [...multipleChoiceAnswers]
                if(questionID == 549097424){
                    let questionNumber15 = questionContent
                }
                nextRoute(questionContentCopy, questionID, history, multipleChoiceCopy, questionIndex)
                setMultipleChoiceAnswers([])
                setMultipleChoiceOthers('')
                updateAnswers(questionContentCopy, currentQuestionNumber, {
                    id: questionID,
                    answers: multipleChoiceAnswers
                }, update)
                props.dispatch({
                    type: `UPDATE_CONTENT`,
                    payload: questionContentCopy
                })
            }
            else{
                setError(true)
            }
        }
        else if(questionDetails.fields.question_type === 'multiple_questions'){
            let numberOfQuestionsAnswered = 0;
            multipleQuestionAnswer.forEach(item => {
                if(item){
                    ++numberOfQuestionsAnswered
                }
            })
            if(numberOfQuestionsAnswered === questionDetails.fields.questions.length){
                setParagraphText('')
                setError(false)
                setnoneError(false)
                let questionContentCopy = JSON.parse(JSON.stringify(questionContent))
                setUpdate(false)
                const multipleQuestionAnswerCopy = [...multipleQuestionAnswer]
                nextRoute(questionContentCopy, questionID, history, multipleQuestionAnswerCopy, questionIndex)
                setMultipleQuestionAnswer([])
                updateAnswers(questionContentCopy, currentQuestionNumber, {
                    id: questionID,
                    answers: multipleQuestionAnswerCopy
                }, update)
                props.dispatch({
                    type: `UPDATE_CONTENT`,
                    payload: questionContentCopy
                })
            }
            else{
                setError(true)
            }
        }
        else if(questionDetails.fields.question_type === 'single_choice'){
            if(Object.keys(singleChoiceAnswer).length > 0){
                if(singleChoiceAnswer.other_id){
                    if(singleChoiceOthers){
                        setError(false)
                        const singleChoiceAnswerCopy = {...singleChoiceAnswer}
                        singleChoiceAnswerCopy.text = singleChoiceOthers
                        singleChoiceNext(singleChoiceAnswerCopy)
                        setSingleChoiceAnswer({})
                        setSingleChoiceOthers('')
                    }
                    else{
                        setError(true)
                    }
                }
                else{
                    setError(false)
                    singleChoiceNext({...singleChoiceAnswer})
                    setSingleChoiceAnswer({})
                    setSingleChoiceOthers('')
                }
            }
            else{
                setError(true)
            }
        }
        else{
            if(openEndedText){
                setError(false)
                let questionContentCopy = JSON.parse(JSON.stringify(questionContent))
                nextRoute(questionContentCopy, questionID, history, [{text: openEndedText}], questionIndex)
                updateAnswers(questionContentCopy, currentQuestionNumber, {
                    id: questionID,
                    answers: [{text: openEndedText}]
                }, update)
                props.dispatch({
                    type: `UPDATE_CONTENT`,
                    payload: questionContentCopy
                })
                setOPenendedText('')
            }
            else{
                setError(true)
            }
        }
    }

    const matrixNext = () => {
        let questionContentCopy = JSON.parse(JSON.stringify(questionContent))
        const matrixCopy = {...matrixSelectedIds}
        if(matrixOthers){
            matrixCopy.otherText = {
                other_id: questionDetails.fields.otherOPtionId,
                text: matrixOthers
            }
        }
        setUpdate(false)
        nextRoute(questionContentCopy, questionID, history, Object.values(matrixCopy), questionIndex)
        setMultipleChoiceOthers('')
        setMatrixOthers('')
        updateAnswers(questionContentCopy, currentQuestionNumber, {
            id: questionID,
            answers: Object.values(matrixCopy)
        }, update)
        props.dispatch({
            type: `UPDATE_CONTENT`,
            payload: questionContentCopy
        }, setMatrixSelectedState({}))
    }

    const singleChoiceNext = (singleChoiceAnswerCopy) => {
        singleChoiceAnswerCopy = [singleChoiceAnswerCopy]
        if(questionDetails.fields.hideRadio){
            if(singleChoiceOthers){
                singleChoiceAnswerCopy.push({
                    other_id: questionDetails.fields.otherOPtionId,
                    text: singleChoiceOthers
                })
            }
        }
        if(questionID == 549097351){
            singleChoiceAnswerCopy[0].row_id = questionDetails.fields.row_id
        }
        let questionContentCopy = JSON.parse(JSON.stringify(questionContent))
        setUpdate(false)
        nextRoute(questionContentCopy, questionID, history, singleChoiceAnswerCopy, questionIndex)
        setSingleChoiceOthers('')
        updateAnswers(questionContentCopy, currentQuestionNumber, {
            id: questionID,
            answers: singleChoiceAnswerCopy
        }, update)
        props.dispatch({
            type: `UPDATE_CONTENT`,
            payload: questionContentCopy
        })
    }

    const prevPage = () => {
        setParagraphText('')
        setError(false)
        if(questionIndex === 14){
            history.push(`/questions/page4`)
        }
        else if(questionID == 549097381){
            history.push(`/questions/page3`)
        }
        else{
            // history.push(`/questions/${questionsAndChoices[questionIndex-1].fields.question_id}`)
            let index = questionContent.questions?questionContent.questions.findIndex(item => item.id == questionID):-1
            if(index == -1){
                history.push(`/questions/${questionContent.questions[currentQuestionNumber-2].id}`)
            }
            else{
                history.push(`/questions/${questionContent.questions[index-1].id}`)
            }
        }
    }

    return (
        <Container>
            
            {questionDetails?<div>
                {questionDetails.fields.question_type === 'matrix' && <div className="bg-white container mb-3" style={{borderRadius: '2px'}}>
                    <div className="p-2" style={{overflow: 'auto'}}>
                        <p dangerouslySetInnerHTML={{__html: `${currentQuestionNumber}. ${t(questionDetails.fields.question_text)}`}}></p>
                        <Table borderless striped>
                            <thead style={{fontSize: '12px', lineHeight: '1.25'}}>
                            <tr>
                                {questionDetails.fields.choices?<td></td>:null}
                                {questionDetails.fields.rowText.map((item, i) => <td align="center" key={i}>{t(item.text)}</td>)}
                            </tr>
                            </thead>
                            <tbody>
                                {questionDetails.fields.choices?questionDetails.fields.choices.map((item, i) => 
                                    <tr key={i}>
                                        <td>{t(item.choice_text)}</td>
                                        {questionDetails.fields.rowText.map((data, j) => <td align="center" key={j}><Radio checked={matrixSelectedIds[item.choice_text]?matrixSelectedIds[item.choice_text].choice_id == data.id:false} color="primary" onChange={() => matrixRadioOnChange(item.choice_text, item.row_id, data.id)} name={item.choice_text} /></td>)}
                                    </tr>
                                ):<tr>{questionDetails.fields.rowText.map((data, j) => <td align="center" key={j}><Radio checked={matrixSelectedIds['col']?matrixSelectedIds['col'].choice_id == data.id:false} color="primary" onChange={() => matrixRadioOnChange('col', questionDetails.fields.row_id, data.id)} name='col' /></td>)}</tr>}
                            </tbody>
                        </Table>
                        {questionDetails.fields.noneText && <div>
                            <FormControlLabel
                                control={<Radio checked={matrixSelectedIds[questionDetails.fields.noneText]?matrixSelectedIds[questionDetails.fields.noneText].choice_id == questionDetails.fields.noneChoiceId:false} color="primary" onChange={() => matrixRadioOnChange(questionDetails.fields.noneText, questionDetails.fields.noneId, questionDetails.fields.noneChoiceId)} name={questionDetails.fields.noneText} />}
                                label={t(questionDetails.fields.noneText)}
                            />
                        </div> }
                        {questionDetails.fields.containsOther && <FormGroup>
                            <Label>{t(questionDetails.fields.otherOptionText)}</Label><br />
                            <TextField style={{paddingBottom: '2%'}} name={questionDetails.fields.otherOptionText} type="text" placeholder={`${t('Type your answer...')}`} onChange={e => setMatrixOthers(e.target.value)} />
                        </FormGroup>}
                    </div>
                    {questionID == 556181583 || questionID == 556181824 || questionID == 556181470 || questionID == 553981633 || questionID == 556167203 || questionID == 556181583 || questionID == 556181518 ? <Data/> : ""  }

                </div>}
                {questionDetails.fields.question_type === 'multiple_choice' && <div className="bg-white container mb-3" style={{borderRadius: '2px'}}>
                    <div className="p-2">
                        <p dangerouslySetInnerHTML={{__html: `${currentQuestionNumber}. ${t(questionDetails.fields.question_text)}`}}></p>
                        <Row className={otherStatus   ? "top" : "bottom"}>
                            {questionDetails.fields.options.map((item, i) => <Col xs={questionDetails.fields.rowSize?12/questionDetails.fields.rowSize:4} key={i}>
                                {!item.hide && <FormControlLabel
                                    control={<Checkbox name={item.text} color="primary" onChange={(e) => multipleChoiceAnswersOnChange(e.target.checked, item.id)} checked={multipleChoiceAnswers.find(data => data.choice_id == item.id)?true:false} />}
                                    label={questionDetails.fields.question_id === "549097340" ? <div style={{display: 'flex',paddingTop:'12px'}}><p style={{fontSize: '14px',fontFamily:'sans-serif',color: 'black'}}><b>{t(item.text.split(':')[0])} {item.id == 3622509768 ? '' : ':'}</b></p><p style={{paddingLeft:'10px',fontSize: '14px',paddingTop:'2px'}}>{t(item.text.split(':')[1])}</p></div>  : t(item.text)}
                                />}
                            </Col> )}
                        </Row> 
                        {questionDetails.fields.containsOther && <div>                            
                            <FormControlLabel
                                control={<Checkbox name={questionDetails.fields.otherOptionText} value={questionDetails.fields.otherOPtionId} onChange={(e) => multipleChoiceAnswersOnChange(e.target.checked, questionDetails.fields.otherOPtionId)} checked={multipleChoiceAnswers.find(item => item.other_id == questionDetails.fields.otherOPtionId)?true:false} color="primary" />}
                                label={t(questionDetails.fields.otherOptionText)}
                            />
                            <br />
                            <TextField style={{paddingBottom: '2%'}} name={questionDetails.fields.otherOptionText} type="text" placeholder={`${t('Type your answer...')}`} value={multipleChoiceOthers} onChange={e => setMultipleChoiceOthers(e.target.value)} />    
                        </div>}
                    </div>
                </div>}
                {questionDetails.fields.question_type === 'single_choice' && <div className="bg-white container mb-3" style={{borderRadius: '2px'}}>
                    <div className="p-2">
                        <p dangerouslySetInnerHTML={{__html: `${currentQuestionNumber}. ${t(questionDetails.fields.question_text)}`}}></p>
                        {paragraphText && t(paragraphText)}
                        <Row>
                            {questionDetails.fields.options.map((item, i) => <Col xs={questionDetails.fields.rowSize?12/questionDetails.fields.rowSize:12} key={i}>
                                <FormControlLabel
                                    control={<Radio name={item.text} color="primary" onChange={(e) => singleChoiceAnswerOnChange(item.id)} checked={singleChoiceAnswer.choice_id == item.id} value={singleChoiceOthers} />}
                                    label={t(item.text)}
                                />
                            </Col> )}
                        </Row> 
                        {questionDetails.fields.containsOther && <div>                            
                            {!questionDetails.fields.hideRadio ? <FormControlLabel
                                control={<Radio name={questionDetails.fields.otherOptionText} onChange={() => singleChoiceAnswerOnChange(questionDetails.fields.otherOPtionId)} color="primary" checked={singleChoiceAnswer.other_id == questionDetails.fields.otherOPtionId} />}
                                label={t(questionDetails.fields.otherOptionText)}
                            />:<InputLabel className="inoutLable" style={{padding: '10px 0 5px 0',fontSize:'12px'}}>{t(questionDetails.fields.otherOptionText)}</InputLabel>}
                            {!questionDetails.fields.hideRadio && <br />}
                            <TextField className="noRadioText" style={{paddingBottom: '2%'}} name={questionDetails.fields.otherOptionText} type="text" placeholder={`${t('Type your answer...')}`} value={singleChoiceOthers} onChange={e => setSingleChoiceOthers(e.target.value)} />    
                        </div>}
                    </div>
                </div>}
                {questionDetails.fields.question_type == 'open_ended' && <div className="bg-white container mb-3" style={{borderRadius: '2px'}}>
                    <div className="p-2">
                        <p dangerouslySetInnerHTML={{__html: `${10}. ${t(questionDetails.fields.question_text)}`}}></p>
                        <div className="spineer-input age-container" style={{display: "flex"}}>
                            <TextField  style={{width:'200px'}} type="number"  InputProps={{
                                inputProps: { 
                                    max: 100, min: 1
                                }
                            }}   placeholder={`${t('Type your answer...')}`} value={openEndedText} onChange={e => setOpenEndedTextFunc(e)} /> 
                            {/* <div style={{fontSize: '0.875rem', color: '#055ce5', marginLeft: '10px'}}>(1, 2, 3, etc)</div> */}
                        </div>
                        { membersCheck ?  <div className="error">{t('Members cannot be more than 99')}</div> : "" }
                    </div>
                </div>
                }
                {questionDetails.fields.question_type === 'multiple_questions' && <MultipleQuestions questionDetails={questionDetails} currentQuestionNumber={currentQuestionNumber} multipleQuestionAnswer={multipleQuestionAnswer} setMultipleAnswerOnChange={setMultipleAnswerOnChange} />}
                <div className="bg-white container mb-3" style={{borderRadius: '2px'}}>
                    {error && <p style={{color: 'red',padding:'10px 0'}}>{flag || questionDetails.fields.question_type === 'multiple_questions' && questionID == '553981634' ? `${t('Pick a different answer for at least ONE time period.')}` : questionDetails.fields.question_type === 'multiple_questions' ? t('Please pick A or B for each of the four pairs') : questionDetails.fields.question_type === 'matrix' && questionID != '549097354'  ? `${t('Please choose ONE answer per row')}` : questionDetails.fields.question_type === 'matrix' && questionID == '549097354' ? `${t('Please answer the question.')}` : questionDetails.fields.otherOPtionId == '3654004081' ? `${t('Please list medication or check a different box.')}` : `${t('Please answer the question.')}`}<br /></p>}
                    {noneError && <p style={{color: 'red',padding:'10px 0'}}>{t('Unselect none to select other options')}<br /></p>}
                    <div className='px-2 py-3' style={{display: 'flex', justifyContent: 'space-between'}}>
                        <p style={{fontSize: '12px', lineHeight: '1.17', color: '#afafaf',marginBottom:'0',paddingTop:'10px'}}>{t('Powered by')}<img src={process.env.LOGO_URI} style={{marginLeft: '10px'}} className="footer-img-logo" /></p>
                        <div className="button-footer-wrapper">
                            <Button style={{backgroundColor: 'transparent', color: '#5e6265', border: 'none'}} onClick={prevPage} type='button' >{t('Previous')}</Button>
                            <Button style = {{marginLeft: '10px', backgroundColor: '#055ce5', color: 'white', borderRadius: '2px', border: 'none'}} onClick={nextPage} type='button' >{t('Next')}</Button>
                        </div>
                    </div>
                </div>
            </div>:null}
        </Container>
    )
}

const mapStateToProps = state => {
    return {
        questionContent : state.question.content,
    }
}

export default connect(mapStateToProps)(QuestionsById)
