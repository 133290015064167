import React from 'react'
import { Input } from 'reactstrap'
import { useTranslation } from 'react-i18next'; 


const GlobalInputComponent = ({
  field,
  form: { touched, errors },
  ...props
}) => {

    const { t, i18n } = useTranslation();

    return ( <div>
        <div className="form-group">
            <Input  {...field} {...props} />
        </div>
        <div>
            {touched[field.name] && errors[field.name] && <div className="error">{t(errors[field.name])} </div>}
        </div>
    </div> )
};

export default GlobalInputComponent