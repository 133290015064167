import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next';
import { Modal, ModalHeader, ModalBody } from 'reactstrap'


function CustomModal({
    state,
    setState,
    children,
    sendToLogin,
    header,
    className,
    ...props
}) {


    const { t, i18n } = useTranslation();


    return (
            <Fragment>
                <Modal className={`mutual-ui ${className}`}  isOpen={state}>
                    <ModalHeader toggle={ sendToLogin ? () => window.location.href = "https://www.compriscare.com/thank-you" : () => setState(false) }>{header ? t(header) : ""}</ModalHeader>
                    <ModalBody className="modal-common-ui">
                        {children}
                    </ModalBody>
                </Modal>
            </Fragment>
    )
}

export default CustomModal
