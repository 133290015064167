const APIS_URL = {
    LOGIN : 'auth/login',
    FORGOT : 'password/forgot',
    RESET : 'password/reset',
    REFRESHTOKEN : 'auth/refresh-token',
    PROFILE : 'profile',
    CLINICS : 'clinics?page=1&per_page_items=25',
    GET_CLINICS_DETAILS : 'clinics',
    ASSESMENT_LIST : 'assessments',
    ADD_ASSESMENT : 'assessments',
    ASSESMENT_STATS : 'assessments/stats',
    CLINIC_MASTER : 'clinic-providers',
    ALL_CLINICS : 'clinics',
    ADD_CLINICS : 'clinics',
    GET_SUPER_DETAILS :'clinic-providers',
    GET_MEMBERS : 'members',
    ADD_MEMBERS : 'members',
    DELETE_MEMBERS  : "members",
    CHANGE_MEMBER_STATUS : "members",
    LOGO : 'clinic-providers',
    INVOICE : 'invoices',
    REFERRAL : 'referrals',
    NOTIFICATIONS : 'notification-preferences',
    PATIENT_FEEDBACKS : "patient-feedbacks",
    CLINIC_FEEDBACKS : "feedbacks",

    // Superadmins
    SUPERADMIN : 'superadmins',
    
    // Plans

    PLANS : '/plans'

}

export {
    APIS_URL
}