import React, { useState, useEffect } from 'react'
import Checkbox from '@material-ui/core/Checkbox';
import Slider from '@material-ui/core/Slider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Container, Row, Col, Label, Button, Table, FormGroup } from 'reactstrap'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next';
import compris from '../../assests/images/001 Compris TM clr.png'
import { updateAnswers, emptyAnswerArray, nextRoute } from './constants'

function EngaedSlider({
    questionContent,
    questionID,
    history,
    ...props
}) {

    const [engagedSlider, setEngagedSlider] = useState()
    const [update, setUpdate] = useState(false)


    const { t } = useTranslation()


    useEffect(() => {
        const previouslyFilledAnswer = questionContent.questions?questionContent.questions.find(item => item.id == 549097351):undefined
        if(previouslyFilledAnswer){
            setUpdate(true)
            let value = marks.find(item => item.id == previouslyFilledAnswer.answers[0].choice_id)
            if(value){
                setEngagedSlider(value.value)
            }
        }
    }, [])


    const marks = [
        {
          value: "0",
          label: "0",
          id: "3622507386"
        },
        {
            value: "1",
            label: "1",
            id: "3622507387"
        },
        {
          value: "2",
          label: "2",
          id: "3622507388"
        },
        {
          value: "3",
          label: "3",
          id: "3622507389"
        },
        {
          value: "4",
          label: "4",
          id: "3622507390"
        },
        {
          value: "5",
          label: "5",
          id: "3622507391"
        },
        {
          value: "6",
          label: "6",
          id: "3622507392"
        },
        {
          value: "7",
          label: "7",
          id: "3622507393"
        },
        {
          value: "8",
          label: "8",
          id: "3622507394"
        },
        {
          value: "9",
          label: "9",
          id: "3622507395"
        },
        {
          value: "10",
          label: "10",
          id: "3622507396"
        }
    ];

    const [error, setError] = useState(false)

    // function valueLabelFormat(value) {
    //     return marks.find((mark) => mark.value == value).label;
    // }

    const questionNo = questionContent.questions?questionContent.questions.findIndex(item => item.id == 549097351):-1

    const nextPage = () => {
        if(engagedSlider || engagedSlider==0){
            setError(false)
            let questionContentCopy = JSON.parse(JSON.stringify(questionContent))
            let choice_id = marks.find(item => item.value == engagedSlider).id
            updateAnswers(questionContentCopy, questionNo==-1?questionContent.questions?questionContent.questions.length+1:0:questionNo+1, {id: "549097351", answers: [{choice_id, row_id: "3622507385"}]}, update)
            props.dispatch({
                type: `UPDATE_CONTENT`,
                payload: questionContentCopy
            }, setEngagedSlider(0))
            history.push(`/surveyQuestions/completion`)
        }
        else{
            setError(true)
        }
    }

    const prevPage = () => {
        let index = questionContent.questions?questionContent.questions.findIndex(item => item.id == 549097351):-1
        if(index == -1){
            history.push(`/surveyQuestions/${questionContent.questions[questionContent.questions.length-1].id}`)
        }
        else{
            history.push(`/surveyQuestions/${questionContent.questions[index-1].id}`)
        }
    }

    return ( <div>
                <Container>
                    <div className="bg-white container mb-3" style={{borderRadius: '2px'}}>
                        <div className="p-2">
                            <p>{questionNo==-1?questionContent.questions?questionContent.questions.length+1:0:questionNo+1}. {t('How much am I currently ENGAGED in my HEALTH and WELL-BEING')}?</p>
                            <Row style={{paddingTop:'23px'}}>
                                <Col xs={12}>
                                        <Slider
                                            value={Number(engagedSlider)}
                                            // valueLabelFormat={valueLabelFormat}
                                            aria-labelledby="discrete-slider-restrict"
                                            step={null}
                                            valueLabelDisplay="auto"
                                            marks={marks}
                                            onChange={(e, newValue) => setEngagedSlider(newValue)}
                                            max={10}
                                        />
                                </Col>
                            </Row>
                        </div> 
                        <div className="sliderCategory d-sm-none d-md-none d-block"> 
                            <Row>
                                <Col md={12} xs={12} sm={12}>
                                    <label className="label"><span>0</span>{t('no need')}</label>
                                </Col>   
                                <Col md={12} xs={12} sm={12}>(
                                    <label className="label"><span>1</span>{t('no interest')}</label>
                                </Col>                
                                <Col md={12} xs={12} sm={12}>
                                <label className="label"><span>2</span>{t('feel pressured')}</label>
                                </Col>  
                                <Col md={12} xs={12} sm={12}>
                                <label className="label"><span>3</span>{t('feel guilty')}</label>
                                </Col>
                                <Col md={12} xs={12} sm={12}>
                                <label className="label"><span>4</span>{t('afraid to try')}</label>
                                </Col> 
                                <Col md={12} xs={12} sm={12}>
                                <label className="label"><span>5</span>{t('unsure')}</label>
                                </Col>
                                <Col md={12} xs={12} sm={12}>
                                    <label className="label"><span>6</span>{t('tired of it all')}</label>
                                </Col>
                                <Col md={12} xs={12} sm={12}>
                                <label className="label"><span>7</span>{t('afraid NOT to try')}</label>
                                </Col>     
                                <Col md={12} xs={12} sm={12}>
                                <label className="label"><span>8</span>{t('want my life back')}</label>
                                </Col>
                                <Col md={12} xs={12} sm={12}>
                                <label className="label"><span>9</span>{t('ready / engaged')}</label>
                                </Col>
                                <Col md={12} xs={12} sm={12}>
                                <label className="label"><span>10</span>{t('never more committed')}</label>
                                </Col>  
                            </Row>
                        </div> 

                        <div className="sliderCategory d-sm-block d-none"> 
                            <Row>
                                <Col md={6} sm={6} xs={12}>
                                <label className="label"><span>0</span>{t('no need')}</label>
                                </Col>
                                
                                <Col md={6} sm={6} xs={12}>
                                    <label className="label"><span>6</span>{t('tired of it all')}</label>
                                </Col>

                                <Col md={6} sm={6} xs={12}>
                                    <label className="label"><span>1</span>{t('no interest')}</label>
                                </Col>
                                
                                <Col md={6} sm={6} xs={12}>
                                <label className="label"><span>7</span>{t('afraid NOT to try')}</label>
                                </Col>
                                
                                <Col md={6} sm={6} xs={12}>
                                <label className="label"><span>2</span>{t('feel pressured')}</label>
                                </Col>
                                
                                <Col md={6} sm={6} xs={12}>
                                <label className="label"><span>8</span>{t('want my life back')}</label>
                                </Col>
                                
                                <Col md={6} sm={6} xs={12}>
                                <label className="label"><span>3</span>{t('feel guilty')}</label>
                                </Col>
                                
                                <Col md={6} sm={6} xs={12}>
                                <label className="label"><span>9</span>{t('ready / engaged')}</label>
                                </Col>
                                
                                <Col md={6} sm={6} xs={12}>
                                <label className="label"><span>4</span>{t('afraid to try')}</label>
                                </Col>
                                
                                <Col md={6} sm={6} xs={12}>
                                <label className="label"><span>10</span>{t('never more committed')}</label>
                                </Col>  
                                <Col md={6} sm={6} xs={12}>
                                <label className="label"><span>5</span>{t('unsure')}</label>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="bg-white container mb-3" style={{borderRadius: '2px'}}>
                        {error && <p style={{color: 'red',padding:'10px 0'}}>{t(`Please answer the question`)}<br /></p>}
                        <div className='px-2 py-3' style={{display: 'flex', justifyContent: 'space-between'}}>
                        <p style={{fontSize: '12px', lineHeight: '1.17', color: '#afafaf',marginBottom:'0',paddingTop:'10px'}}>{t('Powered by')} <a href="https://www.compriscare.com/" target="_blank"><img src={process.env.LOGO_URI} style={{marginLeft: '10px'}} className="footer-img-logo" /></a></p>
                            <div className="button-footer-wrapper">
                                <Button style={{backgroundColor: 'transparent', color: '#5e6265', border: 'none'}} onClick={prevPage} type='button' >{t('Previous')}</Button>
                                <Button style = {{marginLeft: '10px', backgroundColor: '#055ce5', color: 'white', borderRadius: '2px', border: 'none'}} onClick={nextPage} type='button' >{t('Next')}</Button>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        )
}


const mapStateToProps = state => {
    return {
        questionContent : state.question.content,
    }
}

export default connect(mapStateToProps)(EngaedSlider)