import React , {useState } from 'react'
import { useTranslation } from 'react-i18next';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import { Container, Row, Col, Button } from 'reactstrap'

function BiologicalRelatives({
    options,
    setOptions,
    multipleChoiceAnswers,
    multipleChoiceAnswersOnChange,
    multipleChoiceRadioAnswer,
    setMultipleChoiceRadioAnswer
}) {
    const { t } = useTranslation();

    const onCheckboxClick = (option, index, checked) => {
        if(option.subOptions){
            const optionsCopy = [...options]
            optionsCopy[index].showSubQuestion = !optionsCopy[index].showSubQuestion
            if(!optionsCopy[index].showSubQuestion){
                optionsCopy[index].subOptions.forEach(subOption => {
                    multipleChoiceAnswersOnChange(false, subOption.id)
                })
            }
            setOptions(optionsCopy)
        } else {
            if(option.id == 432){
                const optionsCopy = [...options]
                optionsCopy.forEach(option => {
                    option.showSubQuestion = false
                })
                setOptions(optionsCopy)
            }
            multipleChoiceAnswersOnChange(checked, option.id)
        }
    }

    const onRadioClick = (id, index) => {
        const multipleChoiceRadioAnswerCopy = [...multipleChoiceRadioAnswer]
        multipleChoiceRadioAnswerCopy[index] = {choice_id: id}
        setMultipleChoiceRadioAnswer(multipleChoiceRadioAnswerCopy)
    }

    const allowedQuestionIds = [3, 4, 5, 10, 11, 12, 13, 24, 26, 55, 69, 143, 68, 71, 89, 91, 105, 117, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 154, 155, 156, 107, 169, 170, 171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 192, 108, 181, 182, 183, 184, 185, 186, 187, 188, 189, 190, 191, 192, 118, 119, 120, 121];

    return (
        <div className="p-2">
            <div className="bg-white container mb-3" style={{ borderRadius: '.125rem' }}>
                {options.map((item, index) => (
                    <div  className="biological-relatives bottom-spacing">
                    <FormControlLabel
                        control={<Checkbox name={item.text} color="primary" onChange={(e) => onCheckboxClick(item, index, e.target.checked)} checked={item.showSubQuestion ? true : multipleChoiceAnswers.find(data => data.choice_id == item.id) ? true : false} />}
                        label={t(item.text)}
                    />
                    {item.showSubQuestion && <div style={{margin: '14px 0 20px'}}>
                        <p className='sub-question'>{t(item.question)}</p>
                        {item.subOptions.map((subOption) => (
                            <Row>
                                <Col  style={{height: '35px'}} xs={12}>
                                    <FormControlLabel
                                    control={<Radio name={subOption.text} color="primary" onChange={(e) => onRadioClick(subOption.id, index)} checked={multipleChoiceRadioAnswer[index] ? subOption.id == multipleChoiceRadioAnswer[index].choice_id: false} />}
                                    label={t(subOption.text)}
                                    />
                                </Col>
                            </Row>
                        ))}
                    </div>}
                    </div>
                ))}
            </div>
        </div>
    )
}


export default BiologicalRelatives
