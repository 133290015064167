import React , {useState } from 'react'
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

function MultipleInput({
    options,
    multipleInputAnswer,
    setMultipleInputAnswer,
    questionID,
    ageFirstUsedLastUsed
}) {
    const { t } = useTranslation();

    const onTextChange = (id, e, optionIndex, inputIndex) => {
        if(questionID == 90){
            if((e.target.value.slice(-1) == '.' || parseInt(e.target.value) != e.target.value) && e.target.value != ''){
                return;
            }
            if(!ageFirstUsedLastUsed[optionIndex])
                ageFirstUsedLastUsed[optionIndex] = []
            ageFirstUsedLastUsed[optionIndex][inputIndex] = e.target.value
        }
        const multipleInputAnswerCopy = {...multipleInputAnswer}
        multipleInputAnswerCopy[id] = e.target.value
        setMultipleInputAnswer(multipleInputAnswerCopy)
    }

    return (
        <div className="p-2">
            <div className="bg-white container px-3 py-3 mb-3" style={{ borderRadius: '.125rem' }}>
                {options.map((option, optionIndex) => (
                    <div className={questionID == 90 ? 'sub-question-containing-container' : ''}>
                        {option.question && <p className='sub-question' >{option.question}</p>}
                        {option.options ? option.options.map((options, inputIndex) => (
                            <div className="spineer-input" style={{ display: "flex", alignItems: 'end', paddingBottom: '2%' }}>
                                <div style={{ marginLeft: '.625rem', marginRight: '1rem' }}>{options.text}</div>
                                <TextField style={{ width: '7.5rem' }} type="string" onChange={e => onTextChange(options.id, e, optionIndex, inputIndex)} value={multipleInputAnswer[options.id] || ''} />
                            </div>
                        )): <TextField style={{ width: '7.5rem' }} type="string" onChange={e => onTextChange(options.id, e)} value={multipleInputAnswer[options.id]} />
                        }
                    </div>
                ))}
            </div>
        </div>
    )
}


export default MultipleInput
