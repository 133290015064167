import axios from 'axios';
import NProgress from 'nprogress'
import { toast } from 'react-toastify'



export const findQuestion = (arr, id) => {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].fields.question_id == id) {
      return { question: { ...arr[i] }, currentQuestionIndex: i }
    }
  }
}



export const emptyAnswerArray = (obj, previousAnswers) => {
  try {
    let previousAnswersLength = previousAnswers.questions.length
    for (let i = 0; i < previousAnswersLength; i++) {
      if (obj[Number(previousAnswers.questions[i].id)]) {
        // previousAnswers.questions[i].answers = []
        previousAnswers.questions.splice(i, 1)
        --i
        --previousAnswersLength
      }
    }
  }
  catch (e) {
    
  }
}

export const updateAnswers = (arr, index, object, update) => {
  // const index = arr.questions.findIndex(item => item.id == id) 
  // debugger;
  if (update) {
    arr.questions[index - 1] = object
  }
  else {
    arr.questions.splice(index - 1, 0, object)
  }
}

export const nextRoute = (previousAnswers, currentQuestionId, history, currentAnswer, questionIndex) => {
  if (currentQuestionId == 553741432) {
    if (currentAnswer.find(item => item.row_id == 3652080821).choice_id == 3652080822) {
      emptyAnswerArray({ '555286632': true }, previousAnswers)
      history.push(`/questions/${questionsAndChoices[questionIndex + 2].fields.question_id}`)
    }
    else {
      history.push(`/questions/${questionsAndChoices[questionIndex + 1].fields.question_id}`)
    }
  }
  else if (currentQuestionId == 549097381) {
    history.push(`/questions/page4`)
  }
  else if (currentQuestionId == 549097424) {
    const index = currentAnswer.findIndex(item => item.choice_id == 3698124025)
    if (index != -1) {
      const qustionNo15Answer = previousAnswers.questions.find(item => item.id == 553741432).answers
      if (qustionNo15Answer[0].choice_id == 3652080822 && qustionNo15Answer[1].choice_id == 3652080822 && qustionNo15Answer[2].choice_id == 3652080822) {
        emptyAnswerArray({ 553784163: true }, previousAnswers)
        history.push(`/questions/${questionsAndChoices[questionIndex + 2].fields.question_id}`)
      }
      else {
        history.push(`/questions/${questionsAndChoices[questionIndex + 1].fields.question_id}`)
      }
    }
    else {
      history.push(`/questions/${questionsAndChoices[questionIndex + 1].fields.question_id}`)
    }
  }
  // for question number 16
  else if (currentQuestionId == 555286632) {
    let count = 0
    currentAnswer.forEach(item => {
      if (item.choice_id == 3662031697) {
        count += 1
      }
      else if (item.choice_id == 3662031698) {
        count += 1
      }
      else if (item.choice_id == 3662031699) {
        count += 1
      }
    })
    if (count == 3) {
      emptyAnswerArray({ 553831140: true }, previousAnswers)
      history.push(`/questions/${questionsAndChoices[questionIndex + 2].fields.question_id}`)
    }
    else {
      history.push(`/questions/${questionsAndChoices[questionIndex + 1].fields.question_id}`)
    }
  }
  // for 1sdt part of question number 26
  else if (currentQuestionId == 580243889) {
    if (currentAnswer[0].choice_id == 3821196632) {
      emptyAnswerArray({ 555274672: true, 549097447: true, 549097415: true}, previousAnswers)
      history.push(`/questions/549097387`)
    }
    else if(!currentAnswer.find(item => item.choice_id == 3821196634 || item.choice_id == 3821196620 || item.choice_id == 3983757573)){
      emptyAnswerArray({ 549097447: true, 549097415: true}, previousAnswers)
      history.push(`/questions/${questionsAndChoices[questionIndex+3].fields.question_id}`)
    }
    else {
      history.push(`/questions/${questionsAndChoices[questionIndex + 1].fields.question_id}`)
    }
  }
  else if (currentQuestionId == 549097387) {
    if (currentAnswer[0].choice_id == 3622507699) {

      const questionNo25Answer = previousAnswers.questions.find(item => item.id == 580243889).answers

      if (questionNo25Answer[0].choice_id == 3821196632) {
        emptyAnswerArray({ 587379847: true, 553973451: true, 556167203: true, 556181470: true, 556181518: true, 556181583: true, 556181824: true, 554010038: true, 555422217: true }, previousAnswers)
        history.push(`/questions/549097411`)
      }
      else {
        const questionNo26Answer = previousAnswers.questions.find(item => item.id == 554023749).answers
        const rxOpiates = questionNo26Answer.find(item => item.row_id == 3653851638)
        if (questionNo26Answer.every(item => item.choice_id == 3653851651 || item.other_id)) {
          emptyAnswerArray({ 587379847: true, 553973451: true, 556167203: true, 556181470: true, 556181518: true, 556181583: true, 556181824: true, 554010038: true, 555422217: true }, previousAnswers)
          history.push(`/questions/549097448`) //skip to question number 46 
        }
        else {
          history.push(`/questions/${questionsAndChoices[questionIndex + 1].fields.question_id}`)
        }
      }
    }
    else {
      history.push(`/questions/587379847`)
    }
  }
  //death slider question
  else if (currentQuestionId == 587379847) {
    const questionNo25Answer = previousAnswers.questions.find(item => item.id == 580243889).answers
    if (questionNo25Answer[0].choice_id == 3821196632) {
      emptyAnswerArray({ 553973451: true, 556167203: true, 556181470: true, 556181518: true, 556181583: true, 556181824: true, 554010038: true, 555422217: true }, previousAnswers)
      history.push(`/questions/549097448`)
    }
    else {
      const questionNo26Answer = previousAnswers.questions.find(item => item.id == 554023749).answers
      const rxOpiates = questionNo26Answer.find(item => item.row_id == 3653851638)
      if (questionNo26Answer.every(item => item.choice_id == 3653851651 || item.other_id)) {
        emptyAnswerArray({ 553973451: true, 556167203: true, 556181470: true, 556181518: true, 556181583: true, 556181824: true, 554010038: true, 555422217: true }, previousAnswers)
        history.push(`/questions/549097448`) //skip to question number 46 
      }
      else {
        history.push(`/questions/${questionsAndChoices[questionIndex + 1].fields.question_id}`)
      }
    }
  }
  else if(currentQuestionId == 556181825) {
    const questionNo25Answer = previousAnswers.questions.find(item => item.id == 580243889).answers
    if(questionNo25Answer.every(item => item.choice_id == 3821196634 || item.choice_id == 3821196620 || item.choice_id == 3983757573)){
      emptyAnswerArray({ 549097340: true, 553973451: true, 553981634: true})
      history.push(`/questions/555422217`)
    }
    else{
      history.push(`/questions/${questionsAndChoices[questionIndex + 1].fields.question_id}`)
    }
  }
  //condition for question number 37
  else if (currentQuestionId == 553973451) {
    if (currentAnswer.every(item => item.row_id == 3844672728)) {
      emptyAnswerArray({ 556167203: true, 556181470: true, 556181518: true, 556181583: true, 556181824: true }, previousAnswers)
      history.push(`/questions/${questionsAndChoices[questionIndex + 2].fields.question_id}`)
    }
    else {
      history.push(`/questions/${questionsAndChoices[questionIndex + 1].fields.question_id}`)
    }
  }
  else if(currentQuestionId == 549097340){
    if(currentAnswer[0].choice_id == 3622509768){
      emptyAnswerArray({553981634: true, 553973451: true}, previousAnswers)
      history.push('/questions/555422217')
    }
    else{
      history.push(`/questions/${questionsAndChoices[questionIndex+1].fields.question_id}`)
    }
  }
  //condition for 44
  else if (currentQuestionId == 554010038) {
    // if (currentAnswer.every(item => item.choice_id == 3653767317)) {
    //   emptyAnswerArray({ 555422217: true }, previousAnswers)
    //   history.push(`/questions/${questionsAndChoices[questionIndex + 2].fields.question_id}`)
    // }
    // else {
      history.push(`/questions/${questionsAndChoices[questionIndex + 1].fields.question_id}`)
    // }
  }
  // condition for 46
  else if (currentQuestionId == 549097448) {
    const questionNo25Answer = previousAnswers.questions.find(item => item.id == 580243889).answers
    if (questionNo25Answer[0].choice_id == 3821196632) {
      emptyAnswerArray({ 549097449: true, 549097360: true, 549097350: true, 554051873: true, 549097349: true, 549097358: true, 549097354: true }, previousAnswers)
      history.push(`/questions/553825076`)
    }
    else {
      history.push(`/questions/${questionsAndChoices[questionIndex + 1].fields.question_id}`)
    }
  }
  // condition for question number 60
  else if (currentQuestionId == 549097355) {
    if (currentAnswer[0].choice_id == 3861345814) {
      emptyAnswerArray({ 549097357: true, 549097356: true, 549097373: true }, previousAnswers)
      history.push(`/questions/549097385`)
    }
    else {
      history.push(`/questions/${questionsAndChoices[questionIndex + 1].fields.question_id}`)
    }
  }
  // for last page
  else if (currentQuestionId == 549097351) {
    history.push(`/questions/completion`)
  }
  else if (currentQuestionId == 586886313) {
    if (currentAnswer[0].choice_id == 3866486056) {
      emptyAnswerArray({ 549097360: true }, previousAnswers)
      history.push(`/questions/${questionsAndChoices[questionIndex + 2].fields.question_id}`)
    }
    else
      history.push(`/questions/${questionsAndChoices[questionIndex + 1].fields.question_id}`)
  }
  else if (currentQuestionId == 586889293) {
    if (currentAnswer[0].choice_id == 3866484385) {
      emptyAnswerArray({ 549097431: true }, previousAnswers)
      history.push(`/questions/${questionsAndChoices[questionIndex + 2].fields.question_id}`)
    }
    else
      history.push(`/questions/${questionsAndChoices[questionIndex + 1].fields.question_id}`)
  }
  else if (currentQuestionId == 549097427) {
    const questionNo49Answer = previousAnswers.questions.find(item => item.id == 586889293).answers
    if (questionNo49Answer[0].choice_id == 3866484385) {
      emptyAnswerArray({ 549097431: true }, previousAnswers)
      history.push(`/questions/${questionsAndChoices[questionIndex + 2].fields.question_id}`)
    }
    else
      history.push(`/questions/${questionsAndChoices[questionIndex + 1].fields.question_id}`)
  }
  else if (currentQuestionId == 549097373) {
    debugger;
    const questionNo27Answer = previousAnswers.questions.find(item => item.id == 580243889).answers
    if (questionNo27Answer && questionNo27Answer.some(item => item.choice_id == 3821196622 || item.choice_id == 3821196628)) {
      const questionNo28Answer = previousAnswers.questions.find(item => item.id == 554023749).answers
      if (questionNo28Answer && questionNo28Answer.some(item => (item.row_id == 3653851638 || item.row_id == 3653851640) && item.choice_id != 3653851651 && item.choice_id != 3653851652 && item.choice_id != 3653851653)) {
        const questionNo45Answer = previousAnswers.questions.find(item => item.id == 553973451).answers || []
        let symptons = {
          past30: 0,
          one: 0,
          four: 0,
          oneYear: 0,
          fiveplus: 0,
        }
        questionNo45Answer.forEach(item => {
          if (item.choice_id == 3653547374) {
            symptons.past30 += 1
          }
          else if (item.choice_id == 3653547375) {
            symptons.one += 1
          }
          else if (item.choice_id == 3653547376) {
            symptons.four += 1
          }
          else if (item.choice_id == 3653547377) {
            symptons.oneYear += 1
          }
          else if (item.choice_id == 3653696915) {
            symptons.fiveplus += 1
          }
        })
        if (symptons.past30 < 2 && symptons.one < 2 && symptons.four < 2 && symptons.oneYear < 2 && symptons.fiveplus < 2) {
          history.push(`/questions/${questionsAndChoices[questionIndex + 2].fields.question_id}`)
        }
        else {
          const questionNo46Answer = previousAnswers.questions.find(item => item.id == 553973451).answers || []
          if (!questionNo46Answer.some(item => item.row_id == 3653627817 || item.row_id == 3653627819)) {
            history.push(`/questions/${questionsAndChoices[questionIndex + 1].fields.question_id}`)
          }
          else {
            history.push(`/questions/${questionsAndChoices[questionIndex + 1].fields.question_id}`)
          }
        }
      }
      else {
        history.push(`/questions/${questionsAndChoices[questionIndex + 2].fields.question_id}`)
      }
    }
    else {
      history.push(`/questions/${questionsAndChoices[questionIndex + 2].fields.question_id}`)
    }
  }
  else {
    history.push(`/questions/${questionsAndChoices[questionIndex + 1].fields.question_id}`)
  }
}


export const generateReport = (id, reqBody) => {
  return new Promise((resolve, reject) => {
    NProgress.start()
    axios.post(`${process.env.API_URL}public/surveys/${id}/download`, reqBody, {
      headers: {
        'Content-type': 'application/json',
      },
      responseType: 'blob'
    }).then((res) => {
      NProgress.done()
      var binaryData = [];
      binaryData.push(res.data);
      var fileURL = URL.createObjectURL(new Blob(binaryData, { type: "application/pdf" }))
      window.open(fileURL, "_self");
      resolve(1)
    }).catch((e) => {
      toast.error('Please check your internet connection and submit the assessment again')
      NProgress.done()
      reject(e)
    })
  })
}

export const paragraphs = {
  NURTURING_PARAGRAPH: {
    text: 'I am practical, down to earth, and prefer simple solutions. I am also sensitive, caring, and try to make others happy. I don’t like to argue or fight or be around people who do. I may have a hard time saying "no" if someone asks for my help.',
    text2: 'Nurturing Paragraph',
    condition: `(answer[1].choice_id == 3622508803 || answer[1].choice_id == 3622508804) && (answer[2].choice_id == 3622508813 || answer[2].choice_id == 3622508814)`
  },
  RELATIONSHIP_PARAGRAPH: {
    text: "I have deep feelings and strong ideas about what is right and wrong. I am sensitive and can easily get my feelings hurt. I am usually very good at knowing why people feel the way they do. I don't like to argue or fight or be around people who do. I prefer to think of new ways to help others.",
    text2: 'Relationship Paragraph',
    condition: `(answer[1].choice_id == 3622508811 || answer[1].choice_id == 3622508812) && (answer[2].choice_id == 3622508813 || answer[2].choice_id == 3622508814)`
  },
  PRACTICAL_PARAGRAPH: {
    text: 'I am practical, realistic and down to earth. I make most decisions based on what makes good sense, not how other people feel about it. I like clear, simple directions, and use my experience to solve problems. I am good at remembering important facts and details.',
    text2: 'Practical Paragraph',
    condition: `(answer[1].choice_id == 3622508803 || answer[1].choice_id == 3622508804) && (answer[2].choice_id == 3622508805 || answer[2].choice_id == 3622508806)`
  },
  COMPETENT_PARAGRAPH: {
    text: "I make most decisions based on what makes sense, not how people feel about it. I am smart and learn most things easily, which helps me succeed. I can usually see a better way of doing something, and others often look to me to lead them. I like solving problems and set high standards for myself and others.",
    text2: 'Practical Paragraph',
    condition: `(answer[1].choice_id == 3622508811 || answer[1].choice_id == 3622508812) && (answer[2].choice_id == 3622508805 || answer[2].choice_id == 3622508806)`
  }
}

export const questionsAndChoices = [
  {
    "model": "comprisapp.questions",
    "pk": 102,
    "fields": {
      "question_id": "549097386",
      "question_text": "COMPRIS MR# (obtain from your provider before taking the assessment)",
      "survey_id": "294219921",
      "question_type": "open_ended",
      "created_at": "2020-11-21T14:10:12.438Z",
      "updated_at": "2020-11-21T14:10:12.438Z"
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 103,
    "fields": {
      "question_id": "549097380",
      "question_text": "AGE",
      "survey_id": "294219921",
      "question_type": "open_ended",
      "created_at": "2020-11-21T14:10:12.465Z",
      "updated_at": "2020-11-21T14:10:12.465Z"
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 104,
    "fields": {
      "question_id": "549097446",
      "question_text": "GENDER",
      "survey_id": "294219921",
      "question_type": "single_choice",
      "created_at": "2020-11-21T14:10:12.491Z",
      "updated_at": "2020-11-21T14:10:12.491Z"
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 105,
    "fields": {
      "question_id": "549097429",
      "question_text": "Prefer to be called",
      "survey_id": "294219921",
      "question_type": "single_choice",
      "created_at": "2020-11-21T14:10:12.649Z",
      "updated_at": "2020-11-21T14:10:12.649Z"
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 106,
    "fields": {
      "question_id": "549097372",
      "question_text": "Relationship STATUS",
      "survey_id": "294219921",
      "question_type": "single_choice",
      "created_at": "2020-11-21T14:10:12.752Z",
      "updated_at": "2020-11-21T14:10:12.752Z"
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 107,
    "fields": {
      "question_id": "549097400",
      "question_text": "Sexual PREFERENCE",
      "survey_id": "294219921",
      "question_type": "single_choice",
      "created_at": "2020-11-21T14:10:12.856Z",
      "updated_at": "2020-11-21T14:10:12.856Z"
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 108,
    "fields": {
      "question_id": "549097422",
      "question_text": "RACE / ETHNICITY (check ALL that apply)",
      "survey_id": "294219921",
      "question_type": "multiple_choice",
      "created_at": "2020-11-21T14:10:13.096Z",
      "updated_at": "2020-11-21T14:10:13.096Z"
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 109,
    "fields": {
      "question_id": "549097423",
      "question_text": "Religious PREFERENCE",
      "survey_id": "294219921",
      "question_type": "single_choice",
      "created_at": "2020-11-21T14:10:13.334Z",
      "updated_at": "2020-11-21T14:10:13.334Z"
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 110,
    "fields": {
      "question_id": "549097445",
      "question_text": "Highest EDUCATION level",
      "survey_id": "294219921",
      "question_type": "matrix",
      "created_at": "2020-11-21T14:10:13.630Z",
      "updated_at": "2020-11-21T14:10:13.630Z"
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 110.5,
    "fields": {
      "question_id": "549097390",
      "question_text": "Current HOUSING",
      "survey_id": "294219921",
      "question_type": "matrix",
      "created_at": "2020-11-21T14:10:14.367Z",
      "updated_at": "2020-11-21T14:10:14.367Z"
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 111,
    "fields": {
      "question_id": "549097381",
      "question_text": "Number (Including self) in current HOUSEHOLD",
      "survey_id": "294219921",
      "question_type": "open_ended",
      "created_at": "2020-11-21T14:10:13.918Z",
      "updated_at": "2020-11-21T14:10:13.918Z"
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 112,
    "fields": {
      "question_id": "549097432",
      "question_text": "Combined household income",
      "survey_id": "294219921",
      "question_type": "matrix",
      "created_at": "2020-11-21T14:10:13.944Z",
      "updated_at": "2020-11-21T14:10:13.944Z"
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 113,
    "fields": {
      "question_id": "549097367",
      "question_text": "INSURANCE (check ALL that apply)",
      "survey_id": "294219921",
      "question_type": "multiple_choice",
      "created_at": "2020-11-21T14:10:14.158Z",
      "updated_at": "2020-11-21T14:10:14.158Z"
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 115,
    "fields": {
      "question_id": "549097399",
      "question_text": "Current Work: full time, part time, not working, retired, etc.",
      "survey_id": "294219921",
      "question_type": "matrix",
      "created_at": "2020-11-21T14:10:14.576Z",
      "updated_at": "2020-11-21T14:10:14.576Z"
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 116,
    "fields": {
      "question_id": "553741432",
      "question_text": "Ever been (more than 30 days)",
      "survey_id": "294219921",
      "question_type": "matrix",
      "created_at": "2020-11-21T14:10:14.733Z",
      "updated_at": "2020-11-21T14:10:14.733Z",
      choices: [
        {
          choice_text: 'Unemployed other than school or caregiving',
          row_id: 3652080819,
        },
        {
          choice_text: 'homeless',
          row_id: 3652080820,
        },
        {
          choice_text: 'arrested and held',
          row_id: 3652080821
        }
      ],
      rowText: [
        {
          text: 'no',
          id: 3652080822,
        },
        {
          text: 'for less than 6 months',
          id: 3652080823
        },
        {
          text: '6-11 months',
          id: 3652080824
        },
        {
          text: '1-2 yrs',
          id: 3652080825
        },
        {
          text: '3-5 yrs',
          id: 3652080826
        },
        {
          text: '6-9 yrs',
          id: 3652080827
        },
        {
          text: '10-19 yrs',
          id: 3652080828
        },
        {
          text: '20+ yrs',
          id: 3652080829
        },
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 117,
    "fields": {
      "question_id": "555286632",
      "question_text": "When were you being held in a facility (check ALL that apply)",
      "survey_id": "294219921",
      "question_type": "multiple_choice",
      "created_at": "2020-11-21T14:10:15.427Z",
      "updated_at": "2020-11-21T14:10:15.427Z",
      rowSize: 1,
      containsOther: false,
      options: [
        {
          text: 'within the past 30 days',
          id: 3662031697
        },
        {
          text: '1-3 months ago',
          id: 3662031698
        },
        {
          text: '4-11 months ago',
          id: 3662031699
        },
        {
          text: '1-2 years ago',
          id: 3662031700
        },
        {
          text: '3-4 years ago',
          id: 3662031701
        },
        {
          text: '5+ years ago',
          id: 3662031702
        }
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 118,
    "fields": {
      "question_id": "553831140",
      "question_text": "During the PAST 12 MONTHS, how true were the following statements for your household?",
      "survey_id": "294219921",
      "question_type": "matrix",
      "created_at": "2020-11-21T14:10:15.610Z",
      "updated_at": "2020-11-21T14:10:15.610Z",
      choices: [
        {
          choice_text: 'we worried our food would run out before we got money to buy more',
          row_id: 3652636446
        },
        {
          choice_text: "the food we bought didn't last, and we didn't have money to get more",
          row_id: 3652636447
        }
      ],
      rowText: [
        {
          text: 'often true',
          id: 3652636448
        },
        {
          text: `sometimes true`,
          id: 3652636449
        },
        {
          text: `never true`,
          id: 3652636450
        }
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 119,
    "fields": {
      "question_id": "549097424",
      "question_text": "Any LIFE TRAUMA? (check ALL that apply)",
      "survey_id": "294219921",
      "question_type": "multiple_choice",
      "created_at": "2020-11-21T14:10:15.796Z",
      "updated_at": "2020-11-21T14:10:15.796Z",
      noneId: 3698124025,
      containsOther: true,
      otherOPtionId: 3622508171,
      otherOptionText: 'Other (please specify):',
      options: [
        {
          text: 'accident / injury',
          id: 3622508157
        },
        {
          text: 'currently no transportion options',
          id: 3622508172
        },
        {
          text: `parent in jail or prison`,
          id: 3815475772
        },
        {
          text: 'crime / violence',
          id: 3622508156
        },
        {
          text: 'loss of a child',
          id: 3622508159
        },
        {
          text: 'orphanage',
          id: 3622508169
        },
        {
          text: 'natural disaster / fire',
          id: 3622508154
        },
        {
          text: 'loss of a loved one',
          id: 3622508160
        },
        {
          text: 'foster care',
          id: 3622508170
        },
        {
          text: 'war / terrorism',
          id: 3622508155
        },
        {
          text: 'divorce',
          id: 3622508161
        },
        {
          text: 'physical abuse',
          id: 3622508165
        },
        {
          text: 'life-altering illness',
          id: 3622508158
        },
        {
          text: 'chronic loneliness',
          id: 3622508162
        },
        {
          text: 'emotional abuse',
          id: 3622508166
        },
        {
          text: `currently wheelchair aided`,
          id: 3815475770
        },
        {
          text: 'poverty',
          id: 3622508163
        },
        {
          text: 'sexual abuse',
          id: 3622508167
        },
        {
          text: `currently bed confined`,
          id: 3815475771
        },
        {
          text: 'not enough food',
          id: 3622508164
        },
        {
          text: 'none',
          id: 3698124025
        },
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 120,
    "fields": {
      "question_id": "553784163",
      "question_text": "Did substance misuse LEAD TO or RESULT FROM:",
      "survey_id": "294219921",
      "question_type": "matrix",
      "created_at": "2020-11-21T14:10:16.290Z",
      "updated_at": "2020-11-21T14:10:16.291Z",
      choices: [
        {
          choice_text: `unemployment`,
          row_id: 3652345098
        },
        {
          choice_text: `homelessness`,
          row_id: 3652345096
        },
        {
          choice_text: `jail or detention`,
          row_id: 3652345097
        },
        {
          choice_text: "life trauma",
          row_id: 3652538182
        }
      ],
      rowText: [
        {
          text: `no`,
          id: 3652345099
        },
        {
          text: `substance misuse led to`,
          id: 3652345100
        },
        {
          text: `substance misuse resulted from`,
          id: 3652345101
        },
        {
          text: `substance misuse led to AND got worse from`,
          id: 3652345102
        }
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 121,
    "fields": {
      "question_id": "549097425",
      "question_text": "Any COMPULSIONS other than drugs or alcohol? (check ALL that apply)",
      "survey_id": "294219921",
      "question_type": "multiple_choice",
      "created_at": "2020-11-21T14:10:16.740Z",
      "updated_at": "2020-11-21T14:10:16.740Z",
      rowSize: 3,
      noneId: 3622508180,
      containsOther: true,
      otherOPtionId: 3622508188,
      otherOptionText: 'Other:',
      options: [
        {
          text: 'OCD (obsessive-compulsive)',
          id: 3622508175
        },
        {
          text: `sex`,
          id: 3622508178
        },
        {
          text: `video games`,
          id: 3622508181
        },
        {
          text: `eating`,
          id: 3622508176
        },
        {
          text: `exercise`,
          id: 3622508183
        },
        {
          text: `social media`,
          id: 3622508182
        },
        {
          text: `gambling`,
          id: 3622508177
        },
        {
          text: `spending`,
          id: 3622508179
        },
        {
          text: `none`,
          id: 3622508180
        },
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 122,
    "fields": {
      "question_id": "549097426",
      "question_text": "Any BRAIN ILLNESSES / INJURY? (check ALL that apply)",
      "survey_id": "294219921",
      "question_type": "multiple_choice",
      "created_at": "2020-11-21T14:10:17.028Z",
      "updated_at": "2020-11-21T14:10:17.028Z",
      rowSize: 3,
      noneId: 3622508204,
      containsOther: true,
      otherOPtionId: 3622508213,
      otherOptionText: 'Other:',
      options:
        [
          {
            text: `anxiety or panic`,
            id: 3622508194
          },
          {
            text: `bipolar`,
            id: 3622508206
          },
          {
            text: `autism spectrum`,
            id: 3622508201
          },
          {
            text: `depression`,
            id: 3622508195
          },
          {
            text: `schizo-affective`,
            id: 3622508198
          },
          {
            text: `PTSD`,
            id: 3622508202
          },
          {
            text: `ADHD (includes ADD)`,
            id: 3622508196
          },
          {
            text: `schizophrenia`,
            id: 3622508199
          },
          {
            text: `TBI (traumatic brain injury)`,
            id: 3622508203
          },
          {
            text: `oppositional or conduct`,
            id: 3622508205
          },
          {
            text: `personality`,
            id: 3622508200
          },
          {
            text: `thinking or memory difficulty`,
            id: 3622508197
          },
          {hide: true},
          {
            text: `dissociative`,
            id: 3622508207
          },
          {
            text: `none`,
            id: 3622508204
          }
        ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 125,
    "fields": {
      "question_id": "549097441",
      "question_text": "How much do you agree with the following statements?",
      "survey_id": "294219921",
      "question_type": "matrix",
      "created_at": "2020-11-21T14:10:17.679Z",
      "updated_at": "2020-11-21T14:10:17.679Z",
      choices: [
        {
          choice_text: `I am content`,
          row_id: 3622508459
        },
        {
          choice_text: `I often don't think things through before I speak`,
          row_id: 3622508460
        },
        {
          choice_text: `I would like to skydive, or already have`,
          row_id: 3622508461
        },
        {
          choice_text: `I am happy`,
          row_id: 3622508462
        },
        {
          choice_text: `I often involve myself in situations that I later regret`,
          row_id: 3622508463
        },
        {
          choice_text: `I enjoy new and exciting experiences even if they are unconventional`,
          row_id: 3622508464
        },
        {
          choice_text: `I have faith that my future holds great promise`,
          row_id: 3622508465
        },
        {
          choice_text: `It's frightening to feel dizzy or faint`,
          row_id: 3622508466
        },
        {
          choice_text: `I like doing things that frighten me a little`,
          row_id: 3622508467
        },
        {
          choice_text: `It frightens me to feel my heart beat change`,
          row_id: 3622508468
        },
        {
          choice_text: `I usually act without stopping to think`,
          row_id: 3622508469
        },
        {
          choice_text: `I would like to learn to drive a motorcycle, or already have`,
          row_id: 3622508482
        },
      ],
      rowText: [
        {
          text: `Strongly Disagree`,
          id: 3622508470
        },
        {
          text: `Disagree`,
          id: 3622508471
        },
        {
          text: `Agree`,
          id: 3622508472
        },
        {
          text: `Strongly Agree`,
          id: 3622508473
        }
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 126,
    "fields": {
      "question_id": "549097442",
      "question_text": "How much do you agree with the following statements?",
      "survey_id": "294219921",
      "question_type": "matrix",
      "created_at": "2020-11-21T14:10:19.017Z",
      "updated_at": "2020-11-21T14:10:19.017Z",
      choices: [
        {
          choice_text: `I feel proud of my accomplishments`,
          row_id: 3622508495
        },
        {
          choice_text: `I get scared when I'm too nervous`,
          row_id: 3622508496
        },
        {
          choice_text: `Generally, I am an impulsive person`,
          row_id: 3622508497
        },
        {
          choice_text: `I am interested in experience for its own sake even if it is illegal`,
          row_id: 3622508498
        },
        {
          choice_text: `I feel that I'm a failure`,
          row_id: 3622508499
        },
        {
          choice_text: `I get scared when I experience unusual body sensations`,
          row_id: 3622508500
        },
        {
          choice_text: `I would enjoy hiking long distances in wild and uninhabited territory`,
          row_id: 3622508501
        },
        {
          choice_text: `I feel pleasant`,
          row_id: 3622508502
        },
        {
          choice_text: `It scares me when I'm unable to focus on a task`,
          row_id: 3622508503
        },
        {
          choice_text: `I feel I have to be manipulative to get what I want`,
          row_id: 3622508504
        },
        {
          choice_text: `I am very enthusiastic about my future`,
          row_id: 3622508505
        },
      ],
      rowText: [
        {
          text: `Strongly Disagree`,
          id: 3622508483
        },
        {
          text: `Disagree`,
          id: 3622508484
        },
        {
          text: `Agree`,
          id: 3622508485
        },
        {
          text: `Strongly Agree`,
          id: 3622508486
        }
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 126.5,
    "fields": {
      "question_id": "580243889",
      "question_text": "Check any SUBSTANCES you have used MORE than a few times",
      "survey_id": "294219921",
      "question_type": "multiple_choice",
      "created_at": "2020-12-11T07:40:42.139Z",
      "updated_at": "2020-12-11T07:40:42.139Z",
      containsOther: true,
      otherOptionText: `Other (please list):`,
      otherOPtionId: '3821196643',
      noneId: 3821196632,
      options: [
        {text: "smoking tobacco", id: "3821196634"},
        {text: "Rx benzos (anxiety)", id: "3821196623"},
        {text: "heroin", id: "3821196628"},
        {text: "smokeless tobacco (chew / snuff)", id: 3983757573},
        {text: "Rx ADHD (stimulants)", id: "3821196624"},
        {text: "ecstasy / MDMA", id: "3821196633"},
        {text: "vaping", id: "3821196620"},
        {text: "Rx barbiturates (sedatives)", id: "3821196625"},
        {text: "crystal meth", id: "3821196629"},
        {text: "alcohol", id: "3821196621"},
        {text: "marijuana", id: "3821196626"},
        {text: "hallucinogens", id: "3821196630"},
        {text: "Rx opiates (pain)", id: "3821196622"},
        {text: "cocaine / crack", id: "3821196627"},
        {text: "inhalants (non-medical)", id: "3821196631"},
        {hide : true},
        {hide : true},
        {text: "none", id: "3821196632"},
      ]	
    }	
  },
  {
    "model": "comprisapp.questions",
    "pk": 128,
    "fields": {
      "question_id": "549097447",
      "question_text": "How much nicotine (# of cigarettes OR mg/ml vaped) on a typical day (whichever is MORE)?",
      "survey_id": "294219921",
      "question_type": "matrix",
      "created_at": "2020-11-21T14:10:29.289Z",
      "updated_at": "2020-11-21T14:10:29.289Z",
      // containsOther: true,
      otherOPtionId: 3815523960,
      otherOptionText: 'Other (please specify)',
      choices: [
        {
          choice_text: `in the past 30 days`,
          row_id: 3622508544
        },
        {
          choice_text: `1-3 months ago`,
          row_id: 3622508545
        },
        {
          choice_text: `4-11 months ago`,
          row_id: 3622508546
        },
        {
          choice_text: `1-4 years ago`,
          row_id: 3622508547
        },
        {
          choice_text: `5+ years ago`,
          row_id: 3653737632
        },
      ],
      rowText: [
        {
          text: `none`,
          id: 3622508548
        },
        {
          text: `1-4 cigs OR 1mg/ml(0.1%)`,
          id: 3815555159
        },
        {
          text: `5-10 cigs OR 2mg/ml(0.2%)`,
          id: 3622508549
        },
        {
          text: `11-20 cigs OR 3-4mg/ml(0.3 - 0.4%)`,
          id: 3622508550
        },
        {
          text: `21-30 cigs OR  5-6mg/ml(0.5 - 0.6%)`,
          id: 3622508551
        },
        {
          text: `31+ cigs OR 7+mg/ml (0.7-5.0%)`,
          id: 3622508552
        },
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 129,
    "fields": {
      "question_id": "549097415",
      "question_text": "HOW SOON after waking?",
      "survey_id": "294219921",
      "question_type": "matrix",
      "created_at": "2020-11-21T14:10:29.974Z",
      "updated_at": "2020-11-21T14:10:29.974Z",
      choices: [
        {
          choice_text: `in the past 30 days`,
          row_id: 3622508003
        },
        {
          choice_text: `1-3 months ago`,
          row_id: 3622507997
        },
        {
          choice_text: `4-11 months ago`,
          row_id: 3622507998
        },
        {
          choice_text: `1-4 years ago`,
          row_id: 3622507996
        },
        {
          choice_text: `5+ years ago`,
          row_id: 3653739734
        },
      ],
      rowText: [
        {
          text: `within 5 minutes`,
          id: 3622508004
        },
        {
          text: `6-30 minutes`,
          id: 3622508000
        },
        {
          text: `31-60 minutes`,
          id: 3622508001
        },
        {
          text: `after 60 minutes`,
          id: 3622508002
        },
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 132,
    "fields": {
      "question_id": "555274672",
      "question_text": "During the past 30 DAYS, how often did you use",
      "survey_id": "294219921",
      "question_type": "matrix",
      "created_at": "2020-11-21T14:10:26.758Z",
      "updated_at": "2020-11-21T14:10:26.758Z",
      containsOther: true,
      otherOPtionId: 3661958003,
      otherOptionText: 'Other (please list):',
      choices: [
        {
          choice_text: `tobacco`,
          row_id: 3661958021
        },
        {
          choice_text: `vaping`,
          row_id: 3668010003
        },
        {
          choice_text: `alcohol`,
          row_id: 3661958014
        },
        {
          choice_text: `Rx opiates (pain)`,
          row_id: 3661958024
        },
        {
          choice_text: `Rx benzos (anxiety)`,
          row_id: 3661958025
        },
        {
          choice_text: `Rx barbiturates (sedatives)`,
          row_id: 3661958027
        },
        {
          choice_text: `Rx ADHD (stimulants)`,
          row_id: 3661958026
        },
        {
          choice_text: `marijuana`,
          row_id: 3661958022
        },
        {
          choice_text: `cocaine / crack`,
          row_id: 3661958015
        },
        {
          choice_text: `heroin`,
          row_id: 3661958016
        },
        {
          choice_text: `crystal meth`,
          row_id: 3661958018
        },
        {
          choice_text: `ecstasy /MDMA`,
          row_id: 3661958017
        },
        {
          choice_text: `hallucinogens`,
          row_id: 3661958019
        },
        {
          choice_text: `inhalants`,
          row_id: 3661958020
        },
        {
          choice_text: `other`,
          row_id: 3661956317
        }
      ],
      rowText: [
        {
          text: `never`,
          id: 3661958035
        },
        {
          text: `less than  weekly`,
          id: 3662330539
        },
        {
          text: `weekly`,
          id: 3661958033
        },
        {
          text: `2-3x per week`,
          id: 3661958034
        },
        {
          text: `daily or most days`,
          id: 3661958036
        },
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 132.15,
    "fields": {
      "question_id": "549097492",
      "question_text": "2-3 MONTHS AGO, how often were you using:",
      "survey_id": 294219921,
      "question_type": "matrix",
      "created_at": "2020-12-19T17:06:53.780Z",
      "updated_at": "2020-12-19T17:06:53.780Z",
      containsOther: true,
      otherOptionText: 'Other',
      otherOPtionId: 3961554533,
      choices: [
        {choice_text: "tobacco", row_id: 3961554558},
        {choice_text: "vaping", row_id: 3961554550},
        {choice_text: "alcohol", row_id: 3961554551},
        {choice_text: "Rx opiates (pain)", row_id: 3961554561},
        {choice_text: "Rx benzos (anxiety)", row_id: 3961554564},
        {choice_text: "Rx ADHD (stimulants)", row_id: 3961554562},
        {choice_text: "Rx barbiturates (sedatives)", row_id: 3961554563},
        {choice_text: "marijuana", row_id: 3961554559},
        {choice_text: "cocaine / crack", row_id: 3961554552},
        {choice_text: "heroin", row_id: 3961554553},
        {choice_text: "ecstasy /MDMA", row_id: 3961554554},
        {choice_text: "crystal meth", row_id: 3961554555},
        {choice_text: "hallucinogens", row_id: 3961554556},
        {choice_text: "inhalants", row_id: 3961554557},
        {choice_text: "other", row_id: 3961554560}
      ],
      rowText: [
        {text: "never", id: 3961554547},
        {text: "monthly or less", id: 3961554544},
        {text: "2-3x per month", id: 3961554546},
        {text: "weekly", id: 3961554549},
        {text: "2-3x per week", id: 3961554548},
        {text: "daily or most days", id: 3961554545}
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 132.20,
    "fields": {
      "question_id": "555274472",
      "question_text": "4-12 MONTHS AGO, how often were you using:",
      "survey_id": "294219921",
      "question_type": "matrix",
      "created_at": "2020-11-21T14:10:24.214Z",
      "updated_at": "2020-11-21T14:10:24.214Z",
      containsOther: true,
      otherOPtionId: 3661956297,
      otherOptionText: 'other',
      choices: [
        {
          choice_text: `tobacco`,
          row_id: 3661956315
        },
        {
          choice_text: `vaping`,
          row_id: 3668007527
        },
        {
          choice_text: `alcohol`,
          row_id: 3661956308
        },
        {
          choice_text: `Rx opiates (pain)`,
          row_id: 3661956318
        },
        {
          choice_text: `Rx benzos (anxiety)`,
          row_id: 3661956319
        },
        {
          choice_text: `Rx ADHD (stimulants)`,
          row_id: 3661956320
        },
        {
          choice_text: `Rx barbiturates (sedatives)`,
          row_id: 3661956321
        },
        {
          choice_text: `marijuana`,
          row_id: 3661956316
        },
        {
          choice_text: `cocaine / crack`,
          row_id: 3661956309
        },
        {
          choice_text: `heroin`,
          row_id: 3661956310
        },
        {
          choice_text: `ecstasy /MDMA`,
          row_id: 3661956311
        },
        {
          choice_text: `crystal meth`,
          row_id: 3661956312
        },
        {
          choice_text: `hallucinogens`,
          row_id: 3661956313
        },
        {
          choice_text: `inhalants`,
          row_id: 3661956314
        },
        {
          choice_text: `other`,
          row_id: 3661956317
        }
      ],
      rowText: [
        {
          text: `never`,
          id: 3661956329
        },
        {
          text: `monthly   or less`,
          id: 3662322005
        },
        {
          text: `2-3x   per month`,
          id: 3661956327
        },
        {
          text: `weekly`,
          id: 3661956328
        },
        {
          text: `2-3x    per week`,
          id: 3661956330
        },
        {
          text: `daily or    most days`,
          id: 3661956326
        },
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 133,
    "fields": {
      "question_id": "549097387",
      "question_text": "Any BIOLOGICAL RELATIVES with a substance problem other than tobacco/nicotine? (Check all that apply)",
      "survey_id": "294219921",
      "question_type": "multiple_choice",
      "created_at": "2020-11-21T14:10:30.688Z",
      "updated_at": "2020-11-21T14:10:30.688Z",
      options: [
        { text: "father", id: "3622507682" },
        { text: "mother", id: "3622507683" },
        { text: "a sibling", id: "3622507684" },
        { text: "2 siblings", id: "3622507685" },
        { text: "3+ siblings", id: "3622507686" },
        { text: "a child", id: "3622507687" },
        { text: "2 children", id: "3622507688" },
        { text: "3+ children", id: "3622507689" },
        { text: "a grandparent", id: "3622507690" },
        { text: "2 grandparents", id: "3622507691" },
        { text: "3+ grandparents", id: "3622507692" },
        { text: "an aunt or uncle", id: "3622507693" },
        { text: "2 aunts or uncles", id: "3622507694" },
        { text: "3+ aunts or uncles", id: "3622507695" },
        { text: "a cousin, niece or nephew", id: "3622507696" },
        { text: "2 cousins, nieces or nephews", id: "3622507697" },
        { text: "3+ cousins, nieces or nephews", id: "3622507698" },
        { text: "none or don't know", id: "3622507699" }
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 133.5,
    "fields": {
      "question_id": "587379847",
      "question_text": "Any BIOLOGICAL RELATIVES died from a substance problem other than tobacco/nicotine? (check ALL that apply)",
      "survey_id": "294219921",
      "question_type": "multiple_choice",
      "created_at": "2020-12-19T17:06:54.520Z",
      "updated_at": "2020-12-19T17:06:54.520Z"
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 134,
    "fields": {
      "question_id": "556181825",
      "question_text": "How often did you have 5 or more alcohol-containing drinks within a few hours?",
      "survey_id": "294219921",
      "question_type": "matrix",
      "created_at": "2020-11-21T14:10:29.974Z",
      "updated_at": "2020-11-21T14:10:29.974Z",
      choices: [
        {
          choice_text: `in the past 30 days`,
          row_id: 100001
        },
        {
          choice_text: `1-3 months ago`,
          row_id: 100002
        },
        {
          choice_text: `4-11 months ago`,
          row_id: 100003
        },
        {
          choice_text: `1-4 years ago`,
          row_id: 100004
        },
        {
          choice_text: `5+ years ago`,
          row_id: 100005
        },
      ],
      rowText: [
        {
          text: `never`,
          id: 4622508820
        },
        {
          text: `monthly or less`,
          id: 4622508821
        },
        {
          text: `2-3 times/month`,
          id: 4622508822
        },
        {
          text: `weekly`,
          id: 4622508823
        },
        {
          text: `2-3 times/week`,
          id: 4622508824
        },
        {
          text: `daily or most days`,
          id: 4622508825
        },
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 137.1,
    "fields": {
      "question_id": "549097340",
      "question_text": "Check if you ever had the following SYMPTOMS (other than from tobacco/nicotine):",
      "survey_id": "294219921",
      "question_type": "multiple_choice",
      noneId: 3622509768,
      rowSize: 1,
      options: [
        {
          text: `used too much: used more or longer than intended at times`,
          id: 3622508668
        },
        {
          text: `used too often: spent a lot of time using, affected, or hungover`,
          id: 3622508768
        },
        {
          text: `couldn’t stop: tried and failed more than once to cut down or stop`,
          id: 3622508868
        },
        {
          text: `strong cravings: experienced strong desire or craving to use`,
          id: 3622508968
        },
        {
          text: `got in the way: using interfered with taking care of home, family, job or school`,
          id: 3622509068
        },
        {
          text: `worried others: kept using despite concerns of family or friends`,
          id: 3622509168
        },
        {
          text: `used instead of: using took the place of other valued interests or pleasures`,
          id: 3622509268
        },
        {
          text: `risky behavior: did risky things more than once while using, like swimming, using machinery, walking in unsafe areas or having unsafe sex`,
          id: 3622509368
        },
        {
          text: `feeling bad: kept using while feeling depressed or anxious, having memory blackouts or added health risks`,
          id: 3622509468
        },
        {
          text: `needed more: needed to use more to get the same or less effects`,
          id: 3622509568
        },
        {
          text: `withdrawal: knew if I stopped, I couldn’t sleep, would felt shaky, irritable, anxious, depressed, restless, sick, sweaty, or see/hear things`,
          id: 3622509668
        },
        {
          text: `none of the above`,
          id: 3622509768
        },
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 138,
    "fields": {
      "question_id": "553973451",
      "question_text": "<b>When</b> were you having these SYMPTOMS ? (include ALL time periods)",
      "survey_id": "294219921",
      "question_type": "matrix",
      "created_at": "2020-11-21T14:10:32.041Z",
      "updated_at": "2020-11-21T14:10:32.041Z",
      // noneText: `None of the above`,
      // noneId: 3844672728,
      // noneChoiceId: 3653547374,
      choices: [
        {
          choice_text: `used too much`,
          row_id: 3653547362
        },
        {
          choice_text: `used too often`,
          row_id: 3653547364
        },
        {
          choice_text: `couldn’t stop`,
          row_id: 3653547363
        },
        {
          choice_text: `strong cravings`,
          row_id: 3653547365
        },
        {
          choice_text: `got in the way`,
          row_id: 3653547366
        },
        {
          choice_text: `worried others`,
          row_id: 3653547367
        },
        {
          choice_text: `used instead of`,
          row_id: 3653547368
        },
        {
          choice_text: `risky behavior`,
          row_id: 3653547369
        },
        {
          choice_text: `feeling bad`,
          row_id: 3653547370
        },
        {
          choice_text: `needed more`,
          row_id: 3653547371
        },
        {
          choice_text: `withdrawal`,
          row_id: 3653547372
        },
        {
          choice_text: `None of the above`,
          row_id: 3844672728
        },
      ],
      rowText: [
        {
          text: `past 30 days`,
          id: 3653547374
        },
        {
          text: `1-3 months ago`,
          id: 3653547375
        },
        {
          text: `4-11 months ago`,
          id: 3653547376
        },
        {
          text: `1-4 years ago`,
          id: 3653547377
        },
        {
          text: `5+ years ago`,
          id: 3653696915
        },
      ]
    }
  },
  // add new question
  {
    "model": "comprisapp.questions",
    "pk": 146,
    "fields": {
      "question_id": "553981634",
      "question_text": "Which SUBSTANCES led to the symptoms, and when? Check EVERY substance and time period with symptoms. Ignore the rest.",
      "survey_id": "294219921",
      "question_type": "matrix",
      containsOther: true,
      otherOPtionId: 365300006,
      "otherOptionText": `Other (please list):`,
      choices: [
        {
          choice_text: `tobacco`,
          row_id: 3653596771
        },
        {
          choice_text: `vaping`,
          row_id: 3653596772
        },
        {
          choice_text: `alcohol`,
          row_id: 3653596773
        },
        {
          choice_text: `opiates (pain)`,
          row_id: 3653596774
        },
        {
          choice_text: `benzos (anti-anxiety)`,
          row_id: 3653596775
        },
        {
          choice_text: `barbiturates (sedatives)`,
          row_id: 3653596777
        },
        {
          choice_text: `ADHD (stimulants)`,
          row_id: 3653596776
        },
        {
          choice_text: `marijuana`,
          row_id: 3653596778
        },
        {
          choice_text: `cocaine / crack`,
          row_id: 3653596779
        },
        {
          choice_text: `heroin`,
          row_id: 3653596780
        },
        {
          choice_text: `crystal meth`,
          row_id: 3653596782
        },
        {
          choice_text: `ecstasy /MDMA`,
          row_id: 3653596781
        },
        {
          choice_text: `hallucinogens`,
          row_id: 3653596783
        },
        {
          choice_text: `inhalants`,
          row_id: 3653596784
        },
        {
          choice_text: `other`,
          row_id: 3653596785
        }
      ],
      rowText: [
        {
          text: `past 30 days`,
          id: 3653693370
        },
        {
          text: `1-3 months ago`,
          id: 3653693371
        },
        {
          text: `4-11 months ago`,
          id: 3653693372
        },
        {
          text: `1-4 years ago`,
          id: 3653693373
        },
        {
          text: `5+ years ago`,
          id: 3653693374
        },
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 146.8,
    "fields": {
      "question_id": "554010038",
      "question_text": "Likely have, or at one time had, the following substance problem",
      "survey_id": "294219921",
      "question_type": "matrix",
      "created_at": "2020-11-21T14:10:40.991Z",
      "updated_at": "2020-11-21T14:10:40.991Z",
      containsOther: true,
      otherOPtionId: 3653767293,
      otherOptionText: `Other`,
      choices: [
        {
          choice_text: `tobacco`,
          row_id: 3653767313
        },
        {
          choice_text: `vaping`,
          row_id: 3668018437
        },
        {
          choice_text: `alcohol`,
          row_id: 3653771371
        },
        {
          choice_text: `opiates (pain)`,
          row_id: 3653767304
        },
        {
          choice_text: `benzos (anti-anxiety)`,
          row_id: 3653767312
        },
        {
          choice_text: `ADHD (stimulants)`,
          row_id: 3653767314
        },
        {
          choice_text: `barbiturates (sedatives)`,
          row_id: 3653767315
        },
        {
          choice_text: `marijuana`,
          row_id: 3653767316
        },
        {
          choice_text: `cocaine / crack`,
          row_id: 3653767305
        },
        {
          choice_text: `heroin`,
          row_id: 3653767306
        },
        {
          choice_text: `ecstasy /MDMA`,
          row_id: 3653767307
        },
        {
          choice_text: `crystal meth`,
          row_id: 3653767308
        },
        {
          choice_text: `hallucinogens`,
          row_id: 3653767309
        },
        {
          choice_text: `inhalants`,
          row_id: 3653767310
        },
        {
          choice_text: `other`,
          row_id: 3653767311
        }
      ],
      rowText: [
        {
          text: `no`,
          id: 3653767317
        },
        {
          text: `unsure`,
          id: 3653767318
        },
        {
          text: `yes - mild`,
          id: 3653767319
        },
        {
          text: `yes - moderate`,
          id: 3662621737
        },
        {
          text: `yes - severe`,
          id: 3662621738
        }
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 147,
    "fields": {
      "question_id": "555422217",
      "question_text": "AGE FIRST and last used each substance, and WHEN it may have become a <b>problem</b> or <b>dependence</b>?",
      "survey_id": "294219921",
      "question_type": "matrix",
      "created_at": "2020-11-21T14:10:43.100Z",
      "updated_at": "2020-11-21T14:10:43.100Z",
      // containsOther: true,
      // otherOPtionId: 3662615686,
      // "otherOptionText": "Other (please list):",
      choices: [
        {
          choice_text: `tobacco`,
          row_id: 3662615706
        },
        {
          choice_text: `vaping`,
          row_id: 3668022115
        },
        {
          choice_text: `alcohol`,
          row_id: 3662615697
        },
        {
          choice_text: `opiates (pain)`,
          row_id: 3662615698
        },
        {
          choice_text: `benzos (anti-anxiety)`,
          row_id: 3662615705
        },
        {
          choice_text: `barbiturates (sedatives)`,
          row_id: 3662615707
        },
        {
          choice_text: `ADHD (stimulants)`,
          row_id: 3662615708
        },
        {
          choice_text: `marijuana`,
          row_id: 3662615709
        },
        {
          choice_text: `cocaine / crack`,
          row_id: 3662615699
        },
        {
          choice_text: `heroin`,
          row_id: 3662615700
        },
        {
          choice_text: `crystal meth`,
          row_id: 3662615701
        },
        {
          choice_text: `ecstasy /MDMA`,
          row_id: 3662615702
        },
        {
          choice_text: `hallucinogens`,
          row_id: 3662615703
        },
        {
          choice_text: `inhalants`,
          row_id: 3662615704
        },
        {
          choice_text: `other`,
          row_id: 3662615710
        }
      ],
      rowText: [
        {
          text: `age first used`,
          id: 3662615711
        },
        {
          text: `age last used`,
          id: 3662615713
        },
        {
          text: `age when likely became a problem or dependence`,
          id: 3662615712
        },
        {
          text: 'never a problem',
          id: 3662615714
        }
      ]
    }
  },
  {
    "model": "comprisapp.choices",
    "pk": 147.4,
    "fields": {
      "question_id": 549097411,
      "question_text": `Check which one (A or B) you're MORE OFTEN like:`,
      "survey_id": 294219921,
      "created_at": "2020-12-19T17:06:53.146Z",
      "updated_at": "2020-12-19T17:06:53.146Z",
      question_type: 'multiple_questions',
      questions: [
        [
          {
            "questionText": "<span>A.</span>I get my energy from being around others, think outloud, and prefer lots of people in my life.",
            "choices": [
              {
                "text": "agree",
                "id": 3622508801,
                "rowid": 3622508701
              },
              {
                "text": "strongly agree",
                "id": 3622508802,
                "rowid": 3622508701
              }
            ]
          },
          {
            "questionText": "<span>B.</span> I enjoy quiet time with myself or with a small group of close friends, and am a good listener.",
            "choices": [
              {
                "text": "agree",
                "id": 3622508809,
                "rowid": 3622508705
              },
              {
                "text": "strongly agree",
                "id": 3622508810,
                "rowid": 3622508705
              }
            ]
          }
        ],
        [
          {
            "questionText": "<span>A.</span> I'm realistic and down-to-earth and like practical solutions based on what's worked in the past",
            "choices": [
              {
                "text": "agree",
                "id": 3622508803,
                "rowid": 3622508702
              },
              {
                "text": "strongly agree",
                "id": 3622508804,
                "rowid": 3622508702
              }
            ]
          },
          {
            "questionText": "<span>B.</span> I like thinking about new ideas and possibilities and can usually see how it all fits together.",
            "choices": [
              {
                "text": "agree",
                "id": 3622508811,
                "rowid": 3622508706
              },
              {
                "text": "strongly agree",
                "id": 3622508812,
                "rowid": 3622508706
              }
            ]
          }
        ],
        [
          {
            "questionText": "<span>A.</span> I’m logical. I tell the truth, even if it might hurt someone's feelings.",
            "choices": [
              {
                "text": "agree",
                "id": 3622508805,
                "rowid": 3622508703
              },
              {
                "text": "strongly agree",
                "id": 3622508806,
                "rowid": 3622508703
              }
            ]
          },
          {
            "questionText": "<span>B.</span> I'm sensitive to how others feel, tend to take things personally and avoid arguments or conflicts.",
            "choices": [
              {
                "text": "agree",
                "id": 3622508813,
                "rowid": 3622508707
              },
              {
                "text": "strongly agree",
                "id": 3622508814,
                "rowid": 3622508707
              }
            ]
          }
        ],
        [
          {
            "questionText": "<span>A.</span> I like things decided and feel best when I've got a plan and stick with it.",
            "choices": [
              {
                "text": "agree",
                "id": 3622508807,
                "rowid": 3622508704
              },
              {
                "text": "strongly agree",
                "id": 3622508808,
                "rowid": 3622508704
              }
            ]
          },
          {
            "questionText": "<span>B.</span> I like to keep my options open and am comfortable changing plans when necessary.",
            "choices": [
              {
                "text": "agree",
                "id": 3622508815,
                "rowid": 3622508708
              },
              {
                "text": "strongly agree",
                "id": 3622508816,
                "rowid": 3622508708
              }
            ]
          }
        ]
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 148,
    "fields": {
      "question_id": "549097448",
      "question_text": "Any HEALTH problems, or currently pregnant? (check ALL that apply)",
      "survey_id": "294219921",
      "question_type": "multiple_choice",
      "created_at": "2020-11-21T14:10:44.353Z",
      "updated_at": "2020-11-21T14:10:44.353Z",
      noneId: 3654276419,
      containsOther: true,
      otherOPtionId: 3622508578,
      otherOptionText: `Other (please list):`,
      options: [
        {
          text: `arthritis`,
          id: 3622508555
        },
        {
          text: `gastritis`,
          id: 3622508563
        },
        {
          text: `liver disease`,
          id: 3622508570
        },
        {
          text: `asthma`,
          id: 3622508556
        },
        {
          text: `GI bleed`,
          id: 3622508564
        },
        {
          text: `memory loss or dementia`,
          id: 3815580710
        },
        {
          text: `cancer`,
          id: 3622508557
        },
        {
          text: `heart disease`,
          id: 3622508589
        },
        {
          text: `obesity`,
          id: 3622508571
        },
        {
          text: `chronic pain`,
          id: 3622508558
        },
        {
          text: `hep B`,
          id: 3622508565
        },
        {
          text: `osteomyelitis`,
          id: 3622508572
        },
        {
          text: `COPD`,
          id: 3622508559
        },
        {
          text: `hep C`,
          id: 3622508566
        },
        {
          text: `pancreatitis`,
          id: 3622508573
        },
        {
          text: `Covid-19`,
          id: 3815580709
        },
        {
          text: `high B/P`,
          id: 3622508568
        },
        {
          text: `pregnant (currently)`,
          id: 3815580711
        },
        {
          text: `diabetes`,
          id: 3622508560
        },
  
        {
          text: `HIV`,
          id: 3622508567
        },
        {
          text: `seizures (alcohol-related)`,
          id: 3622508574
        },
        {
          text: `DTs (withdrawal delusions)`,
          id: 3622508561
        },
        {
          text: `IV drug misuse`,
          id: 3622508580
        },
        {
          text: `STDs`,
          id: 3622508582
        },
        {
          text: `endocarditis`,
          id: 3622508562
        },
        {
          text: `IV drug infections`,
          id: 3622508581
        },
        {
          text: `stroke`,
          id: 3622508575
        },
        {
          hide : true
        },
        { 
          text: `kidney disease`,
          id: 3622508569
        },
        {
          text: `none`,
          id: 3654276419
        }
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 148.5,
    "fields": {
      "question_id": "586886313",
      "question_text": "Any negative EVENTS from your substance use? (check ALL that apply)",
      "survey_id": "294219921",
      "question_type": "multiple_choice",
      "created_at": "2020-12-19T17:06:55.839Z",
      "updated_at": "2020-12-19T17:06:55.839Z",
      noneId: 3866486056,
      // containsOther: true,
      // otherOPtionId: 386   6397233,
      "otherOptionText": "Other (please list):",
      options: [
        { text: "relationships", id: "3866397261" },
        { text: "infections", id: "3866397254" },
        { text: "incarcerations", id: "3866397258" },
        { text: "injuries / accidents", id: "3866397250" },
        { text: "hospital stays", id: "3866397255" },
        { text: "job losses", id: "3622507398" },
        { text: "memory blackouts", id: "3866397251" },
        { text: "detoxes", id: "3866397256" },
        { text: "suicide attempts", id: "3866397259" },
        { text: "overdoses", id: "3866397252" },
        { text: "arrests", id: "3866397257" },
        { text: "deaths", id: "3866397263" },
        { text: "Narcan saves", id: "3866397253" },
        { text: "DUIs", id: "3866397260" },
        { text: "none", id: "3866486056" },
        { text: "ER visits", id: "3866397262" },
        { text: "other", id: "3866397233" },
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 149,
    "fields": {
      "question_id": "549097360",
      "question_text": "How many substance-related NEGATIVE EVENTS happened?",
      "survey_id": "294219921",
      "question_type": "matrix",
      "created_at": "2020-11-21T14:10:45.864Z",
      "updated_at": "2020-11-21T14:10:45.864Z",
      // "otherOptionText": "Other (please list):",
      choices: [
        {
          choice_text: `relationships`,
          row_id: 3622507473
        },
        {
          choice_text: `injuries or accidents`,
          row_id: 3622507490
        },
        {
          choice_text: `memory blackouts`,
          row_id: 3622507491
        },
        {
          choice_text: `overdoses`,
          row_id: 3622507474
        },
        {
          choice_text: `Narcan saves`,
          row_id: 3622507509
        },
        {
          choice_text: `ER visits`,
          row_id: 3622507475
        },
        {
          choice_text: `infections`,
          row_id: 3622507514
        },
        {
          choice_text: `hospital stays`,
          row_id: 3622507492
        },
        {
          choice_text: `detoxes`,
          row_id: 3622507515
        },
        {
          choice_text: `arrests`,
          row_id: 3622507476
        },
        {
          choice_text: `DUIs`,
          row_id: 3622507477
        },
        {
          choice_text: `job losses`,
          row_id: 3622507478
        },
        {
          choice_text: `incarcerations`,
          row_id: 3622507479
        },
        {
          choice_text: `suicide attempts`,
          row_id: 3622507480
        },
        {
          choice_text: `deaths`,
          row_id: 3622507493
        },
        {
          choice_text: `other`,
          row_id: 3622508850
        },
      ],
      rowText: [
        {
          text: '1',
          id: 3622507482
        },
        {
          text: '2',
          id: 3622507483
        },
        {
          text: '3',
          id: 3622507484
        },
        {
          text: '4-5',
          id: 3622507485
        },
        {
          text: '6-9',
          id: 3622507486
        },
        {
          text: `10 or more`,
          id: 3622507487
        }
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 154,
    "fields": {
      "question_id": "549097354",
      "question_text": "Any failed attempts to QUIT?",
      "survey_id": "294219921",
      "question_type": "matrix",
      "created_at": "2020-11-21T14:10:49.437Z",
      "updated_at": "2020-11-21T14:10:49.437Z",
      row_id: 3622507292,
      rowText: [
        {
          text: `no`,
          id: 3622507293
        },
        {
          text: `1`,
          id: 3622507294
        },
        {
          text: `2`,
          id: 3622507295
        },
        {
          text: `3`,
          id: 3622507296
        },
        {
          text: `4-5`,
          id: 3622507297
        },
        {
          text: `6-9`,
          id: 3622507364
        },
        {
          text: `10 or more`,
          id: 3622507365
        }
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 160,
    "fields": {
      "question_id": "553825076",
      "question_text": "In the PAST TWO WEEKS, how often were you bothered by",
      "survey_id": "294219921",
      "question_type": "matrix",
      "created_at": "2020-11-21T14:10:53.831Z",
      "updated_at": "2020-11-21T14:10:53.831Z",
      choices: [
        { choice_text: "little interest or pleasure in doing things", row_id: "3652596770" },
        { choice_text: "feeling down, depressed or hopeless", row_id: "3652596771" },
        { choice_text: "suicidal (hurting self) thoughts or behaviors", row_id: "3652596772" },
        { choice_text: "homicidal (hurting others) thoughts or behaviors", row_id: "3652596773" }
      ],
      rowText: [
        { text: "not at all", id: "3652596774" },
        { text: "several days", id: "3652596775" },
        { text: "more than half the days", id: "3652596776" },
        { text: "nearly every day", id: "3652596777" },
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 166,
    "fields": {
      "question_id": "549097439",
      "question_text": "How much do you agree with the following statements?",
      "survey_id": "294219921",
      "question_type": "matrix",
      "created_at": "2020-11-21T14:10:57.847Z",
      "updated_at": "2020-11-21T14:10:57.848Z",
      choices: [
        { choice_text: "I eat healthy food", row_id: "3622508407" },
        { choice_text: "I don't exercise", row_id: "3622508408" },
        { choice_text: "I have meaning and purpose", row_id: "3622508424" },
        { choice_text: "I'm a poor listener / communicator", row_id: "3622508447" }, // 549097440
        { choice_text: "My income is stable and covers my expenses", row_id: "3622508422" },
        { choice_text: "I have a supportive community", row_id: "3622508451" },
        { choice_text: "I have a positive attitude / practice daily gratitude", row_id: "3622508426" },
        { choice_text: "I get less than 7 hours of sleep daily ", row_id: "3622508409" },
        { choice_text: "I live in a substance-free space (no nicotine or problem substances)", row_id: "3622508423" },
        { choice_text: "I have clear values / spirituality ", row_id: "3622508425" },
        { choice_text: "My living environment is unsafe", row_id: "3622508406" },
        { choice_text: "I face my fears and challenges", row_id: "3622508427" },
        { choice_text: "I have a supportive partner", row_id: "3622508449" },
      ],
      rowText: [
        { text: "Strongly Disagree", id: "3622508410" },
        { text: "Disagree", id: "3622508411" },
        { text: "Agree", id: "3622508412" },
        { text: "Strongly Agree", id: "3622508413" }
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 167,
    "fields": {
      "question_id": "549097440",
      "question_text": "How much do you agree with the following statements?",
      "survey_id": "294219921",
      "question_type": "matrix",
      "created_at": "2020-11-21T14:10:59.154Z",
      "updated_at": "2020-11-21T14:10:59.154Z",
      // containsOther: true,
      otherOPtionId: "3622508430",
      otherOptionText: `Other (please specify)`,
      choices: [
        { choice_text: "I have supportive family / friends", row_id: "3622508450" },
        { choice_text: "I don't have a trusted healthcare provider", row_id: "3622508452" },
        { choice_text: "I forgive myself and others", row_id: "3622508428" },
        { choice_text: "I'm unproductive, with very few achievements", row_id: "3622508446" },
        { choice_text: "I have a good sense of humor", row_id: "3622508429" },
        { choice_text: "I'm good at solving problems", row_id: "3622508448" },
        { choice_text: "I'm poor at managing stress", row_id: "3622508457" },
        { choice_text: "I am open and honest", row_id: "3622508442" },
        { choice_text: "I am kind and helpful to others ", row_id: "3622508443" },
        { choice_text: "I have very few interests or hobbies", row_id: "3622508444" },
        { choice_text: "I practice mindfulness / meditation", row_id: "3622508458" },
        { choice_text: "I have positive role model(s)", row_id: "3622508441" },
        { choice_text: "I know and use my strengths ", row_id: "3622508445" },
      ],
      rowText: [
        { text: "Strongly Disagree", id: "3622508453" },
        { text: "Disagree", id: "3622508454" },
        { text: "Agree", id: "3622508455" },
        { text: "Strongly Agree", id: "3622508456" }
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 167.4,
    "fields": {
      "question_id": "549097412",
      "question_text": "How much do you AGREE with this description of you?",
      "survey_id": "294219921",
      "question_type": "single_choice",
      "created_at": "2020-11-21T14:11:00.751Z",
      "updated_at": "2020-11-21T14:11:00.751Z",
      rowSize: 4,
      options: [
        {
          "text": "strongly disagree",
          "id": 3622508817
        },
        {
          "text": "disagree",
          "id": 3622508818
        },
        {
          "text": "agree",
          "id": 3622508819
        },
        {
          "text": "strongly agree",
          "id": 3622508820
        }
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 168,
    "fields": {
      "question_id": "549097351",
      "question_text": "How much am I currently ENGAGED in my HEALTH and WELL-BEING?",
      "survey_id": "294219921",
      "question_type": "single_choice",
      "created_at": "2020-11-21T14:11:00.751Z",
      "updated_at": "2020-11-21T14:11:00.751Z",
      row_id: "3622507385",
      rowSize: 2,
      options: [
        { text: " no need", id: "3622507386" },
        { text: " no interest", id: "3622507387" },
        { text: "feel pressured", id: "3622507388" },
        { text: "feel guilty", id: "3622507389" },
        { text: "afraid to try", id: "3622507390" },
        { text: "unsure", id: "3622507391" },
        { text: "tired of it all", id: "3622507392" },
        { text: "afraid NOT to try", id: "3622507393" },
        { text: "want my life back", id: "3622507394" },
        { text: "ready / engaged", id: "3622507395" },
        { text: "never more ready / engaged", id: "3622507396" }
      ]
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 169,
    "fields": {
      "question_id": "549097352",
      "question_text": "First 3 digits of ZIP / POSTAL Code",
      "survey_id": "294219921",
      "question_type": "open_ended",
      "created_at": "2020-11-21T14:11:01.066Z",
      "updated_at": "2020-11-21T14:11:01.066Z"
    }
  },
  {
    "model": "comprisapp.questions",
    "pk": 170,
    "fields": {
      "question_id": "549097388",
      "question_text": "Your INITIALS - 1st letter of your first, middle, and last name",
      "survey_id": "294219921",
      "question_type": "open_ended",
      "created_at": "2020-11-21T14:11:01.097Z",
      "updated_at": "2020-11-21T14:11:01.097Z"
    }
  }
]
