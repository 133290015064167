import React, { Component } from 'react';
import { connect } from 'react-redux';


export default function(ComposedComponent) {
    class Authentication extends Component {     
        componentDidMount() {
            var authToken = localStorage.getItem('usertoken')
            if(!authToken){
                this.props.history.push('/');
            }
        }
        render() {
            return <ComposedComponent {...this.props} />
        }
    }
    
    function mapStateToProps(state) {
        return { 
            ...state
        };
    }

   return connect(mapStateToProps)(Authentication);

}