import React, {  Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import routes from '../appLayout/nav'
import Header from '../appLayout/header'
import { withRouter } from 'react-router-dom' 
import Sidebar from '../appLayout/sidebar'
import BottomContent from '../components/onBoarding/bottomContent'
import BottomContent2 from '../components/onBoarding/bottoContent2'

function LayOut( {
    match,
    location : {
        pathname
    },
    ...props
}){
    return (
        <div className="app">
            <Header/>
            <div className="app-body">
            <Sidebar/>
            <main className="main">                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      
                <Suspense fallback={<div>Loading...</div>}>
                    <Switch>
                        {routes.map((route, idx) => {
                            return route.component ? (
                            <Route
                                key={idx}
                                path={route.path}
                                exact={route.exact}
                                name={route.name}
                                render={props => (
                                    <route.component {...props} />
                                )} />
                            
                            ) : (null);
                        })}
                    <Redirect from="/" to="/assesment" />
                    </Switch>
                </Suspense>
            </main>
            </div>
            {
                pathname == '/assessments' || pathname == '/shortAssessments' || pathname == '/users' ||  pathname == '/feedback' ||
                pathname == '/billingDetails' ||  pathname == '/referral' ? <BottomContent/> : <BottomContent2/>
            }
        </div>
    );
}

export default withRouter(LayOut);
