import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from './locales/en.json'
import translationESP from './locales/esp.json'

const resources = {
    en: {
      translation: translationEN
    },
    esp : {
        translation : translationESP
    }
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: "en",
    nsSeparator: false,
    keySeparator: false,  
    fallbackLng: 'en',
    debug: false,
    interpolation: {
      escapeValue: true,
    }
  });


export default i18n;
