import React, { useEffect, useState } from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Container, Row, Col, Button, Table } from 'reactstrap'
import { Formik } from 'formik'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import compris from '../../assests/images/001 Compris TM clr.png'
import { updateAnswers } from './constants'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next';
import fullTimeWork from '../../assests/images/full time work.png'
import partTimeWork from '../../assests/images/partTimeWork.png'
import unemployed from '../../assests/images/unemployed.png'
import retired from '../../assests/images/retired.png'
import others from '../../assests/images/others.png'

function Page4({
    questionContent,
    history,
    ...props
}) {

    const { t }  = useTranslation()

    const [error, setError] = useState(false)
    const [noneError, setNoneError] = useState(false)
    const [questionNumber, setQuestionNumber] = useState(0)
    
    useEffect(() => {
        if(questionContent.questions?questionContent.questions.findIndex(item => item.id == 549097381) != -1:false)
            setQuestionNumber(10)
        else
            setQuestionNumber(9)
    }, [questionContent])

    const nextPage = (values) => {
        if(values.insurance.length>0 && values.combinedIncome && values.work){
            setError(false)
            let questionContentCopy = JSON.parse(JSON.stringify(questionContent))
            updateAnswers(questionContentCopy, questionNumber+1, {
                id: 549097367,
                answers: values.insurance
            }, questionContentCopy.questions.findIndex(item => item.id == 549097367)!=-1?true:false)
            updateAnswers(questionContentCopy, questionNumber+3, {
                id: 549097399,
                answers: [{
                    choice_id: values.work,
                    row_id: 3622507825
                }]
            }, questionContentCopy.questions.findIndex(item => item.id == 549097399)!=-1?true:false)
            updateAnswers(questionContentCopy, questionNumber+2, {
                id: 549097432,
                answers: [
                    {
                        choice_id: values.combinedIncome,
                        row_id: 3622508322
                    }
                ]
            }, questionContentCopy.questions.findIndex(item => item.id == 549097432)!=-1?true:false)
            props.dispatch({
                type: `UPDATE_CONTENT`,
                payload: questionContentCopy
            })
            history.push(`/questions/553741432`)
        }
        else{
            setError(true)
        }
    }
    const prevPage = () => {
        if(questionContent.questions.find( item => item.id == 549097381))
            history.push(`/questions/549097381`)
        else
            history.push(`/questions/page3`)
    }

    const insuranceOnChange = (e, id, setField, values) => {
        if(e.target.checked){
            let insuranceCopy = [...values.insurance]
            if(id == 3622507499){
                insuranceCopy = [{
                    choice_id: id
                }]
            }
            else{
                insuranceCopy.push({choice_id: id})
                const noneIndex = insuranceCopy.findIndex(item => item.choice_id == 3622507499)
                if(noneIndex != -1)
                insuranceCopy.splice(noneIndex, 1)
            }
            setField('insurance', insuranceCopy, true)
        }
        else{
            const insuranceCopy = [...values.insurance]
            insuranceCopy.splice(insuranceCopy.findIndex(item => item.choice_id == id), 1)
            setField('insurance', insuranceCopy, true)
        }
    }

    return (
        <div>
            <Container>
            <Formik
                initialValues={{
                    insurance: questionContent.questions?questionContent.questions.findIndex(item => item.id == 549097367)!=-1?questionContent.questions[questionContent.questions.findIndex(item => item.id == 549097367)].answers:[]:[],
                    work: questionContent.questions?questionContent.questions.findIndex(item => item.id == 549097399)!=-1?questionContent.questions[questionContent.questions.findIndex(item => item.id == 549097399)].answers[0].choice_id:'':'',
                    combinedIncome: questionContent.questions?questionContent.questions.findIndex(item => item.id == 549097432)!=-1?questionContent.questions[questionContent.questions.findIndex(item => item.id == 549097432)].answers[0].choice_id:'':'',
                }}
                onSubmit={(values, actions) => {
                    nextPage(values)
                }}  
            >{({
                handleSubmit,
                values,
                resetForm,
                setFieldValue,
            }) => (
                    <form autoComplete="false" onSubmit={handleSubmit}>
                        <div className="bg-white container mb-3" style={{borderRadius: '2px'}}>
                            <div className="p-2">
                                <p>{questionNumber+1}. {t('Combined household income')}</p>
                                <RadioGroup row aria-label="position" name="income" onChange={e => setFieldValue('combinedIncome', e.target.value, true)}>
                                    <Table borderless>
                                        <thead style={{backgroundColor: '#f9f9f9', fontSize: '12px', lineHeight: '1.25'}}>
                                        <tr>
                                            <td align="center">{t('Under $20k')}</td>
                                            <td align="center">{t('$20k-$49k')}</td>
                                            <td align="center">{t('$50k-$99k')}</td>
                                            <td align="center">{t('$100k-$149k')}</td>
                                            <td align="center">{t('$150k-$249k')}</td>
                                            <td align="center">{t('$250k+')}</td>
                                            <td align="center">{t('Prefer not to answer')}</td>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td align="center">
                                                    <Radio color="primary" value='3622508318' checked={values.combinedIncome == 3622508318?true:false} />
                                                </td>
                                                <td align="center">
                                                    <Radio color="primary" value='3622508319' checked={values.combinedIncome == 3622508319?true:false} />
                                                </td>
                                                <td align="center">
                                                    <Radio color="primary" value='3622508320' checked={values.combinedIncome == 3622508320?true:false} />
                                                </td>
                                                <td align="center">
                                                    <Radio color="primary" value='3622508321' checked={values.combinedIncome == 3622508321?true:false} />
                                                </td>
                                                <td align="center">
                                                    <Radio color="primary" value='3622508323' checked={values.combinedIncome == 3622508323?true:false} />
                                                </td>
                                                <td align="center">
                                                    <Radio color="primary" value='3622508324' checked={values.combinedIncome == 3622508324?true:false} />
                                                </td>
                                                <td align="center">
                                                    <Radio color="primary" value='3622508325' checked={values.combinedIncome == 3622508325?true:false} />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </RadioGroup>
                            </div>
                        </div>
                        <div className="bg-white container mb-3" style={{borderRadius: '2px'}}>
                        <div className='p-2'>
                                <p>{questionNumber+2}. {t('INSURANCE: (check ALL that apply)')}</p>
                                <Row>
                                    <Col>
                                        <FormControlLabel
                                            control={<Checkbox color="primary" name="state" onChange={e => insuranceOnChange(e, 3622507494, setFieldValue, values)} checked={values.insurance.find(item => item.choice_id == 3622507494)?true:false} />}
                                            label={t('state / managed medicaid, medi-cal, etc.')}
                                        />
                                    </Col>
                                    <Col>
                                        <FormControlLabel
                                            control={<Checkbox color="primary" name="VA" onChange={e => insuranceOnChange(e, 3622507498, setFieldValue, values)} checked={values.insurance.find(item => item.choice_id == 3622507498)?true:false} />}
                                            label={t('VA')}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormControlLabel
                                            control={<Checkbox color="primary" name="federal" onChange={e => insuranceOnChange(e, 3622507495, setFieldValue, values)} checked={values.insurance.find(item => item.choice_id == 3622507495)?true:false} />}
                                            label={t('federal / medicare or managed medicare')}
                                        />
                                    </Col>
                                    <Col>
                                        <FormControlLabel
                                            control={<Checkbox color="primary" name="disability" onChange={e => insuranceOnChange(e, 3622507508, setFieldValue, values)} checked={values.insurance.find(item => item.choice_id == 3622507508)?true:false} />}
                                            label={t('permanent disability')}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormControlLabel
                                            control={<Checkbox color="primary" name="employer" onChange={e => insuranceOnChange(e, 3622507496, setFieldValue, values)} checked={values.insurance.find(item => item.choice_id == 3622507496)?true:false} />}
                                            label={t('employer')}
                                        />
                                    </Col>
                                    <Col>
                                        <FormControlLabel
                                            control={<Checkbox color="primary" name="none" onChange={e => insuranceOnChange(e, 3622507499, setFieldValue, values)} checked={values.insurance.find(item => item.choice_id == 3622507499)?true:false} />}
                                            label={t('none')}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormControlLabel
                                            control={<Checkbox color="primary" name="private" onChange={e => insuranceOnChange(e, 3622507497, setFieldValue, values)} checked={values.insurance.find(item => item.choice_id == 3622507497)?true:false} />}
                                            label={t('private')}
                                        />
                                    </Col>
                                </Row>
                                {noneError && <p style={{color: 'red'}}>{`${t('Unselect none to select other options')}`}<br /></p>}
                            </div>
                        </div>
                        <div className="bg-white container mb-3" style={{borderRadius: '2px'}}>
                            <div className="p-2">
                                <p>{questionNumber+3}. {t('Current WORK:')}</p>
                                <Row style={{padding: '0 15px'}} className='pb-2 gender-wrapper'>
                                    <Col style={{border: 'solid 1px #b9b9b9', borderRadius: '5px',marginRight: '10px', justifyContent: 'center', alignItems: 'center', cursor: 'pointer'}} className={values.work == '3622507824'?'border-active':''} onClick={()=>setFieldValue('work', 3622507824, true)} >
                                        <span className="image-Ui"><img src={fullTimeWork}/></span>
                                        <div>{t('full time')}</div>
                                    </Col>
                                    <Col style={{border: 'solid 1px #b9b9b9', borderRadius: '5px',marginRight: '10px', justifyContent: 'center', alignItems: 'center', cursor: 'pointer'}} className={values.work == '3622507826'?'border-active':''} onClick={()=>setFieldValue('work', 3622507826, true)} >
                                        <span className="image-Ui"><img src={partTimeWork}/></span>
                                        <div>{t('part time')}</div>
                                    </Col>

                                    <Col style={{border: 'solid 1px #b9b9b9', borderRadius: '5px',marginRight: '10px', justifyContent: 'center', alignItems: 'center', cursor: 'pointer'}} className={values.work == '3622507819'?'border-active':''} onClick={()=>setFieldValue('work', 3622507819, true)} >
                                        <span className="image-Ui"><img src={unemployed}/></span>
                                        <div>{t('not working')}</div>
                                    </Col>
                                    
                                    <Col style={{border: 'solid 1px #b9b9b9', borderRadius: '5px',marginRight: '10px', justifyContent: 'center', alignItems: 'center', cursor: 'pointer'}} className={values.work == '3622507823'?'border-active':''} onClick={()=>setFieldValue('work', 3622507823, true)} >
                                        <span className="image-Ui"><img src={retired}/></span>
                                        <div>{t('retired')}</div>
                                    </Col>
                                    <Col style={{border: 'solid 1px #b9b9b9', borderRadius: '5px',marginRight: '10px', justifyContent: 'center', alignItems: 'center', cursor: 'pointer'}} className={values.work == '3622507820'?'border-active':''} onClick={()=>setFieldValue('work', 3622507820, true)} >
                                        <span className="image-Ui"><img src={others}/></span>
                                        <div>{t('school/raising family/care-giving')}</div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div className="bg-white container mb-3" style={{borderRadius: '2px'}}>
                            {error && <p style={{color: 'red',padding:'10px 0'}}>{t('Please answer all the questions')}<br /></p>}
                            <div className='px-2 py-3' style={{display: 'flex', justifyContent: 'space-between'}}>
                                <p style={{fontSize: '12px', lineHeight: '1.17', color: '#afafaf',marginBottom:'0',paddingTop:'10px'}}>{t('Powered by')} <a href="https://www.compriscare.com/" target="_blank"><img src={process.env.LOGO_URI} style={{marginLeft: '10px'}} className="footer-img-logo" /></a></p>
                                <div className="button-footer-wrapper">
                                    <Button style={{backgroundColor: 'transparent', color: '#5e6265', border: 'none'}} onClick={prevPage} type='button' >{t('Previous')}</Button>
                                    <Button style = {{marginLeft: '10px', backgroundColor: '#055ce5', color: 'white', borderRadius: '2px', border: 'none'}} >{t('Next')}</Button>
                                </div>
                            </div>
                        </div>
                    </form>
                    )
            }
            </Formik>
            </Container>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        questionContent : state.question.content,
    }
}

export default connect(mapStateToProps)(Page4)
