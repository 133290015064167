import {OPEN_TYPE, FETCH_ASSESMENT_LIST , CLEAR_REDUCER, ASSESMENT_FILTER,SHORT_ASSESMENT_FILTER} from '../type'

const initialState = {
    assesmentDetailStatus : false,
    assesmentList : {},
    assesmentFilter : {
        page : '',
        per_page_items : '',
        search : '',
        tz_value : Intl.DateTimeFormat().resolvedOptions().timeZone,
        status : '',
        clinician_name : '',
        is_public : false,
        clinic_name : '',
        category_type : '',
        corporate_name : '',
        clinic_provider_id :'',
        clinic_id : '',
        created_date : '',
        used_date : '',
        sort_by : '',
        order : ''
    },
    shortAssesmentFilter : {
        page : '',
        per_page_items : '',
        search : '',
        tz_value : Intl.DateTimeFormat().resolvedOptions().timeZone,
        status : '',
        clinician_name : '',
        is_public : false,
        clinic_name : '',
        category_type : '',
        corporate_name : '',
        clinic_provider_id :'',
        clinic_id : '',
        created_date : '',
        used_date : '',
        sort_by : '',
        order : ''
    }
}

const assesmentReducer = (state = initialState,action) => {

    switch(action.type){
        case OPEN_TYPE : 
            return { 
                ...state,
                assesmentDetailStatus : action.payload,
            }
        case FETCH_ASSESMENT_LIST : 
            return { 
                ...state,
                assesmentList : action.payload,
            }
        case CLEAR_REDUCER : 
            return {
                assesmentFilter : {
                    page : '',
                    per_page_items : '',
                    search : '',
                    status : '',
                    clinician_name : '',
                    clinic_name : '',
                    clinic_provider_id :'',
                    clinic_id : '',
                    created_date : '',
                    used_date : '',
                    sort_by : '',
                    order : ''
                }
            }
        case ASSESMENT_FILTER :
            return { 
                ...state,
                assesmentFilter : action.payload,
            }
        case SHORT_ASSESMENT_FILTER :
            return { 
                ...state,
                shortAssesmentFilter : action.payload,
            }
        default : 
        return state
    }
}

export { 
    assesmentReducer
}