import { AppContainer } from 'react-hot-loader';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import 'bootstrap/dist/css/bootstrap.min.css';
import custom from './styles/_custom.scss'
import { Provider } from "react-redux";
import { createBrowserHistory } from 'history';
import store from './redux/store'
import './i18n'
//import store , { browserHistory  } from '../store';


const appHistory = createBrowserHistory()

const render = Component =>
  ReactDOM.render(
      <AppContainer>
          <Provider store={store} >
            <Component history={appHistory} />
          </Provider>
      </AppContainer>,
    document.getElementById('root')
);



if ('serviceWorker' in navigator) {
    // window.addEventListener('load', () => {
    //   navigator.serviceWorker.register('/service-worker.js').then(registration => {
    //     
    //   }).catch(registrationError => {
    //     
    //   });
    // });
 }

render(App);

// Webpack Hot Module Replacement API
if (module.hot) module.hot.accept('./app', () => render(App));