import React, { useState, useEffect, memo, Fragment  } from 'react'
import CustomDropdown from '../helperComponents/dropdown'
import service from '../interceptor'
import { useTranslation } from 'react-i18next';


function Notification(){

    const [state,setState] = useState(false)



    const [notifications,updateNotification] = useState([])
    

    const markAsRead =  () => {
        service.put('/notifications').then( (res) => {
            setState(false)
        })
    }


    const clearNotification = () => {
        service.delete('/notifications').then( (res) => {
            updateNotification([])
        })
    }

    useEffect( () => {
        service.get('/notifications').then( (res) => {
            updateNotification(res.content.notifications)
        })
    }, [])


    const { t, i18n } = useTranslation();


    return (
        <div>
            <CustomDropdown className={`${notifications.length > 0 ? "active" : "noactive"}`} header={t('Notifications')} state={state} setState={setState}>
                <div className="notification-block-wrapper">
                    <ul>
                        {
                            notifications && notifications.length > 0 ? 
                            notifications.map( (item,index) => {
                                    return <div data-read={item.read ? "readed" : "not-readed"} className="notification-block">
                                                <h4>{t(item.title)}</h4>
                                                <li>{<span>{t(item.message)}</span>}</li>
                                            </div>
                            }) : <p className="no-notification">{t('No New Notification')}</p>
                        }
                    </ul>
                    <div className="controls">
                        <ul>
                            <li>
                                <a href="#" onClick={ () => markAsRead() }>{t('Mark all as read')}</a>
                            </li>
                            <li>
                                <a href="#" onClick={ () => clearNotification()  }>{`${t('Clear notification')}`}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </CustomDropdown>
        </div>
    )
}

export default Notification