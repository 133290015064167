import React from "react";


// AuthGuard

import Authentication from '../utils/authGuard'

const Assesment = React.lazy(() => import("../components/assesment/assesment"));

const ShortAssesment = React.lazy(() => import("../components/shortAssessment/assesment"));


const Users = React.lazy( () => import('../components/users/user') )
const BillingDetails = React.lazy( () => import('../components/billingDetails/billingDetail'))
const Membership = React.lazy( () => import('../components/membership/membership') )
const Security = React.lazy( () => import('../components/security/security') )
const ClinicProfile = React.lazy( () =>  import('../components/clinicProfile/clinicProfile') )
const FAQS = React.lazy( () => import('../components/faq/faqs'))

const clinicMaster = React.lazy( () => import('../components/clinicMaster/clinicMaster') )
const clinicView  = React.lazy( () => import('../components/clinicMaster/clinincView') )

const Feedback = React.lazy( () => import('../components/feedback/feedback'))
const Notification = React.lazy( () =>  import('../components/notifications/notification') )

const memberList = React.lazy( () => import('../components/members/memberList'))

const MemberShipView = React.lazy( () => import('../components/membership/memberShipView') )

const Referral = React.lazy( () => import('../components/referal/referal') )

const Corplinks = React.lazy( () => import('../components/corporateLinks/corplink') )



const routes = [
    {
        path: "/assessments",
        exact: true,
        name: "Provider Assessments",
        component: Authentication(Assesment)
    },
    {
        path: "/shortAssessments",
        exact: true,
        name: "Unlinked Assessments",
        component: Authentication(ShortAssesment)
    },
    {
        path: "/corpLinks",
        exact: true,
        name: "Corplinks",
        component: Authentication(Corplinks)
    },
    {
        path : "/memberList/:_id",
        exact: true,
        name: "Members List",
        component: Authentication(memberList)
    },
    {
        path: "/feedback",
        exact: true,
        name: "Feedbacks",
        component: Authentication(Feedback)
    },
    {
        path: "/reports",
        exact: true,
        name: "Reports",
        component: Authentication(Assesment)
    },
    {
        path: "/billingDetails",
        exact: true,
        name: "Billing Details",
        component: Authentication(BillingDetails)
    },
    {
        path: "/membership",
        exact: true,
        name: "Membership",
        component: Authentication(Membership)
    },
    {
        path: "/membership/:_id/:_price/:_currentPlan/:_isUnlimited",
        exact: true,
        name: "Membership",
        component: Authentication(Membership)
    },
    {
        path: "/users",
        exact: true,
        name: "Users",
        component: Authentication(Users)
    },
    {
        path: "/security",
        exact: true,
        name: "Security",
        component: Authentication(Security)
    },
    {
        path: "/notification",
        exact: true,
        name: "Notification",
        component: Authentication(Notification)
    },
    {
        path: "/clinicProfile",
        exact: true,
        name: "Clinic Profile",
        component: Authentication(ClinicProfile)
    },
    {
        path: "/faqs",
        exact: true,
        name: "FAQ'S",
        component: Authentication(FAQS)
    },
    {
        path: "/clinicMaster",
        exact: true,
        name: "Clinic Master",
        component: Authentication(clinicMaster)
    },
    {
        path: "/clinicView/:_id",
        exact: true,
        name: "Clinic View",
        component: Authentication(clinicView)
    },
    {
        path: "/memberShipView",
        exact: true,
        name: "Membership View",
        component: Authentication(MemberShipView)
    },
    {
        path: "/referral",
        exact: true,
        name: "Referaal",
        component: Authentication(Referral)
    }
];

export default routes;
