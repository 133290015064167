import {PROFILE, INDEX_CHANGE } from '../type'

const initialState = {
    profileDetails : {},
    index : ''
}

const onboardingReducer = (state = initialState,action) => {
        switch(action.type){
            case PROFILE : 
            return { 
                ...state,
                profileDetails : action.payload,
            }
            case INDEX_CHANGE :
                return {
                    ...state,
                    index : action.payload,
                }
            default : 
            return state
        }
}

export { 
    onboardingReducer
}