import React , {useState } from 'react'
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { medicationOptionsList } from './constants'

function Medication({
    answers,
    setAnswers,
    listToShow,
    setListToShow
}) {
    const [medicationsAdded, setMedicationsAdded] = useState(listToShow.length-1)
    const { t } = useTranslation();

    const addMedication = () => {
        setListToShow([...listToShow, medicationOptionsList[medicationsAdded + 1]])
        setMedicationsAdded(medicationsAdded+1)
    }

    const updateMedication = ({id, text}, e) => {
        setAnswers({...answers, [id]: e.target.value})
    }

    const deleteMedication = () => {
        const lastItemInList = listToShow.slice(-1)
        const answersCopy = {...answers}
        lastItemInList && lastItemInList[0] && lastItemInList[0].forEach((item) => {
            delete answersCopy[item.id]
        })
        listToShow.splice(-1)
        setAnswers(answersCopy)
        setListToShow([...listToShow])
        setMedicationsAdded(medicationsAdded-1)
    }

    return (
        <div className="p-2">
            <div className="bg-white container px-3 py-3 mb-3" style={{ borderRadius: '.125rem' }}>
                {listToShow.map(list => list.map(options => (
                    <div className="spineer-input medication-container" style={{ display: "flex", alignItems: 'end', paddingBottom: '2%' }}>
                        <div style={{ marginLeft: '.625rem', marginRight: '1rem' }}>{options.text}</div>
                        <TextField style={{ width: '7.5rem' }} type="string" placeholder={`${t(options.text)}`} onChange={e => updateMedication(options, e)} value={answers[options.id]} />
                    </div>
                )))}
                <Button className='add-button' variant="text" disabled={medicationsAdded>=4} onClick={addMedication} style={{}}>Add another medication</Button>
                <Button className='add-button' variant="text" disabled={medicationsAdded==0} onClick={deleteMedication} style={{}}>Delete medication</Button>
            </div>
        </div>
    )
}


export default Medication
