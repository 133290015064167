import React from 'react'
import TextField from '@material-ui/core/TextField';

const GlobalMaterialInputComponent = ({
  field,
  form: { touched, errors },
  ...props
}) => {


   return ( <div>
        <div className="form-group">
            <TextField  {...field} {...props} />
        </div>
        <div>
            {touched[field.name] && errors[field.name] && <div className="error">{t(errors[field.name])}</div>}
        </div>
    </div>
   )
};

export default GlobalMaterialInputComponent